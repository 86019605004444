import React, { useContext, useState, useEffect, useRef } from 'react'

import { Tooltip } from 'antd'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { objectDeepCopy } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBellSlash } from '@fortawesome/free-regular-svg-icons'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import { useDispatch } from 'react-redux'
import { APP_STATES } from '../../reducers/AppStates/actionsType'
import useOuterClick from '../institution/Quotas/hooks/useOuterClick'
import { GlobalFiltersContext } from '../../Providers/GlobalFiltersProvider'

import '../../assets/chat-widget.scss'

const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED'
const MESSAGE_SENT = 'MESSAGE_SENT'

const ChatWidget = props => {
  const { setInternshipSearch } = useContext(GlobalFiltersContext)

  const [messages, setMessages] = useState([])
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)
  const innerRef = useOuterClick(() => {
    if (!props.getPreventClosing) {
      props.setInternshipForChat(null)
    }
  })

  const messagesContainer = useRef()
  const messageInput = useRef()
  const isFirstRun = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    savePropsToState(props)
  }, [])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    savePropsToState(props)
  }, [props.messages])

  async function savePropsToState (props) {
    let messages = objectDeepCopy(props.messages)
    const offset = moment().utcOffset()

    messages = messages.map(m => {
      m.timestamp = moment.utc(m.timestamp.date).add(offset, 'm')
      return m
    })
    messages = messages.sort((m1, m2) =>
      m1.timestamp.isAfter(m2.timestamp) ? 1 : -1
    )

    setMessages(messages)

    const receivedMessages = messages.filter(item => item.from.username !== props.getUser.username)
    if (receivedMessages.length > 0) {
      setHasUnreadMessages(true)
    }

    scrollToBottom()
  }

  function scrollToBottom () {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight
    }
  }

  function emptyInput () {
    messageInput.current.value = ''
  }

  function handleKeyUp (e) {
    if (e.key === 'Enter') {
      props.sendMessage(e.target.value)
      emptyInput()
    }
  }

  function renderMessages () {
    const renderedMessages = messages
    let lastTimestamp = null

    return renderedMessages.map(m => {
      let timestampNode = null
      if (
        lastTimestamp === null ||
        !lastTimestamp
          .clone()
          .add(30, 'minutes')
          .isSameOrAfter(m.timestamp)
      ) {
        let format = 'DD/MM/YYYY - HH:mm'
        if (
          m.timestamp
            .clone()
            .add(1, 'week')
            .isSameOrAfter(moment())
        ) { format = 'dddd - HH:mm' }
        if (
          m.timestamp
            .clone()
            .add(1, 'day')
            .isSameOrAfter(moment()) &&
          m.timestamp.format('DD') === moment().format('DD')
        ) { format = 'HH:mm' }

        timestampNode = (
          <div className='timestamp'>{m.timestamp.format(format)}</div>
        )
      }
      lastTimestamp = m.timestamp.clone()

      return (
        <div
          key={m.id}
          className={
            'message ' + (m.origin === MESSAGE_RECEIVED ? 'received' : 'sent')
          }
        >
          {timestampNode}
          <div className='text-bubble'>
            <div className='author-username'>{m.from.username}</div>
            <div className='text'>{m.message}</div>
          </div>
        </div>
      )
    })
  };

  function handleMarkAsUnread () {
    let receivedMessages = messages.filter(item => item.from.username !== props.getUser.username)
    if (receivedMessages.length > 0) {
      receivedMessages = orderBy(receivedMessages, ['id'], ['desc'])
      props.markMessageUnread(receivedMessages[0].id, props.getUser)
      if (props.onUnread) {
        props.onUnread()
      }
    }
  }

  function handleCalendarChange () {
    let date = moment().format('YYYY-MM-DD')

    if (props.startDate.includes('T')) {
      const dateParts = props.startDate.split('T')
      date = moment(dateParts[0])
    } else if (props.startDate.includes('/')) {
      const dateParts = props.startDate.split('/')
      date = moment(`20${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)
    }

    setInternshipSearch(props.title)
    dispatch({
      type: APP_STATES.SET_DATE,
      payload: date.startOf('isoWeek').hours(0).minutes(0).seconds(0)
    })
  }

  return (
    <div
      className={`chat-widget flex-column ${props.getMessagesListIsDisplayed ? 'messages-displayed' : ''}`}
      ref={innerRef}
    >
      <div className='top-bar flex-column'>
        <div className='flex-row'>
          <div className='contact flex-fill'>{props.title}</div>
          <FontAwesomeIcon
            icon='times'
            className='close-button'
            onClick={props.onClose}
          />
        </div>
        <div className='subject'>{props.subtitle}</div>
        <div className='action-buttons'>
          {hasUnreadMessages
            ? (
              <Tooltip placement='left' title={props.t('Mark as unread')} overlayStyle={{ zIndex: 999999999999999 }}>
                <button disabled={props.messageWasMarkedAsUnread} onClick={handleMarkAsUnread}>
                  <FontAwesomeIcon icon={faBellSlash} />
                </button>
              </Tooltip>
            )
            : ''}
          <Tooltip placement='right' title={props.t('Go to internship date')} overlayStyle={{ zIndex: 999999999999999 }}>
            <button type='button' onClick={handleCalendarChange}>
              <FontAwesomeIcon icon='calendar' />
            </button>
          </Tooltip>
        </div>

      </div>
      <div
        className='messages-container flex-fill flex-column'
        ref={messagesContainer}
      >
        {renderMessages()}
        {props.loading ? (
          <div
            style={{
              textAlign: 'center',
              color: '#9E9E9E',
              padding: '5px 10px 15px 10px'
            }}
          >
            {props.t('Loading')}
          </div>
        ) : null}
      </div>
      <div className='input-container'>
        <input
          placeholder={props.t('Write message and send with ENTER')}
          onKeyUp={handleKeyUp}
          ref={messageInput}
        />
      </div>
    </div>
  )
}

export { MESSAGE_RECEIVED, MESSAGE_SENT }
export default connect(mapStateToProps, mapDispatchToProps)(ChatWidget)
