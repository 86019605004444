export const getFullName = specialityYear => {
  if (specialityYear) {
    return `${specialityYear.specialityName} (${specialityYear.studyYearName})`
  }

  return 'Without year'
}

export const getFullAbbreviation = specialityYear => {
  if (specialityYear) {
    return `${specialityYear.specialityAbbreviation} (${specialityYear.studyYearName})`
  }

  return 'Without speciality'
}
