import React from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import TableFooter from './TableFooter'
import LoadingDiv from './LoadingDiv'

import './assets/simple-data-table.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const SimpleDataTable = ({ columns, data, loading, metaData, onPageChange, t }) => {
  const getValueByKey = (d, key, translate) => {
    if (typeof key === 'string') {
      return translate ? t(d[key]) : d[key]
    }

    if (typeof key === 'function') {
      return translate ? t(key(d)) : key(d)
    }

    return translate ? t('Invalid key type') : 'Invalid key type'
  }

  return (
    <div className='simple-data-table-container'>
      <div className='table-container'>
        <table className='simple-data-table'>
          <thead>
            <tr>
              {columns.map((c, index) => {
                return (
                  <th key={'col-' + index} style={c.style ?? {}}>
                    {t(c.title)}
                  </th>
                )
              })}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {data.map((d, rindex) => {
                return (
                  <tr key={'row-' + rindex}>
                    {columns.map((c, cindex) => {
                      return (
                        <td key={`cell-${rindex}.${cindex}`} style={c.style ?? {}}>
                          {getValueByKey(d, c.key, c.translate)}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          )}
        </table>
      </div>
      {!loading && (
        <TableFooter
          className='simple-data-table-footer'
          metaData={metaData}
          onPageChange={onPageChange}
        />
      )}
      {loading && <LoadingDiv />}
    </div>
  )
}

export default connect(mapStateToProps)(SimpleDataTable)
