import { Select } from 'antd'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getFullAbbreviation } from '../../../utils/entities/schoolSpeciality'
import { callFunctionIfDefined } from '../../../utils/functionHelper'

const { Option } = Select

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const AssistantsSelector = ({
  disabled, assistants, schoolSpecialities, value, onBodyRendered, onSearch, onSelect, t
}) => {
  const renderSpecialitiesOptions = () => {
    const options = [
      <Option
        key='empty'
        value={0}
        data={{ assistant: null, schoolSpeciality: null }}
        filter='/'
      >
        {t('Without assistant')}
      </Option>
    ]

    if (schoolSpecialities) {
      schoolSpecialities.forEach((ss, index) => {
        const specialityString = `${t(getFullAbbreviation(ss))} - ${t('Without assistant')}`

        options.push(
          <Option
            key={`-${ss.id}`}
            value={-ss.id}
            data={{ schoolSpeciality: ss, assistant: null }}
            filter={specialityString}
          >
            {specialityString}
          </Option>
        )
      })
    }

    return options
  }

  const renderAssistantsOptions = () => {
    const options = []

    if (assistants) {
      assistants.forEach((s, index) => {
        const fullName = `${s.lastname} ${s.firstname}`
        const specialityString = t(getFullAbbreviation(s.schoolSpeciality))

        options.push(
          <Option
            key={`-${s.id}`}
            value={s.id}
            data={{ schoolSpeciality: null, assistant: s }}
            filter={`${specialityString} - ${fullName}`}
          >
            {specialityString} - <b>{fullName}</b>
          </Option>
        )
      })
    }

    return options
  }

  const handleSelect = (value, option) => {
    callFunctionIfDefined(onSelect, [value, option.props.data])
  }

  return (
    <Select
      disabled={disabled}
      filterOption={onSearch}
      onSelect={handleSelect}
      showArrow={false}
      showSearch
      className='narrow'
      value={value}
    >
      {renderSpecialitiesOptions()}
      {renderAssistantsOptions()}
    </Select>
  )
}

export default connect(mapStateToProps)(AssistantsSelector)
