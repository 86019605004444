import React, { useContext } from 'react'
import { GlobalContext } from '../Providers/GlobalProvider'

const ContextAwareInterface = ({ children }) => {
  const { environment } = useContext(GlobalContext)

  return React.cloneElement(children, { environment })
}

export default ContextAwareInterface
