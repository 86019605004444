import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getInstitutionSectors } from '../../../utils/api/institution'
import { callFunctionIfDefined } from '../../../utils/functionHelper'
import Selector from './Selector'
import { sortByString } from '../../../utils/arrayUtils'

import '../../../assets/internships-manager.scss'

const INSTITUTIONS_STYLE = { width: '275px' }
const SECTOR_STYLE = { width: '175px' }

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const InstitutionsAndSectorsSelector = ({ disabled, institutions, internship, user, onSelect }) => {
  const [sectors, setSectors] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (internship.institution) {
      setLoading(true)

      getInstitutionSectors(internship.institution, user).then(json => {
        if (json && json.data) {
          setSectors(sortByString(json.data, 'name'))
        }

        setLoading(false)
      })
    } else {
      setSectors([])
    }
  }, [internship.institution?.id])

  const handleInstitutionSelected = (value, data) => {
    callFunctionIfDefined(onSelect, [{ institution: data, sector: null }])
  }

  const handleSectorSelected = (value, data) => {
    callFunctionIfDefined(onSelect, [{ sector: data }])
  }

  return (
    <div className='flex-row'>
      <Selector
        data={institutions ?? []}
        dataKey='name'
        disabled={disabled}
        onSelect={handleInstitutionSelected}
        showArrow={false}
        showEmpty
        showSearch
        style={INSTITUTIONS_STYLE}
        value={internship.institution ? internship.institution.id : -1}
      />
      {!loading && (
        <Selector
          data={sectors}
          dataKey='name'
          disabled={disabled || !sectors.length}
          onSelect={handleSectorSelected}
          showArrow={false}
          showEmpty
          showSearch
          style={SECTOR_STYLE}
          value={!internship.sector || internship.sector.parent === null ? -1 : internship.sector.id}
        />
      )}
      {loading && (
        <div className='flex-center'>
          <div className='loading-icon black' />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionsAndSectorsSelector)
