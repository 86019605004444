import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

/**
 * @deprecated use fetchCountries instead
 */
export const getCountries = user => {
  return requestWithPromise(
    '/api/Country/simple/0/500',
    GET,
    null,
    user
  )
}

export const fetchCountries = user => {
  return requestWithPromise(
    '/api/countries',
    GET,
    null,
    user
  )
}
