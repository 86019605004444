import { TableSwitch } from '../Components/shared/Tables/Cells/TableSwitch'
import { TableInput } from '../Components/shared/Tables/Cells/TableInput'
import SectionsSelect from '../Components/shared/Tables/Cells/SectionsSelect'
import SchoolYearsSelect from '../Components/shared/Tables/Cells/SchoolYearsSelect'
import ChangeSectionStudent from '../Components/shared/TutorialComponents/ChangeSectionStudent'
import StudentPropertiesSelect from '../Components/institution/Constraints/StudentPropertiesSelect'
import StandardSwitch from '../Components/institution/Constraints/StandardSwitch'
import SpecialitySelect from '../Components/shared/Tables/Cells/SpecialitySelect'
import StudyYearSelect from '../Components/shared/Tables/Cells/StudyYearSelect'

const getTableComponent = componentName => {
  const componentsList = {
    Switch: TableSwitch,
    StandardSwitch: StandardSwitch,
    Input: TableInput,
    SectionsSelect: SectionsSelect,
    SchoolYearsSelect: SchoolYearsSelect,
    ChangeSectionStudent: ChangeSectionStudent,
    StudentPropertiesSelect: StudentPropertiesSelect,
    SpecialitySelect: SpecialitySelect,
    StudyYearSelect: StudyYearSelect
  }

  return componentsList[componentName]
}

const makeDisablingLineCondition = (key, condition) => {
  return { key, condition }
}

const getDisablingCondition = conditionName => {
  const conditions = {
    isNull: (data, key) => {
      return data[key] === null
    }
  }

  return conditions[conditionName]
}

const disableLineIf = (data, disablingLineCondition) => {
  if (!(data && disablingLineCondition)) {
    return false
  }

  const { key, condition } = disablingLineCondition
  const applyCondition = getDisablingCondition(condition)

  return applyCondition ? applyCondition(data, key) : false
}

const makeTableGetter = callback => {
  return data => callback(data)
}

export {
  getTableComponent,
  makeDisablingLineCondition,
  getDisablingCondition,
  disableLineIf,
  makeTableGetter
}
