import React, { useContext, useEffect, useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import frLocale from '@fullcalendar/core/locales/fr'
import nlLocale from '@fullcalendar/core/locales/nl'
import { requestWithPromise } from '../../../utils'
import moment from 'moment'
import { internshipStates, SCHEDULE_ADDED } from '../../../utils/constants'
import '../../../assets/student-desiderata.scss'
import { Tooltip } from 'antd'
import { formatDate } from '../../../utils/utilsDesiderata'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getMaccsInternshipShifts } from '../../../utils/api/maccsInternship'
import MaccsDesiderataUpdateModal from './MaccsDesiderataUpdateModal'
import MaccsDesiderataCreationModal from './MaccsDesiderataCreationModal'
import { MaccsFullCalendarEvent } from '../../../utils/entities/external/maccsFullCalendarEvent'
import CalendarHeader from './CalendarHeader'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const BREAK_POINT = 620

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsShiftsCalendar = ({ activeLanguage, internship, user, onClose, onInternshipUpdate, t }) => {
  const { eventCodes } = useContext(GlobalContext)

  const initialDate = formatDate(internship.startDate)
  const internshipToEvent = {
    title: t('Internship'),
    start: initialDate,
    end: formatDate(internship.endDate),
    display: 'background'
  }

  const [shifts, setShifts] = useState([])
  const [loading, setLoading] = useState(false)
  const [creationModalVisible, setCreationModalVisible] = useState(false)
  const [areDatesSelected, setAreDatesSelected] = useState(false)
  const [selectedDates, setSelectedDates] = useState('')
  const [selectedShift, setSelectedShift] = useState()
  const [events, setEvents] = useState([internshipToEvent])
  const [isUpdatable, setIsUpdatable] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  let locale
  switch (activeLanguage) {
    case 'fr':
      locale = frLocale
      break
    case 'nl':
      locale = nlLocale
      break
    default:
      locale = null
  }

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (internship && user) {
      setLoading(true)

      getMaccsInternshipShifts(internship, user).then(shifts => {
        setShifts(shifts)
        setLoading(false)
      })
    }
  }, [internship, user])

  useEffect(() => {
    if (internship) {
      setIsUpdatable((
        internship.state === internshipStates.ACCEPTED ||
        internship.state === internshipStates.ACCEPTED_UNMANAGED ||
        internship.state === internshipStates.SCHEDULE_ADDED
      ))
    }
  }, [internship])

  useEffect(() => {
    const events = [{ ...internshipToEvent }]

    shifts.forEach(s => events.push(new MaccsFullCalendarEvent(s, eventCodes)))

    setEvents(events)
  }, [shifts, eventCodes])

  const refreshShifts = () => {
    setLoading(true)

    getMaccsInternshipShifts(internship, user).then(shifts => {
      setShifts(shifts)
      setLoading(false)
    })
  }

  const onDateClick = dates => {
    setAreDatesSelected(true)
    setSelectedDates(dates)
  }

  const onUnselect = () => {
    if (width < BREAK_POINT) {
      setAreDatesSelected(false)
      setSelectedDates('')
    }
  }

  const onEventClick = eventInfo => {
    // workaround fullcalendar bug : when short press on background event, nothing should happen
    if (eventInfo.event.display === 'background') {
      return
    }

    setSelectedDates(eventInfo.event.startStr)

    requestWithPromise('/shift/' + eventInfo.event.id, 'GET', null, user)
      .then(({ data }) => {
        const shift = {
          id: data.id,
          start: data.startDate.date,
          end: data.endDate.date,
          pause: data.pause,
          eventCodeType: data.eventCodeType,
          deleted: data.deleted
        }

        setSelectedShift(shift)
      })
  }

  const handleUpdateModalClose = (shift, deleted) => {
    if (deleted && shift) {
      const newShifts = shifts.filter(s => s.id !== shift.id)

      if (newShifts.length === 0) {
        onInternshipUpdate({
          ...internship,
          state: internship.institution && !internship.institution.managed
            ? internshipStates.ACCEPTED_UNMANAGED
            : internshipStates.ACCEPTED
        })
      }

      setShifts(newShifts)
    } else if (shift) {
      setShifts(shifts.map(s => {
        return s.id === shift.id ? shift : s
      }))
    }

    handleModalClose(null)
  }

  const handleModalClose = (shift, loading = false) => {
    setCreationModalVisible(false)
    setAreDatesSelected(false)
    setSelectedDates('')
    setSelectedShift(undefined)

    if (shift) {
      if (internship.state < internshipStates.SCHEDULE_ADDED) {
        onInternshipUpdate({ ...internship, state: internshipStates.SCHEDULE_ADDED })
      }

      setShifts([...shifts, shift])
    }

    if (loading) {
      setLoading(true)
    }
  }

  const renderEventContent = eventInfo => {
    if (!eventInfo.event.title || eventInfo.event.title === 'Internship' || eventInfo.event.title === 'Stage') {
      return (<div />)
    }

    const title = JSON.parse(eventInfo.event.title)

    const tooltip = (
      <div>
        {title.main}
        <br />
        {title.subtitle}
      </div>
    )

    return (
      <Tooltip title={tooltip}>
        <div>
          <b>{title.main}</b>
          <br />
          <i>{title.subtitle}</i>
        </div>
      </Tooltip>
    )
  }

  const handleMutipleCreation = () => {
    if (internship.state < SCHEDULE_ADDED) {
      onInternshipUpdate({ ...internship, state: SCHEDULE_ADDED })
    }

    refreshShifts()
  }

  const customButtonClick = () => {
    let shiftExists = false

    if (areDatesSelected) {
      const format = 'YYYY-MM-DD'
      const start = moment(selectedDates.startStr, format)
      const end = moment(selectedDates.endStr, format).subtract(1, 'days')
      shifts.map(s => {
        if (moment(s.startDate.date).isBetween(start, end, 'day', '[]') && s.deleted === false) {
          shiftExists = true
        }
      })
    } else {
      window.alert(t('Please select a range of dates for your schedule'))
    }

    if (shiftExists) {
      window.alert(t('A shift already exists for a date in this period'))
    } else {
      setCreationModalVisible(true)
    }
  }

  const headerToolbar = width > BREAK_POINT && areDatesSelected && isUpdatable
    ? { start: 'title', center: 'customButton', end: 'prev,next' }
    : { start: 'title', end: 'prev,next' }
  const footerToolbar = width < BREAK_POINT && areDatesSelected && isUpdatable
    ? { center: 'customButton' }
    : null

  return (
    <div>
      <CalendarHeader
        internship={internship}
        loading={loading}
        shifts={shifts}
        onClose={onClose}
        onInternshipUpdate={onInternshipUpdate}
      />
      <FullCalendar
        // listPlugin allows the calendar to function correctly - do not delete
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        locale={locale}
        initialView='dayGridMonth'
        firstDay={1}
        selectable
        initialDate={initialDate}
        events={events}
        eventClick={onEventClick}
        select={onDateClick}
        unselectAuto={width >= BREAK_POINT}
        unselect={onUnselect}
        customButtons={{
          customButton: {
            text: t('Submit my schedule'),
            click: customButtonClick
          }
        }}
        headerToolbar={headerToolbar}
        footerToolbar={footerToolbar}
        handleWindowResize
        contentHeight={width < BREAK_POINT ? 'auto' : null}
        selectLongPressDelay={500}
        eventContent={renderEventContent}
      />
      <MaccsDesiderataCreationModal
        dates={selectedDates}
        internship={internship}
        visible={creationModalVisible}
        onClose={handleModalClose}
        onMultipleCreation={handleMutipleCreation}
      />
      <MaccsDesiderataUpdateModal
        date={selectedDates}
        onCancel={handleModalClose}
        onClose={handleUpdateModalClose}
        isUpdatable={isUpdatable}
        internship={internship}
        shift={selectedShift}
      />
    </div>
  )
}

export default connect(mapStateToProps)(MaccsShiftsCalendar)
