import React, { useEffect, useState } from 'react'
import { Form, Popconfirm, Button, Modal, InputNumber, TimePicker, Divider } from 'antd'
import moment from 'moment'
import MaccsEventCodeSelect from './Components/MaccsEventCodeSelect'
import { updateMaccsInternshipShift } from '../../../utils/api/maccsInternship'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { remove } from '../../../utils/api/shift'
import { Shift } from '../../../utils/entities/shift'

const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSS'
const HOURS_MINUTES_FORMAT = 'HH:mm'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsDesiderataUpdateModal = ({ date, internship, isUpdatable, shift, user, onClose, t }) => {
  const [title, setTitle] = useState('')
  const [values, setValues] = useState({})
  const [visible, setVisible] = useState(false)

  const showPopconfirm = () => {
    setVisible(true)
  }

  useEffect(() => {
    if (date && t) {
      if (isUpdatable) {
        setTitle(t('Modify the schedule for ') + date)
      } else {
        setTitle(t('Schedule for ') + date)
      }
    }
  }, [isUpdatable, t, date])

  useEffect(() => {
    if (shift) {
      setValues({
        id: shift.id,
        startTime: moment(shift.start, FULL_DATE_FORMAT),
        endTime: moment(shift.end, FULL_DATE_FORMAT),
        pause: shift.pause,
        eventCodeType: shift.eventCodeType,
        deleted: shift.deleted
      })
    }
  }, [shift])

  const handleDelete = () => {
    remove(shift, user).then(json => {
      const deletedShift = json?.data ?? null

      if (deletedShift !== null) {
        onSuccess(t('The shift has been successfully deleted'))
      } else {
        onError(t('The shift could not be deleted'))
      }

      handleClose(deletedShift, true)
    })
  }

  const handleClose = (shift = null, deleted = false) => {
    setVisible(false)

    onClose(shift, deleted)
  }

  const handleSubmit = () => {
    const updatedShift = new Shift({ ...shift, startDate: shift.start, endDate: shift.end })

    updatedShift.startTime = values.startTime
    updatedShift.endTime = values.endTime
    updatedShift.pause = values.pause
    updatedShift.eventCodeType = values.eventCodeType

    updateMaccsInternshipShift(internship, updatedShift, user).then(json => {
      const newShift = json?.data ?? null

      if (newShift !== null) {
        onSuccess(t('The shift has been successfully updated'))
      } else {
        onError(t('The shift could not be updated'))
      }

      handleClose(newShift)
    })
  }

  const renderFooter = () => {
    if (isUpdatable) {
      return (
        <div>
          <Button key='back' onClick={() => handleClose(null)}>
            {t('Cancel')}
          </Button>
          <Popconfirm
            title={t('Are you sure you want to delete this shift?')}
            visible={visible}
            onConfirm={handleDelete}
            onCancel={() => handleClose(null)}
          >
            <Button type='danger' onClick={showPopconfirm}>
              {t('Delete shift')}
            </Button>
          </Popconfirm>
          <Button
            key='submit'
            type='primary'
            onClick={handleSubmit}
          >
            {t('Submit')}
          </Button>
        </div>
      )
    }
  }

  return (
    <Modal
      title={title}
      visible={!!shift}
      onCancel={() => handleClose(null)}
      okButtonProps={isUpdatable ? { htmlType: 'submit', form: 'shiftUpdateForm' } : { onClick: () => handleClose(null) }}
      footer={renderFooter()}
    >
      {!isUpdatable && (
        <div>
          <h3>{t('Start time')}</h3>
          <p>{moment(values.startTime).format(HOURS_MINUTES_FORMAT)}</p>
          <h3>{t('End time')}</h3>
          <p>{moment(values.endTime).format(HOURS_MINUTES_FORMAT)}</p>
          <h3>{t('Pause')}</h3>
          <p>{values.pause} min</p>
          <h3>{t('Event code')}</h3>
          <p>{values.eventCode}</p>
        </div>
      )}
      {isUpdatable && (
        <Form
          id='shiftUpdateForm'
          layout='vertical'
          initialvalues={values}
        >
          <Form.Item name='startTime' label={t('Start time')}>
            <TimePicker
              size='large'
              minuteStep={5}
              value={values.startTime}
              placeholder={moment(values.startTime).format(HOURS_MINUTES_FORMAT)}
              format='HH:mm'
              onChange={(value) => {
                const startDate = values.startTime.clone()
                startDate.hours(value.format('HH'))
                startDate.minutes(value.format('mm'))
                startDate.seconds(0)

                setValues({ ...values, startTime: moment(startDate.format(FULL_DATE_FORMAT)) })
              }}
            />
          </Form.Item>
          <Form.Item name='endTime' label={t('End time')}>
            <TimePicker
              size='large'
              minuteStep={5}
              value={values.endTime}
              placeholder={moment(values.endTime).format(HOURS_MINUTES_FORMAT)}
              format='HH:mm'
              onChange={(value) => {
                const endDate = values.endTime.clone()
                endDate.hours(value.format('HH'))
                endDate.minutes(value.format('mm'))
                endDate.seconds(0)
                if (endDate < values.startTime) {
                  endDate.add(1, 'day')
                }

                setValues({ ...values, endTime: moment(endDate.format(FULL_DATE_FORMAT)) })
              }}
            />
          </Form.Item>
          <Form.Item name='pause' label={t('Pause')}>
            <InputNumber
              size='large'
              min={0}
              step={5}
              value={values.pause}
              placeholder={values.pause}
              onChange={(value) => {
                setValues({ ...values, pause: value })
              }}
            />
          </Form.Item>
          <Form.Item name='eventCode' label={t('Event code')} style={{ marginBottom: 0 }}>
            <MaccsEventCodeSelect
              update
              value={values.eventCodeType}
              onSelect={(eventCode) => {
                setValues({ ...values, eventCodeType: eventCode.typeId })
              }}
            />
          </Form.Item>
        </Form>
      )}
      <Divider />
    </Modal>
  )
}

export default connect(mapStateToProps)(MaccsDesiderataUpdateModal)
