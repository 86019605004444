import React from 'react'

import { SettingsFactory } from '../TablePieces/TableHeadersTools'
import SearchFieldFactory from '../TablePieces/TableHeadersTools/SearchFieldFactory'
import AddButton from './TableHeaderPart/AddButton'

const TableHeader = ({
  addButtonText, disableAdd, disableSearchFields, disableSettings, searchFieldPlaceholder,
  onColumnVisibilitySelected, onDataAdd, onDataFilter, onLineDensitySelected, onLineShownSelected, onSearchColumnSelect
}) => {
  const handleDataAdd = () => {
    runFunctionIfExist(onDataAdd)
  }

  const handleFilter = searchValue => {
    runFunctionIfExist(onDataFilter, [searchValue])
  }

  const handleLineShownSelected = lineShown => {
    runFunctionIfExist(onLineShownSelected, [lineShown])
  }

  const handleLinesDensitySelected = linesDensity => {
    runFunctionIfExist(onLineDensitySelected, [linesDensity + 'px'])
  }

  const runFunctionIfExist = (func, functionParameters) => {
    if (typeof func === 'function') {
      functionParameters ? func(...functionParameters) : func()
    }
  }

  return (
    <div className='flex-row table-header'>
      {!disableSearchFields &&
        <SearchFieldFactory
          updateFilter={handleFilter}
          placeHolder={searchFieldPlaceholder}
        />}
      {!disableSettings &&
        <SettingsFactory
          onLineShownSelected={handleLineShownSelected}
          onLineDensitySelected={handleLinesDensitySelected}
          onColumnVisibilitySelected={onColumnVisibilitySelected}
        />}

      <div className='flex-fill' />

      {!disableAdd &&
        <AddButton
          onDataAdd={handleDataAdd}
          addText={addButtonText}
        />}
    </div>
  )
}

export default TableHeader
