import React, { useEffect, useRef, useState } from 'react'

import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, DatePicker, Popover } from 'antd'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { EUROPEAN_DATE, FULL_DATE_WITHOUT_TIMEZONE } from '../../../utils/constants'
import { downloadFileWithPromise, getJobResultWithPromise } from '../../../utils'
import { getUser } from '../../../reducers/UserReducer'
import { getMaccsInternshipsShifts } from '../../../utils/api/export'
import { isA404Error } from '../../../Errors/utils'

const RangePicker = DatePicker.RangePicker

const INTERVAL_TIME = 1500

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsShiftsExportButton = ({ activeLanguage, date, filters, user, t }) => {
  const [checked, setChecked] = useState(false)
  const [period, setPeriod] = useState(null)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const jobInterval = useRef(null)

  useEffect(() => {
    return () => {
      if (jobInterval.current) {
        clearInterval(jobInterval)
      }
    }
  }, [])

  useEffect(() => {
    if (date) {
      setPeriod([date.clone().startOf('isoWeek'), date.clone().add(3, 'week').endOf('isoWeek')])
    }
  }, [date])

  const handleExport = () => {
    setLoading(true)
    setVisible(false)

    const body = {
      startDate: period[0].format(FULL_DATE_WITHOUT_TIMEZONE),
      endDate: period[1].format(FULL_DATE_WITHOUT_TIMEZONE),
      language: activeLanguage
    }

    Object.keys(filters).forEach(key => {
      body[key] = filters[key]
    })

    setLoading(false)

    getMaccsInternshipsShifts(body, user).then(json => {
      jobInterval.current = setInterval(() => {
        getJobResultWithPromise(json.job, user).then(json => {
          if (json?.data && !json?.error) {
            const fileName = json.data.result
            downloadFileWithPromise('/spreadsheet/download/' + fileName, fileName, user)
          }

          clearInterval(jobInterval.current)
          setLoading(false)
        }).catch(err => {
          if (isA404Error(err)) {
            clearInterval(jobInterval.current)
            setLoading(false)
          }
        })
      }, INTERVAL_TIME)
    })
  }

  return (
    <Popover
      content={
        <ul className='shifts-export-options'>
          <li>
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              {t('Use current filters')}
            </Checkbox>
          </li>
          <li><Button onClick={handleExport}>{t('Current view')}</Button></li>
          <li>
            <Button onClick={handleExport}>{t('Custom period')}</Button>
            <div style={{ width: '2vw' }} />
            <RangePicker
              value={period}
              onChange={setPeriod}
              format={EUROPEAN_DATE}
            />
          </li>
        </ul>
      }
      placement='left'
      title={(
        <div className='shifts-export-title'>
          <FontAwesomeIcon icon={faFileExport} />
          &nbsp;
          {t('Export')}
        </div>
      )}
      trigger='click'
      visible={visible}
      onVisibleChange={setVisible}
    >
      <Button type='default' loading={loading}>
        <FontAwesomeIcon icon={faFileExport} />
        &nbsp;
        {t('Export')}
      </Button>
    </Popover>
  )
}

export default connect(mapStateToProps)(MaccsShiftsExportButton)
