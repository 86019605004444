import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getDateTitle } from './utils/componentGetters'
import { DAY_AND_MONTH_FORMAT } from './utils/constants'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import { INSTITUTION_MACCS_CONTEXT, MACCS_ENVIRONMENT, NURSES_CONTEXT, ROLE_INSTITUTION_GROUP_ADMIN, SCHOOL_MACCS_CONTEXT } from '../../../utils/constants'
import moment from 'moment'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const TH_STUDENT_TILE_STYLE = { width: '550px' }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InternshipsTableHead = ({ user, t }) => {
  const { environment } = useContext(GlobalContext)
  const { dataProvider } = useContext(InternshipsManagerContext)
  const { addMode, dateColumns } = useContext(dataProvider.context)
  const DateTitle = getDateTitle(user.context, user.roles, environment)

  const renderTitleDate = () => {
    const currentDate = moment()
    currentDate.set('hour', 0)
    currentDate.set('minute', 0)
    currentDate.set('second', 0)

    return dateColumns.map((dates, index) => {
      return (
        <DateTitle
          key={dates.startDate.format(DAY_AND_MONTH_FORMAT)}
          startDate={dates.startDate}
          endDate={dates.endDate}
          displayAction={addMode}
          index={index}
          isCurrent={dates.startDate <= currentDate && dates.endDate >= currentDate}
        />
      )
    })
  }

  const renderFirstColumn = () => {
    if (user.context === INSTITUTION_MACCS_CONTEXT || (user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) && environment === MACCS_ENVIRONMENT)) {
      return (
        <>
          {t('Assistant')} - {t('Assistant speciality')} - {t(t('Internship year'))}<br />
          {t('School')} - {t('Internship speciality')}
        </>
      )
    }

    if (NURSES_CONTEXT.includes(user.context)) {
      return (
        <>
          {t('Schools')} - {t('Students')}<br />
          {t('Sections')} - {t('Internship year')} - {t('Care units')}
        </>
      )
    }

    if (user.context === SCHOOL_MACCS_CONTEXT) {
      return (
        <>
          {t('Assistant speciality')} - {t('Assistant')} - {t('Working regime')}<br />
          {t('Institution')} - {t('Internship speciality')}
        </>
      )
    }
  }

  return (
    <thead>
      <tr>
        <th style={user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) && environment === MACCS_ENVIRONMENT ? {} : TH_STUDENT_TILE_STYLE}>
          {renderFirstColumn()}
        </th>
        {DateTitle !== null && renderTitleDate()}
      </tr>
    </thead>
  )
}

export default connect(mapStateToProps)(InternshipsTableHead)
