import React, { createContext } from 'react'

const DefaultSettingsContext = createContext()

const DefaultSettingsProvider = props => {
  return (
    <DefaultSettingsContext.Provider value={{}}>
      {props.children}
    </DefaultSettingsContext.Provider>
  )
}

export {
  DefaultSettingsProvider as default,
  DefaultSettingsContext
}
