import { requestWithPromise } from '..'
import { DELETE, GET, POST } from '../apiHelper'

export const remove = (shift, user) => {
  return requestWithPromise(
    `/api/shifts/${shift.id}`,
    DELETE,
    null,
    user
  )
}

export const getShiftActs = (shift, user) => {
  return requestWithPromise(
    `/api/shifts/${shift.id}/acts`,
    GET,
    null,
    user
  )
}

export const createShiftAct = (shift, act, user) => {
  return requestWithPromise(
    `/api/shifts/${shift.id}/acts`,
    POST,
    act,
    user
  )
}
