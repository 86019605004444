import React, { createContext, useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getUserSpecialyYears, linkUserToSpecialityYear, unlinkUserToSpecialityYear } from '../../../utils/api/users'
import { notification } from 'antd'
import { ROLE_ADMIN_HOSPITAL_RO } from '../../../utils/constants'
import { DATA_TYPE_SELECT, DATA_TYPE_CHECK_CONTACT_PERSON } from './UserSpecialityTable'
import { addContactPersonToSpecialityYear, addMultipleContactPersonsToSpecialityYear, getAllSpecialityYears } from '../../../utils/api/specialityYear'
import { getErrorBody, onError } from '../../../utils/apiHelper'
import { HTTP_CONFLICT } from '../../../utils/http'

const SUCCESSFUL_LINK_MESSAGE = 'Speciality successfully linked'
const SUCCESSFUL_UNLINK_MESSAGE = 'Speciality successfully unlinked'
const SUCCESSFUL_LINK_SWITCH_MESSAGE = 'Linked Speciality successfully modified'

const InstitutionUserSpecialitiesContext = createContext()

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser),
    t: getTranslate(state.locale)
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

let InstitutionUserSpecialitiesProvider = props => {
  const { user, selectedUser, t } = props

  const [availableSpecialityYears, setAvailableSpecialityYears] = useState([])
  const [userSpecialityYears, setUserSpecialityYears] = useState([])
  const [contactPersonsSpecialityYears, setContactPersonsSpecialityYears] = useState({})
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user.institutions) {
      getAllSpecialityYears(user)
        .then(data => setAvailableSpecialityYears(data.map(s => {
          s.name = getSpecialityYearName(s)

          return s
        })))
    }
  }, [user.institutions])

  useEffect(() => {
    if (selectedUser && user) {
      setLoading(true)
      getUserSpecialyYears(selectedUser, user)
        .then(data => {
          const userSpecialityYears = []
          const contactPersonsSpecialityYears = {}

          for (const specialityYear of data) {
            specialityYear.name = getSpecialityYearName(specialityYear)
            userSpecialityYears.push(specialityYear)

            contactPersonsSpecialityYears[specialityYear.id] = specialityYear.contactPersons.map(contactPerson => {
              return contactPerson.id
            })
          }

          setUserSpecialityYears(userSpecialityYears)
          setContactPersonsSpecialityYears(contactPersonsSpecialityYears)
          setLoading(false)
        })
    }
  }, [selectedUser, user])

  useEffect(() => {
    if (t && user.roles) {
      setColumns(makeColumns(t, availableSpecialityYears, user.roles ?? []))
    }
  }, [availableSpecialityYears, t, user.roles])

  const makeColumns = (t, specialityYears, roles) => {
    return [
      {
        type: DATA_TYPE_SELECT,
        name: t('Speciality'),
        key: 'name',
        options: specialityYears.map((specialityYear, index) => {
          return {
            id: specialityYear.name,
            name: specialityYear.name,
            index
          }
        }),
        disabled: roles.includes(ROLE_ADMIN_HOSPITAL_RO)
      },
      {
        type: DATA_TYPE_CHECK_CONTACT_PERSON,
        name: props.t('Contact persons'),
        key: 'contactPerson',
        disabled: roles.includes(ROLE_ADMIN_HOSPITAL_RO)
      }
    ]
  }

  const clearStates = () => {
    setUserSpecialityYears([])
    setLoading(false)
  }

  const onLinkSpeciality = ({ name }) => {
    const specialityYear = availableSpecialityYears.find(s => s.name === name)

    if (specialityYear) {
      linkUserToSpecialityYear(selectedUser, specialityYear, user).then(({ data }) => {
        const newUserSpecialityYears = [...userSpecialityYears]
        const newContactPersonsSpecialityYears = { ...contactPersonsSpecialityYears }

        data.name = getSpecialityYearName(data)
        newUserSpecialityYears.push(data)

        newContactPersonsSpecialityYears[data.id] = data.contactPersons.map(contactPerson => {
          return contactPerson.id
        })

        setUserSpecialityYears(newUserSpecialityYears)
        setContactPersonsSpecialityYears(newContactPersonsSpecialityYears)
        notification.success({
          message: t(SUCCESSFUL_LINK_MESSAGE),
          placement: 'bottomRight'
        })
      }).catch(error => {
        console.error(error)
      })
    }
  }

  const onModifyLinkSpeciality = (data) => {
    const specialityYear = availableSpecialityYears.find(s => s.name === data.name)
    const oldSpecialityYear = userSpecialityYears.find(s => s.id === data.id)
    const newContactPersonsSpecialityYears = { ...contactPersonsSpecialityYears }

    if (specialityYear && oldSpecialityYear) {
      linkUserToSpecialityYear(selectedUser, specialityYear, user, true).then(({ data }) => {
        const newUserSpecialityYears = [...userSpecialityYears]

        data.name = getSpecialityYearName(data)
        newUserSpecialityYears.push(data)

        newContactPersonsSpecialityYears[data.id] = data.contactPersons.map(contactPerson => {
          return contactPerson.id
        })

        return newUserSpecialityYears
      }).then(userSpecialityYears => {
        unlinkUserToSpecialityYear(selectedUser, oldSpecialityYear, user).then(data => {
          setUserSpecialityYears(userSpecialityYears.filter(sp => {
            return sp.id !== data.id
          }))

          const newContactPersonsSpecialityYearsWithoutSpeciality = {}

          for (const key in newContactPersonsSpecialityYears) {
            if (parseInt(key) !== data.id) {
              newContactPersonsSpecialityYearsWithoutSpeciality[parseInt(key)] = newContactPersonsSpecialityYears[parseInt(key)]
            }
          }

          setContactPersonsSpecialityYears(newContactPersonsSpecialityYearsWithoutSpeciality)
          notification.success({
            message: t(SUCCESSFUL_LINK_SWITCH_MESSAGE),
            placement: 'bottomRight'
          })
        })
      }).catch(error => {
        getErrorBody(error).then(({ code, message }) => {
          if (code === HTTP_CONFLICT) {
            onError(t('This user is already linked to this speciality'))
          } else {
            onError(message)
          }
        })
      })
    }
  }

  const onUnlinkSpeciality = ({ name }) => {
    const specialityYear = availableSpecialityYears.find(s => s.name === name)

    if (specialityYear) {
      unlinkUserToSpecialityYear(selectedUser, specialityYear, user)
        .then(data => {
          setUserSpecialityYears(userSpecialityYears.filter(sp => {
            return sp.id !== data.id
          }))

          const newContactPersonsSpecialityYears = {}

          for (const key in contactPersonsSpecialityYears) {
            if (parseInt(key) !== data.id) {
              newContactPersonsSpecialityYears[parseInt(key)] = contactPersonsSpecialityYears[parseInt(key)]
            }
          }

          setContactPersonsSpecialityYears(newContactPersonsSpecialityYears)
          notification.success({
            message: t(SUCCESSFUL_UNLINK_MESSAGE),
            placement: 'bottomRight'
          })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const getSpecialityYearName = specialityYear => {
    if (specialityYear) {
      return `${specialityYear.specialityAbbreviation} (${specialityYear.studyYearName})`
    }

    return 'Unkown speciality'
  }

  const userIsContactPerson = specialityYearId => {
    if (selectedUser && contactPersonsSpecialityYears[specialityYearId]) {
      return contactPersonsSpecialityYears[specialityYearId].includes(selectedUser.id)
    } else {
      return false
    }
  }

  const setMultipleUserContactPersons = specialityYears => {
    const body = {
      userToAdd: selectedUser.id,
      specialityYears: specialityYears
    }

    addMultipleContactPersonsToSpecialityYear(body, user)
      .then(jsonResponse => {
        const newContactPersonsSpecialityYears = { ...contactPersonsSpecialityYears }

        for (const specialityYearId of specialityYears) {
          let contactPersonsSpecialityYear = []
          if (contactPersonsSpecialityYears[specialityYearId].includes(selectedUser.id)) {
            for (const userId of contactPersonsSpecialityYears[specialityYearId]) {
              if (userId !== selectedUser.id) {
                contactPersonsSpecialityYear.push(userId)
              }
            }
          } else {
            contactPersonsSpecialityYear = contactPersonsSpecialityYear.concat(contactPersonsSpecialityYears[specialityYearId])
            contactPersonsSpecialityYear.push(selectedUser.id)
          }

          newContactPersonsSpecialityYears[specialityYearId] = contactPersonsSpecialityYear
        }

        setContactPersonsSpecialityYears(newContactPersonsSpecialityYears)
        notification.success({ message: props.t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setUserContactPerson = specialityYearId => {
    const body = {
      userToAdd: selectedUser.id
    }

    addContactPersonToSpecialityYear(specialityYearId, body, user)
      .then(jsonResponse => {
        let contactPersonsSpecialityYear = []

        if (contactPersonsSpecialityYears[specialityYearId].includes(selectedUser.id)) {
          for (const userId of contactPersonsSpecialityYears[specialityYearId]) {
            if (userId !== selectedUser.id) {
              contactPersonsSpecialityYear.push(userId)
            }
          }
        } else {
          contactPersonsSpecialityYear = contactPersonsSpecialityYear.concat(contactPersonsSpecialityYears[specialityYearId])
          contactPersonsSpecialityYear.push(selectedUser.id)
        }

        const newContactPersonsSpecialityYears = { ...contactPersonsSpecialityYears }
        newContactPersonsSpecialityYears[specialityYearId] = contactPersonsSpecialityYear

        setContactPersonsSpecialityYears(newContactPersonsSpecialityYears)
        notification.success({ message: props.t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  return (
    <InstitutionUserSpecialitiesContext.Provider value={{
      user,
      columns,
      loading,
      selectedUser,
      userSpecialityYears,
      clearStates,
      onLinkSpeciality,
      onModifyLinkSpeciality,
      onUnlinkSpeciality,
      userIsContactPerson,
      setMultipleUserContactPersons,
      setUserContactPerson,
      t
    }}
    >
      {props.children}
    </InstitutionUserSpecialitiesContext.Provider>
  )
}

InstitutionUserSpecialitiesProvider = connect(mapStateToProps, mapDispatchToProps)(InstitutionUserSpecialitiesProvider)

export {
  InstitutionUserSpecialitiesProvider as default,
  InstitutionUserSpecialitiesContext
}
