/** HTTP ERROR CODES */
export const HTTP_OK = 200
export const HTTP_NOT_FOUND = 404
export const HTTP_CONFLICT = 409
export const HTTP_GONE = 410
export const HTTP_INTERNAL_ERROR = 500

/** SERVERS URLS */

export const PROD_URL_PATTERN = 'app.interneo.pro'
export const MACCS_PROD_URL_PATTERN = 'ucl.interneo.pro'
export const STAGING_URL_PATTERN = 'demo.interneo.pro'
export const MACCS_STAGING_URL_PATTERN = 'ucl.staging.interneo.pro'
export const QUOTAS_URL_PATTERN = 'feature-quota-rework-part1.review.interneo.pro'
export const SANDBOX_URL_PATTERN = 'sandbox.interneo.pro'
export const DEV_URL_PATTERN = 'localhost'

export const PROD_API_URL = 'https://api.interneo.pro'
export const MACCS_PROD_API_URL = 'https://api.ucl.interneo.pro'
export const QUOTAS_API_URL = 'https://api-quotas.interneo.pro'
export const SANDBOX_API_URL = 'https://api-sandbox.interneo.pro'
export const STAGING_API_URL = 'https://api-demo.interneo.pro'
export const MACCS_STAGING_API_URL = 'https://api.ucl.staging.interneo.pro'
export const DEV_API_URL = 'http://localhost:8080'
