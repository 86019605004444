import { requestWithPromise } from '../index'
import { GET, POST } from '../apiHelper'

export const getInternshipMessages = (internship, user) => {
  return requestWithPromise(
    `/internship-message/consult/${internship.id}`,
    GET,
    null,
    user
  )
}

export const sendsReadReceipt = (internship, user) => {
  return requestWithPromise(
    '/internship-message/read',
    POST,
    { internship: internship.id },
    user
  )
}

export const createInternshipMessages = (internship, content, user) => {
  return requestWithPromise(
    '/internship-message/send',
    POST,
    { internship: internship.id, content },
    user
  )
}

export const markAllAsRead = (user) => {
  return requestWithPromise(
    '/internship-message/read-all',
    POST,
    {},
    user
  )
}
