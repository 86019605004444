import { downloadFile, requestMultipart, requestWithPromise } from '..'
import { API_DATE_FORMAT, GET, makeQueryStringFromObject, POST } from '../apiHelper'
import moment from 'moment'

const XLSX_FORMAT = 'xlsx'
const CSV_FORMAT = 'csv'
const SUPPORTED_EXPORT_FORMATS = [XLSX_FORMAT, CSV_FORMAT]

export const downloadAssistantMassImportTemplate = (user, language) => {
  return downloadFile(
    `/export/assistants/template${makeQueryStringFromObject({ language })}`,
    'assistants_template.xlsx',
    user
  )
}

export const downloadSpreadSheet = (fileName, user) => {
  downloadFile(
    `/spreadsheet/download/${fileName}`,
    fileName,
    user,
    GET
  )
}

export const getMaccsInternshipsByPeriod = (body, user) => {
  return requestWithPromise(
    '/export/maccs-internships/by-period',
    POST,
    body,
    user
  )
}

export const getMaccsInternshipsShifts = (body, user) => {
  return requestWithPromise(
    '/export/maccs-internships/shifts',
    POST,
    body,
    user
  )
}

export const getJob = (jobId, user) => {
  return requestWithPromise(
    `/job-result/${jobId}`,
    GET,
    null,
    user
  )
}

export const importAssistants = (file, user, language = 'fr') => {
  return requestMultipart(
    '/import/assistants',
    POST,
    { file, language },
    user,
    true
  ).then(response => response.blob()).then(blob => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.download = 'assistants_import_result.xlsx'
    document.body.appendChild(a)
    a.click()
    a.remove()
  })
}

export const getDocument = async (jobId, user, onError, onSuccess, refreshRate) => {
  const maxIteration = 10
  const interval = { ref: null, iteration: 0 }

  interval.ref = setInterval(() => {
    interval.iteration++

    requestWithPromise(
      `/job-result/${jobId}`,
      GET,
      null,
      user,
      true,
      true
    ).then(json => {
      clearInterval(interval.ref)

      return downloadSpreadsheet(json.data.result, user)
    }).then(() => {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }).catch(response => {
      if (interval.iteration === maxIteration) {
        clearInterval(interval.ref)

        if (typeof onError === 'function') {
          onError(response)
        }
      }
    })
  }, refreshRate ?? 1500)
}

export const downloadSpreadsheet = (fileName, user) => {
  return downloadFile(
    '/spreadsheet/download/' + fileName,
    fileName,
    user,
    GET
  )
}

export class ExportParameters {
  constructor ({ format }) {
    if (!format || !SUPPORTED_EXPORT_FORMATS.includes(format)) {
      format = XLSX_FORMAT
    }

    this.format = format
  }

  toBody () {
    return {
      format: this.format
    }
  }
}

export const exportInstitutionQuotas = (user, institution, { format, startDate, endDate, language, sectorIds = [] }) => {
  return requestWithPromise(
    `/export/institutions/${institution.id}/quotas`,
    POST,
    { format, startDate, endDate, language, sectorIds },
    user,
    true,
    true
  )
}

export class PeriodExportParameters extends ExportParameters {
  constructor ({ format, startDate, endDate }) {
    super({ format })

    this.startDate = moment(startDate ?? null)
    this.endDate = moment(endDate ?? null)
  }

  toBody () {
    return {
      end: this.endDate.format(API_DATE_FORMAT),
      format: this.format,
      start: this.startDate.format(API_DATE_FORMAT)
    }
  }
}
