import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import InstitutionsTable from './InstitutionsTable'
import { ACTIONS } from '../../../Components/shared/DataTable/utils/actions'
import { Modal } from 'antd'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { deleteInstitution, getAllInstitutions, getInstitutionUsers } from '../../../utils/api/institution'
import { NURSE_ENVIRONMENT, ORDER_BY, ROLE_INSTITUTION_GROUP_ADMIN } from '../../../utils/constants'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import InstitutionFormModal from '../../../HOC/Forms/InstitutionFormModal'
import { getErrorBody, onError, onSuccess } from '../../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'
import { deleteInstitutionFromInstitutionGroup } from '../../../utils/api/institutionGroup'
import LazySelect from '../../../Components/antd/Selects/LazySelect'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import InstitutionSectorsDrawer from './InstitutionSectorsDrawer'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InstitutionsView = ({ user, onInstitutionFormSelected, t }) => {
  const gContext = useContext(GlobalContext)
  const igContext = useContext(InstitutionGroupContext)

  const [institutions, setInstitutions] = useState({ data: [], meta: new Metadata({ pageSize: 20 }) })
  const [loading, setLoading] = useState(false)
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: ORDER_BY.NAME, pageSize: 20 }))
  const [selectedInstitution, setSelectedInstitution] = useState({ data: null, action: null })
  const [targetInstitution, setTargetInstitution] = useState(null)

  const timer = useRef(null)

  const fetchInstitutions = useCallback((user, tableParameters) => {
    setLoading(true)

    getAllInstitutions(user, tableParameters).then(json => {
      if (json?.data || json?.meta) {
        const institutions = {}

        institutions.data = json?.data ?? []
        institutions.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setInstitutions(institutions)
      }

      setLoading(false)
    })
  }, [setInstitutions, setLoading, getAllInstitutions])

  useEffect(() => {
    if (user) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => fetchInstitutions(user, tableParameters), 300)
    }
  }, [user.institutionGroup, tableParameters, timer])

  const handleDeleteInstitution = institution => {
    deleteInstitution(user, institution, { targetInstitution: targetInstitution?.id }).then(json => {
      deleteInstitutionFromInstitutionGroup(user, user.institutionGroup, institution).then(json => {
        if (json?.data) {
          onSuccess('institutions_view.actions.delete.success')
          igContext.setInstitutions(igContext.institutions.filter(i => i.id !== institution.id))
          gContext.setInstitutions(gContext.institutions.filter(i => i.id !== institution.id))
        }

        fetchInstitutions(user, tableParameters)
      }).catch(error => {
        getErrorBody(error).then(({ message }) => onError(t(message)))
      })
    })
  }

  const checkDeleteInstitution = useCallback(i => {
    getInstitutionUsers(user, i, { countOnly: true, excludedRole: ROLE_INSTITUTION_GROUP_ADMIN }).then(json => {
      const data = json?.data

      if (typeof data === 'number') {
        if (data === 0) {
          handleDeleteInstitution(i)
        } else {
          setSelectedInstitution({ data: i, action: ACTIONS.DELETE })
        }
      }
    })
  }, [user, tableParameters, fetchInstitutions, handleDeleteInstitution, t])

  const handleTriggerAction = useCallback((institution, action) => {
    if (action === ACTIONS.CREATE || action === ACTIONS.EDIT) {
      onInstitutionFormSelected(institution)
    } else if (action === ACTIONS.DELETE) {
      checkDeleteInstitution(institution)
    } else if (action === ACTIONS.DISPLAY_INSTITUTION_SECTORS) {
      setSelectedInstitution({ data: institution, action: ACTIONS.DISPLAY_INSTITUTION_SECTORS })
    }
  }, [onInstitutionFormSelected, checkDeleteInstitution])

  return (
    <>
      <InstitutionFormModal onSave={i => {
        igContext.setInstitutions([...igContext.institutions, i])
        gContext.setInstitutions([...gContext.institutions, i])
        fetchInstitutions(user, tableParameters)
      }}
      >
        <InstitutionsTable
          data={institutions.data}
          loading={loading}
          metadata={institutions.meta}
          parameters={tableParameters}
          onParametersChange={setTableParameters}
          onTriggerAction={handleTriggerAction}
        />
      </InstitutionFormModal>
      <Modal
        title={t('institutions_view.delete_modal.title')}
        visible={selectedInstitution.action === ACTIONS.DELETE}
        onOk={() => {
          handleDeleteInstitution(selectedInstitution.data)
          setSelectedInstitution({ data: null, action: null })
          setTargetInstitution(null)
        }}
        onCancel={() => {
          setSelectedInstitution({ data: null, action: null })
          setTargetInstitution(null)
        }}
        okButtonProps={{ disabled: !targetInstitution || targetInstitution.id === selectedInstitution.data.id }}
        okText={t('institutions_view.delete_modal.buttons.ok')}
        cancelText={t('institutions_view.delete_modal.buttons.cancel')}
      >
        {selectedInstitution.action === ACTIONS.DELETE && (
          <div>
            {t('institutions_view.delete_modal.body')}
            <LazySelect
              dataKey='name'
              getData={getAllInstitutions}
              placeholder={t('Select an institution')}
              placement='top'
              style={{ width: '100%' }}
              value={targetInstitution}
              onSelect={setTargetInstitution}
              showSearch
            />
          </div>
        )}
      </Modal>
      {gContext.environment === NURSE_ENVIRONMENT && (
        <InstitutionSectorsDrawer
          width='600px'
          institution={selectedInstitution.action === ACTIONS.DISPLAY_INSTITUTION_SECTORS ? selectedInstitution.data : null}
          onClose={() => setSelectedInstitution({ data: null, action: null })}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps)(InstitutionsView)
