import { BACKEND_DATE_FORMAT, FULL_DATE_WITHOUT_TIMEZONE } from '../constants'
import { isObject } from '..'

export const createQuota = (sector, startDate, endDate) => {
  return {
    sector: { id: sector.id },
    section: null,
    school: null,
    sectionToYear: null,
    total: 0,
    startDate: startDate.format(BACKEND_DATE_FORMAT),
    endDate: endDate.format(BACKEND_DATE_FORMAT)
  }
}

export class Quota {
  constructor (school, sector, section, sectionToYear, total, startDate, endDate, id) {
    this.id = id ?? -1
    this.startDate = startDate
    this.endDate = endDate
    this.school = school
    this.sector = sector
    this.section = section
    this.sectionToYear = sectionToYear
    this.total = total
  }

  get toBody () {
    return {
      sector: this.sector.id,
      section: isObject(this.section) ? this.section.id : null,
      school: isObject(this.school) ? this.school.id : null,
      sectionToYear: isObject(this.sectionToYear) ? this.sectionToYear.id : null,
      startDate: this.startDate.format(FULL_DATE_WITHOUT_TIMEZONE),
      endDate: this.endDate.format(FULL_DATE_WITHOUT_TIMEZONE),
      total: this.total
    }
  }
}
