import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'

const MessagesButton = ({ unreadMessagesCount }) => {
  return (
    <button
      className={`new-messages-notification-wrapper ${unreadMessagesCount > 1000 ? 'small-text' : ''} ${unreadMessagesCount === 0 ? 'disabled' : ''}`}
      type='button'
    >
      <FontAwesomeIcon icon={faBell} />
      {unreadMessagesCount > 0 && <div className='unread-messages'>{unreadMessagesCount}</div>}
    </button>
  )
}

export default MessagesButton
