import React, { useEffect, useMemo, useState } from 'react'
import { shape, number } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeh, faSmile, faFrown } from '@fortawesome/free-regular-svg-icons'
import { Button, Popconfirm } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { GET } from '../../../utils/apiHelper'
import { request, generalErrorHandler } from '../../../utils'

import '../../../assets/recruitment-module.scss'

const NEGATIVE_ANSWER = 'RED_FACE'
const NEUTRAL_ANSWER = 'ORANGE_FACE'
const POSITIVE_ANSWER = 'GREEN_FACE'

const StudentEvaluationByInstitution = ({ getActiveLanguage, getEvaluationQuestions, internship, getUser, fetchEvaluationQuestions, t }) => {
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true)
  const [questions, setQuestions] = useState([])
  const [questionsRetrieved, setQuestionsRetrieved] = useState(false)
  const [comment, setComment] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [showPopconfirm, setShowPopconfirm] = useState(true)
  const [submitError, setSubmitError] = useState(false)
  // readOnlyMode is true when we have a previous evaluation
  const [readOnlyMode, setReadOnlyMode] = useState(false)
  const [displayConfirmationMessage, setDisplayConfirmationMessage] = useState(false)
  const [studentName, setStudentName] = useState('')

  const language = useMemo(() => getActiveLanguage.split('_')[0], [getActiveLanguage])

  useEffect(() => {
    // load the list of questions in state
    const institutionId = internship.institution

    const getQuestions = async () => fetchEvaluationQuestions(institutionId, getUser).then(parseQuestions)
    const { defaultQuestions, institutionQuestions } = getEvaluationQuestions

    if (!defaultQuestions.length || !institutionQuestions[institutionId]) {
      getQuestions()
    } else {
      parseQuestions([...defaultQuestions, ...institutionQuestions[institutionId]])
    }
  }, [])

  useEffect(() => {
    // after the questions are set, check if we have an existing evaluation
    if (questionsRetrieved) {
      loadEvaluation()
    }
  }, [questionsRetrieved])

  useEffect(() => {
    setShowPopconfirm(comment.length === 0)
  }, [comment])

  const parseQuestions = questions => {
    if (questions) {
      const institutionQuestionTypes = []

      questions.forEach(item => {
        if (item.institution !== null) {
          institutionQuestionTypes.push(item.questionType)
        }
      })

      const parsedQuestions = questions.filter(item =>
        item.institution !== null ||
      !institutionQuestionTypes.includes(item.questionType))
        .map(item => {
          const translationText = {}

          item.questions.forEach(questionText => {
            translationText[questionText.language] = questionText.value
          })

          return {
            id: item.id,
            questionType: item.questionType,
            isDefault: item.isDefault,
            texts: translationText,
            answer: null,
            invalid: false
          }
        })

      setQuestions(parsedQuestions)
      setQuestionsRetrieved(true)
    }
  }

  const loadEvaluation = async () => {
    const { data } = await request(`/evaluation/internship/${internship.id}`, GET, null, getUser)

    internship ? setStudentName(internship.firstname) : setStudentName(t('this student'))

    if (data) {
      // set the values
      const { studentEvaluationAnswers, studentEvaluationComment } = data
      if (studentEvaluationComment && studentEvaluationComment.length) {
        setComment(studentEvaluationComment || '')
      }
      if (studentEvaluationAnswers && studentEvaluationAnswers.length) {
        const questionsUpdatedAnswers = questions.map(question => {
          const answerForQuestion = studentEvaluationAnswers.find(item => item.question === question.id)
          if (answerForQuestion) {
            question.answer = answerForQuestion.answer
          }

          return question
        })

        setReadOnlyMode(true)
        setQuestions(questionsUpdatedAnswers)
      }
    }

    setShowLoadingAnimation(false)
  }

  const validateAnswers = () => {
    let isValid = true
    const checkedQuestions = questions.map(question => {
      if (!question.answer) {
        question.invalid = true
        isValid = false
      }
      return question
    })
    if (!isValid) {
      setQuestions(checkedQuestions)
      setSubmitError(true)
    } else if (submitError) {
      setSubmitError(false)
    }
    return isValid
  }

  const submitFeedback = async () => {
    // first validate each question was answered
    if (!validateAnswers()) {
      return
    }
    // prepare the send object
    const dataToSend = {
      internshipId: internship.id,
      answers: questions.map(item => ({ questionId: item.id, questionType: item.questionType, answer: item.answer })),
      comment: comment.trim().length ? comment : null
    }
    try {
      setSubmitting(true)
      const { data } = await request('/evaluation/internship/by-institution', 'POST', dataToSend, getUser)
      setSubmitting(false)
      // auto close the drawer if there's no error
      if (data !== undefined) {
        setReadOnlyMode(true)
        setDisplayConfirmationMessage(true)
      }
    } catch (err) {
      generalErrorHandler(err)
      setSubmitting(false)
    }
  }

  const handleAnswerSelection = (questionId, answer) => {
    const questionsUpdatedAnswers = questions.map(question => {
      if (question.id === questionId) {
        question.answer = answer
        question.invalid = false
      }
      return question
    })
    setQuestions(questionsUpdatedAnswers)
    if (submitError) {
      validateAnswers()
    }
  }

  if (showLoadingAnimation) {
    return <div className='loading-ring' />
  }

  return (
    <div className='recruitment-module-wrapper'>
      <div className='info-text'>{t('How was your internship experience with')} {studentName} {t('within your unit?')}</div>
      <label>{t('Questions')}</label>
      <div className='questions-list'>
        {questions.map(item => (
          <div key={item.id} className={`question-item ${item.invalid ? 'invalid' : ''}`}>
            <div className='question-text'>{item.texts[language]}</div>
            <div className='question-options'>
              <button
                disabled={readOnlyMode}
                type='button'
                className={item.answer === NEGATIVE_ANSWER ? 'negative-answer-selected' : ''}
                onClick={() => { handleAnswerSelection(item.id, NEGATIVE_ANSWER) }}
              >
                <FontAwesomeIcon icon={faFrown} />
              </button>
              <button
                disabled={readOnlyMode}
                type='button'
                className={item.answer === NEUTRAL_ANSWER ? 'medium-answer-selected' : ''}
                onClick={() => { handleAnswerSelection(item.id, NEUTRAL_ANSWER) }}
              >
                <FontAwesomeIcon icon={faMeh} />
              </button>
              <button
                disabled={readOnlyMode}
                type='button'
                className={item.answer === POSITIVE_ANSWER ? 'positive-answer-selected' : ''}
                onClick={() => { handleAnswerSelection(item.id, POSITIVE_ANSWER) }}
              >
                <FontAwesomeIcon icon={faSmile} />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className='comments-wrapper'>
        <label>{t('Comments')}</label>
        <textarea
          disabled={readOnlyMode}
          value={comment}
          onChange={(e) => { setComment(e.target.value) }}
          placeholder={t("Comments about student's internship")}
        />
      </div>
      <div className={`submit-feedback-wrapper ${submitError ? 'space-between' : ''}`}>
        {submitError && <span className='submit-error'>{t('Please answer all questions')}</span>}
        {readOnlyMode && <span className='previously-evaluated'>{displayConfirmationMessage ? t('Your feedback was recorded, thank you!') : t('This internship has already received feedback')}</span>}
        {!readOnlyMode && (
          showPopconfirm
            ? (
              <Popconfirm
                placement='top'
                okType='danger'
                title={t("You won't be able to add a comment after submitting the evaluation. Do you want to continue?")}
                okText={t('Yes')}
                cancelText={t('Cancel')}
                onConfirm={submitFeedback}
                overlayClassName='evaluation-popconfirm'
              >
                <Button
                  type='primary'
                  htmlType='button'
                  loading={submitting}
                >
                  <FontAwesomeIcon icon='check' />&nbsp;
                  {t('Confirm')}
                </Button>
              </Popconfirm>
            )
            : (
              <Button
                type='primary'
                htmlType='button'
                loading={submitting}
                onClick={submitFeedback}
              >
                <FontAwesomeIcon icon='check' />&nbsp;
                {t('Confirm')}
              </Button>
            )
        )}
      </div>
    </div>
  )
}

StudentEvaluationByInstitution.propTypes = {
  internship: shape({
    id: number,
    institution: number
  }).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEvaluationByInstitution)
