import React, { createContext, useEffect, useState } from 'react'
import { getLanguages, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import { getFullAbbreviation, getFullName } from '../../utils/entities/specialityYear'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getLanguages: getLanguages(state.locale)
  }
}

const InstitutionAssistantContext = createContext()

let InstitutionAssistantProvider = ({ children, getLanguages, onSelectAssistant, onPasswordReset, t }) => {
  const [loading, setLoading] = useState(true)
  const [languages, setLanguages] = useState([])

  useEffect(() => {
    if (getLanguages) {
      setLanguages(getLanguages.map(({ code, name }) => ({
        id: code,
        name: t(name)
      })))
    }
  }, [getLanguages, t])

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_STRING,
      name: t('Speciality'),
      key: 'specialityName',
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('financialYear'),
      key: d => {
        return getFullAbbreviation(d.financialYear)
      },
      tooltipKey: d => {
        return getFullName(d.financialYear)
      },
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Lastname'),
      key: 'lastname',
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Firstname'),
      key: 'firstname',
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Language'),
      key: 'language',
      options: languages,
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Email'),
      key: 'email',
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Registration number'),
      key: 'matricule',
      isNullable: true,
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Phone'),
      key: 'phoneNumber',
      disabled: true
    },
    {
      type: DATA_TYPE_DATE,
      name: t('Birthdate'),
      key: 'birthdate',
      format: 'DD/MM/YYYY',
      isNullable: true,
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Gender'),
      key: 'gender',
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Nationality'),
      key: 'nationality',
      disabled: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('NISS'),
      key: 'niss',
      password: true,
      disabled: true
    }
  ]

  const additionalActions = [
    {
      iconName: 'user-graduate',
      type: 'primary',
      title: t('Assistant sheet'),
      onClick: onSelectAssistant
    }
  ]

  return (
    <InstitutionAssistantContext.Provider value={{
      additionalActions,
      loading,
      setLoading,
      columns
    }}
    >
      {children}
    </InstitutionAssistantContext.Provider>
  )
}

InstitutionAssistantProvider = connect(mapStateToProps)(InstitutionAssistantProvider)

export {
  InstitutionAssistantProvider as default,
  InstitutionAssistantContext
}
