import { Select } from 'antd'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../../../Components/shared/SmartTable'
import { updateContactPerson } from '../../../reducers/ContactPersonsReducer/actions'
import { getUser } from '../../../reducers/UserReducer'
import { canonizeString, validateFormInput } from '../../../utils'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const mapDispatchToProps = dispatch => ({
  updateContactPerson: bindActionCreators(updateContactPerson, dispatch)
})

const Option = Select.Option

const ContactPersonsTable = ({
  contactPersons, loading, additionalActions, onChangeSelect, allOptionText, selectValue,
  optionsSelect, t, getUser, updateContactPerson
}) => {
  const COLUMNS = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_STRING,
      name: t('Lastname'),
      key: 'lastname',
      validate: data => {
        return validateFormInput('freeText', data, true)
      }
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Firstname'),
      key: 'firstname',
      validate: data => {
        return validateFormInput('freeText', data, true)
      }
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Email'),
      key: 'email',
      validate: data => {
        return validateFormInput('email', data)
      }
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Phone'),
      key: 'phone'
    }
  ]

  const handleSaveContactPerson = async (contact, stopSmartTableLoading) => {
    try {
      await updateContactPerson(contact, getUser)
      stopSmartTableLoading()
    } catch (err) {
      console.error(err)
      stopSmartTableLoading()
    }
  }

  const renderSelect = () => {
    if (optionsSelect) {
      return (
        <div>
          <Select
            showSearch
            className='select-sector'
            value={selectValue === null ? 'all' : selectValue}
            filterOption={(input, option) =>
              canonizeString(option.props.children).indexOf(
                canonizeString(input)
              ) > -1}
            onChange={e => onChangeSelect(e)}
          >
            {<Option key='all' value='all' title={allOptionText}>{allOptionText}</Option>}

            {optionsSelect.map(s => {
              return (
                <Option key={s.id} value={s.id} title={s.name}>
                  {s.name}
                </Option>
              )
            })}
          </Select>
        </div>
      )
    }
  }

  return (
    <>
      {renderSelect()}
      <SmartTable
        columns={COLUMNS}
        data={contactPersons}
        loading={loading}
        noDataText={t('There are no contact persons')}
        onDataEdit={handleSaveContactPerson}
        additionalActions={additionalActions}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonsTable)
