import React, { createContext, useContext, useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { fetchInstitutionSchools, getInstitutionSectors } from '../../utils/api/institution'
import { GlobalContext } from '../GlobalProvider'
import moment from 'moment'
import { TIME_PERIODS_TYPE, getTimePeriod } from '../../utils/timePeriods'
import { isObject } from '../../utils'
import { getBySection } from '../../utils/api/sectionToYear'

export const QuotasContext = createContext()

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const QuotasProvider = ({ children, user }) => {
  const [schoolYearsBySection, setSchoolYearsBySection] = useState({})
  const [sectors, setSectors] = useState([])
  const [schools, setSchools] = useState([])
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState(moment())
  const [periodType, setPeriodType] = useState(TIME_PERIODS_TYPE[1])
  const [period, setPeriod] = useState({})

  useEffect(() => {
    if (user) {
      setLoading(true)

      const sectorsPromise = getInstitutionSectors(user.institutions[0], user, { orderBy: 'name', strictMode: 1, archived: false })
        .then(json => {
          if (json && json.data) {
            setSectors(json.data)
          }
        })
      const schoolsPromise = fetchInstitutionSchools(user.institutions[0], user).then(json => {
        if (json && json.data) {
          setSchools(json.data)
        }
      })

      Promise.all([sectorsPromise, schoolsPromise]).then(() => setLoading(false))
    }
  }, [user])

  useEffect(() => {
    setPeriod(getTimePeriod(date, periodType))
  }, [date, periodType])

  const getSchoolYears = async section => {
    let schoolYears = []

    if (isObject(section)) {
      if (schoolYearsBySection[section.id]) {
        schoolYears = schoolYearsBySection[section.id]
      } else {
        const json = await getBySection(section, user)

        if (json && json.data) {
          schoolYears = json.data

          const newSchoolYearsBySection = { ...schoolYearsBySection }
          newSchoolYearsBySection[section.id] = schoolYears
          setSchoolYearsBySection(newSchoolYearsBySection)
        }
      }
    }

    return schoolYears
  }

  return (
    <QuotasContext.Provider
      value={{
        globalLoading: useContext(GlobalContext).globalLoading,
        date,
        getSchoolYears,
        loading,
        period,
        periodType,
        schools,
        sections: useContext(GlobalContext).sections,
        sectors,
        setDate,
        setPeriodType,
        user
      }}
    >
      {children}
    </QuotasContext.Provider>
  )
}

export default connect(mapStateToProps)(QuotasProvider)
