import React, { useEffect, useRef, useState } from 'react'

import { FULL_DATE_WITHOUT_TIMEZONE, internshipStates } from '../../../utils/constants'
import { Metadata } from '../../../utils/http'
import Footer from './Footer'
import Header from './Header'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getAllMaccsInternships } from '../../../utils/api/maccsInternship'
import { SCHEDULE_DISPLAY_STATE } from '../../../utils/entities/maccsInternship'
import Loading from '../../../Components/shared/Loading'
import { getTranslate } from 'react-localize-redux'
import InternshipsScheduler from './InternshipsScheduler'

import '../../../assets/maccs-shifts-manager.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsShiftsManager = ({ end, start, user, t }) => {
  const [internships, setInternships] = useState([])
  const [metadata, setMetadata] = useState(new Metadata({ perPage: 10 }))
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState({ search: '', schoolSpeciality: null })

  const timer = useRef(null)

  useEffect(() => {
    if (start && end && metadata.page) {
      setLoading(true)

      clearTimeout(timer.current)

      timer.current = setTimeout(() => {
        const parameters = metadata.toQueryParameters()

        parameters.endDate = end.format(FULL_DATE_WITHOUT_TIMEZONE)
        parameters.startDate = start.format(FULL_DATE_WITHOUT_TIMEZONE)
        parameters.state = SCHEDULE_DISPLAY_STATE

        if (filter.search) {
          parameters.search = filter.search
        }

        if (filter.speciality) {
          parameters.speciality = filter.speciality
        }

        if (filter.school) {
          parameters.school = filter.school
        }

        getAllMaccsInternships(parameters, user).then(json => {
          if (json?.data) {
            setInternships(json.data)
          }

          if (json?.meta) {
            setMetadata(new Metadata({ ...metadata, total: json.meta.pages.total, totalPages: json.meta.pages.total_pages }))
          }

          setLoading(false)
        })
      }, 500)
    }
  }, [start, end, metadata.page, filter, user.id])

  const handleInternshipValidation = updatedInternship => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === updatedInternship.id) {
        internship.state = internshipStates.SCHEDULE_VALIDATED
      }

      return internship
    })

    setInternships(updatedInternships)
  }

  const handleInternshipInvalidation = (updatedInternship, newState) => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === updatedInternship.id) {
        internship.state = newState
      }

      return internship
    })

    setInternships(updatedInternships)
  }

  return (
    <div className='maccs-shifts-manager'>
      <Header onFiltersChange={setFilter} onDateChange={() => setMetadata(new Metadata({ ...metadata, page: 1 }))} />
      <div className='shifts-manager-body'>
        {loading && (
          <Loading size='10x' />
        )}
        {!loading && !!internships.length && (
          <InternshipsScheduler
            endDate={end}
            internships={internships}
            startDate={start}
            onInvalidateInternship={handleInternshipInvalidation}
            onValidateInternship={handleInternshipValidation}
          />
        )}
        {!loading && !internships.length && (
          <div className='empty-body'>
            {t('shifts_manager.no_internships')}
          </div>
        )}
      </div>
      <Footer
        dataType='internship'
        metadata={metadata}
        loading={loading}
        pageSize={10}
        onMetadataChange={setMetadata}
      />
    </div>
  )
}

export default connect(mapStateToProps)(MaccsShiftsManager)
