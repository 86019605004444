import React, { createContext, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

export const GlobalFiltersContext = createContext()

const GlobalFiltersProvider = ({ children, user }) => {
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [internshipSearch, setInternshipSearch] = useState('')

  return (
    <GlobalFiltersContext.Provider
      value={{ internshipSearch, selectedInstitution, setInternshipSearch, setSelectedInstitution }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  )
}

export default connect(mapStateToProps)(GlobalFiltersProvider)
