import moment, { isMoment } from 'moment'

export const EUROPEAN_DATE = 'DD/MM/YYYY'
export const DATA_PROCESSING_FORMAT = 'YYYY-MM-DD'
export const SHORT_EUROPEAN_DATE = 'DD/MM'
export const DAY = 'day'
export const MONTH = 'month'
export const ISO_WEEK = 'isoWeek'
export const MONTH_STRING_FORMAT = 'MMMM'
export const DAY_STRING_FORMAT = 'dddd'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const SHORT_DATE_FORMAT = 'DD/MM'
export const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const HOURS_MINUTES_FORMAT = 'HH:mm'
export const FULL_TIME_FORMAT = 'HH:mm:ss'
export const HOURS_SPLIT_CHARACTER = ':'
export const HOURS_LABEL_CHARACTER = 'h'

export const getNextWeek = date => {
  return date.clone().add(7, DAY)
}

export const getPastWeek = date => {
  return date.clone().add(-7, DAY)
}

export const getNextMonthFirstMonday = date => {
  return getFirstMondayOfMonth(date.clone().add(1, MONTH))
}

export const getFirstMondayOfMonth = date => {
  return date.clone().startOf(MONTH).add(6, DAY).startOf(ISO_WEEK)
}

export const getPastMonthLastSunday = date => {
  return getLastSundayOfMonth(date.clone().add(-1, MONTH))
}

export const getLastSundayOfMonth = date => {
  return date.clone().endOf(MONTH).add(-6, DAY).endOf(ISO_WEEK)
}

export const toEuropeanDate = date => {
  if (date) {
    return date.format(EUROPEAN_DATE)
  }

  return '/'
}

export const convertTime = (time, inputSeparator = ':', outputSeparator = 'h', format = HOURS_MINUTES_FORMAT, deleteFirstZero = true) => {
  const timeArray = time.split(inputSeparator)

  switch (format) {
    case HOURS_MINUTES_FORMAT:
    {
      let hours = deleteFirstZero ? timeArray[0].replace(/^0+/, '') : timeArray[0]

      if (hours === '') {
        hours = '0'
      }

      return hours + outputSeparator + timeArray[1]
    }
  }
}

export const timeToSeconds = (time, splitCharacter = HOURS_SPLIT_CHARACTER, positive = true) => {
  let seconds = 0

  if (typeof time === 'string' && time.includes(splitCharacter)) {
    const timeParts = time.split(splitCharacter)

    seconds = Number(timeParts[0]) * 3600 + Number(timeParts[1] ?? '0') * 60 + Number(timeParts[2] ?? '0')
  }

  return positive ? seconds : -seconds
}

export const getTimezoneLessMoment = dateString => {
  if (isMoment(dateString)) {
    return dateString
  }

  return typeof dateString === 'string' ? moment(dateString.split('+')[0]) : null
}

export const secondsToTime = (seconds, includeSeconds = false) => {
  let time = includeSeconds ? '00:00:00' : '00:00'

  if (seconds > 0) {
    const timeHours = Math.floor(seconds / 3600)
    const timeMinutes = Math.floor((seconds % 3600) / 60)

    time = numberToTimeString(timeHours) + ':' + numberToTimeString(timeMinutes)

    if (includeSeconds) {
      const timeSeconds = seconds % 60

      time += ':' + numberToTimeString(timeSeconds)
    }
  }

  return time
}

export const numberToTimeString = number => {
  return (number > 9 ? '' : '0') + number
}

export const getMoment = value => {
  let newMoment = moment()

  if (typeof value === 'string') {
    const timeString = value.includes('+') ? value.split('+')[0] : value

    newMoment = moment(timeString)
  } else if (isMoment(value)) {
    newMoment = value
  }

  return newMoment
}
