import { createSelector } from 'reselect'
import { getAppStates } from './actions'

export const selectAppLoading = createSelector(
  state => getAppStates(state).loadings,
  loadings => {
    const { workingRegimes } = loadings

    return workingRegimes
  }
)
