import { getAllWorkingRegimes } from '../../utils/api/workingRegime'
import { APP_STATES } from './actionsType'

const { SET_PAUSE_ACTIVATION, GET_WORKING_REGIME } = APP_STATES

export const getAppStates = state => {
  return state.appStates
}

export const fetchWorkingRegimes = user => {
  return async (dispatch, getState) => {
    getAllWorkingRegimes(user).then(data => {
      dispatch({
        type: GET_WORKING_REGIME,
        payload: data
      })
    })
  }
}

export const setPauseActivationState = newState => {
  return {
    type: SET_PAUSE_ACTIVATION,
    payload: newState
  }
}
