import { Select } from 'antd'

import React from 'react'

const { Option } = Select

const NullableSelect = ({ children, className, value, onChange, ...props }) => {
  return (
    <Select className={className} value={value} onChange={onChange} {...props}>
      <Option key={-1} value={null}> / </Option>
      {children}
    </Select>
  )
}

export default NullableSelect
