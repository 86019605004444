import { Card } from 'antd'
import moment from 'moment'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { formatTimeFromMinutes } from '../../utils'
import { internshipStates } from '../../utils/constants'
import InformationDisplay from './InformationDisplay'
import InternshipStatePellet from './InternshipStatePellet'
import { faBriefcase, faMapMarker, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const MaccsInternshipCard = (props) => {
  const { maccsInternship, setSelectedMaccsInternshipInfo, t } = props

  const maccsInternshipInfo = [
    {
      id: 1,
      icon: faMapMarker,
      title: t('Location'),
      caption: (maccsInternship.institution === null
        ? t('Without institution')
        : maccsInternship.institution.name)
    },
    {
      id: 2,
      icon: faBriefcase,
      title: t('Working regime'),
      caption: maccsInternship.workingRegime === null
        ? t('Without working regime')
        : t('Working regime') + ' : ' + t(maccsInternship.workingRegimeName)
    },
    {
      id: 3,
      icon: faCalendarAlt,
      title: t('Dates'),
      caption: (
        t('From') + ' ' +
        moment(maccsInternship.startDate).format('DD MMMM YYYY') +
        ' ' + t('to') + ' ' +
        moment(maccsInternship.endDate).format('DD MMMM YYYY')
      )
    }
  ]

  const computeTotalTimeInMaccsInternship = maccsInternship => {
    if (maccsInternship.state !== internshipStates.SCHEDULE_VALIDATED || maccsInternship.shifts !== undefined || maccsInternship.shifts.length === 0) {
      return null
    }

    let totalTime = null

    maccsInternship.shifts.forEach(shift => {
      if (shift.id > -1) {
        let shiftMinutesDuration = 0
        const shiftStartMoment = moment(shift.startDate.date)
        const shiftEndMoment = moment(shift.endDate.date)

        if (shiftStartMoment > shiftEndMoment) {
          /**
           * because the backend returns the same date even if the actual end date is
           * passed midnight, we add one day to the end time so we can have a correct computation
           * of the duration
           */
          shiftMinutesDuration = shiftEndMoment.add(1, 'day').diff(shiftStartMoment, 'minutes') - shift.pause
        } else {
          shiftMinutesDuration = shiftEndMoment.diff(shiftStartMoment, 'minutes') - shift.pause
        }

        if (!totalTime) {
          totalTime = 0
        }

        totalTime += shiftMinutesDuration
      }
    })

    if (totalTime !== null) {
      // format the minutes to H:MM
      totalTime = formatTimeFromMinutes(totalTime)
    }

    return totalTime
  }

  // compute total
  const totalTime = computeTotalTimeInMaccsInternship(maccsInternship)

  return (
    <Card
      className='fake-button'
      key={maccsInternship.id}
      style={{ marginTop: '15px' }}
      onClick={() => setSelectedMaccsInternshipInfo(maccsInternship)}
    >
      <div className='flex-row'>
        <InformationDisplay
          information={maccsInternshipInfo}
          hideTitle
        />
        <div className='flex-fill' />
        <div className='student-fiche-right-side'>
          <InternshipStatePellet internship={maccsInternship} />
          {totalTime &&
            <div className='internship-total-time'>
              <label>{t('Total (hours)')}</label>
              <p>{totalTime}</p>
            </div>}
        </div>
      </div>
    </Card>
  )
}

export default connect(mapStateToProps)(MaccsInternshipCard)
