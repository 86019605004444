import React, { useEffect, useState } from 'react'

import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import { validateFormInput } from '../../../../utils'
import { getInstitution, getInstitutionDocuments, oldUpdateInstitution } from '../../../../utils/api/institution'
import { getCountries } from '../../../../utils/api/constant'
import FilesManager from '../../../shared/FilesManager'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING, DATA_TYPE_SELECT } from '../../../shared/SmartTable'
import { ROLE_LOCAL_SECRETARY } from '../../../../utils/constants'

const Institution = props => {
  const [institution, setInstitution] = useState(null)
  const [countries, setCountries] = useState([])
  const [columns, setColumns] = useState([])
  const [enableFilesManaging, setEnableFilesManaging] = useState(false)
  const [selectedInstitutionFiles, setSelectedInstitutionFiles] = useState(null)

  useEffect(() => {
    if (props.getUser && props.t) {
      const canEdit = props.getUser.roles.includes(ROLE_LOCAL_SECRETARY)

      setEnableFilesManaging(canEdit)
      setColumns([
        {
          type: DATA_TYPE_ID,
          key: 'id'
        },
        {
          type: DATA_TYPE_STRING,
          name: props.t('Name'),
          key: 'name',
          validate: (data) => validateFormInput('freeText', data, true),
          disabled: !canEdit
        },
        {
          type: DATA_TYPE_STRING,
          name: props.t('Address'),
          key: 'address',
          validate: (data) => validateFormInput('freeText', data, true),
          disabled: !canEdit
        },
        {
          type: DATA_TYPE_STRING,
          name: props.t('Zipcode'),
          key: 'zipcode',
          validate: (data) => validateFormInput('freeText', data, true),
          disabled: !canEdit
        },
        {
          type: DATA_TYPE_STRING,
          name: props.t('City'),
          key: 'city',
          validate: (data) => validateFormInput('name', data, true),
          disabled: !canEdit
        },
        {
          type: DATA_TYPE_SELECT,
          name: props.t('Country'),
          key: 'country',
          options: countries,
          disabled: !canEdit
        },
        {
          type: DATA_TYPE_STRING,
          name: props.t('Description for students'),
          key: 'description',
          disabled: !canEdit
        }
      ])
    }
  }, [props.getUser, props.t, countries])

  useEffect(() => {
    if (props.getUser) {
      const institution = props.getUser?.institutions[0] ?? null
      if (institution) {
        getInstitution(props.getUser, institution)
          .then(json => {
            if (json && json.data) {
              setInstitution(extractInstitutionData(json.data))
            }
          })
      }
      getCountries(props.getUser)
        .then(json => {
          if (json && json.data) {
            setCountries(json.data)
          }
        })
    }
  }, [props.getUser])

  const handleEditInstitution = (institution) => {
    oldUpdateInstitution(institution, props.getUser)
      .then(json => {
        if (json && json.data) {
          const institution = json.data

          if (institution && institution.country) {
            institution.country = { id: institution.country }
          }

          setInstitution(extractInstitutionData(institution))
        }
      })
  }

  const extractInstitutionData = institution => {
    return {
      id: institution.id,
      name: institution.name,
      address: institution.address,
      zipcode: institution.zipcode,
      country: institution.country === null ? null : institution.country.id,
      city: institution.city,
      description: institution.description
    }
  }

  return (
    <div>
      <SmartTable
        hideTableControls
        columns={columns}
        data={institution !== null ? [institution] : []}
        loading={institution === null}
        onDataEdit={handleEditInstitution}
        additionalActions={[
          { iconName: 'file', type: 'primary', title: props.t('Manage files'), onClick: setSelectedInstitutionFiles }
        ]}
      />
      {institution !== null && (
        <FilesManager
          entity={selectedInstitutionFiles}
          entityName='Institution'
          onClose={() => setSelectedInstitutionFiles(null)}
          userContextFiltered
          preventChanges={!enableFilesManaging}
          getDocuments={() => getInstitutionDocuments(institution, props.getUser)}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Institution)
