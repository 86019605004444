import React, { useEffect } from 'react'

import Analytics from '../utils/analytics'
import DataTable from '../Components/DataTables/DataTable'
import { IN_INSTITUTION_STATE, getAssistantBirthdate, getAssistantEmail, getAssistantFirstname, getAssistantFullFinancialYearAbbreviation, getAssistantFullSpecialityAbbreviation, getAssistantLastname, getAssistantMatricule, getAssistantPhone, getEndDate, getInstitutionName, getSchoolName, getSpecialityAbbreviation, getStartDate, getWorkingRegimeName } from '../utils/entities/maccsInternship'
import { INSTITUTION_MACCS_CONTEXT, SCHOOL_MACCS_CONTEXT } from '../utils/constants'
import { getAllMaccsInternships } from '../utils/api/maccsInternship'
import { downloadSpreadSheet, getMaccsInternshipsByPeriod } from '../utils/api/export'

const COLUMNS_DATA = [
  {
    title: 'Start',
    key: getStartDate,
    style: { maxWidth: '100px' }
  },
  {
    title: 'End',
    key: getEndDate,
    style: { maxWidth: '100px' }
  },
  {
    title: 'School',
    key: getSchoolName,
    style: { maxWidth: '200px' },
    context: INSTITUTION_MACCS_CONTEXT
  },
  {
    title: 'Institution',
    key: getInstitutionName,
    style: { maxWidth: '200px' },
    context: SCHOOL_MACCS_CONTEXT
  },
  {
    title: "Internship's speciality",
    key: getSpecialityAbbreviation
  },
  {
    title: 'Financial year',
    key: getAssistantFullFinancialYearAbbreviation
  },
  {
    title: "Assistant's speciality",
    key: getAssistantFullSpecialityAbbreviation
  },
  {
    title: 'Working regime',
    key: getWorkingRegimeName,
    translate: true
  },
  {
    title: 'Lastname',
    key: getAssistantLastname
  },
  {
    title: 'Firstname',
    key: getAssistantFirstname
  },
  {
    title: 'Email',
    key: getAssistantEmail,
    style: { maxWidth: '400px' }
  },
  {
    title: 'Registration number',
    key: getAssistantMatricule
  },
  {
    title: 'Phone',
    key: getAssistantPhone
  },
  {
    title: 'Birthdate',
    key: getAssistantBirthdate
  }
]

const AssistantsReportingView = props => {
  useEffect(() => {
    Analytics.pageView('/institution-badge')
  }, [])

  return (
    <DataTable
      columns={COLUMNS_DATA}
      getData={(user, parameters) => {
        return getAllMaccsInternships({ ...parameters, state: IN_INSTITUTION_STATE }, user)
      }}
      apiExport={getMaccsInternshipsByPeriod}
      apiDownload={downloadSpreadSheet}
    />
  )
}

export default AssistantsReportingView
