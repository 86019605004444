// This is just a file with routes constants to make sure we don't misspell the routes
export default {
  ASSISTANTS: '/assistants',
  BADGES: '/badges',
  CHANGELOG: '/changelog',
  CONTACT_PERSONS: '/contact-persons',
  INSTITUTIONS: '/institutions',
  INSTITUTION_SECTORS: '/institution-sectors',
  INTERNSHIPS: '/internships',
  LOGIN: '/login',
  MODULES_SETTINGS: '/modules-settings',
  PASSWORD_RESET_REQUEST: '/password-reset-request',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_CHANGE: '/password-change',
  PRESETS_MANAGER: '/presets-manager',
  PROFILE_PAGE: '/profile-page',
  QUOTAS: '/quotas',
  SCHOOLS: '/schools',
  SCHOOL_SECTION: '/school-section',
  SECTION_EVALUATION_BY_STUDENT: '/section-evaluation',
  SETTINGS: '/settings',
  SHIFTS_MANAGER: '/shifts-manager',
  SIGNUP: '/signup',
  SPECIALITIES: '/specialities',
  STUDENTS: '/students',
  STUDENTS_EVALUATION: '/students-evaluation',
  STUDENT_SIGNUP: '/student-signup',
  SWITCH_USER: '/switch-user',
  TAGS_MANAGEMENT: '/tags',
  USER_SIGNUP: '/user-signup',
  // "WIDGETS" route below
  SHIFTS_WIDGET: '/widget/shifts',
  // super admin only routes below:
  MAILS_LIST: '/mails-list',
  CHANGELOG_MANAGEMENT: '/changelog-management',
  CONFIRM_ADDITIONAL_EMAIL: '/confirm-additional-email'
}
