import React, { useContext, useMemo } from 'react'

import { getDateCellClassName, getDisablingState, getNewPeriod, getParametersByIndexes } from '../utils/utils'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import IconDateCell from './IconDateCell'
import QuotaDateCell from './QuotaDateCell'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import moment from 'moment'
import { internshipStates } from '../../../../utils/constants'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SchoolDateCell = ({ disabled, startDate, endDate, index, periodIndexes, onDoubleClick, onPeriodChange, isHovered, t }) => {
  const { internshipProvider, periodType } = useContext(InternshipsManagerContext)
  const { internship, quotas } = useContext(internshipProvider.context)

  const className = useMemo(() => getDateCellClassName(index, periodIndexes), [index, periodIndexes])
  const parameters = useMemo(() => getParametersByIndexes(periodIndexes, index), [periodIndexes, index])
  const inPeriod = useMemo(() => index >= periodIndexes.startIndex && index <= periodIndexes.lastIndex, [index, periodIndexes])
  const localDisabled = useMemo(() => getDisablingState(internship) || internship.state === internshipStates.PRE_ACCEPTED || disabled, [internship, disabled])

  const changePeriod = () => {
    const period = getNewPeriod(parameters, startDate, endDate, periodType)

    if (period === null) {
      console.error('invalid parameters for DateCell : ', parameters)
    }

    callFunctionIfDefined(onPeriodChange, [period, parameters.direction, index])
  }

  const handleDoubleClick = () => {
    if (typeof onDoubleClick === 'function') {
      onDoubleClick(internship)
    }
  }

  return (
    <td className={className} onDoubleClick={handleDoubleClick}>
      {isHovered && parameters.icon && !localDisabled && (
        <IconDateCell
          className={className}
          placement='top'
          title={t(parameters.title)}
          icon={parameters.icon}
          onClick={changePeriod}
        />
      )}
      {inPeriod && (!parameters.icon || !isHovered || localDisabled) && (
        <QuotaDateCell
          endDate={typeof internship.endDate === 'string' ? moment(internship.endDate.split('+')[0]) : moment()}
          placement='top'
          school={internship.school}
          sector={internship.sector}
          startDate={typeof internship.startDate === 'string' ? moment(internship.startDate.split('+')[0]) : moment()}
          t={t}
          value={quotas && typeof quotas[index] === 'number' ? quotas[index] : null}
        />
      )}
    </td>
  )
}

export default connect(mapStateToProps)(SchoolDateCell)
