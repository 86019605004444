import { CONTACT_PERSONS } from './actionsType'

const { GET_CONTACT_PERSONS, UPDATE_CONTACT_PERSON } = CONTACT_PERSONS

const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONTACT_PERSONS: {
      return action.payload
    }
    case UPDATE_CONTACT_PERSON: {
      return state.map(contactPerson => {
        if (contactPerson.id === action.payload.id) {
          return action.payload
        }
        return contactPerson
      })
    }
    default:
      return state
  }
}
