import React, { useRef, useState } from 'react'

import { Button, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { downloadConvention, uploadDocument } from '../../../utils/api/maccsInternshipDocument'
import { ACCEPTED_DOCUMENT_TYPES } from '../../../utils/constants'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ConventionButton = ({ document, internship, loading, t, user, afterDocumentDownload }) => {
  const [uploading, setUploading] = useState(false)

  const inputRef = useRef(null)

  const handleUpload = e => {
    setUploading(true)

    const file = e.target.files[0]

    if (file && ACCEPTED_DOCUMENT_TYPES.indexOf(file.type) > -1) {
      const body = {
        'document[maccsinternship]': internship.id,
        file: file,
        type: 'convention'
      }

      uploadDocument(body, user)
        .then(json => {
          afterDocumentDownload(json.data)
          setUploading(false)

          notification.success({ message: 'Convention de stage envoyée !', placement: 'bottomRight' })
        })
    } else {
      notification.error({ message: this.props.t('Only PDF, Word, Excel and CSV file types are supported.'), placement: 'bottomRight' })
    }
  }

  return (
    <div className='flex-row'>
      {loading &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='loading-icon black' />
          <div style={{ marginLeft: '4px', marginTop: '4px' }}>{t('Loading convention')}</div>
        </div>}
      {!loading && document &&
        <Button type='default' size='small' onClick={() => downloadConvention(document, user)} loading={uploading}>
          <FontAwesomeIcon icon='download' />&nbsp;{t('Download')}
        </Button>}
      <div className='h-spacing' />
      {!loading && document && document.locked && <div>{t('Your internship convention has been accepted.')}</div>}
      {!loading && document && !document.locked &&
        <Button type='primary' size='small' onClick={() => inputRef.current.click()} loading={uploading}>
          <FontAwesomeIcon icon='redo' />&nbsp;{t('Update')}
        </Button>}
      {!loading && !document &&
        <Button size='small' type='primary' onClick={() => inputRef.current.click()} loading={uploading}>
          <FontAwesomeIcon icon='plus' />&nbsp;{t('Add')}
        </Button>}
      <input type='file' ref={inputRef} onChange={handleUpload} accept={ACCEPTED_DOCUMENT_TYPES.join(',')} style={{ display: 'none' }} />
    </div>
  )
}

export default connect(mapStateToProps)(ConventionButton)
