import React, { useState } from 'react'
import FilesManager from '../../shared/FilesManager'
import { Button } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import '../../../assets/profile-page.scss'

const FILE_BUTTON_TEXT = 'Manage personnal files'

const PersonnalFiles = (props) => {
  const [loadFiles, setLoadFiles] = useState(false)

  return (
    <div className='flex-column'>
      <Button id='file-getter-header' className='fit-content margin-left' type='primary' onClick={() => setLoadFiles(!loadFiles)}> {props.t(FILE_BUTTON_TEXT)} </Button>
      <FilesManager
        entity={loadFiles ? props.entity : null}
        entityName={props.entityName ? props.entityName : 'Student'}
        onClose={() => setLoadFiles(false)}
        userContextFiltered
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalFiles)
