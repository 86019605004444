import React, { useState, useEffect, useMemo } from 'react'

import { Drawer, notification } from 'antd'
import { requestWithPromise, generalErrorHandler, recursiveIncludes, isObject } from '../../utils'
import UserSectorTable, { DATA_TYPE_SELECT, DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR, DATA_TYPE_CHECK_CONTACT_PERSON, DATA_TYPE_TAG } from './UserSectorTable'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ADMIN_CONTEXT, ORDER_BY, ROLE_HOSPITAL_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN } from '../../utils/constants'
import { getUser, setUserManagedSectors } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { onError } from '../../utils/apiHelper'
import { bindActionCreators } from 'redux'
import Loading from '../../HOC/Loading'
import { getInstitutionSectors } from '../../utils/api/institution'
import { getUserSectors } from '../../utils/api/user'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser),
    t: getTranslate(state.locale)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setReduxUserManagedSectors: bindActionCreators(setUserManagedSectors, dispatch)
  }
}

const UserManagedSectors = ({ user, schoolId, institutionSectors = [], selectedUser, onClose, setReduxUserManagedSectors, t }) => {
  const [userManagedSectors, setUserManagedSectors] = useState([])
  const [userSectors, setUserSectors] = useState({ contactPersons: [], managedBy: [] })
  const [loading, setLoading] = useState(false)

  const isSuperAdmin = useMemo(() => user.context === ADMIN_CONTEXT, [user])
  const unarchivedInstitutionSectors = useMemo(() => {
    const sectors = []

    if (institutionSectors && institutionSectors.length > 0) {
      institutionSectors.forEach(s => {
        if (!s.archived) {
          sectors.push(s)
        }
      })
    }

    return sectors
  }, [institutionSectors])
  const managedSectors = useMemo(() => {
    return userManagedSectors.map(s => {
      return {
        id: s.id,
        sector: s.id,
        name: s.name,
        archived: !unarchivedInstitutionSectors.find(is => s.id === is.id)
      }
    })
  }, [userManagedSectors, unarchivedInstitutionSectors])

  useEffect(() => {
    if (selectedUser !== null) {
      const synchroTools = { function: getUserSectors, parameters: [user, selectedUser] }

      if (recursiveIncludes(selectedUser.roles, [ROLE_HOSPITAL_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN])) {
        synchroTools.function = getInstitutionSectors
        synchroTools.parameters = [selectedUser.institutions[0], user, { archived: null, orderBy: ORDER_BY.NAME }]
      }

      setLoading(true)

      synchroTools.function(...synchroTools.parameters).then(json => {
        if (json?.data) {
          loadUser(json.data)
        }

        setLoading(false)
      })
    } else {
      setUserManagedSectors([])
    }
  }, [selectedUser, user])

  const loadUser = sectors => {
    const userSectors = {
      contactPersons: [],
      managedBy: []
    }

    sectors.forEach(s => {
      userSectors.contactPersons[s.id] = s.contactPersons.map(contactPerson => {
        return contactPerson.id
      })
      userSectors.managedBy[s.id] = s.managedBy.map(user => {
        return user.id
      })
    })

    setUserSectors(userSectors)
    setUserManagedSectors(sectors)
  }

  const handleSectorAddition = sector => {
    if (sector.newId === null) {
      return
    }

    const newSector = institutionSectors.find(s => s.id === sector.newId)

    let managedSectors = userManagedSectors
    const userManagedSectorIds = managedSectors.map(s => s.id)

    if (userManagedSectorIds.includes(newSector.id)) {
      onError(t('This user already coordinates this sector'))
      return
    } else {
      managedSectors = userManagedSectors.concat(newSector)
    }

    handleUserCoordinatedSectorUpdating(managedSectors)
  }

  const handleSectorEdition = sector => {
    const editedSector = institutionSectors.find(s => s.id === sector.newId)
    const userManagedSectorIds = managedSectors.map(s => s.id)

    if (userManagedSectorIds.includes(editedSector.id)) {
      onError(t('This user already coordinates this sector'))

      return
    }

    handleUserCoordinatedSectorUpdating(userManagedSectors.map(ums => ums.id === sector.prevId ? editedSector : ums))
  }

  const handleSectorDeletion = sector => {
    const managedSectors = userManagedSectors.filter(ums => ums.id !== sector.sector)

    handleUserCoordinatedSectorUpdating(managedSectors)
  }

  const handleUserCoordinatedSectorUpdating = (userManagedSectors, confirmationMessage = false) => {
    if (selectedUser.id === user.id) {
      setReduxUserManagedSectors(userManagedSectors)
    }

    setUserManagedSectors(userManagedSectors)

    const body = { managedSectors: userManagedSectors.map(ums => ums.id) }

    if (isSuperAdmin && schoolId) {
      body.schoolId = schoolId
    }

    requestWithPromise('/user/modify/' + selectedUser.id, 'POST', body, user)
      .then((json) => {
        if (confirmationMessage) {
          notification.success({ message: t('Saved changes'), placement: 'bottomRight' })
        }
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const userIsContactPerson = sectorId => {
    if (selectedUser && userSectors.contactPersons[sectorId]) {
      return userSectors.contactPersons[sectorId].includes(selectedUser.id)
    }
  }

  const userIsAdmin = sectorId => {
    if (selectedUser && userSectors.managedBy[sectorId]) {
      return userSectors.managedBy[sectorId].includes(selectedUser.id)
    }
  }

  const setUserContactPerson = sectorId => {
    const body = {
      userToAdd: selectedUser.id
    }

    requestWithPromise(`/api/sectors/${sectorId}/contact-persons`, 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setManagedUser = sectorId => {
    const body = {
      userToAdd: selectedUser.id
    }

    requestWithPromise(`/api/sectors/${sectorId}/managed-user`, 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setMultipleUserContactPersons = sectors => {
    const body = {
      userToAdd: selectedUser.id,
      sectors: sectors
    }

    requestWithPromise('/sectors/contact-persons', 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setMultipleManagedUsers = sectors => {
    const body = {
      userToAdd: selectedUser.id,
      sectors: sectors
    }

    requestWithPromise('/sectors/managed-users', 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const searchWithFilter = (value, isAdmin) => {
    const search = typeof value !== 'undefined' ? `?search=${value}` : ''

    if (isAdmin) {
      requestWithPromise(`/api/institutions/${selectedUser.institutions[0].id}/sectors${search}`, 'GET', null, user)
        .then(jsonResponse => {
          if (jsonResponse && jsonResponse.data) {
            loadUser(jsonResponse.data)
          }
        })
    } else {
      requestWithPromise(`/api/users/${selectedUser.id}/sectors${search}`, 'GET', null, user)
        .then(jsonResponse => {
          if (jsonResponse && jsonResponse.data) {
            loadUser(jsonResponse.data)
          }
        })
    }
  }

  const columns = useMemo(() => [
    {
      type: DATA_TYPE_SELECT,
      name: t('Sector'),
      options: unarchivedInstitutionSectors
    },
    {
      type: DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR,
      name: t('Local administrator')
    },
    {
      type: DATA_TYPE_CHECK_CONTACT_PERSON,
      name: t('Contact persons')
    },
    {
      type: DATA_TYPE_TAG,
      name: t('Status')
    }
  ], [t, unarchivedInstitutionSectors])

  const additionalActions = [
    {
      icon: faTrash,
      type: 'danger',
      title: 'Delete',
      titlePopconfirm: t('Delete this sector?'),
      handleOnClick: data => handleSectorDeletion(data)
    }
  ]

  return (
    <Drawer
      title={isObject(selectedUser) ? t('Sectors managed by') + ' ' + selectedUser.email : '?'}
      width='640px'
      onClose={onClose}
      visible={selectedUser !== null}
    >
      <Loading loading={loading}>
        <UserSectorTable
          onDataAdd={handleSectorAddition}
          onDataEdit={handleSectorEdition}
          data={managedSectors}
          columns={columns}
          additionalActions={additionalActions}
          userIsContactPerson={userIsContactPerson}
          userIsAdmin={userIsAdmin}
          setUserContactPerson={setUserContactPerson}
          setManagedUser={setManagedUser}
          setMultipleUserContactPersons={setMultipleUserContactPersons}
          setMultipleManagedUsers={setMultipleManagedUsers}
          user={selectedUser}
          searchWithFilter={searchWithFilter}
        />
      </Loading>
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagedSectors)
