import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import {Switch, Card, Tooltip, Button, Input, Form} from 'antd'
import { connect } from 'react-redux'
import '../../../assets/profile-page.scss'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'

const DATA_USAGE_TITLE = 'Use of my data'
const DATA_USAGE_TIPS_TEXT = "Manage the sharing of your Interneo's data with other parties."

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const OptInForm = (props) => {
  const [optIn, setOptIn] = useState(false)

  useEffect(() => {
    if (typeof props.optInValue === 'boolean') {
      setOptIn(props.optInValue)
    }
  }, [props.optInValue])

  const onSwitchChange = () => props.modifyOptIn(props.getUser, !optIn, setOptIn)

  return (
    <div style={{ marginBottom: props.marginBottom ?? '5px'}}>
      <Card
        type='inner'
        title={[props.t(DATA_USAGE_TITLE), ' ',
          <Tooltip key='personal_section_tooltip' placement='right' title={props.t(DATA_USAGE_TIPS_TEXT)}>
            <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
          </Tooltip>]}
      >
        {[
          <Switch key='recruitement' checked={optIn} onChange={onSwitchChange} />,
          ' ',
          props.t('for recruitment purposes')
        ]}
      </Card>
    </div>
  )
}

export default connect(mapStateToProps)(OptInForm)
