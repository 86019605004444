import { requestWithPromise } from '..'
import { DELETE, PATCH, POST } from '../apiHelper'

export const createRejectionReason = (institution, reason, user) => {
  return requestWithPromise(
    '/api/RejectionReason',
    POST,
    { institution: institution.id, name: reason.name },
    user
  )
}

export const deleteRejectionReason = (reason, user) => {
  return requestWithPromise(
    `/api/RejectionReason/${reason.id}`,
    DELETE,
    null,
    user
  )
}

export const updateRejectionReason = (reason, user) => {
  return requestWithPromise(
    `/api/RejectionReason/${reason.id}`,
    PATCH,
    { name: reason.name },
    user
  )
}
