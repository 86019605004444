import React, { useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import { COLUMNS_INFO_COUNT, DEFAULT_NUMBER_OF_WEEKS } from '../../shared/InternshipsManager/utils/constants'
import { getInternshipLine } from '../../shared/InternshipsManager/utils/componentGetters'
import '../../../assets/internships-manager.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const NO_INTERNSHIPS_TEXT = 'There is no internship to show for these dates.'

const MaccsInternshipsTableBody = ({ loading, t }) => {
  const { dataProvider, internshipProvider } = useContext(InternshipsManagerContext)
  const {
    user, highlightedIndexes, internships, refreshInternships,
    refreshQuotas, onEarlyStartDate, onLineHover
  } = useContext(dataProvider.context)
  const { Provider } = internshipProvider
  const InternshipLine = getInternshipLine(user.context)
  const timer = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleLineHover = indexes => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (highlightedIndexes === null || indexes.startIndex !== highlightedIndexes.startIndex || indexes.lastIndex !== highlightedIndexes.lastIndex) {
      timer.current = setTimeout(() => {
        onLineHover(indexes)
      }, 100)
    }
  }

  return (
    <tbody>
      {!loading && Provider && InternshipLine &&
        internships.map((i, index) => {
          return (
            <Provider
              key={`internship-${index}`}
              internship={i}
              refreshInternships={refreshInternships}
              refreshQuotas={refreshQuotas}
              onEarlyStartDate={onEarlyStartDate}
            >
              <InternshipLine onLineHover={handleLineHover} />
            </Provider>
          )
        })}
      {!loading && internships.length === 0 &&
        <tr>
          <td colSpan={COLUMNS_INFO_COUNT + DEFAULT_NUMBER_OF_WEEKS}>
            {t(NO_INTERNSHIPS_TEXT)}
          </td>
        </tr>}
      {loading && (
        <tr>
          <td id='loading-td'>
            <div>
              <div className='table-loading-ring' />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  )
}

export default connect(mapStateToProps)(MaccsInternshipsTableBody)
