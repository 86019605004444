import React, { Component } from 'react'
import { arrayOf, shape, string, oneOfType, number, bool } from 'prop-types'
import { Drawer, notification } from 'antd'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request, requestMultipart, downloadFile, generalErrorHandler } from '../../utils'
import { isArray, isObject } from 'lodash'

export const DOCUMENT_TYPE = 'document'
export const INTERNSHIP_VALIDATION_TYPE = 'validation'
export const INTERNSHIP_DOCUMENT_TYPE = 'internship_document'

export const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'image/jpeg'
]
const STUDENT_DOCUMENT_TYPES = [
  'application/pdf',
  'image/jpeg'
]
const STUDENT_ENTITY_NAME = 'Student'

class FilesManager extends Component {
  static propTypes = {
    /**
     * uploadParams -> can have object items, (key:value) these are sent to the server when uploading the file
     * It was needed when I implemented the uploading of validation document by students
     */
    uploadParams: arrayOf(shape({
      key: string,
      value: oneOfType([string, number, bool])
    })),
    /**
     * If preventChanges si true, user won't be able to upload or remove items
     */
    preventChanges: bool
  }

  static defaultProps={
    uploadParams: [],
    preventChanges: false
  }

  state = {
    files: [],
    loading: false,
    loaded: false,
    uploading: false
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.entity === null) {
      this.setState({ files: [], loaded: false })
    }

    if (this.props.getDocuments && !this.state.loaded) {
      this.setState({ loading: true, loaded: true })

      this.props.getDocuments().then(data => {
        const newState = ({
          loading: false
        })

        if (data) {
          newState.files = data.documents ?? data
        }

        this.setState(newState)
      })
    }

    if (this.props.entity === null && isObject(nextProps.entity) && !isArray(nextProps.files) && (!this.props.getDocuments || !nextProps.getDocuments)) {
      this.setState({ loading: true })
      request('/api/' + nextProps.entityName + '/' + nextProps.entity.id + '/all', 'GET', null, this.props.getUser)
        .then(json => {
          let files = json.data.documents

          files = this.filterByProps(files, nextProps)

          this.setState({
            loading: false,
            files: files
          })
        })
    }

    if (isArray(nextProps.files)) {
      this.setState({
        loading: false,
        files: nextProps.files
      })
    }
  }

  filterByProps = (files, props) => {
    if (typeof props.fileType === 'undefined') {
      return files
    }

    if (props.fileType === INTERNSHIP_VALIDATION_TYPE) {
      return files.filter(f => f.id === props.entity.studentValidationDocumentId)
    }

    if (props.fileType === INTERNSHIP_DOCUMENT_TYPE) {
      return files.filter(f => f.id !== props.entity.studentValidationDocumentId && f.type === DOCUMENT_TYPE)
    }

    return files.filter(f => f.type === props.fileType)
  }

  fileTypeIsValid = (file, entityName) => {
    const acceptedDocumentTypes = entityName === STUDENT_ENTITY_NAME
      ? { list: STUDENT_DOCUMENT_TYPES, message: this.props.t('Only PDF file type is authorized.') }
      : { list: ACCEPTED_DOCUMENT_TYPES, message: this.props.t('Only PDF, Word, Excel and CSV file types are supported.') }

    if (file && acceptedDocumentTypes.list.indexOf(file.type) > -1) {
      return null
    }

    return acceptedDocumentTypes.message
  }

  handleFileUpload = (e) => {
    this.setState({ uploading: true })

    const file = e.target.files[0]
    const message = this.fileTypeIsValid(file, this.props.entityName)

    if (!message) {
      const body = {
        file: file
      }

      this.props.uploadParams.forEach(item => {
        if (item.key && item.value !== undefined) {
          body[`document[${item.key}]`] = item.value
        }
      })

      body['document[' + this.props.entityName.toLowerCase() + ']'] = this.props.entity.id

      requestMultipart('/' + this.props.entityName.toLowerCase() + '/document/upload', 'POST', body, this.props.getUser)
        .then(json => {
          const files = this.state.files
          const newFileDetails = json.data

          files.unshift(newFileDetails)

          this.setState({
            uploading: false,
            files: files,
            type: typeof this.props.type === 'undefined' ? 'document' : this.props.type
          })

          if (this.props.onUploadsucceeded) {
            this.props.onUploadsucceeded(newFileDetails)
          }
        })
    } else {
      notification.error({ message: message, placement: 'bottomRight' })

      this.setState({ uploading: false })
    }

    e.target.value = ''
  }

  onFileDownload = (file) => {
    const fileObject = this.state.files.filter(f => f.id === file.id)[0]

    try {
      downloadFile('/' + this.props.entityName.toLowerCase() + '/document/download/' + fileObject.id, fileObject.originalName, this.props.getUser)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  deleteFile = (file) => {
    let files = this.state.files

    files = files.filter(f => f.id !== file.id)
    this.setState({ files: files })

    if (typeof this.props.onFileDelete === 'function') {
      this.props.onFileDelete(file)
    } else {
      request('/api/' + this.props.entityName + 'Document/' + file.id, 'DELETE', null, this.props.getUser)
    }
  }

  handleClose = () => {
    this.setState({ loading: false, loaded: false })
    this.props.onClose()
  }

  render () {
    const { t, width, entity, preventChanges } = this.props
    const columns = [
      { type: DATA_TYPE_ID, key: 'id' },
      { type: DATA_TYPE_STRING, name: t('Name'), key: 'name' }
    ]
    const files = this.state.files.map(f => {
      return {
        id: f.id,
        name: f.originalName
      }
    })

    return (
      <Drawer
        title={t('Files manager')}
        width={typeof width === 'undefined' ? '80%' : width}
        onClose={this.handleClose}
        visible={!!entity || isArray(this.props.files)}
      >
        <SmartTable
          columns={columns}
          data={files}
          addLineOnAddData={false}
          addDataText={t('Upload file')}
          loading={this.state.loading || this.state.uploading}
          noDataText={t('There is no attached file.')}
          onDataAdd={!preventChanges ? () => this.refs.fileInput.click() : undefined}
          onDataDelete={!preventChanges ? this.deleteFile : undefined}
          additionalActions={[
            { iconName: 'download', type: 'primary', title: t('Download file'), onClick: (i) => this.onFileDownload(i), customClassCallback: data => { return data.filesCount > 0 ? 'green-button' : '' } }
          ]}
          disableAddButton={this.props.maxFiles ? this.props.maxFiles <= files.length : false}
        />
        <input
          type='file'
          ref='fileInput'
          onChange={this.handleFileUpload}
          accept={this.props.entityName === STUDENT_ENTITY_NAME ? STUDENT_DOCUMENT_TYPES.join(',') : ACCEPTED_DOCUMENT_TYPES.join(',')}
          style={{ display: 'none' }}
        />
      </Drawer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesManager)
