import React from 'react'

import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchool, faUser } from '@fortawesome/free-solid-svg-icons'
import { getSchoolView, getUsersProvider, getUsersView } from './selectors'
import SchoolUserSpecialitiesProvider, { SchoolUserSpecialitiesContext } from '../../../Components/MACCS/Settings/SchoolUserSpecialitiesProvider'

const { TabPane } = Tabs

const USER_TAB_NAME = 'Manage users'
const IDENTITY_TAB_NAME = 'School identity'
const TAB_STYLE = { marginTop: '3px' }

const SchoolSettings = props => {
  const specialitiesProvider = {
    Provider: SchoolUserSpecialitiesProvider,
    context: SchoolUserSpecialitiesContext
  }
  const Users = getUsersView(props.getUser?.context)
  const School = getSchoolView(props.getUser?.context)
  const { Provider, context } = getUsersProvider(props.getUser?.context)

  return (
    <Tabs defaultActiveKey='1' animated={false}>
      <TabPane
        key='1'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon={faUser} style={TAB_STYLE} />
            <div className='h-spacing' />
            {props.t(USER_TAB_NAME)}
          </div>
        }
      >
        {Users !== null && Provider !== null && (
          <Provider specialitiesProvider={specialitiesProvider}>
            <Users enableDelete customContext={context} />
          </Provider>
        )}
      </TabPane>
      <TabPane
        key='2'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon={faSchool} style={TAB_STYLE} />
            <div className='h-spacing' />
            {props.t(IDENTITY_TAB_NAME)}
          </div>
        }
      >
        <School />
      </TabPane>
    </Tabs>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSettings)
