import React, { useEffect, useState } from 'react'

import { downloadFile, generalErrorHandler } from '../../../utils'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const FilesListDisplay = ({ route, entity, type, t, user }) => {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (route && entity && user) {
      setLoading(true)
      route(user, entity).then(json => {
        setFiles(json.data)
        setLoading(false)
      })
    }
  }, [route, user, entity])

  const downloadInternshipFile = fileId => {
    const file = files.find(f => f.id === fileId)

    try {
      downloadFile(`/${type}/document/download/${fileId}`, file.originalName, user)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  return (
    <div className='flex-column files-list-display'>
      {loading &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='loading-icon black' />
          <div style={{ marginLeft: '4px', marginTop: '4px' }}>{t('Loading files')}</div>
        </div>}
      {!loading && files.map(f => {
        return <a style={{ marginBottom: '10px' }} key={f.id} onClick={() => downloadInternshipFile(f.id)}>{f.originalName}</a>
      })}
    </div>
  )
}

export default connect(mapStateToProps)(FilesListDisplay)
