import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST } from '../apiHelper'

export const addContactPerson = (schoolSpecialityId, body, user) => {
  return requestWithPromise(`/api/school-specialities/${schoolSpecialityId}/contact-person`, POST, body, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const addMultipleContactPersons = (body, user) => {
  return requestWithPromise('/school-specialities/contact-persons', POST, body, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const createSchoolSpeciality = (schoolSpeciality, user) => {
  return requestWithPromise('/api/school-specialities',
    POST,
    schoolSpeciality,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const deleteSchoolSpeciality = (schoolSpeciality, user) => {
  return requestWithPromise(
    `/api/school-specialities/${schoolSpeciality.id}`,
    DELETE,
    null,
    user
  )
}

export const getAllSchoolSpeciality = user => {
  return requestWithPromise('/api/school-specialities',
    GET,
    null,
    user
  ).then(json => {
    if (json?.data) {
      return json.data
    }
  })
}

export const getContactPersonsSchoolSpeciality = (schoolSpeciality, user) => {
  return requestWithPromise(`/api/school-specialities/${schoolSpeciality.id}/contact-persons`, GET, null, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const updateSchoolSpeciality = (schoolSpeciality, body, user) => {
  return requestWithPromise(
    `/api/school-specialities/${schoolSpeciality.id}`,
    PATCH,
    body,
    user
  )
}
