import moment from 'moment'
import { ABSENCE_CATEGORY } from '../../../Components/shared/ShiftsManager/constants'

const ABSENT_EVENT_BORDER_COLOR = 'red'
const ABSENT_EVENT_TEXT_COLOR = 'red'
const EVENT_CODE_COLOR = 'blue'
const EVENT_CODE_BORDER_COLOR = 'blue'
const DEFAULT_TEXT_COLOR = 'black'
const DEFAULT_BORDER_COLOR = 'black'

export class MaccsFullCalendarEvent {
  constructor (shift, eventCodes) {
    let eventCode = null

    if (shift.eventCodeType) {
      eventCode = eventCodes.find(ec => ec.typeId === shift.eventCodeType.id)
    }

    this.id = shift.id
    this.start = moment(shift.startDate.split('+')[0]).format('YYYY-MM-DD')
    this.end = moment(shift.endDate.split('+')[0]).format('YYYY-MM-DD')
    this.title = JSON.stringify(buildTitle(shift, eventCode))
    this.display = 'auto'
    this.color = 'white'
    this.textColor = getTextColorByShift(shift, eventCode)
    this.borderColor = getBorderColorByShiftAndPreset(shift, eventCode)
  }
}

const buildTitle = (shift, eventCode) => {
  const mainTitle = eventCode?.code ?? null
  const subtitle = (
    shift.startTime.substring(0, 5) +
    ' - ' +
    shift.endTime.substring(0, 5)
  )

  if (mainTitle === null) {
    return { main: subtitle }
  }

  return { main: mainTitle, subtitle: subtitle }
}

const getTextColorByShift = (shift, eventCode) => {
  if (shift !== null && eventCode && eventCode.category === ABSENCE_CATEGORY) {
    return ABSENT_EVENT_TEXT_COLOR
  }

  if (shift !== null && shift.eventCode) {
    return EVENT_CODE_COLOR
  }

  return DEFAULT_TEXT_COLOR
}

const getBorderColorByShiftAndPreset = (shift, eventCode) => {
  if (shift !== null && eventCode && eventCode.category === ABSENCE_CATEGORY) {
    return ABSENT_EVENT_BORDER_COLOR
  }

  if (shift !== null && shift.eventCode) {
    return EVENT_CODE_BORDER_COLOR
  }

  return DEFAULT_BORDER_COLOR
}
