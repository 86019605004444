import remove from 'lodash/remove'
import { notification } from 'antd'
import { canonizeString, request, generalErrorHandler, filterSectorsAndSetRootSectorOfInstitution } from '../../utils'
import appConfig from '../../config'
import { getTranslate } from 'react-localize-redux'

const SET_INSTITUTIONS = 'UDPATE_INSTITUTIONS'
const SET_INSTITUTION_TIME_PRESETS = 'SET_INSTITUTION_TIME_PRESETS'
const REMOVE_INSTITUTION_USER = 'REMOVE_INSTITUTION_USER'
const CLEAR_MULTI_INSTITUTIONS_PRESETS = 'CLEAR_MULTI_INSTITUTIONS_PRESETS'
const SET_INSTITUTION_OPTIONS_BY_ID = 'SET_INSTITUTION_OPTIONS_BY_ID'

export const getInstitutions = state => {
  return state.getInstitutions.list
}

export const setInstitutions = institutions => {
  institutions = institutions.sort((i1, i2) => {
    return canonizeString(i1.name).localeCompare(canonizeString(i2.name))
  })
  institutions = institutions.map(i => {
    if (i.sectors) {
      i.sectors = i.sectors.sort((s1, s2) => {
        return canonizeString(s1.name).localeCompare(canonizeString(s2.name))
      })
    } else {
      i.sectors = []
    }
    return i
  })

  return {
    type: SET_INSTITUTIONS,
    institutions: institutions
  }
}

export const fetchInstitutions = (currentUser) => {
  return async (dispatch, getState) => {
    try {
      let institutionsList = []
      const url = `/institutions/0/${appConfig.apiPageSize}`
      const { data, nextPage } = await request(url, 'GET', null, currentUser)
      let nextPageInfo = nextPage
      institutionsList.push(...data)
      while (nextPageInfo) {
        const newPageResults = await request(nextPageInfo, 'GET', null, currentUser)
        if (newPageResults.data) {
          institutionsList.push(...newPageResults.data)
        }
        nextPageInfo = newPageResults.nextPage
      }

      if (institutionsList.length) {
        institutionsList = institutionsList.sort((i1, i2) => {
          return canonizeString(i1.name).localeCompare(canonizeString(i2.name))
        })
        institutionsList = institutionsList.map(i => {
          i.sectors = i.sectors.sort((s1, s2) => {
            return canonizeString(s1.name).localeCompare(canonizeString(s2.name))
          })

          return i
        })

        // find the root sector and translate its name
        const translationMethod = getTranslate(getState().locale)

        // set the root sector
        filterSectorsAndSetRootSectorOfInstitution(
          institutionsList[0],
          translationMethod('Without care unit')
        )

        await dispatch({
          type: SET_INSTITUTIONS,
          institutions: institutionsList
        })

        return institutionsList
      }
    } catch (err) {
      generalErrorHandler(err)
      return null
    }
  }
}

export const setInstitutionTimePresets = ({
  institutionId,
  sectorId,
  presetsList
}) => {
  return {
    type: SET_INSTITUTION_TIME_PRESETS,
    payload: { institutionId, sectorId, presetsList }
  }
}

export const removeInstitutionUser = ({
  institutionId,
  userId,
  currentUser
}) => {
  request(`/api/User/${userId}`, 'DELETE', null, currentUser)
  return {
    type: REMOVE_INSTITUTION_USER,
    payload: { institutionId, userId }
  }
}

export const clearInstitutionsPresets = () => {
  return {
    type: CLEAR_MULTI_INSTITUTIONS_PRESETS
  }
}

/**
 * This is the actual method which updates the list of presets for each section
 */
const updatePresetsList = ({ state, institutionId, sectorId, presetsList }) => {
  return state.list.map(institution => {
    if (institution.id === institutionId) {
      institution.sectors = institution.sectors.map(sector => {
        if (sector.id === sectorId) {
          return { ...sector, presets: presetsList }
        }
        return sector
      })
      return institution
    }
    return institution
  })
}

const handleUserRemove = ({ state, institutionId, userId }) => {
  return state.list.map(institution => {
    if (institution.id === institutionId) {
      remove(institution.users, item => item.id === userId)
    }
    return institution
  })
}

const handleClearInstitutionsPresets = state => {
  return state.list.map(institution => {
    institution.sectors = institution.sectors.map(sector => {
      if (sector.presets) {
        const { presets, ...otherSectorProps } = sector
        return { ...otherSectorProps }
      }
      return sector
    })
    return institution
  })
}

const setInstitutionOptions = (id, options) => {
  return {
    type: SET_INSTITUTION_OPTIONS_BY_ID,
    payload: { id, options }
  }
}

export default (state = { list: [] }, action) => {
  switch (action.type) {
    case SET_INSTITUTIONS: {
      return { ...state, list: action.institutions }
    }
    case SET_INSTITUTION_TIME_PRESETS:
      return { ...state, list: updatePresetsList({ ...action.payload, state }) }
    case REMOVE_INSTITUTION_USER:
      return { ...state, list: handleUserRemove({ ...action.payload, state }) }
    case CLEAR_MULTI_INSTITUTIONS_PRESETS:
      return { ...state, list: handleClearInstitutionsPresets(state) }
    case SET_INSTITUTION_OPTIONS_BY_ID: {
      let institutions = [...state.list]

      institutions = institutions.map(institution => {
        if (institution.id === action.payload.id) {
          institution.institutionOptions = action.payload.options
        }

        return institution
      })

      return { ...state, list: institutions }
    }
    default:
      return state
  }
}
