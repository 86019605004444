import React from 'react'
import { withRouter } from 'react-router-dom'
import Cookie from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserSettingsGeneral from './UserSettingsGeneral'
import UserSettingsSecurity from './UserSettingsSecurity'
import UserSettingsStudentExtraInfo from './UserSettingsStudentExtraInfo'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import { Modal, Tabs, Button, notification } from 'antd'
import { request, generalErrorHandler, recursiveIncludes } from '../../utils'
import { setLanguage } from '../../utils/locale'
import { ROLE_ASSISTANT, ROLE_HOSPITAL_ADMIN, ROLE_STUDENT } from '../../utils/constants'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ManagingEmailsPage from './ManagingEmailsPage'
import UserProfileImage from './UserProfileImage'
import { compose } from 'redux'
import routes from '../../routes'

const TabPane = Tabs.TabPane

class UserSettings extends React.Component {
  state = {
    visible: false,
    activeTab: '1',
    loading: false,
    studentExtraContactDetails: {
      emailRows: [],
      phoneRows: []
    }
  };

  componentDidMount () {
    // get extra details if user is a student
    if (this.props.getUser.context === 'STUDENT') {
      this.fetchStudentExtraDetails()
    }
  }

  fetchStudentExtraDetails = async () => {
    try {
      const { data } = await request('/student/get-additional-contact-info', 'GET', null, this.props.getUser)
      if (data) {
        const emails = []
        const phones = []
        data.forEach(item => {
          if (item.type === 'email') {
            emails.push({ value: item.value })
          } else {
            phones.push({ value: item.value })
          }
        })
        this.setState({
          studentExtraContactDetails: {
            emailRows: emails,
            phoneRows: phones
          }
        })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  handleShowModal = () => {
    this.setState({ visible: true })
  };

  handleCancel = e => {
    this.setState({
      visible: false
    })
  };

  handleSubmit = () => {
    if (this.state.activeTab === '1') {
      const form = this.generalFormRef.props.form
      form.validateFields((err, values) => {
        if (!err) {
          this.setState({ loading: true })
          request(
            '/api/User/' + this.props.getUser.id,
            'PATCH',
            values,
            this.props.getUser
          )
            .then(json => {
              if (json.status && json.status === 'error') {
                this.setState({ loading: false })
                form.resetFields()
                return
              }
              notification.success({
                message: this.props.t('Profile updated !'),
                placement: 'bottomRight'
              })
              this.props.updateUserProfile(json.data)
              if (
                typeof Cookie.get('user') !== 'undefined' &&
                Cookie.get('user') !== 'undefined' &&
                typeof Cookie.get('token') !== 'undefined' &&
                Cookie.get('token') !== 'undefined'
              ) {
                Cookie.set('user', JSON.stringify(json.data), { expires: 7 })
              }

              const { addTranslationForLanguage, setActiveLanguage, getActiveLanguage, getUser } = this.props

              if (getActiveLanguage !== values.language) {
                // update the language of the site
                setLanguage({
                  language: values.language,
                  addTranslationForLanguage,
                  setActiveLanguage,
                  getActiveLanguage,
                  getUser: () => { return getUser }
                })
              }

              this.setState({
                visible: false,
                loading: false
              })
              form.resetFields()
            })
            .catch(error => { generalErrorHandler(error) })
        }
      })
    }

    if (this.state.activeTab === '2') {
      const form = this.securityFormRef.props.form
      form.validateFields((err, values) => {
        if (!err) {
          this.setState({ loading: true })

          request(
            '/user/change-password',
            'POST',
            {
              current_password: values.oldPassword,
              password: values.newPassword,
              password_confirm: values.newPasswordConfirm
            },
            this.props.getUser
          )
            .then(json => {
              if (typeof json.error !== 'undefined') {
                this.setState({ loading: false })
                notification.error({
                  message: this.props.t('Wrong old password.'),
                  placement: 'bottomRight'
                })
                return
              }

              notification.success({
                message: this.props.t('Password updated !'),
                placement: 'bottomRight'
              })
              form.resetFields()
              this.setState({ visible: false, loading: false })
            })
            .catch(error => {
              generalErrorHandler(error)
              this.setState({ loading: false })
              notification.error({
                message: this.props.t('Wrong old password.'),
                placement: 'bottomRight'
              })
            })
        }
      })
    }

    if (this.state.activeTab === '3') {
      const form = this.studentExtraInfoFormRef.props.form
      form.validateFields((err, values) => {
        if (!err) {
          this.setState({ loading: true })

          const emails = []
          const phones = []

          for (const [key, value] of Object.entries(values)) {
            const lowerValue = value.toLowerCase()
            if (key.includes('email') && !emails.includes(lowerValue)) {
              emails.push(lowerValue)
            } else if (key.includes('phone') && !phones.includes(lowerValue)) {
              phones.push(lowerValue)
            }
          }

          request(
            '/student/save-additional-contact-info',
            'POST',
            {
              emails,
              phones
            },
            this.props.getUser)
            .then(json => {
              if (json.status === 'error') {
                this.setState({ loading: false })
                notification.error({
                  message: json.message,
                  placement: 'bottomRight'
                })
                return
              }
              if (typeof json.error !== 'undefined') {
                this.setState({ loading: false })
                notification.error({
                  message: this.props.t('Error saving additional details'),
                  placement: 'bottomRight'
                })
                return
              }

              // check if we have duplicate emails
              const { notUniqueEmails } = json.data
              if (notUniqueEmails && notUniqueEmails.length) {
                notification.error({
                  message: this.props.t(`Couldn't save the additional contact details because the following emails are already used in our system: ${emails}`, { emails: notUniqueEmails.join(', ') }),
                  placement: 'bottomRight'
                })
                this.setState({ loading: false })
                return
              }

              notification.success({
                message: this.props.t('Saved additional details'),
                placement: 'bottomRight'
              })

              // update the state
              const newEmails = emails.map(item => ({ value: item }))
              const newPhones = phones.map(item => ({ value: item }))
              this.setState({
                studentExtraContactDetails: {
                  emailRows: newEmails,
                  phoneRows: newPhones
                }
              })

              this.setState({ loading: false })
            })
            .catch(error => {
              generalErrorHandler(error)
              this.setState({ loading: false })
              notification.error({
                message: this.props.t('Error saving additional details.'),
                placement: 'bottomRight'
              })
            })
        }
      })
    }
  };

  // https://ant.design/components/form/?locale=en-US#components-form-demo-form-in-modal
  saveGeneralFormRef = generalFormRef => {
    this.generalFormRef = generalFormRef
  };

  saveSecurityFormRef = securityFormRef => {
    this.securityFormRef = securityFormRef
  };

  saveStudentExtraContactInfoFormRef = studentExtraInfoFormRef => {
    this.studentExtraInfoFormRef = studentExtraInfoFormRef
  };

  render () {
    let accountName = this.props.getUser.firstname + ' ' + this.props.getUser.lastname
    if (this.props.getUser.firstname === null || this.props.getUser.lastname === null) {
      accountName = this.props.getUser.email
    }
    return (
      <div>
        <div
          onClick={() => {
            if (recursiveIncludes(this.props.getUser.roles, [ROLE_ASSISTANT, ROLE_STUDENT])) {
              this.handleShowModal()
            } else {
              this.props.history.push(routes.PROFILE_PAGE)
            }
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '3%',
            padding: '15px'
          }}
        >
          <div style={{ marginRight: '5%' }}>
            <UserProfileImage />
          </div>
          <div>
            {accountName}
            <br />
            <p style={{ textAlign: 'center', fontSize: '10px', color: 'grey', alignSelf: 'center' }}>
              {(this.props.getUser.firstname !== null && this.props.getUser.lastname !== null) && this.props.getUser.email}
            </p>
          </div>
        </div>
        <Modal
          title={
            this.props.t('Edit profile of') + ' ' + accountName
          }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button
              onClick={this.handleCancel}
              key='cancel'
              type='default'
              loading={this.state.loading}
            >
              <FontAwesomeIcon icon='times' />
              &nbsp;{this.props.t('Cancel')}
            </Button>,
            <Button
              onClick={this.handleSubmit}
              key='save'
              type='primary'
              loading={this.state.loading}
            >
              <FontAwesomeIcon icon='save' />
              &nbsp;{this.props.t('Save')}
            </Button>
          ]}
        >
          <Tabs
            defaultActiveKey={this.state.activeTab}
            onChange={e => this.setState({ activeTab: e })}
            animated={false}
          >
            <TabPane tab={this.props.t('General information')} key='1'>
              <UserSettingsGeneral
                wrappedComponentRef={this.saveGeneralFormRef}
              />
            </TabPane>
            {this.props.getUser.context === 'STUDENT' && (
              <TabPane tab={this.props.t('Additional details')} key='3'>
                <UserSettingsStudentExtraInfo
                  wrappedComponentRef={this.saveStudentExtraContactInfoFormRef}
                  data={this.state.studentExtraContactDetails}
                />
              </TabPane>
            )}
            <TabPane tab={this.props.t('Security')} key='2'>
              <UserSettingsSecurity
                wrappedComponentRef={this.saveSecurityFormRef}
              />
            </TabPane>
            {this.props.getUser.roles.includes(ROLE_HOSPITAL_ADMIN) && (
              <TabPane
                key='4'
                tab={
                  <div className='flex-row'>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ marginTop: '3px' }}
                    />
                    <div className='h-spacing' />
                    {this.props.t('Managing emails')}
                  </div>
                }
              >
                <ManagingEmailsPage />
              </TabPane>
            )}
          </Tabs>
        </Modal>
      </div>
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserSettings)
