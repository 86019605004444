import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Metadata } from '../../../utils/http'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const DEFAULT_ENTITY_NAME = 'data'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const Footer = ({ dataType, metadata, pageSize, onMetadataChange, t }) => {
  const [totalDataText, setTotalDataText] = useState('')

  useEffect(() => {
    const name = metadata.total <= 1 ? dataType ?? DEFAULT_ENTITY_NAME : (dataType ?? DEFAULT_ENTITY_NAME) + '.plural'

    setTotalDataText(`${metadata.total} ${t(name)}`)
  }, [dataType, metadata.total, t])

  return (
    <div className='footer-container'>
      <div className='total-data-text'>{totalDataText}</div>
      <div className='flex-fill' />
      <div className='flex-row'>
        <Tooltip placement='top' title={t('First page')}>
          <Button
            type='default'
            size='small'
            disabled={metadata.totalPages === 1 || metadata.page === 1}
            onClick={() => onMetadataChange(new Metadata({ ...metadata, page: 1 }))}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Previous page')}>
          <Button
            type='default'
            size='small'
            disabled={metadata.totalPages === 1 || metadata.page === 1}
            onClick={() => onMetadataChange(new Metadata({ ...metadata, page: metadata.page - 1 }))}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <div className='page-index'>
          {metadata.page} / {metadata.totalPages}
        </div>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Next page')}>
          <Button
            type='default'
            size='small'
            disabled={
              metadata.totalPages <= 1 || metadata.page >= metadata.totalPages
            }
            onClick={() => onMetadataChange(new Metadata({ ...metadata, page: metadata.page + 1 }))}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Last page')}>
          <Button
            type='default'
            size='small'
            disabled={
              metadata.totalPages <= 1 || metadata.page >= metadata.totalPages
            }
            onClick={() => onMetadataChange(new Metadata({ ...metadata, page: metadata.totalPages }))}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
