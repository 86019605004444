import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchool } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'

const ACADEMIC_DATA_TITLE = 'My school contact information'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const PersonalSchoolInformations = props => {
  const [school, setSchool] = useState({})
  const [person, setPerson] = useState({})

  useEffect(() => {
    setSchool(props.school)
    setPerson(props.person)
  }, [
    props.school,
    props.person,
  ])

  const renderSchoolAddress = () => {
    const { address, city, zipcode, country } = school
    let schoolAddress = `${address}, ${city}, ${zipcode}`

    if (Array.isArray(country) && typeof country.code !== 'undefined') {
      schoolAddress += `, ` + country.code
    }

    return schoolAddress
  }

  return (
    <>
      <h2 className='simple border'>{props.t(ACADEMIC_DATA_TITLE)}</h2>

      <Row className='prl-5'>
        <Col className='col-pb-22'>
          <h3>
            <FontAwesomeIcon key={0} icon={faSchool} className='fontAwesomeIcon-mr-10' />
            <strong>{props.t('Address')}</strong>
          </h3>

          {school.id !== 'undefined' ? renderSchoolAddress() : props.t('No information')}
        </Col>

        <Col className='col-pb-22'>
          <h3>
            <FontAwesomeIcon key={1} icon='at' className='fontAwesomeIcon-mr-10' />
            <strong>{props.t('Email')}</strong>
          </h3>

          {person.id !== 'undefined' && person.email !== null ? person.email : props.t('No information')}
        </Col>

        <Col>
          <h3>
            <FontAwesomeIcon key={2} icon='phone' className='fontAwesomeIcon-mr-10' />
            <strong>{props.t('Phone number')}</strong>
          </h3>

          {person.id !== 'undefined' && person.phoneNumber !== null && person.phoneNumber !== ''
            ? person.phoneNumber
            : props.t('No information')
          }
        </Col>
      </Row>
    </>
  )
}

export default connect(mapStateToProps)(PersonalSchoolInformations)
