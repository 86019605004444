import React, { useState, useEffect, useContext } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { MaccsInternshipContext } from '../../../../Context/InternshipsManager/MaccsInternshipProvider'
import { MaccsInternshipsDataContext } from '../../../../Context/InternshipsManager/MaccsInternshipsDataProvider'
import { getUser } from '../../../../reducers/UserReducer'
import { orderByNameSectors } from '../../../../Selectors/Sectors/SectorsSelector'
import { REFUSED, ROLE_ADMIN_HOSPITAL_RO } from '../../../../utils/constants'
import Selector from '../../../shared/Selectors/Selector'

const NO_SCHOOL_TEXT = 'Without school'
const NO_STUDENT_TEXT = 'Without student'
const NO_INTERNSHIP_YEAR_TEXT = 'Without internship year'
const NO_SPECIALITY_TEXT = 'Without speciality'
const SCHOOL_STYLE = {
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  marginBottom: '0px'
}

const mapStateToProps = state => {
  return {
    sectors: orderByNameSectors(state),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsInstitutionInternshipData = ({ displaySectorSelector, internship, sectors, t, user }) => {
  const { specialities } = useContext(MaccsInternshipsDataContext)
  const { onUpdate } = useContext(MaccsInternshipContext)
  const [textualData, setTextualData] = useState({})

  useEffect(() => {
    if (internship && typeof t === 'function') {
      const newTextualData = {
        school: internship.school ? internship.school.name : t(NO_SCHOOL_TEXT),
        assistant: internship.assistant ? `${internship.assistant.lastname} ${internship.assistant.firstname}` : t(NO_STUDENT_TEXT),
        internshipYear: internship.internshipYear ? internship.internshipYear.studyYearName : t(NO_INTERNSHIP_YEAR_TEXT),
        speciality: internship.schoolSpeciality ? internship.schoolSpeciality.specialityYear.specialityAbbreviation : t(NO_SPECIALITY_TEXT)
      }

      setTextualData(newTextualData)
    }
  }, [internship, t])

  const handleSpecialitySelect = value => {
    onUpdate({ speciality: value === -1 ? null : value })
  }

  return (
    <div className='internship-info-old'>
      <div style={{ display: 'flex' }}>
        <b>{textualData.assistant}</b>&nbsp; - {textualData.speciality}
      </div>
      <div className='flex-row'>
        <p style={SCHOOL_STYLE}>
          {textualData.school}
        </p>
        <Selector
          data={specialities}
          dataKey='abbreviation'
          disabled={
            specialities.length === 0 ||
            user.roles.includes(ROLE_ADMIN_HOSPITAL_RO) ||
            internship.state === REFUSED
          }
          onSelect={handleSpecialitySelect}
          showArrow={false}
          showSearch
          value={internship.speciality ? internship.speciality.id : -1}
          style={{ width: '150px' }}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(MaccsInstitutionInternshipData)
