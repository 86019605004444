import { INSTITUTION_CONTEXT, INSTITUTION_MACCS_CONTEXT, MACCS_ENVIRONMENT, ROLE_INSTITUTION_GROUP_ADMIN, SCHOOL_CONTEXT, SCHOOL_MACCS_CONTEXT } from '../../../../utils/constants'
import InstitutionGroupInternshipData from '../../../MACCS/InternshipsManager/InternshipData/InstitutionGroupInternshipData'
import MaccsInstitutionInternshipData from '../../../MACCS/InternshipsManager/InternshipData/MaccsInstitutionInternshipData'
import MaccsSchoolInternshipData from '../../../MACCS/InternshipsManager/InternshipData/MaccsSchoolInternshipData'
import InstitutionMonthTitle from '../../../MACCS/InternshipsManager/MonthTitle/InstitutionMonthTitle'
import SchoolMonthTitle from '../../../MACCS/InternshipsManager/MonthTitle/SchoolMonthTitle'
import InstitutionDateTitle from '../DateTitle/InstitutionDateTitle'
import SchoolDateTitle from '../DateTitle/SchoolDateTitle'
import InstitutionInternshipData from '../InternshipData/InstitutionInternshipData'
import SchoolInternshipData from '../InternshipData/SchoolInternshipData'
import InstitutionInternshipLine from '../InternshipLine/InstitutionInternshipLine'
import SchoolInternshipLine from '../InternshipLine/SchoolInternshipLine'

export const getInternshipLine = context => {
  if (context === SCHOOL_CONTEXT || context === SCHOOL_MACCS_CONTEXT) {
    return SchoolInternshipLine
  }

  if (context === INSTITUTION_CONTEXT || context === INSTITUTION_MACCS_CONTEXT) {
    return InstitutionInternshipLine
  }

  return null
}

export const getDateTitle = (context, roles, environment) => {
  if (roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
    return environment === MACCS_ENVIRONMENT ? InstitutionMonthTitle : InstitutionDateTitle
  }

  if (context === SCHOOL_CONTEXT) {
    return SchoolDateTitle
  }

  if (context === INSTITUTION_CONTEXT) {
    return InstitutionDateTitle
  }

  if (context === SCHOOL_MACCS_CONTEXT) {
    return SchoolMonthTitle
  }

  if (context === INSTITUTION_MACCS_CONTEXT) {
    return InstitutionMonthTitle
  }

  return null
}

export const getSchoolInternshipData = context => {
  if (context === SCHOOL_CONTEXT) {
    return SchoolInternshipData
  }

  if (context === SCHOOL_MACCS_CONTEXT) {
    return MaccsSchoolInternshipData
  }

  return null
}

export const getInstitutionInternshipData = (context, roles, environment) => {
  if (roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
    return environment === MACCS_ENVIRONMENT ? InstitutionGroupInternshipData : InstitutionInternshipData
  }

  if (context === INSTITUTION_CONTEXT) {
    return InstitutionInternshipData
  }

  if (context === INSTITUTION_MACCS_CONTEXT) {
    return MaccsInstitutionInternshipData
  }

  return null
}
