import React from 'react'

const CheckboxUserTable = ({ setUserContactPerson, entityId, isChecked, handleChangeChecked, indexEntity, disabled }) => {
  const checkEntity = () => {
    if (typeof setUserContactPerson === 'function') {
      setUserContactPerson(entityId, isChecked)
    }

    handleChangeChecked(indexEntity)
  }

  return (
    <div className='div-checkbox'>
      <input checked={isChecked} onChange={checkEntity} className='checkbox' type='checkbox' disabled={disabled} />
    </div>
  )
}

export default (CheckboxUserTable)
