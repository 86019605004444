import React from 'react'
import { bool, arrayOf, shape, string, number, oneOfType } from 'prop-types'

import MenuItem from './MenuItem'

const MenuDesktop = ({ collapsed, menuItems }) => {
  return (
    <div
      className='menu hidden-mobile flex-column'
      style={{
        height: '100vh',
        width: collapsed ? '60px' : '300px'
      }}
    >
      <div className='flex-row header'>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          {collapsed ? (
            <img
              src='/assets/interneo_picto.svg'
              alt='Interneo logo'
              style={{ marginTop: '10px' }}
            />
          ) : null}
          {collapsed ? null : <span className='title'>Interneo</span>}
        </div>
      </div>
      <div className='flex-column flex-fill' style={{ fontSize: '16px' }}>
        {menuItems.map(item => (
          <MenuItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  )
}

MenuDesktop.propTypes = {
  collapsed: bool.isRequired,
  menuItems: arrayOf(
    shape({
      id: number,
      label: string,
      route: string,
      iconName: oneOfType([string, shape({ prefix: string, iconName: string })]),
      showLabel: bool
    })
  )
}

export default MenuDesktop
