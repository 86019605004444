import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

export const getAllWorkingRegimes = user => {
  return requestWithPromise(
    '/api/working-regimes',
    GET,
    null,
    user
  ).then(json => {
    return json && json.data ? json.data : []
  })
}
