import React, { createContext, useEffect, useState } from 'react'
import { getSchoolSpecialities } from '../../../utils/api/school'
import { getUserSpecialities, linkUserToSpeciality, unlinkUserToSpeciality } from '../../../utils/api/users'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { DATA_TYPE_SELECT, DATA_TYPE_CHECK_CONTACT_PERSON } from './UserSpecialityTable'
import { addContactPerson, addMultipleContactPersons } from '../../../utils/api/schoolSpeciality'

const SUCCESSFUL_LINK_MESSAGE = 'Speciality successfully linked'
const SUCCESSFUL_UNLINK_MESSAGE = 'Speciality successfully unlinked'
const SUCCESSFUL_LINK_SWITCH_MESSAGE = 'Linked Speciality successfully modified'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser),
    t: getTranslate(state.locale)
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const SchoolUserSpecialitiesContext = createContext()

let SchoolUserSpecialitiesProvider = props => {
  const { user, selectedUser, t } = props

  const [availableSpecialities, setAvailableSpecialities] = useState([])
  const [userSpecialities, setUserSpecialities] = useState([])
  const [contactPersonsSpecialities, setContactPersonsSpecialities] = useState({})
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user.school) {
      getSchoolSpecialities(user.school, user).then(json => {
        if (json?.data) {
          setAvailableSpecialities(json.data.map(s => {
            s.name = getSpecialityName(s.specialityYear)

            return s
          }))
        }
      })
    }
  }, [user.school])

  useEffect(() => {
    if (selectedUser && user) {
      setLoading(true)
      getUserSpecialities(selectedUser, user)
        .then(data => {
          const userSpecialities = []
          const contactPersonsSpecialities = {}

          for (const speciality of data) {
            speciality.name = getSpecialityName(speciality.specialityYear)
            userSpecialities.push(speciality)

            contactPersonsSpecialities[speciality.id] = speciality.contactPersons.map(contactPerson => {
              return contactPerson.id
            })
          }

          setUserSpecialities(userSpecialities)
          setContactPersonsSpecialities(contactPersonsSpecialities)
          setLoading(false)
        })
    }
  }, [selectedUser, user])

  useEffect(() => {
    if (t) {
      setColumns(makeColumns(t, availableSpecialities))
    }
  }, [availableSpecialities, t])

  const makeColumns = (t, specialities) => {
    return [
      {
        type: DATA_TYPE_SELECT,
        name: t('Speciality'),
        key: 'name',
        options: availableSpecialities.map((speciality, index) => {
          return {
            id: speciality.name,
            name: speciality.name,
            index
          }
        })
      },
      {
        type: DATA_TYPE_CHECK_CONTACT_PERSON,
        name: props.t('Contact persons'),
        key: 'contactPerson'
      }
    ]
  }

  const getSpecialityName = specialityYear => {
    if (specialityYear) {
      return `${specialityYear.specialityAbbreviation} (${specialityYear.studyYearName})`
    }

    return 'Unkown speciality'
  }

  const clearStates = () => {
    setUserSpecialities([])
    setLoading(false)
  }

  const onLinkSpeciality = ({ name }) => {
    const speciality = availableSpecialities.find(s => s.name === name)

    if (speciality) {
      linkUserToSpeciality(selectedUser, speciality, user)
        .then(data => {
          const newUserSpecialities = [...userSpecialities]
          const newContactPersonsSpecialities = { ...contactPersonsSpecialities }

          data.name = getSpecialityName(data.specialityYear)
          newUserSpecialities.push(data)

          newContactPersonsSpecialities[data.id] = data.contactPersons.map(contactPerson => {
            return contactPerson.id
          })

          setUserSpecialities(newUserSpecialities)
          setContactPersonsSpecialities(newContactPersonsSpecialities)
          notification.success({
            message: t(SUCCESSFUL_LINK_MESSAGE),
            placement: 'bottomRight'
          })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const onModifyLinkSpeciality = (data) => {
    const speciality = availableSpecialities.find(s => s.name === data.name)
    const oldSpeciality = userSpecialities.find(s => s.id === data.id)

    if (speciality && oldSpeciality) {
      linkUserToSpeciality(selectedUser, speciality, user)
        .then(data => {
          const newUserSpecialities = [...userSpecialities]
          const newContactPersonsSpecialities = { ...contactPersonsSpecialities }

          data.name = getSpecialityName(data.specialityYear)
          newUserSpecialities.push(data)

          newContactPersonsSpecialities[data.id] = data.contactPersons.map(contactPerson => {
            return contactPerson.id
          })

          setContactPersonsSpecialities(newContactPersonsSpecialities)
          return newUserSpecialities
        })
        .then(userSpecialities => {
          unlinkUserToSpeciality(selectedUser, oldSpeciality, user)
            .then(data => {
              setUserSpecialities(userSpecialities.filter(sp => {
                return sp.id !== data.id
              }))

              const newContactPersonsSpecialities = {}

              for (const key in contactPersonsSpecialities) {
                if (parseInt(key) !== data.id) {
                  newContactPersonsSpecialities[parseInt(key)] = contactPersonsSpecialities[parseInt(key)]
                }
              }

              setContactPersonsSpecialities(newContactPersonsSpecialities)
              notification.success({
                message: t(SUCCESSFUL_LINK_SWITCH_MESSAGE),
                placement: 'bottomRight'
              })
            })
        })
        .catch(error => {
          // @TODO 12/12/2022 : improve error hanlding by resetting userSpecialities
          // Not possible with smartTable due to data mutation
          console.error(error)
        })
    }
  }

  const onUnlinkSpeciality = ({ name }) => {
    const speciality = availableSpecialities.find(s => s.name === name)

    if (speciality) {
      unlinkUserToSpeciality(selectedUser, speciality, user)
        .then(data => {
          setUserSpecialities(userSpecialities.filter(sp => {
            return sp.id !== data.id
          }))

          const newContactPersonsSpecialities = {}

          for (const key in contactPersonsSpecialities) {
            if (parseInt(key) !== data.id) {
              newContactPersonsSpecialities[parseInt(key)] = contactPersonsSpecialities[parseInt(key)]
            }
          }

          setContactPersonsSpecialities(newContactPersonsSpecialities)
          notification.success({
            message: t(SUCCESSFUL_UNLINK_MESSAGE),
            placement: 'bottomRight'
          })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const userIsContactPerson = specialityId => {
    if (selectedUser && contactPersonsSpecialities[specialityId]) {
      return contactPersonsSpecialities[specialityId].includes(selectedUser.id)
    } else {
      return false
    }
  }

  const setMultipleUserContactPersons = schoolSpecialities => {
    const body = {
      userToAdd: selectedUser.id,
      schoolSpecialities: schoolSpecialities
    }

    addMultipleContactPersons(body, user)
      .then(jsonResponse => {
        const newContactPersonsSpecialities = { ...contactPersonsSpecialities }

        for (const schoolSpecialityId of schoolSpecialities) {
          let contactPersonsSpeciality = []

          if (contactPersonsSpecialities[schoolSpecialityId].includes(selectedUser.id)) {
            for (const userId of contactPersonsSpecialities[schoolSpecialityId]) {
              if (userId !== selectedUser.id) {
                contactPersonsSpeciality.push(userId)
              }
            }
          } else {
            contactPersonsSpeciality = contactPersonsSpeciality.concat(contactPersonsSpecialities[schoolSpecialityId])
            contactPersonsSpeciality.push(selectedUser.id)
          }

          newContactPersonsSpecialities[schoolSpecialityId] = contactPersonsSpeciality
        }

        setContactPersonsSpecialities(newContactPersonsSpecialities)
        notification.success({ message: props.t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setUserContactPerson = schoolSpecialityId => {
    const body = {
      userToAdd: selectedUser.id
    }

    addContactPerson(schoolSpecialityId, body, user)
      .then(jsonResponse => {
        let contactPersonsSpeciality = []

        if (contactPersonsSpecialities[schoolSpecialityId].includes(selectedUser.id)) {
          for (const userId of contactPersonsSpecialities[schoolSpecialityId]) {
            if (userId !== selectedUser.id) {
              contactPersonsSpeciality.push(userId)
            }
          }
        } else {
          contactPersonsSpeciality = contactPersonsSpeciality.concat(contactPersonsSpecialities[schoolSpecialityId])
          contactPersonsSpeciality.push(selectedUser.id)
        }

        const newContactPersonsSpecialities = { ...contactPersonsSpecialities }
        newContactPersonsSpecialities[schoolSpecialityId] = contactPersonsSpeciality

        setContactPersonsSpecialities(newContactPersonsSpecialities)
        notification.success({ message: props.t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  return (
    <SchoolUserSpecialitiesContext.Provider value={{
      user,
      columns,
      loading,
      selectedUser,
      userSpecialities,
      clearStates,
      onLinkSpeciality,
      onModifyLinkSpeciality,
      onUnlinkSpeciality,
      userIsContactPerson,
      setMultipleUserContactPersons,
      setUserContactPerson,
      t
    }}
    >
      {props.children}
    </SchoolUserSpecialitiesContext.Provider>
  )
}

SchoolUserSpecialitiesProvider = connect(mapStateToProps, mapDispatchToProps)(SchoolUserSpecialitiesProvider)

export {
  SchoolUserSpecialitiesProvider as default,
  SchoolUserSpecialitiesContext
}
