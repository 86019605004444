import { Drawer } from 'antd'
import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getContactPersonSpecialitiesBySchool } from '../../utils/api/users'
import SmartTable, { DATA_TYPE_STRING } from './SmartTable'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const ContactPersonSpecialitiesSchoolWidget = props => {
  const [contactPersonSchoolSpecialities, setContactPersonSchoolSpecialities] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (props.user !== null && props.getUser !== null) {
      getContactPersonSpecialitiesBySchool(props.user, props.school, props.getUser)
        .then(data => {
          data.map(schoolSpeciality => {
            schoolSpeciality.name = schoolSpeciality.specialityYear.specialityAbbreviation + ' (' + schoolSpeciality.specialityYear.studyYearName + ')'
          })

          setContactPersonSchoolSpecialities(data)
          setLoading(false)
        })
    }
  }, [props.user, props.getUser])

  const COLUMNS = [
    {
      type: DATA_TYPE_STRING,
      name: props.t('Specialities'),
      key: 'name'
    }
  ]

  return (
    <Drawer
      title={props.user !== null ? props.t('Specialities managed by') + ' ' + props.user.email : '?'}
      width='640px'
      onClose={props.onClose}
      visible={props.user !== null}
    >
      <SmartTable
        loading={loading}
        columns={COLUMNS}
        data={contactPersonSchoolSpecialities}
        noDataText={props.t('Without speciality')}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonSpecialitiesSchoolWidget)
