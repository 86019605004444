import { requestWithPromise } from '../index'
import { GET, PATCH, POST } from '../apiHelper'
import { buildURIByObject } from '../httpTools'

export const getMaccsInternshipMessages = (maccsInternship, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/internship-messages`,
    GET,
    null,
    user
  )
}

export const sendsReadReceiptMaccs = (maccsInternship, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/internship-messages/sends-read-receipt`,
    PATCH,
    {},
    user
  )
}

export const sendsReadReceiptAllMaccs = (user, date) => {
  return requestWithPromise(
    `/api/maccs-internships/internship-messages/sends-read-receipt?${buildURIByObject({ 'start-date': date })}`,
    PATCH,
    {},
    user
  )
}

export const createMaccsInternshipMessages = (maccsInternship, content, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/internship-messages`,
    POST,
    { content },
    user
  )
}

export const getTotalsMessageUnreadByMaccsInternshipsIds = (ids, user) => {
  return requestWithPromise(
    `/maccs-internship/internship-messages/unread?ids[]=${ids}`,
    GET,
    null,
    user
  )
}

export const getMaccsInternshipMessagesUnreadTotal = (user, date) => {
  return requestWithPromise(
    `/maccs-internship/internship-messages/unread-total?${buildURIByObject({ 'start-date': date })}`,
    GET,
    null,
    user
  )
}
