import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { MaccsInternshipsDataContext } from '../../../Context/InternshipsManager/MaccsInternshipsDataProvider'
import InternshipsTableHead from '../../shared/InternshipsManager/InternshipsTableHead'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import MaccsInternshipsTableBody from './MaccsInternshipsTableBody'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const MaccsInternshipsTable = ({ loading }) => {
  const { dataProvider, internshipProvider } = useContext(InternshipsManagerContext)
  const { onTableLeave, dataLoading } = useContext(MaccsInternshipsDataContext)

  return (
    <div className='manager-table-container'>
      {dataProvider.context && internshipProvider.context && (
        <table className='manager-table' onMouseLeave={onTableLeave}>
          <InternshipsTableHead />
          <MaccsInternshipsTableBody loading={loading && dataLoading} />
        </table>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(MaccsInternshipsTable)
