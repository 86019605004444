import React, { useState, useEffect, useRef } from 'react'

import SearchCheckbox from '../../../Components/antd/Selects/SearchCheckbox'
import Loading from '../../../Components/shared/Loading'
import { fetchInstitutionScore, getInstitutionSectors } from '../../../utils/api/institution'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { DatePicker, Tooltip } from 'antd'
import locale from 'antd/es/date-picker/locale/fr_FR'
import ScoreSummaryCard from '../../../Components/institution/ScoreSummaryCard'
import { fetchSectorScore } from '../../../utils/api/sector'
import moment from 'moment'
import useLocalStorage from '../../../hooks/UseLocalStorage'

const { RangePicker } = DatePicker

const DEFAULT_STYLE = {
  padding: '10px',
  paddingRight: '10px',
  border: '1px solid #e8e8e8',
  borderRadius: '10px',
  overflowY: 'auto',
  height: '100%',
  maxWidth: '250px',
  minWidth: '150px',
  width: '50%'
}

const LOCAL_STORAGE_START = 'institutionScoreSummary.start.moment'
const LOCAL_STORAGE_END = 'institutionScoreSummary.end.moment'
const INITIAL_START = moment().subtract(1, 'year').startOf('isoWeek')
const INITIAL_END = moment()

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser),
    t: getTranslate(state.locale)
  }
}

const InstitutionScoreSummaryView = ({ user, t }) => {
  const [loading, setLoading] = useState(true)
  const [sectors, setSectors] = useState([])
  const [selectedSectors, setSelectedSectors] = useState([])
  const [start, setStart] = useLocalStorage(LOCAL_STORAGE_START, INITIAL_START, true)
  const [end, setEnd] = useLocalStorage(LOCAL_STORAGE_END, INITIAL_END, true)

  const sectorScores = useRef({})

  useEffect(() => {
    getInstitutionSectors(user.institutions[0], user, { orderBy: 'name', strictMode: 1, archived: false }).then(json => {
      if (json && json.data) {
        setSectors(json.data)
      }

      setLoading(false)
    })
  }, [selectedSectors])

  const handleRangeChange = dates => {
    if (dates !== undefined && dates !== null && dates.length > 0) {
      const start = dates[0].clone()
      const end = dates[1].clone()

      setStart(start)
      setEnd(end)
      sectorScores.current = {}
    }
  }

  const handleSectorCheck = (sector, add) => {
    let newSectors = null

    if (add) {
      newSectors = [...selectedSectors, sector]
    } else {
      newSectors = selectedSectors.filter(s => s.id !== sector.id)
      delete sectorScores.current[sector.id]
    }

    setSelectedSectors(newSectors)
  }

  const handleDataChange = (id, score) => {
    sectorScores.current[id] = score
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 175px)', width: '100%' }}>
      <div style={DEFAULT_STYLE}>
        {!loading && (
          <>
            <Tooltip
              title={t('Time period')}
              placement='top'
            >
              <RangePicker
                locale={locale}
                onChange={handleRangeChange}
                value={[start, end]}
                format='DD/MM/YYYY'
              />
            </Tooltip>
            <SearchCheckbox
              checkAllOption
              items={sectors}
              labelKey='name'
              tooltipKey='name'
              title={t('Sectors')}
              valueKey='id'
              style={{ height: '90%' }}
              onCheck={handleSectorCheck}
              onCheckAll={sectors => {
                setSelectedSectors(sectors)
              }}
            />
          </>
        )}
        {(loading) && <Loading size='1x' />}
      </div>
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <ScoreSummaryCard
          title={user.institutions[0].name}
          size='large'
          request={fetchInstitutionScore}
          entityId={user.institutions[0].id}
          user={user}
          end={end}
          start={start}
          score={sectorScores.current[-1]}
          isInstitution
          onChange={handleDataChange}
        />
        {selectedSectors.map((sector, index) => {
          return (
            <ScoreSummaryCard
              key={index}
              title={sector.name}
              size='small'
              request={fetchSectorScore}
              entityId={sector.id}
              user={user}
              end={end}
              start={start}
              score={sectorScores.current[sector.id]}
              onChange={handleDataChange}
            />
          )
        })}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionScoreSummaryView)
