import { MACCS_INTERNSHIPS_MANAGER } from './actionsType'

const { SET_REFUSED_MACCS_INTERNSHIP } = MACCS_INTERNSHIPS_MANAGER

const INITIAL_STATE = {
  refusedInternship: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REFUSED_MACCS_INTERNSHIP: {
      return { ...state, refusedInternship: action.payload }
    }
    default:
      return state
  }
}
