import React, { useState, useEffect, useRef } from 'react'

import Search from 'antd/lib/input/Search'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const TIME_BEFORE_UPDATE = 250
const SEARCH_FIELD_STYLE = { width: 175 }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const SearchField = ({ className, placeholder, value, onChange, t }) => {
  const [currentValue, setCurrentValue] = useState('')
  const timerRef = useRef(null)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleChange = e => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    const value = e.target.value

    setCurrentValue(value)

    timerRef.current = setTimeout(() => {
      onChange(value)
    }, TIME_BEFORE_UPDATE)
  }

  return (
    <div className={className ?? ''}>
      <Search
        value={currentValue}
        placeholder={t('Filter internships')}
        onChange={handleChange}
        style={SEARCH_FIELD_STYLE}
        className='app-tour-2'
      />
    </div>
  )
}

export default connect(mapStateToProps)(SearchField)
