import React, { useContext, useMemo, useState } from 'react'

import { faGraduationCap, faKey } from '@fortawesome/free-solid-svg-icons'
import { recursiveIncludes } from '../../utils'
import { ROLE_ADMIN_HOSPITAL_RO, ROLE_ADMIN_RESEAU, ROLE_LOCAL_SECRETARY, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_PRESI_MASTER, ROLE_CLINICAL_SECRETARY, ROLE_INSTITUTION_GROUP_ADMIN } from '../../utils/constants'
import SupervisedUserSpecialities from '../MACCS/Settings/SupervisedUserSpecialities'
import AdminResetPassword from './AdminResetPassword'
import SmartTable from './SmartTable'

const MaccsUsers = ({ customContext }) => {
  const {
    user, userPermissions, users, loading, SpecialitiesProvider, columns,
    handleUserModification, handleUserSuppression, handleUserCreation, t
  } = useContext(customContext)

  const [selectedPasswordResettingUser, setSelectedPasswordResettingUser] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)

  const additionalActions = useMemo(() => {
    const additionalActions = [
      {
        key: 'assigned-specialities',
        iconName: faGraduationCap,
        type: 'primary',
        title: t('Assigned specialities'),
        onClick: setSelectedUser,
        disabledCallback: u => {
          if (recursiveIncludes(
            user.roles,
            [ROLE_PRESI_MASTER, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_CLINICAL_SECRETARY]
          )) {
            return u.id !== user.id
          }

          return recursiveIncludes(
            u.roles,
            [ROLE_ADMIN_RESEAU, ROLE_ADMIN_HOSPITAL_RO, ROLE_LOCAL_SECRETARY, ROLE_INSTITUTION_GROUP_ADMIN]
          )
        }
      }
    ]

    if (userPermissions.canResetPassword) {
      additionalActions.push({
        key: 'reset-password',
        iconName: faKey,
        type: 'primary',
        title: t('Reset password'),
        onClick: setSelectedPasswordResettingUser
      })
    }

    return additionalActions
  }, [user, userPermissions.canResetPassword, t, setSelectedPasswordResettingUser, setSelectedUser])

  return (
    <div>
      {columns.length > 0 &&
        <SmartTable
          columns={columns}
          data={users}
          loading={loading}
          onDataEdit={handleUserModification}
          onDataAdd={handleUserCreation}
          onDataDelete={userPermissions.canDelete ? handleUserSuppression : undefined}
          addDataText={t('Add user')}
          noDataText={t('No user')}
          additionalActions={additionalActions}
          showAddButtonDisabled={!userPermissions.canCreate}
        />}
      <SpecialitiesProvider.Provider selectedUser={selectedUser}>
        <SupervisedUserSpecialities
          onClose={() => setSelectedUser(null)}
          customContext={SpecialitiesProvider.context}
        />
      </SpecialitiesProvider.Provider>
      {userPermissions.canResetPassword &&
        <AdminResetPassword
          userToResetPassword={selectedPasswordResettingUser}
          onClose={() => setSelectedPasswordResettingUser(null)}
        />}
    </div>
  )
}

export default MaccsUsers
