import React, { useEffect, useState } from 'react'
import { DATE_FORMAT_API, MACCS_CONTEXT, NURSES_CONTEXT } from '../../../utils/constants'
import { getMaccsInternshipMessagesUnreadTotal } from '../../../utils/api/maccsInternshipMessage'
import { markAllAsReadByContext } from '../../../utils/api/internshipMessageByContext'
import AllMessagesReadBell from './AllMessagesReadBell'
import HasUnreadMessagesBell from './HasUnreadMessagesBell'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { getUnreadMessages, readAllMessages } from '../../../reducers/NewMessagesReducer'
import { bindActionCreators } from 'redux'
import { getAppStates } from '../../../reducers/AppStates/actions'

import '../../../assets/new-messages-notification.scss'

const TIME_RELOAD_MESSAGES = 5000

const mapStateToProps = state => {
  return {
    currentDate: getAppStates(state).date,
    t: getTranslate(state.locale),
    unreadMessages: getUnreadMessages(state),
    user: getUser(state.getUser)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readAllMessages: bindActionCreators(readAllMessages, dispatch)
  }
}

const NewMessagesBell = ({ currentDate, unreadMessages, user, readAllMessages, t }) => {
  const [loading, setLoading] = useState(false)
  const [totalMessagesUnread, setTotalMessagesUnread] = useState(0)

  useEffect(() => {
    if (NURSES_CONTEXT.includes(user.context)) {
      const messagesUnread = unreadMessages || 0

      setTotalMessagesUnread(messagesUnread)
    }
  }, [unreadMessages])

  useEffect(() => {
    if (MACCS_CONTEXT.includes(user.context)) {
      runTotalsMessage(user, currentDate)

      const interval = setInterval(() => {
        runTotalsMessage(user, currentDate)
      }, TIME_RELOAD_MESSAGES)

      return () => clearInterval(interval)
    }
  }, [user, currentDate])

  const runTotalsMessage = (user, date) => {
    getMaccsInternshipMessagesUnreadTotal(user, date.format(DATE_FORMAT_API)).then(json => {
      if (json?.data) {
        setTotalMessagesUnread(json.data.total)
      }
    })
  }

  const markAllAsRead = async () => {
    setLoading(true)

    markAllAsReadByContext(user, currentDate)
      .then(json => {
        runTotalsMessage(user, currentDate)

        setLoading(false)
      })
  }

  return (
    <div>
      {totalMessagesUnread === 0 && (
        <AllMessagesReadBell unreadMessagesCount={totalMessagesUnread} />
      )}
      {totalMessagesUnread !== 0 && (
        <HasUnreadMessagesBell
          loading={loading}
          unreadMessagesCount={totalMessagesUnread}
          onClick={markAllAsRead}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMessagesBell)
