import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import SmartTable from '../../shared/SmartTable'
import { getAllAssistants } from '../../../utils/api/assistant'
import moment from 'moment'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const InstitutionAssistants = props => {
  const [assistants, setAssistants] = useState([])

  const {
    loading,
    setLoading,
    columns,
    additionalActions
  } = useContext(props.customContext)

  useEffect(() => {
    if (props.getUser) {
      getAllAssistants(props.getUser)
        .then((jsonData) => {
          const assistants = jsonData.data.map(assistant => {
            assistant.specialityName = assistant.schoolSpeciality && assistant.schoolSpeciality.specialityYear
              ? assistant.schoolSpeciality.specialityYear.specialityAbbreviation
              : null

            if (assistant.birthdate) {
              assistant.birthdate = moment(assistant.birthdate).format('DD/MM/YYYY')
            }

            return assistant
          })

          setAssistants(assistants)
          setLoading(false)
        })
    }
  }, [props.getUser])

  return (
    <>
      <SmartTable
        columns={columns}
        data={assistants}
        loading={loading}
        noDataText={props.t('You do not have any assistants.')}
        additionalActions={additionalActions}
      />
    </>
  )
}

export default connect(mapStateToProps)(InstitutionAssistants)
