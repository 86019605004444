import React, { useContext, useEffect, useState } from 'react'

import { Avatar } from 'antd'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { getAssistantProfileImage } from '../../reducers/SchoolAssistantsReducer/selectors'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAssistantProfileImage } from '../../reducers/SchoolAssistantsReducer/actions'
import { getUser } from '../../reducers/UserReducer'

const PATH_ASSETS = '/assets'

const mapStateToProps = state => {
  return {
    getUser: getUser(state.getUser),
    profileImageAssistant: getAssistantProfileImage(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAssistantProfileImage: bindActionCreators(fetchAssistantProfileImage, dispatch)
})

const UserProfileImage = ({ getUser, profileImageAssistant, fetchAssistantProfileImage }) => {
  const { profileImage } = useContext(GlobalContext)

  const [source, setSource] = useState(`${PATH_ASSETS}/default_profile_img.png`)

  useEffect(() => {
    if (getUser?.roles?.includes('ROLE_ASSISTANT')) {
      fetchAssistantProfileImage(getUser)
    }
  }, [getUser])

  useEffect(() => {
    let src = profileImage || `${PATH_ASSETS}/default_profile_img.png`

    if (profileImageAssistant) {
      src = profileImageAssistant || `${PATH_ASSETS}/default_profile_img.png`
    }

    setSource(src)
  }, [profileImage, profileImageAssistant])

  return (
    <Avatar
      size={60}
      src={source}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileImage)
