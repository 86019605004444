import { requestWithPromise } from '..'
import { GET, PATCH, POST, makeQueryString } from '../apiHelper'

export const getSchoolSpecialities = (school, user, parameters = { excludeRoot: true }) => {
  return requestWithPromise(
    `/api/schools/${school.id}/school-specialities${makeQueryString(parameters)}`,
    GET,
    null,
    user)
}

export const getSchoolByUser = user => {
  return requestWithPromise(
    `/api/School/${user.school.id}`,
    GET,
    null,
    user
  )
}

export const getMaccsLinkedInstitutions = (school, user) => {
  return requestWithPromise(`/api/schools/${school}/institutions-maccs`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const oldUpdateSchool = (school, user) => {
  return requestWithPromise(
    `/api/School/${school.id}`,
    PATCH,
    school,
    user
  )
}

export const editSchool = (school, user) => {
  return requestWithPromise(
    `/api/schools/${school.id}`,
    PATCH,
    school,
    user
  )
}

export const fetchSchools = user => {
  return requestWithPromise(
    `/api/School/${user.school.id}`,
    GET,
    null,
    user
  )
}

export const getSectionsWithContactPerson = (school, user) => {
  return requestWithPromise(
    `/api/schools/${school.id}/school-sections?withContactPerson=1`,
    GET,
    null,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const getSchoolSpecialitiesOnlyWithContactPerson = (school, user) => {
  return requestWithPromise(
    `/api/schools/${school.id}/school-specialities?only-with-contact-persons=1`,
    GET,
    null,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const fetchSchoolOptions = (school, user) => {
  return requestWithPromise(
    `/api/schools/${school.id}/school-options`,
    GET,
    null,
    user
  )
}

export const getAllSchools = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/schools${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const switchSchoolOption = (school, body, user) => {
  return requestWithPromise(
    `/api/schools/${school.id}/school-options`,
    POST,
    body,
    user
  )
}

export const getSchoolStudents = (school, currentUser) => {
  return requestWithPromise(
    `/api/schools/${school.id}/students`,
    GET,
    null,
    currentUser
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getSchoolSchoolSections = (user, school) => {
  return requestWithPromise(
    `/api/schools/${school.id}/school-sections`,
    GET,
    null,
    user
  ).then(json => json?.data[school.id] ?? [])
}

export const getAllData = (user, school) => {
  return requestWithPromise(
    `/api/schools/${school.id}/all`,
    GET,
    null,
    user
  )
}
