import { QUOTAS } from './actionsType'

const {
  INCREMENT_QUOTA_BY_INDEX, DECREMENT_QUOTA_BY_INDEX, INCREMENT_QUOTA_BY_INTERNSHIP,
  DECREMENT_QUOTA_BY_INTERNSHIP, MOVE_QUOTA_BY_INTERNSHIPS, SET_QUOTA, SET_QUOTAS,
  SET_QUOTAS_FOR_MULTIPLES_SECTORS, SET_QUOTAS_TREE, SET_QUOTAS_TREE_LOADING,
  MODIFY_QUOTA_TOTAL, DELETE_QUOTA, ADD_QUOTAS
} = QUOTAS

const INITIAL_STATE = {
  quotas: {},
  tree: {},
  treeIsLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_QUOTAS: {
      const newState = { ...state.quotas }
      const quotas = action.payload

      Object.keys(quotas).forEach(id => {
        newState[id] = formatQuotaDates(quotas[id])
      })

      state.quotas = newState

      return state
    }
    case DECREMENT_QUOTA_BY_INDEX: {
      const { index, pathToNode, internship } = action.payload

      if (internship.sector) {
        const newState = { ...state.tree[index][internship.sector] }

        incrementStateByPath(newState, pathToNode, -1)

        state.tree[index][internship.sector] = newState
      }

      return state
    }
    case DECREMENT_QUOTA_BY_INTERNSHIP: {
      const { indexes, pathToNodeByIndex, internship } = action.payload

      incrementStateByInternshipByIndexes(state.tree, internship, pathToNodeByIndex, -1, indexes)

      return state
    }
    case INCREMENT_QUOTA_BY_INDEX: {
      const { index, pathToNode, internship } = action.payload

      if (internship.sector) {
        const newState = { ...state.tree[index][internship.sector] }

        incrementStateByPath(newState, pathToNode, 1)

        state.tree[index][internship.sector] = newState
      }

      return state
    }
    case INCREMENT_QUOTA_BY_INTERNSHIP: {
      const { indexes, pathToNodeByIndex, internship } = action.payload

      incrementStateByInternshipByIndexes(state.tree, internship, pathToNodeByIndex, 1, indexes)

      return state
    }
    case MOVE_QUOTA_BY_INTERNSHIPS: {
      const { pathToNodeByIndex, internship, updatedPathToNodeByIndex, updatedInternship, indexes } = action.payload

      incrementStateByInternshipByIndexes(state.tree, updatedInternship, updatedPathToNodeByIndex, -1, indexes)
      incrementStateByInternshipByIndexes(state.tree, internship, pathToNodeByIndex, 1, indexes)

      return state
    }
    case SET_QUOTA: {
      const newState = { ...state.quotas }
      const quota = action.payload

      newState[quota.id] = formatQuotaDates(quota)

      state.quotas = newState

      return state
    }
    case SET_QUOTAS: {
      const newState = { ...state.quotas }
      const quotas = action.payload

      Object.keys(quotas).forEach(id => {
        newState[id] = formatQuotaDates(quotas[id])
      })

      state.quotas = newState

      return state
    }
    case SET_QUOTAS_FOR_MULTIPLES_SECTORS: {
      const quotas = action.payload

      Object.keys(quotas).forEach(id => {
        quotas[id] = formatQuotaDates(quotas[id])
      })

      state.quotas = { ...state.quotas, ...quotas }

      return state
    }
    case SET_QUOTAS_TREE: {
      const trees = { ...state.tree }
      const newTrees = action.payload.trees
      const offset = action.payload.startIndex

      newTrees.forEach((tree, index) => {
        trees[offset + index] = tree
      })

      return { ...state, tree: trees, treeIsLoading: false }
    }
    case SET_QUOTAS_TREE_LOADING: {
      return { ...state, treeIsLoading: action.payload }
    }
    case DELETE_QUOTA: {
      const newState = { ...state.quotas }
      const quotaId = action.payload

      delete newState[quotaId]

      state.quotas = newState

      return state
    }
    case MODIFY_QUOTA_TOTAL: {
      const newState = { ...state.quotas }
      const quota = action.payload

      newState[quota.id] = formatQuotaDates(quota)

      state.quotas = newState

      return state
    }
    default:
      return state
  }
}

const incrementStateByInternshipByIndexes = (stateTree, internship, paths, value, indexes) => {
  if (!internship.sector) {
    return
  }

  indexes.forEach((dateIndex, index) => {
    const newState = { ...stateTree[dateIndex][internship.sector] }
    const path = paths[index]

    incrementStateByPath(newState, path, value)

    stateTree[dateIndex][internship.sector] = newState
  })
}

const incrementStateByPath = (state, pathToNode, value) => {
  let currentNode = state

  pathToNode.forEach(key => {
    currentNode = currentNode[key]
  })

  if (typeof currentNode.remaining !== 'undefined') {
    currentNode.remaining += value
  }
}

const formatQuotaDates = quota => {
  quota.startDate = quota.startDate.split('+')[0]
  quota.endDate = quota.endDate.split('+')[0]

  return quota
}
