import React, { useState, useEffect, useRef } from 'react'

import ChatWidget, { MESSAGE_RECEIVED, MESSAGE_SENT } from './ChatWidget'
import moment from 'moment'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import {
  INSTITUTION_CONTEXT,
  INSTITUTION_MACCS_CONTEXT,
  MACCS_CONTEXT,
  NURSES_CONTEXT,
  SCHOOL_CONTEXT,
  SCHOOL_MACCS_CONTEXT
} from '../../utils/constants'
import {
  createInternshipMessagesByContext,
  getInternshipMessagesByContext,
  sendsReadReceiptByContext
} from '../../utils/api/internshipMessageByContext'

const NO_ASSISTANT_TEXT = 'Without assistant'
const NO_STUDENT_TEXT = 'Without student'
const NO_INSTITUTION_TEXT = 'Without institution'

const ChatManager = props => {
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [messageWasMarkedAsUnread, setMessageWasMarkedAsUnread] = useState(false)

  const isFirstRun = useRef(true)

  const zendeskWidget = document.getElementsByClassName('zEWidget-launcher')[0]

  let refreshInterval

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false

      return
    }
    if (props.getChatInternship === null) {
      setMessages([])
      autoRefreshChatManager(false)

      if (typeof zendeskWidget !== 'undefined') {
        zendeskWidget.style.bottom = '0px'
      }
    } else {
      setLoading(true)
      getMessages(props.getChatInternship)
      setMessageWasMarkedAsUnread(false)
      autoRefreshChatManager(true)

      if (typeof zendeskWidget !== 'undefined') zendeskWidget.style.bottom = '450px'
    }

    return function cleanup () {
      autoRefreshChatManager(false)
    }
  }, [props.getChatInternship])

  useEffect(() => {
    if (messageWasMarkedAsUnread) {
      props.setInternshipForChat(null)
    } else {
      clearInterval(refreshInterval)
    }
  }, [messageWasMarkedAsUnread])

  function autoRefreshChatManager (enabled) {
    clearInterval(refreshInterval)

    if (enabled) {
      refreshInterval = setInterval(
        () => {
          const position = document.getElementsByClassName('messages-container')[0].scrollTop
          getMessages(props.getChatInternship, position)
        }
        , 5000)
    }
  }

  function getMessages (internship, position) {
    if (internship) {
      getInternshipMessagesByContext(internship, props.getUser)
        .then(response => {
          if (response && response.data) {
            let newMessages = response.data
            const internshipId = messages[0] ? messages[0].internship : 0
            const newInternshipId = newMessages[0] ? newMessages[0].internship : 0

            if (
              internshipId !== newInternshipId ||
            (internshipId === newInternshipId && messages.length !== newMessages.length)
            ) {
              setConversationRead(internship)

              newMessages = newMessages.map(m => {
                m.origin = props.getUser.username === m.from.username ? MESSAGE_SENT : MESSAGE_RECEIVED

                return m
              })

              setLoading(false)
              setMessages(newMessages)
            }

            setLoading(false)
          }
          setLoading(false)
          document.getElementsByClassName('messages-container')[0].scrollTop = position
        })
    }
  }

  function postMessage (message) {
    setLoading(true)

    createInternshipMessagesByContext(props.getChatInternship, message, props.getUser)
      .then(json => {
        const message = json.data
        let newMessages = messages

        message.from = { username: props.getUser.username }

        newMessages = newMessages.concat(message)

        setLoading(false)
        setMessages(newMessages)
      })

    setConversationRead(props.getChatInternship)
  }

  function setConversationRead (internship) {
    if (messageWasMarkedAsUnread) {
      return
    }

    props.readMessage(internship.id)

    sendsReadReceiptByContext(internship, props.getUser)
  }

  function handleCloseChatManager () {
    setConversationRead(props.getChatInternship)
    clearInterval(refreshInterval)
    props.setInternshipForChat(null)
    setMessageWasMarkedAsUnread(false)

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }

  function handleMessageUnread () {
    props.setInternshipForChat(null)

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }

  const getSchoolName = internship => {
    let name = ''

    if (internship.school && typeof internship.school === 'object') {
      name = `${props.t('Contact')} ${internship.school?.name ?? internship.school}`
    } else if (internship.school) {
      name = `${props.t('Contact')} ${internship.schoolName ?? internship.school}`
    } else {
      name = props.t('Without school')
    }

    return name
  }

  const getInstitutionName = internship => {
    let name = ''

    if (internship.institution && typeof internship.institution === 'object') {
      name = `${props.t('Contact')} ${internship.institution?.name ?? internship.institution}`
    } else if (internship.institution) {
      name = `${props.t('Contact')} ${internship.institutionName ?? internship.institution}`
    } else {
      name = props.t('Without institution')
    }

    return name
  }

  const getDate = date => {
    if (date.includes('/')) {
      return date
    }

    if (date.includes('+')) {
      date = date.split('+')[0]
    }

    const formatedDate = moment(date).format('DD/MM/YY')

    if (formatedDate === 'Invalid date') {
      return date
    }

    return formatedDate
  }

  const internship = props.getChatInternship

  if (!internship) return null

  const titleWidgetByContext = (internship, context) => {
    if (typeof internship.studentName !== 'undefined') {
      return renderTitleOldWay(internship)
    }

    return renderTitle(internship, context)
  }

  const renderTitle = (internship, context) => {
    const { student, assistant } = internship

    let subtitle = ''

    if (NURSES_CONTEXT.includes(context)) {
      if (internship.studentName) {
        return internship.studentName
      }

      if (student) {
        return typeof student === 'object' && student !== null
          ? `${student.lastname} ${student.firstname}`
          : `${internship.lastname} ${internship.firstname}`
      }

      return props.t(NO_STUDENT_TEXT)
    }

    if (MACCS_CONTEXT.includes(context)) {
      subtitle = assistant ? `${assistant.lastname} ${assistant.firstname}` : props.t(NO_ASSISTANT_TEXT)
    }

    subtitle = `${subtitle}, ${props.t('from')} ${getDate(internship.startDate)}`
    subtitle = `${subtitle} ${props.t('to')} ${getDate(internship.endDate)}`

    return subtitle
  }

  const renderTitleOldWay = internship => {
    let subtitle = internship.studentName ?? props.t(NO_STUDENT_TEXT)

    subtitle = `${subtitle}, ${props.t('from')} ${internship.startDate}`
    subtitle = `${subtitle} ${props.t('to')} ${internship.endDate}`

    return subtitle
  }

  const subtitleWidgetByContext = (internship, context) => {
    if (typeof internship.studentName !== 'undefined') {
      return renderSubtitleOldWay(internship, context)
    }

    return renderSubtitle(internship, context)
  }

  const renderSubtitle = (internship, context) => {
    let title = props.t(NO_INSTITUTION_TEXT)

    if ((context === SCHOOL_CONTEXT || context === SCHOOL_MACCS_CONTEXT) && internship.institution !== null) {
      title = `${props.t('Contact')} ${internship.institution.name}`
    }

    if ((context === INSTITUTION_CONTEXT || context === INSTITUTION_MACCS_CONTEXT) && internship.school !== null) {
      title = `${props.t('Contact')} ${internship.school.name}`
    }

    return title
  }

  const renderSubtitleOldWay = (oldInternship, context) => {
    let subtitle = NO_INSTITUTION_TEXT

    if (props.getUser.context === SCHOOL_CONTEXT) {
      subtitle = getInstitutionName(internship)
    } else if (props.getUser.context === INSTITUTION_CONTEXT) {
      subtitle = getSchoolName(internship)
    }

    subtitle = `${subtitle}, ${props.t('from')} ${getDate(internship.startDate)}`
    subtitle = `${subtitle} ${props.t('to')} ${getDate(internship.endDate)}`

    return subtitle
  }

  return (
    <ChatWidget
      loading={loading}
      messages={loading ? [] : messages}
      onClose={handleCloseChatManager}
      sendMessage={postMessage}
      subtitle={subtitleWidgetByContext(internship, props.getUser.context)}
      title={titleWidgetByContext(internship, props.getUser.context)}
      onUnread={handleMessageUnread}
      messageWasMarkedAsUnread={messageWasMarkedAsUnread}
      startDate={internship.startDate}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatManager)
