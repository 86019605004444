import React, { useState, useEffect, useRef, useCallback } from 'react'

import { Modal, Button } from 'antd'
import ReactCrop from 'react-image-crop'
import { } from '../../../utils'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import 'react-image-crop/dist/ReactCrop.css'
import '../../../assets/profile-page.scss'

const CROP_ASPECT = 1
const CROP_UNIT = ''
const CROP_WIDTH = 65

const ChooseProfilePictureModal = (props) => {
  const [image, setImage] = useState(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [visible, setVisible] = useState(false)
  const [crop, setCrop] = useState(null)

  const imgRef = useRef(null)

  /**
   * Set the picture to be cropped to null when the Component is unmount
   */
  useEffect(() => {
    return () => {
      setImage(null)
    }
  }, [])

  /**
   * Set a new picture in the local state every time the property "image" changed
   */
  useEffect(() => {
    if (props.image) {
      setImage(props.image)
    }
  }, [props.image])

  /**
   * Set a new modal visibility in the local state every time the property "visible" changed
   */
  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  /**
   * Render a new cropped picture every time the local state "completedCrop" changed
   */
  useEffect(() => {
    if (image) {
      callback()
    }
  }, [completedCrop])

  const callback = async () => {
    const croppedPicture = await onCropCompleted(crop, 'testFile')

    props.getCroppedPicture(croppedPicture)
  }

  /**
   * Use cropped picture in order to create a new Blob
   *
   * @param {Object} crop     the properties used to extract picture part
   * @param {string} fileName the name of the created blob
   * @returns Promise in wich a canva will be converted into a Blob
   */
  const onCropCompleted = (crop, fileName) => {
    const image = imgRef.current
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = crop.width
    canvas.height = crop.height

    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName
        resolve(blob)
      }, 'image/jpeg', 1)
    })
  }

  /**
   * Set new cropping information when the user modify the cropping window position or size
   *
   * @param {Object} crop the properties used to define a specific picture part
   */
  const onCropChange = crop => {
    if (crop.width !== 0 && crop.height !== 0) {
      setCrop(crop)
    }
  }

  /**
   * Handle the closing of the modal if the OK button is clicked
   */
  const handleOk = () => {
    setVisible(false)
    setCompletedCrop(crop)

    if (props.onOk) {
      props.onOk()
    }
  }

  /**
   * Handle the closing of the modal if the Cancel button is clicked or
   *  if the user clicked outside of the modal
   */
  const handleCancel = () => {
    setVisible(false)

    if (props.onCancel) {
      props.onCancel()
    }
  }

  /**
   * Managed the loading of the picture in the cropping Component
   */
  const onLoad = useCallback((img) => {
    const aspect = props.aspect ? props.aspect : CROP_ASPECT
    let squareSize = 0

    imgRef.current = img

    if (img.width < img.height) {
      squareSize = Math.floor(img.width * (CROP_WIDTH / 100))
    } else {
      squareSize = Math.floor(img.height * (CROP_WIDTH / 100))
    }
    setCrop({
      aspect: aspect,
      unit: props.unit ? props.unit : CROP_UNIT,
      width: squareSize,
      height: squareSize,
      x: img.width / 2 - squareSize / 2,
      y: img.height / 2 - squareSize / 2
    })

    return false
  }, [])

  return (
    <Modal
      destroyOnClose
      id='modal_size'
      title={props.t(props.title ? props.title : 'Position your new picture')}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleOk}>
          {props.t('Save new profile picture')}
        </Button>
      ]}
    >
      <ReactCrop
        src={image}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={c => onCropChange(c)}
      />
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseProfilePictureModal)
