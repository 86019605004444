import { SCHOOL_ASSISTANTS } from './actionsType'
import { remove } from 'lodash'

const {
  SET_ASSISTANT,
  SET_ASSISTANTS,
  REMOVE_ASSISTANT,
  SET_SCHOOL_SPECIALITIES,
  SET_ASSISTANT_PROFILE_IMAGE,
  SET_EXTRA_CONTACT_INFO,
  SET_OPTIN,
  UPDATE_ASSISTANT
} = SCHOOL_ASSISTANTS

const INITIAL_STATE = {
  assistant: {},
  assistants: [],
  schoolSpecialities: [],
  profileImage: null,
  extraContactInfos: [],
  optin: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ASSISTANT: {
      if (state !== action.payload) {
        return {
          ...state,
          assistant: action.payload
        }
      }

      return state
    }
    case SET_ASSISTANTS: {
      if (state !== action.payload) {
        return {
          ...state,
          assistants: action.payload
        }
      }

      return state
    }
    case REMOVE_ASSISTANT: {
      const assistants = state.assistants.slice()

      remove(assistants, item => item.id === action.payload)

      return {
        ...state,
        assistants
      }
    }
    case SET_SCHOOL_SPECIALITIES: {
      if (state !== action.payload) {
        return {
          ...state,
          schoolSpecialities: action.payload
        }
      }

      return state
    }
    case SET_ASSISTANT_PROFILE_IMAGE: {
      if (state !== action.payload) {
        return {
          ...state,
          profileImage: action.payload
        }
      }

      return state
    }
    case SET_EXTRA_CONTACT_INFO: {
      if (state !== action.payload) {
        return {
          ...state,
          extraContactInfos: action.payload
        }
      }

      return state
    }
    case SET_OPTIN: {
      if (state !== action.payload) {
        return {
          ...state,
          optin: action.payload
        }
      }

      return state
    }
    case UPDATE_ASSISTANT: {
      const newState = { ...state }

      newState.assistants = state.assistants.map(a => {
        if (a.id === action.payload.id) {
          return action.payload
        }

        return a
      })

      return newState
    }
    default:
      return state
  }
}
