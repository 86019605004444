import React, { useMemo } from 'react'
import { getTranslate } from 'react-localize-redux'
import DataTable from '../../../Components/shared/DataTable/DataTable'
import { ORDER_BY } from '../../../utils/constants'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import Cookies from 'js-cookie'
import { getFullName, getYear } from '../../../utils/entities/schoolSpeciality'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SpecialitiesTable = ({ data, institution = null, loading, metadata, parameters, user, onParametersChange, onTriggerAction, t }) => {
  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '180px' : '139px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => [
    {
      title: t('data_table.column_title.name'),
      orderBy: ORDER_BY.NAME,
      key: s => {
        const name = getFullName(s)
        const maxLength = !institution ? 50 : 30

        return name.length > maxLength ? `${name.slice(0, maxLength - 3)}...` : name
      },
      tooltip: getFullName
    },
    { title: t('data_table.column_title.year'), orderBy: ORDER_BY.COMMENT, key: getYear }
  ], [institution, t])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        columns={columns}
        data={data}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(SpecialitiesTable)
