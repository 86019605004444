import React, { useState } from 'react'

import AssistantInfo from '../../../Components/MACCS/Internships/AssistantInfo'
import { getUser } from '../../../reducers/UserReducer'
import { getAssistantsProvider, getAssistantsView } from './componentGetters'
import { connect } from 'react-redux'
import AdminResetPassword from '../../../Components/shared/AdminResetPassword'

import '../../../assets/assistants-view.scss'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}
const Assistants = ({ user }) => {
  const [selectedAssistant, setSelectedAssistant] = useState(null)
  const [selectedAssistantForPasswordReset, setSelectedAssistantForPasswordReset] = useState(null)

  const Assistants = getAssistantsView(user?.context)
  const { Provider, context } = getAssistantsProvider(user?.context)

  return (
    <>
      {Assistants !== null && Provider !== null && (
        <Provider
          onSelectAssistant={setSelectedAssistant}
          onPasswordReset={setSelectedAssistantForPasswordReset}
        >
          <Assistants customContext={context} />
        </Provider>
      )}
      <AssistantInfo
        assistant={selectedAssistant}
        onClose={() => setSelectedAssistant(null)}
      />
      <AdminResetPassword
        userToResetPassword={selectedAssistantForPasswordReset}
        onClose={() => setSelectedAssistantForPasswordReset(null)}
        person='assistantId'
      />
    </>
  )
}

export default connect(mapStateToProps)(Assistants)
