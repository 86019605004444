import {
  DATE_FORMAT_API,
  MACCS_CONTEXT,
  NURSES_CONTEXT
} from '../constants'
import {
  createInternshipMessages,
  getInternshipMessages,
  markAllAsRead,
  sendsReadReceipt
} from './internshipMessage'
import {
  createMaccsInternshipMessages,
  getMaccsInternshipMessages,
  sendsReadReceiptAllMaccs,
  sendsReadReceiptMaccs
} from './maccsInternshipMessage'

export const getInternshipMessagesByContext = (internship, user) => {
  if (NURSES_CONTEXT.includes(user.context)) {
    return getInternshipMessages(internship, user)
  }

  if (MACCS_CONTEXT.includes(user.context)) {
    return getMaccsInternshipMessages(internship, user)
  }

  return {
    then: () => console.error('Read permission on internship messages was denied.')
  }
}

export const sendsReadReceiptByContext = (internship, user) => {
  if (NURSES_CONTEXT.includes(user.context)) {
    return sendsReadReceipt(internship, user)
  }

  if (MACCS_CONTEXT.includes(user.context)) {
    return sendsReadReceiptMaccs(internship, user)
  }

  return {
    then: () => console.error('Save permission on internship messages was denied.')
  }
}

export const createInternshipMessagesByContext = (internship, content, user) => {
  if (NURSES_CONTEXT.includes(user.context)) {
    return createInternshipMessages(internship, content, user)
  }

  if (MACCS_CONTEXT.includes(user.context)) {
    return createMaccsInternshipMessages(internship, content, user)
  }

  return {
    then: () => console.error('Save permission on internship message was denied.')
  }
}

export const markAllAsReadByContext = (user, date) => {
  if (NURSES_CONTEXT.includes(user.context)) {
    return markAllAsRead(user)
  }

  if (MACCS_CONTEXT.includes(user.context)) {
    return sendsReadReceiptAllMaccs(user, date.format(DATE_FORMAT_API))
  }

  return {
    then: () => console.error('Save permission on internship message was denied.')
  }
}
