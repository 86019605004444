import { requestImage } from '..'
import { GET } from '../apiHelper'

export const getImageByName = (imageName, user) => {
  return requestImage(
    `/profil/image/${imageName}`,
    GET,
    null,
    user
  )
}
