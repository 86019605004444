import React, { useContext } from 'react'
import { Divider, Row, Select, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const { Option } = Select

const MaccsEventCodeSelect = ({ value, update, onSelect, t }) => {
  const { eventCodes } = useContext(GlobalContext)

  return (
    <div>
      {update && (
        <Divider orientation='left'> {t('Event code')} </Divider>
      )}
      <Row gutter={16}>
        <Row className='desiderata-eventcode-select'>
          <Select
            placeholder={t('Encode an event')}
            value={value}
            size='large'
            style={{ width: '100%' }}
            onChange={(value, option) => onSelect(option.props.data)}
          >
            {
              eventCodes.map(ec => {
                return (
                  <Option value={ec.typeId} key={ec.id} data={ec}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {ec.code}
                      <Tooltip
                        placement='right'
                        title={ec.label}
                      >
                        <FontAwesomeIcon icon='info-circle' />
                      </Tooltip>
                    </div>
                  </Option>
                )
              })
            }
          </Select>
        </Row>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MaccsEventCodeSelect)
