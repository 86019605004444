import React, { useState, useEffect } from 'react'

import { Select } from 'antd'

const Option = Select.Option
const STYLE = {
  width: '100%'
}
const PLACEHOLDER = 'Select a speciality'

const SpecialitySelect = props => {
  const [displayValue, setDisplayValue] = useState('')
  const [specialities, setSpecialities] = useState({})

  useEffect(() => {
    if (props.data && props.valueKey && (props.dataColumn[props.valueKey] || props.valueKey === -1) && props.dataColumn) {
      setDisplayValue('')
      setSpecialities(props.dataColumn)
    }
  }, [props.data, props.valueKey, props.dataColumn])

  const handleChange = (value) => {
    const data = { ...props.data }

    data['newSpeciality'] = value
    data['complete'] = false

    if(data.newStudyYear !== undefined) {
      delete data.newStudyYear
    }

    props.onChange(data)
  }

  const renderOptions = () => {
    return Object.keys(specialities).map(key => {
      const value = specialities[key].id
      const content = specialities[key].abbreviation
      const title = specialities[key].name

      const newOption = renderOption(key, value, content, title)

      if (specialities[key].id === props.valueKey && displayValue === '') {
        setDisplayValue(specialities[key].id)
      }

      return newOption
    })
  }

  const renderOption = (key, value, content, title) => {
    if (title) {
      return (
        <Option
          key={'option' + key}
          value={value}
          title={title}
        >
          {content}
        </Option>
      )
    }

    return (
      <Option
        key={'option' + key}
        value={value}
      >
        {content}
      </Option>
    )
  }

  const filterOptions = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return specialities[props.valueKey] !== undefined || props.valueKey === -1
  ? (
    <Select
      showSearch
      placeholder={PLACEHOLDER}
      optionFilterProp='children'
      onChange={handleChange}
      disabled={props.disabled}
      onFocus={() => props.onFocus()}
      onBlur={() => props.onBlur()}
      style={STYLE}
      value={displayValue}
      filterOption={filterOptions}
    >
      {renderOptions()}
    </Select>
  )
  : (<div />)

}

export default SpecialitySelect