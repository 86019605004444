import React from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../../routes'
import AssistantsInternshipsManagerView from '../../Views/MACCS/MaccsInternshipsManager/AssistantsInternshipsManagerView'
import AssistantProfilePage from '../../Components/MACCS/ProfilePage/AssistantProfilePage'

const AssistantRouter = () => {
  return (
    <Switch>
      <Route path={routes.INTERNSHIPS}>
        <div className='inner-spacing'>
          <AssistantsInternshipsManagerView />
        </div>
      </Route>
      <Route path={routes.PROFILE_PAGE}>
        <AssistantProfilePage />
      </Route>
    </Switch>
  )
}

export default withRouter(AssistantRouter)
