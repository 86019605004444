import React, { useEffect, useState, useContext } from 'react'

import DateTitle from './DateTitle'
import { makeInternship } from '../../../../utils/entities/internship'
import { oldCreateInternship } from '../../../../utils/api/internship'
import { getUser } from '../../../../reducers/UserReducer'
import { DAY_AND_MONTH_FORMAT } from '../utils/constants'
import { InternshipsDataContext } from '../../../../Context/InternshipsManager/InternshipsDataProvider'
import { FULL_DATE_WITHOUT_TIMEZONE, ROLE_COORDINATOR } from '../../../../utils/constants'
import { faCalendar, faPlus } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { onSuccess } from '../../../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SchoolDateTitle = ({ startDate, endDate, displayAction, index, isCurrent, user, t }) => {
  const { highlightedIndexes, refreshInternships } = useContext(InternshipsDataContext)

  const [isHighlighted, setIsHighlighted] = useState(false)

  useEffect(() => {
    if (typeof index !== 'undefined' && highlightedIndexes) {
      setIsHighlighted(index >= highlightedIndexes.startIndex && index <= highlightedIndexes.lastIndex)
    } else {
      setIsHighlighted(false)
    }
  }, [index, highlightedIndexes])

  const handleAction = ({ startDate, endDate }) => {
    const newInternship = makeInternship({ startDate, endDate })

    newInternship.school = user.school.id
    newInternship.startDate = newInternship.startDate.format(FULL_DATE_WITHOUT_TIMEZONE)
    newInternship.endDate = newInternship.endDate.format(FULL_DATE_WITHOUT_TIMEZONE)

    if (user.roles.includes(ROLE_COORDINATOR)) {
      newInternship.section = user.coordinatedSections[0].id
    }

    oldCreateInternship(newInternship, user).then(json => {
      if (json?.data) {
        refreshInternships()
        onSuccess(t('school_date_title.actions.create_internship'))
      }
    })
  }

  return (
    <DateTitle
      startDate={startDate}
      endDate={endDate}
      isCurrent={isCurrent}
      dateFormat={DAY_AND_MONTH_FORMAT}
      actionShown={displayAction}
      icon={displayAction ? faPlus : faCalendar}
      iconTitle={displayAction ? 'Add an internship' : 'Edit schedules'}
      index={index}
      highlighted={isHighlighted}
      onAction={handleAction}
    />
  )
}

export default connect(mapStateToProps)(SchoolDateTitle)
