import React from 'react'
import StudentQuickMenu from '../../../Components/shared/InternshipsManager/StudentQuickMenu'
import { INSTITUTION_MACCS_CONTEXT, SCHOOL_MACCS_CONTEXT, internshipStates } from '../../../utils/constants'
import InternshipValidationButton from '../../../Components/shared/ShiftsManager/InternshipValidationButton'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faSchool, faUserGraduate } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/maccs-shifts-manager.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const InternshipCard = ({ context, internship, onSelectInternship, onSelectAssistant, onSelectValidation, t }) => {
  const getValidateButtonTooltip = internship => {
    if (internship.state === internshipStates.SCHEDULE_TO_VALIDATE) {
      return t('internship_card.internship_to_validate_tooltip')
    }

    if (internship.state === internshipStates.SCHEDULE_VALIDATED) {
      return t('Internship validated')
    }

    return t('Validate internship')
  }

  return (
    <div className='internship-card-container'>
      <div className='internship-card-title'>
        <StudentQuickMenu
          internship={internship}
          onSelectedStudentInfo={onSelectAssistant}
          onSelectedInternshipInfo={onSelectInternship}
          inShiftManager
        />
        <div className='internship-information'>
          <div>
            <div className='regular-image'> <FontAwesomeIcon icon={faUserGraduate} /> </div>
            <span><b> {internship.assistant && `${internship.assistant.firstname} ${internship.assistant.lastname}`} </b></span>
          </div>
          <div>
            <div className='regular-image'> <FontAwesomeIcon icon={faSchool} /> </div>
            {context === SCHOOL_MACCS_CONTEXT && internship.institution && (
              <span> {internship.institution.name} </span>
            )}
            {context === INSTITUTION_MACCS_CONTEXT && internship.school && (
              <span> {internship.school.name} </span>
            )}
          </div>
          <div>
            <div className='regular-image'> <FontAwesomeIcon icon={faGraduationCap} /> </div>
            <div className='last-text'>
              {internship.speciality && internship.speciality.name}
            </div>
          </div>
        </div>
      </div>
      <div className='internship-card-buttons'>
        <InternshipValidationButton
          validateButtonTooltip={getValidateButtonTooltip(internship)}
          internship={internship}
          onDisplayValidationDrawer={onSelectValidation}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipCard)
