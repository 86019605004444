import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, notification, Popconfirm, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState, useRef, Suspense, useContext } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { downloadFile, formatTimeFromMinutes, getJobResultWithPromise } from '../../../../utils'
import { exportValidatedMaccsInternship, getMaccsInternshipShifts, invalidateScheduleMaccsInternship, sendMaccsInternshipMessage, validateScheduleMaccsInternship } from '../../../../utils/api/maccsInternship'
import { GET } from '../../../../utils/apiHelper'
import { INSTITUTION_CONTEXT, internshipStates } from '../../../../utils/constants'
import { recruitmentModuleEnabled } from '../../../../utils/internship-utils'
import InvalidateInternshipModal from '../../InvalidateInternshipModal'
import StudentEvaluationByInstitution from '../../StudentEvaluationByInstitution'
import ValidationWarning from '../InternshipValidation/ValidationWarning'
import { ABSENCE_CATEGORY } from '../constants'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const { TabPane } = Tabs

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const INITIAL_VALIDATION_BUTTON_TEXT = 'Internship validated'
const HOVERING_VALIDATION_BUTTON_TEXT = 'Invalidate?'
const INITIAL_VALIDATION_BUTTON_ICON = faCheck
const HOVERING_VALIDATION_BUTTON_ICON = faTimes
const HOVERING_VALIDATION_BUTTON_STYLE = {
  backgroundColor: '#FF7F7F',
  fontWeight: 'bold',
  color: 'white'
}
const INITIAL_VALIDATION_BUTTON_STYLE = {
  backgroundColor: 'rgb(126, 183, 62)',
  fontWeight: 'bold',
  color: 'white'
}

const renderButtonProps = (text, style, icon) => {
  return {
    text,
    icon,
    style: {
      ...style,
      justifyContent: 'center',
      width: '150px'
    }
  }
}

const MaccsInternshipValidation = ({ bottom, maccsInternship, title, onClose, t, user, onValidate, onInvalidate, addTaggedEntity }) => {
  const { eventCodes } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)
  const [assistantName, setAssistantName] = useState('')
  const [validationDocumentDetails, setValidationDocumentDetails] = useState(null)
  const [showDownloadButton, setShowDownloadButton] = useState(true)
  const [validationEnabledOn, setValidationEnabledOn] = useState(null)
  const [maccsInternshipInstitution, setMaccsInternshipInstitution] = useState(null)
  const [feedbackEnabled, setFeedbackEnabled] = useState(false)
  const [shifts, setShifts] = useState([])
  const [totalTime, setTotalTime] = useState(0)
  const [totalPause, setTotalPause] = useState(0)
  const [tableMaxHeight, setTableMaxHeight] = useState(null)
  const [displayedTabKey, setDisplayedTabKey] = useState('1')
  const [internshipDisplayState, setInternshipDisplayState] = useState(null)
  const [currentInternship, setCurrentInternship] = useState({})
  const [validating, setValidating] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [validationButton, setValidationButton] = useState(renderButtonProps(
    INITIAL_VALIDATION_BUTTON_TEXT,
    INITIAL_VALIDATION_BUTTON_STYLE,
    INITIAL_VALIDATION_BUTTON_ICON
  ))
  const [isInvalidationModalOpen, setIsInvalidationModalOpen] = useState(false)

  const bodyRef = useRef(null)

  useEffect(() => {
    if (maccsInternship) {
      loadShifts(maccsInternship)

      if (maccsInternship.assistantValidationDocument && bottom) {
        setValidationDocumentDetails(maccsInternship.assistantValidationDocument)
        setShowDownloadButton(true)
      } else {
        setShowDownloadButton(false)
      }

      setAssistantName(maccsInternship.assistant.firstname + ' ' + maccsInternship.assistant.lastname)
      setMaccsInternshipInstitution(maccsInternship.institution)
      setCurrentInternship(maccsInternship)
    }
  }, [maccsInternship, bottom])

  useEffect(() => {
    if (shifts.length && recruitmentModuleEnabled(maccsInternship)) {
      setFeedbackEnabled(true)
    }
  }, [maccsInternship, shifts])

  useEffect(() => {
    computeTableHeight()
  }, [feedbackEnabled])

  useEffect(() => {
    // set the state name
    for (const stateName in internshipStates) {
      if (internshipStates[stateName] === maccsInternship.state) {
        const loweredCaseState = stateName.toLowerCase()
        setInternshipDisplayState(`${loweredCaseState.charAt(0).toUpperCase()}${loweredCaseState.slice(1)}`)
        break
      }
    }
  }, [maccsInternship.state])

  const loadShifts = internship => {
    setLoading(true)

    getMaccsInternshipShifts(internship, user, { 'order-by': 'end-date' }).then(shifts => {
      const lastShiftDate = shifts.length > 0
        ? moment(shifts[shifts.length - 1].endDate)
        : moment(internship.endDate)

      if (lastShiftDate && lastShiftDate.startOf('day') > moment().startOf('day')) {
        setValidationEnabledOn(lastShiftDate.format('DD/MM/YYYY'))
      }

      let totalPause = 0
      let totalTime = 0
      const startDate = moment(internship.startDate).startOf('day')
      const endDate = moment(internship.endDate).endOf('day')

      setShifts(shifts.map(s => {
        const shiftStartDate = moment(s.startDate)
        const shiftEndDate = moment(s.endDate)

        if (s.id > -1) {
          const parsedShift = {
            id: s.id,
            date: shiftStartDate.format('DD/MM'),
            outsideOfInternshipDates: shiftStartDate.clone().startOf('day') < startDate || shiftEndDate.clone().endOf('day') > endDate,
            isDesiderata: s.isDesiderata
          }

          if (s.eventCodeType && s.eventCodeType.category === ABSENCE_CATEGORY) {
            const event = eventCodes.find(e => e.typeId === s.eventCodeType.id)

            console.log(event)

            parsedShift.absentLabel = event.label
          } else {
            parsedShift.startTime = shiftStartDate.format('HH:mm')
            parsedShift.endTime = shiftEndDate.format('HH:mm')
            parsedShift.pause = s.pause > 0 ? s.pause : 0
            totalPause += parsedShift.pause
            // compute the time difference in hours

            if (shiftStartDate > shiftEndDate) {
            /**
             * because the backend returns the same date even if the actual end date is
             * passed midnight, we add one day to the end time so we can have a correct computation
             * of the duration
             */
              parsedShift.duration = shiftEndDate.add(1, 'day').diff(shiftStartDate, 'minutes')
            } else {
              parsedShift.duration = shiftEndDate.diff(shiftStartDate, 'minutes')
            }

            // subtract the duration of the pause
            parsedShift.duration -= parsedShift.pause

            if (parsedShift.duration < 0) {
              parsedShift.duration = 0
            }
            // increment total hours
            totalTime += parsedShift.duration

            // format the row total time
            parsedShift.duration = formatTimeFromMinutes(parsedShift.duration)

            // format the pause
            parsedShift.pause = formatTimeFromMinutes(parsedShift.pause)
            parsedShift.exactLocation = s.exactLocation
          }

          return parsedShift
        }
      }))

      // now format the total time & total pause
      const totalTimeHours = parseInt(totalTime / 60)
      let totalTimeMinutes = parseInt(totalTime % 60)

      if (totalTimeMinutes < 10) {
        totalTimeMinutes = `0${totalTimeMinutes}`
      }

      const totalPauseHours = parseInt(totalPause / 60)
      let totalPauseMinutes = parseInt(totalPause % 60)

      if (totalPauseMinutes < 10) {
        totalPauseMinutes = `0${totalPauseMinutes}`
      }

      setTotalTime(`${totalTimeHours}:${totalTimeMinutes}`)
      setTotalPause(`${totalPauseHours}:${totalPauseMinutes}`)
      setLoading(false)
      computeTableHeight()
    })
  }

  const computeTableHeight = () => {
    if (bodyRef.current) {
      const percentageHeight = feedbackEnabled ? 55 : 56

      setTableMaxHeight(bodyRef.current.clientHeight * percentageHeight / 100)
    }
  }

  const handleTabChange = tabKey => {
    setDisplayedTabKey(tabKey)
  }

  const handleValidation = () => {
    setValidating(true)

    validateScheduleMaccsInternship({ maccsInternship: maccsInternship.id }, user)
      .then((data) => {
        onValidate()
        setValidating(false)

        if (recruitmentModuleEnabled({ ...maccsInternship, institution: maccsInternshipInstitution, state: data.state })) {
          setFeedbackEnabled(true)
          // and move directly to evaluation tab
          setTimeout(() => {
            setDisplayedTabKey('2')
          }, 10)
        }

        notification.success({ message: t('Validated'), placement: 'bottomRight' })
      })
      .catch(error => {
        setValidating(false)
        console.error(error)
      })
  }

  const handleDocumentPrint = () => {
    setPrinting(true)

    if (validationDocumentDetails) {
      // assistant already uploaded a document, we have to download it
      try {
        downloadFile(`/maccsinternship/document/download/${maccsInternship.assistantValidationDocument.id}`, validationDocumentDetails.originalName, user)
        setPrinting(false)
      } catch (err) {
        setPrinting(false)
        console.error(err)
      }
    } else {
      exportValidatedMaccsInternship({ maccsInternship: maccsInternship.id }, user)
        .then(job => {
          const longPollingIntervalExport = setInterval(() => {
            getJobResultWithPromise(job, user)
              .then(json => {
                if (
                  (typeof json.error === 'undefined' || json.error === false) &&
                  typeof json.data !== 'undefined'
                ) {
                  clearInterval(longPollingIntervalExport)
                  setPrinting(false)

                  const fileName = json.data.result

                  try {
                    downloadFile(
                      `/validation-docs/download/${fileName}`,
                      fileName,
                      user,
                      GET
                    )
                  } catch (err) {
                    console.error(err)
                    clearInterval(longPollingIntervalExport)
                    setPrinting(false)
                  }
                }
              })
          }, 1500)
        })
    }
  }

  const handleMaccsInternshipInvalidation = () => {
    invalidateScheduleMaccsInternship({ maccsInternship: maccsInternship.id }, user)
      .then(data => {
        onInvalidate(data.state)
        setFeedbackEnabled(false)

        notification.success({ message: t('De-validated'), placement: 'bottomRight' })

        sendMaccsInternshipMessage({ maccsInternship: maccsInternship.id, content: getInvalidationMessageContent(user) }, user)
      })

    setIsInvalidationModalOpen(false)
  }

  const getInvalidationMessageContent = user => {
    return t('The internship has been de-validated by') + ` ${user.username}`
  }

  const handleMaccsInternshipInvalidationCancelation = () => {
    setIsInvalidationModalOpen(false)
  }

  const renderValidationScreen = () => {
    const showShiftsTable = !loading && shifts.length > 0

    return (
      <div className='internship-validation-summary' ref={bodyRef}>
        <div className='internship-details'>
          <div>{t('Internship details')}</div>
          <div>
            <span>{t('Start date')}: <b>{moment(maccsInternship.startDate).format('DD/MM/YYYY')}</b></span>, {' '}
            <span>{t('End date')}: <b>{moment(maccsInternship.endDate).format('DD/MM/YYYY')}</b></span>
            {internshipDisplayState && <span>, {t('Status')}: <b>{(t(internshipDisplayState))}</b></span>}
          </div>
        </div>
        <h3>{t('Shifts summary table')}</h3>
        <div className='table-wrapper'>
          {showShiftsTable &&
            <table>
              <thead>
                <tr>
                  <th className='col-date'>{t('Date')}</th>
                  <th className='col-start'>{t('Start time')}</th>
                  <th className='col-end'>{t('End time')}</th>
                  <th className='col-pause'>{t('Pause')}</th>
                  <th className='col-total'>{t('Total (hours)')}</th>
                </tr>
              </thead>
            </table>}
          {loading && <div className='loading-ring' />}
          {!loading && shifts.length === 0 && <div className='no-shifts-warning'>{t('No shifts for this internship, it can\'t be validated')}</div>}
          <div className={`shifts-table ${!showShiftsTable ? 'no-border' : ''}`} style={{ maxHeight: tableMaxHeight || 'unset' }}>
            {showShiftsTable &&
              <table>
                {shifts.length && (
                  <tbody>
                    {shifts.map(shift => {
                      if (shift.absentLabel) {
                        return (
                          <tr key={shift.id}>
                            <td className='col-date'>
                              {shift.date}
                              {shift.outsideOfInternshipDates && <ValidationWarning t={t} />}
                              {shift.isDesiderata && <ValidationWarning t={t} desiderataWarning />}
                            </td>
                            <td className='absent' colSpan='4'>{shift.absentLabel}</td>
                          </tr>)
                      }

                      return (
                        <tr key={shift.id}>
                          <td className='col-date'>
                            {shift.date}
                            {shift.outsideOfInternshipDates && <ValidationWarning t={t} />}
                            {shift.isDesiderata && <ValidationWarning t={t} desiderataWarning />}
                          </td>
                          <td className='col-start'>{shift.startTime}</td>
                          <td className='col-end'>{shift.endTime}</td>
                          <td className='col-pause'>{shift.pause}</td>
                          <td className='col-total'>{shift.duration}</td>
                        </tr>
                      )
                    })}
                  </tbody>)}
              </table>}
          </div>
          {showShiftsTable &&
            <table className='total-summary'>
              <thead>
                <tr>
                  <td className='col-total-sum'>{t('Total (hours)')}</td>
                  <td className='col-total-pause'>{totalPause}</td>
                  <td className='col-total'>{totalTime}</td>
                </tr>
              </thead>
            </table>}
        </div>
        {renderValidationViewBottom(showShiftsTable)}
      </div>
    )
  }

  const renderValidationViewBottom = enable => {
    if (!(enable && bottom)) {
      return (<div />)
    }

    return (
      <div className='buttons-wrapper'>
        {renderValidationsButtons()}
        <Button
          disabled={validating || (showDownloadButton && !validationDocumentDetails)}
          onClick={handleDocumentPrint}
          loading={printing}
        >
          <FontAwesomeIcon icon='file-alt' />&nbsp;
          {t(showDownloadButton ? 'Download signed validation document' : 'Print Validation document')}
        </Button>
      </div>
    )
  }

  const renderValidationsButtons = () => {
    if (maccsInternship.state === internshipStates.SCHEDULE_VALIDATED) {
      return (
        <Button
          style={validationButton.style}
          disabled={false}
          loading={validating}
          onClick={() => setIsInvalidationModalOpen(true)}
          onMouseEnter={() => setValidationButton(renderButtonProps(
            HOVERING_VALIDATION_BUTTON_TEXT,
            HOVERING_VALIDATION_BUTTON_STYLE,
            HOVERING_VALIDATION_BUTTON_ICON
          ))}
          onMouseLeave={() => setValidationButton(renderButtonProps(
            INITIAL_VALIDATION_BUTTON_TEXT,
            INITIAL_VALIDATION_BUTTON_STYLE,
            INITIAL_VALIDATION_BUTTON_ICON
          ))}
        >
          <FontAwesomeIcon icon={validationButton.icon} />&nbsp;
          {t(validationButton.text)}
        </Button>
      )
    }

    if (validationEnabledOn) {
      return (
        <div className='validated-enabled-on'>
          {`${t('Validation will be enabled on')}: ${validationEnabledOn}`}
        </div>
      )
    }

    return (
      <Popconfirm
        cancelText={t('Cancel')}
        okText={t('Yes')}
        onConfirm={handleValidation}
        placement='top'
        title={t('Do you confirm internship validation ?')}
      >
        <Button
          disabled={false}
          type='primary'
          loading={validating}
        >
          <FontAwesomeIcon icon='check' />&nbsp;
          {t('Validate')}
          {validating && <div className='loading-icon black' />}
        </Button>
      </Popconfirm>
    )
  }

  return (
    <Drawer
      title={title ?? t('Internship validation for') + ' ' + assistantName}
      width='550px'
      onClose={onClose}
      visible
      className='internship-validation-drawer'
    >
      {feedbackEnabled && !loading && user.context === INSTITUTION_CONTEXT ? (
        <Tabs tabPosition='bottom' defaultActiveKey='1' activeKey={displayedTabKey} onChange={handleTabChange}>
          <TabPane tab={t('Validation')} key='1'>{renderValidationScreen()}</TabPane>
          <TabPane tab={t('Feedback')} key='2'>
            <Suspense fallback={<div>Loading...</div>}>
              <StudentEvaluationByInstitution internship={currentInternship} />
            </Suspense>
          </TabPane>
        </Tabs>
      ) : renderValidationScreen()}

      <InvalidateInternshipModal
        visible={isInvalidationModalOpen}
        onOk={handleMaccsInternshipInvalidation}
        onCancel={handleMaccsInternshipInvalidationCancelation}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(MaccsInternshipValidation)
