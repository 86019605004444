import React, { useContext, useEffect, useState } from 'react'

import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import InternshipValidationButton from '../../ShiftsManager/InternshipValidationButton'
import InstitutionDateCell from '../DateCell/InstitutionDateCell'
import StudentQuickMenu from '../StudentQuickMenu'
import { getInstitutionInternshipData } from '../utils/componentGetters'
import { getIndexesByColumns, getValidationTooltipText } from '../utils/utils'
import InstitutionStatesSelector from '../../Selectors/StatesSelector/InstitutionStatesSelector'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { MACCS_ENVIRONMENT, ROLE_INSTITUTION_GROUP_ADMIN } from '../../../../utils/constants'

const DEFAULT_INDEXES = { startIndex: 0, lastIndex: 0 }

const InstitutionInternshipLine = ({ internshipContext, getQuotasState, onLineHover }) => {
  const { environment } = useContext(GlobalContext)
  const { dataProvider, internshipProvider, periodType } = useContext(InternshipsManagerContext)
  const { dateColumns, user, onInternshipSelected, onStudentSelected, setChat, setInternshipToValidate, t } = useContext(dataProvider.context)
  const { internship, onUpdate, handleStateChange, totalsMessageUnreadByInternships } = useContext(internshipProvider.context)

  const [isHovered, setIsHovered] = useState(false)
  const [periodIndexes, setPeriodIndexes] = useState(DEFAULT_INDEXES)
  const [displaySectorSelector, setDisplaySectorSelector] = useState(false)

  const InternshipData = getInstitutionInternshipData(user.context, user.roles, environment)

  useEffect(() => {
    if (internship.startDate && internship.endDate && periodType) {
      setPeriodIndexes(getIndexesByColumns(internship, dateColumns, periodType))
    }
  }, [internship, dateColumns, periodType])

  const handleLineHover = () => {
    setIsHovered(true)
    onLineHover(periodIndexes)
  }

  const handleLineLeave = () => {
    setIsHovered(false)
  }

  const handleUpdate = sector => {
    setDisplaySectorSelector(false)
    onUpdate({ sector: sector.id })
  }

  return (
    <tr
      className='internship-line'
      onMouseOver={handleLineHover}
      onMouseLeave={handleLineLeave}
    >
      <td>
        <div className={user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) && environment === MACCS_ENVIRONMENT ? 'internship-card-extended' : 'internship-card-old'}>
          <div className='quick-menu-container'>
            <StudentQuickMenu
              noMargin
              internship={internship}
              onSelectedInternshipInfo={onInternshipSelected}
              onSelectedInternshipChat={setChat}
              onSelectedForSectorChange={() => setDisplaySectorSelector(!displaySectorSelector)}
              onSelectedStudentInfo={onStudentSelected}
              totalsMessageUnreadByInternships={totalsMessageUnreadByInternships}
            />
          </div>
          <InternshipData
            displaySectorSelector={displaySectorSelector}
            internship={internship}
            onUpdate={handleUpdate}
          />
          <div className='status-validation-wrapper'>
            <InstitutionStatesSelector
              internship={internship}
              onChange={handleStateChange}
            />
            {user.canValidateInternship && internship.shifts && internship.shifts.length > 0 &&
              <InternshipValidationButton
                validateButtonTooltip={t(getValidationTooltipText(internship))}
                internship={internship}
                onDisplayValidationDrawer={setInternshipToValidate}
              />}
          </div>
        </div>
      </td>
      {dateColumns.map((dates, index) => {
        return (
          <InstitutionDateCell
            key={index}
            startDate={dates.startDate}
            endDate={dates.endDate}
            index={index}
            isHovered={isHovered}
            periodIndexes={periodIndexes}
          />
        )
      })}
    </tr>
  )
}

export default InstitutionInternshipLine
