import { createSelector } from 'reselect'
import moment from 'moment'
import { getSchoolAssistants } from './actions'

export const getAssistant = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => schoolAssistants.assistant
)

export const getSpecialityYear = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => {
    const speciality = schoolAssistants.assistant.schoolSpeciality

    return speciality?.specialityYear ?? null
  }
)

export const getSchool = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => {
    const speciality = schoolAssistants.assistant.schoolSpeciality

    return speciality?.school ?? null
  }
)

export const getAssistantProfileImage = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => schoolAssistants.profileImage
)

export const getExtraContactInfos = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => schoolAssistants.extraContactInfo
)

export const getExtraContactInfosMap = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => {
    const extraContactInfoMap = { emails: [], phones: [] }

    if (typeof schoolAssistants.extraContactInfos === 'object') {
      Object.keys(schoolAssistants.extraContactInfos).map(t => {
        schoolAssistants.extraContactInfos[t].map((eci, k) => {
          const type = eci.type + (eci.type.slice(-1) !== 's' ? 's' : '')

          extraContactInfoMap[type].push(eci.value)
        })
      })
    }

    return extraContactInfoMap
  }
)

export const getOptIn = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => schoolAssistants.optin
)

export const getOptInValue = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => {
    let value = null

    if (typeof schoolAssistants.optin === 'object') {
      Object.keys(schoolAssistants.optin).map(item => {
        if (value === null) {
          value = schoolAssistants.optin[item].accepted
        }
      })
    }

    return value !== null ? value : false
  }
)

export const schoolAssistantsMap = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => {
    return Object.keys(schoolAssistants.assistants).map(k => {
      const assistant = schoolAssistants.assistants[k]

      return {
        ...assistant,
        speciality: assistant.schoolSpeciality?.id ?? null,
        birthdate: typeof assistant.birthdate === 'string'
          ? moment(assistant.birthdate).format('DD/MM/YYYY')
          : null
      }
    })
  }
)

export const schoolSpecialitiesMap = createSelector(
  state => getSchoolAssistants(state),
  schoolAssistants => {
    return Object.keys(schoolAssistants.schoolSpecialities).map(k => {
      const schoolSpeciality = schoolAssistants.schoolSpecialities[k]
      const { specialityYear } = schoolSpeciality

      return {
        id: schoolSpeciality.id,
        name: `${specialityYear.specialityAbbreviation} (${specialityYear.studyYearName})`,
        nameTitle: specialityYear.specialityName,
        specialityYear
      }
    })
  }
)
