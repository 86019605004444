import React, { createContext, useEffect, useState } from 'react'
import { getLanguages, getTranslate } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { notification } from 'antd'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { getUser } from '../../reducers/UserReducer'
import { fetchAssistants, fetchSchoolSpecialities, removeAssistant } from '../../reducers/SchoolAssistantsReducer/actions'
import { schoolSpecialitiesMap } from '../../reducers/SchoolAssistantsReducer/selectors'
import { validateFormInput } from '../../utils'
import { createAssistant, updateAssistant } from '../../utils/api/assistant'
import { DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_SELECT, DATA_TYPE_SELECTOR, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import { COUNTRIES, GENDER_OPTIONS } from '../../utils/constants'
import { getAllSpecialityYears } from '../../utils/api/specialityYear'
import { SCHOOL_ASSISTANTS } from '../../reducers/SchoolAssistantsReducer/actionsType'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    getLanguages: getLanguages(state.locale),
    schoolSpecialities: schoolSpecialitiesMap(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeAssistant: bindActionCreators(removeAssistant, dispatch),
    fetchAssistants: bindActionCreators(fetchAssistants, dispatch),
    fetchSchoolSpecialities: bindActionCreators(fetchSchoolSpecialities, dispatch)
  }
}

const SmartTableContext = createContext()
let SmartTableProvider = ({
  children, user, getLanguages, schoolSpecialities, fetchAssistants, fetchSchoolSpecialities,
  onPasswordReset, onSelectAssistant, removeAssistant, t
}) => {
  const [loading, setLoading] = useState(true)
  const [languages, setLanguages] = useState([])
  const [specialityYearsBySpeciality, setSpecialityYearsBySpeciality] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      getAllSpecialityYears(user).then(data => {
        const newSpecialityYears = {}

        data.forEach(s => {
          if (newSpecialityYears[s.speciality.id] === undefined) {
            newSpecialityYears[s.speciality.id] = []
          }

          newSpecialityYears[s.speciality.id].push(s)
        })

        setSpecialityYearsBySpeciality(newSpecialityYears)
      })
    }
  }, [user])

  useEffect(() => {
    if (getLanguages) {
      setLanguages(getLanguages.map(({ code, name }) => ({
        id: code,
        name: t(name)
      })))
    }
  }, [getLanguages, t])

  useEffect(() => {
    if (user && typeof fetchSchoolSpecialities === 'function') {
      fetchSchoolSpecialities(user)
    }
  }, [user, fetchSchoolSpecialities])

  const onDataAdd = async (assistant, stopLoadingCallback) => {
    assistant = cleanUpAssistantData(assistant)
    assistant.stillInSchool = true
    assistant.birthdate = formatAssistantBirthdateInBackendForm(assistant)

    createAssistant(assistant, user)
      .then(data => {
        stopLoadingCallback()

        if (typeof data !== 'undefined') {
          fetchAssistants(user, setLoading)
          notification.success({
            message: t('The assistant was successfully created'),
            placement: 'bottomRight'
          })
        }
      })
  }

  const onDataEdit = async (assistant, stopLoadingCallback) => {
    assistant = cleanUpAssistantData(assistant)
    assistant.birthdate = formatAssistantBirthdateInBackendForm(assistant)

    if (assistant.financialYear && assistant.financialYear.id) {
      assistant.financialYear = assistant.financialYear.id
    }

    if (assistant.financialYear === -1) {
      assistant.financialYear = null
    }

    if (assistant.schoolSpeciality && assistant.schoolSpeciality.id) {
      assistant.schoolSpeciality = assistant.schoolSpeciality.id
    }

    updateAssistant(assistant, assistant, user)
      .then(data => {
        stopLoadingCallback()

        if (data) {
          if (!data.updateResultNotification) {
            notification.success({ message: t('Recorded'), placement: 'bottomRight' })
            dispatch({
              type: SCHOOL_ASSISTANTS.UPDATE_ASSISTANT,
              payload: data
            })

            return
          }

          data.updateResultNotification === 'assistant.edit.not.allowed'
            ? notification.error({
              message: t(data.updateResultNotification),
              placement: 'bottomRight'
            })
            : notification.success({
              message: t(data.updateResultNotification),
              placement: 'bottomRight'
            })
        }
      })
  }

  const onDataDelete = assistant => {
    removeAssistant({ assistantId: assistant.id, user: user })

    notification.success({ message: t('Recorded'), placement: 'bottomRight' })
  }

  const formatAssistantBirthdateInBackendForm = assistant => {
    if (assistant.birthdate === null || assistant.birthdate.trim().length === 0) {
      return null
    }

    const birthdateParts = assistant.birthdate.split('/')

    return `${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`
  }

  const cleanUpAssistantData = assistant => {
    if (typeof assistant.user !== 'undefined') {
      delete assistant.user
    }

    return assistant
  }

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_SELECT,
      name: t('Speciality'),
      key: 'speciality',
      options: schoolSpecialities,
      width: 300,
      postChange: d => {
        d.financialYear = null
      }
    },
    {
      type: DATA_TYPE_SELECTOR,
      name: t('Financial year'),
      key: 'financialYear',
      dataKey: d => d.financialYear ? d.financialYear.id : -1,
      optionKey: o => `${o.specialityAbbreviation} (${o.studyYearName})`,
      options: d => {
        // Needed because SmartTable Component alter the structure of data pass into it.
        if (d.speciality) {
          const schoolSpeciality = schoolSpecialities.find(ss => ss.id === d.speciality)

          return schoolSpeciality ? specialityYearsBySpeciality[schoolSpeciality.specialityYear.speciality.id] : -1
        }

        if (d.schoolSpeciality && d.schoolSpeciality.specialityYear) {
          return specialityYearsBySpeciality[d.schoolSpeciality.specialityYear.speciality.id]
        }

        return []
      },
      width: 125
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Lastname'),
      key: 'lastname',
      validate: data => validateFormInput('name', data, true)
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Firstname'),
      key: 'firstname',
      validate: data => validateFormInput('name', data, true)
    },
    {
      type: DATA_TYPE_SELECT,
      name: t('Language'),
      key: 'language',
      options: languages,
      preventAutoSelectDefaultValue: true
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Email'),
      key: 'email',
      validate: data => validateFormInput('email', data, true)
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Registration number'),
      key: 'matricule',
      isNullable: true,
      validate: data => validateFormInput('freeText', data, false)
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Phone'),
      key: 'phoneNumber',
      validate: data => validateFormInput('freeText', data, false)
    },
    {
      type: DATA_TYPE_DATE,
      name: t('Birthdate'),
      key: 'birthdate',
      format: 'DD/MM/YYYY',
      isNullable: true,
      validate: data => validateFormInput('freeText', data, false)
    },
    {
      type: DATA_TYPE_SELECT,
      name: t('Gender'),
      key: 'gender',
      options: GENDER_OPTIONS(t)
    },
    {
      type: DATA_TYPE_SELECT,
      name: t('Nationality'),
      key: 'nationality',
      options: COUNTRIES(t)
    },
    {
      type: DATA_TYPE_STRING,
      name: t('NISS'),
      key: 'niss',
      validate: data => validateFormInput('freeText', data, false),
      password: true
    }
  ]

  const additionalActions = [
    {
      iconName: 'user-graduate',
      type: 'primary',
      title: t('Assistant sheet'),
      onClick: onSelectAssistant
    },
    {
      iconName: faKey,
      type: 'primary',
      title: t('Reset password'),
      onClick: onPasswordReset
    }
  ]

  return (
    <SmartTableContext.Provider value={{
      loading,
      setLoading,
      columns,
      additionalActions,
      onDataAdd,
      onDataEdit,
      onDataDelete
    }}
    >
      {children}
    </SmartTableContext.Provider>
  )
}

SmartTableProvider = connect(mapStateToProps, mapDispatchToProps)(SmartTableProvider)

export {
  SmartTableProvider as default,
  SmartTableContext
}
