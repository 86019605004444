import { requestWithPromise } from '..'
import { DELETE, GET, makeQueryString, makeQueryStringFromObject, PATCH, POST } from '../apiHelper'
import { LANGUAGE_TAG_EN } from '../constants'

export const CONTEXTS = {
  shifts: 'shifts',
  documents: 'documents',
  validationDocument: 'validation-document'
}

export const acceptMaccsInternship = (internship, user) => {
  return requestWithPromise('/maccs-internship/accept',
    POST,
    { maccsInternship: internship.id },
    user
  )
}

export const cancelMaccsInternship = (internship, user) => {
  return requestWithPromise('/maccs-internship/cancel',
    PATCH,
    { maccsInternship: internship.id },
    user
  )
}

export const createMaccsInternship = (body, user) => {
  return requestWithPromise(
    '/api/maccs-internships',
    POST,
    body,
    user
  )
}

export const deleteMaccsInternship = (internship, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${internship.id}`,
    DELETE,
    null,
    user
  )
}

export const duplicateMaccsInternship = (internship, user) => {
  return requestWithPromise(
    '/maccs-internship/duplicate',
    POST,
    { maccsInternship: internship.id },
    user
  )
}

export const getAllMaccsInternships = (queryParameters, user) => {
  return requestWithPromise(
    `/api/maccs-internships${makeQueryString(queryParameters)}`,
    GET,
    null,
    user
  )
}

export const refuseMaccsInternship = (internship, user) => {
  return requestWithPromise('/maccs-internship/refuse',
    PATCH,
    { maccsInternship: internship.id },
    user
  )
}

export const resetToPending = (internship, user) => {
  return requestWithPromise('/maccs-internship/reset-to-pending',
    PATCH,
    { maccsInternship: internship.id },
    user
  )
}

export const updateMaccsInternship = (internship, body, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${internship.id}`,
    PATCH,
    body,
    user
  )
}

export const getMaccsInternshipShifts = (maccsInternship, user, parameters = {}) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/shifts${makeQueryStringFromObject(parameters)}`,
    GET,
    null,
    user)
    .then(json => json?.data ?? [])
}

export const getMaccsInternshipDocuments = (maccsInternship, user, parameters = {}) => {
  const queryString = makeQueryStringFromObject(parameters)

  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/documents${queryString}`,
    GET,
    null,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const validateScheduleMaccsInternship = (body, user) => {
  return requestWithPromise(
    '/maccs-internship/validate-schedule',
    PATCH,
    body,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const invalidateScheduleMaccsInternship = (body, user) => {
  return requestWithPromise(
    '/maccs-internship/invalidate-schedule',
    PATCH,
    body,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const sendMaccsInternshipMessage = ({ maccsInternship, content }, user) => {
  return requestWithPromise(
    '/maccs-internship-message/send',
    POST,
    { maccsInternship, content },
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const exportValidatedMaccsInternship = (body, user, parameters = {}) => {
  return requestWithPromise(
    `/maccs-internship/export-validated-maccs-internship${makeQueryStringFromObject(parameters)}`,
    POST,
    body,
    user)
    .then(({ job, status }) => {
      if (status === 'success') {
        return job
      }
    })
}

export const getContactPersons = (maccsInternship, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/contact-persons`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const createMaccsInternshipShift = (maccsInternship, shift, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/shifts`,
    POST,
    shift.toRequestBody(),
    user
  )
}

export const updateMaccsInternshipShift = (maccsInternship, shift, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/shifts/${shift.id}`,
    PATCH,
    shift.toRequestBody(),
    user
  )
}

export const getValidationDocument = (maccsInternship, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/assistant-validation-document`,
    GET,
    null,
    user
  ).then(json => json?.data ?? [])
}

export const deleteValidationDocument = (maccsInternship, user) => {
  return requestWithPromise(
    `/api/maccs-internships/${maccsInternship.id}/assistant-validation-document`,
    DELETE,
    null,
    user
  ).then(json => json?.data)
}

export const exportTimeline = (user, { start, end, filters = {}, language = LANGUAGE_TAG_EN }) => {
  return requestWithPromise(
    '/maccs-internship/export-timeline',
    POST,
    { start, end, filters, language },
    user
  )
}
