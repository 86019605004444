import React from 'react'

import StringStatePellet from './StringStatePellet'

const INNER_DIV_STYLE = { width: '100vw' }

const StringStateOptionBody = props => {
  const { state } = props

  return (
    <div className='flex-row'>
      <div style={INNER_DIV_STYLE}>
        {props.text}
      </div>
      <div className='flex-column'>
        <div className='flex-fill' />
        <StringStatePellet state={state} />
        <div className='flex-fill' />
      </div>
    </div>
  )
}

export default StringStateOptionBody
