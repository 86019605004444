
import InstitutionAssistants from '../../../Components/MACCS/Assistants/InstitutionAssistants'
import SchoolAssistants from '../../../Components/MACCS/Assistants/SchoolAssistants'
import InstitutionAssistantProvider, { InstitutionAssistantContext } from '../../../Context/Assistants/InstitutionAssistantProvider'
import SmartTableProvider, { SmartTableContext } from '../../../Context/Assistants/SmartTableProvider'
import { INSTITUTION_MACCS_CONTEXT, SCHOOL_MACCS_CONTEXT } from '../../../utils/constants'

export const getAssistantsView = context => {
  if (SCHOOL_MACCS_CONTEXT === context) {
    return SchoolAssistants
  }

  if (INSTITUTION_MACCS_CONTEXT === context) {
    return InstitutionAssistants
  }

  return null
}

export const getAssistantsProvider = context => {
  if (SCHOOL_MACCS_CONTEXT === context) {
    return {
      Provider: SmartTableProvider,
      context: SmartTableContext
    }
  }

  if (INSTITUTION_MACCS_CONTEXT === context) {
    return {
      Provider: InstitutionAssistantProvider,
      context: InstitutionAssistantContext
    }
  }

  return {
    Provider: null,
    context: null
  }
}
