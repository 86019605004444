import React, { Component, Suspense } from 'react'

import { compose } from 'redux'
import { Switch, Route, withRouter } from 'react-router-dom'
// import the widgets
import ShiftsWidget from './Widgets/ShiftsWidget'
import ApiErrorCatcher from './Components/shared/ApiErrorCatcher'
import AppCrash from './Components/shared/AppCrash'
import AppBanner from './Components/shared/AppBanner'
import AppLoading from './Components/shared/AppLoading'
import AppManager from './Views/AppManager'
import BasicFormWrapper from './Components/shared/BasicFormWrapper'
import AnonymousFormWrapper from './Components/shared/AnonymousFormWrapper'
import Cookie from 'js-cookie'
import LoginView from './Views/shared/LoginView'
import PasswordForceUpdateForm from './Components/anonymous/PasswordForceUpdateForm'
import PasswordResetAskForm from './Components/anonymous/PasswordResetAskForm'
import PasswordResetForm from './Components/anonymous/PasswordResetForm'
import SignUpForm from './Components/anonymous/SignUpForm'
import StudentSignUpForm from './Components/anonymous/StudentSignUpForm'
import ConfirmAdditionalEmail from './Components/anonymous/ConfirmAdditionalEmail'
import Theme from './Components/shared/Theme'
import UserSwitch from './Components/anonymous/UserSwitch'
import MessageBox from './Components/shared/MessageBox'
import ChatManager from './Components/shared/ChatManager'
import blue from './themes/blue.json'
import { notification } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from './reducers/Dispatchers'
import {
  request,
  getUrlParameterByName,
  getCurrentUrl,
  objectDeepCopy,
  getContextFromRoles,
  filterSectorsAndSetRootSectorOfInstitution,
  filterSectionsAndSetRootSectionOfSchool
} from './utils'
import {
  ROLE_INSTITUTION_GROUP_ADMIN, ROLE_VALIDATOR, ADMIN_CONTEXT, ASSISTANT_CONTEXT, INSTITUTION_CONTEXT, MACCS_CONTEXT, SCHOOL_CONTEXT,
  STUDENT_CONTEXT, NONE_CONTEXT, ROLE_ADMIN, ROLE_NURSE
} from './utils/constants'
import { URIEncode } from './utils/apiHelper'
import appConfig from './config'
import { setLanguage } from './utils/locale'
import Analytics from './utils/analytics'
import routes from './routes'
import ChangeLogNotification from './Views/shared/ChangeLogNotification'
import GlobalProvider from './Providers/GlobalProvider'

import { fetchSchoolOptions } from './utils/api/school'
import GlobalFiltersProvider from './Providers/GlobalFiltersProvider'
import SchoolProvider from './Providers/SchoolProvider'

import '../src/utils/FontAwesomeLibrary'
import './assets/index.scss'
import 'antd/dist/antd.css'
import ContextAwareInterface from './Views/ContextAwareInterface'

// use React.lazy to load the student validation component and to have code splitting
const SectionEvaluationByStudent = React.lazy(() => import('./Components/anonymous/SectionEvaluationByStudent'))

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      appCrashedInfo: null,
      showPasswordResetForm: false,
      showOptinsForm: false,
      /**
       * optinsForUser -> if this is true we show only one agreement checkbox, the "recruitment" option is used only for students
       */
      optinsForUser: false,
      ready: false,
      languageSet: false,
      isWidgetRoute: window.location.href.includes('/widget/'),
      schoolOptions: []
    }

    // initialize analytics
    Analytics.initialize()

    this.previouslySelectedLanguage = null
  }

  get passwordChangeDisplayed () {
    const { showPasswordResetForm, showOptinsForm } = this.state
    return showPasswordResetForm || showOptinsForm
  }

  componentDidMount () {
    if (window.location.href.includes('/widget/')) {
      // don't do any of the automatic loading if we are running in "widget" mode
      return
    }
    this.props.setTheme(blue)
    this.checkUserTimerIndex = 0
    this.checkUserTimer = setInterval(async () => {
      if (this.props.getUser.id || this.checkUserTimerIndex === 10) {
        await this.executeSetLanguage(this.props.getActiveLanguage)
        this.setState({ languageSet: true })
        clearInterval(this.checkUserTimer)
      } else {
        this.checkUserTimerIndex += 1
      }
    }, 100)
    this.refreshAppBannerWarning()
    this.timerRefreshAppBannerWarning = setInterval(
      () => this.refreshAppBannerWarning(),
      60000
    )

    const newUserToken = getUrlParameterByName('user-token')
    if (
      typeof newUserToken !== 'undefined' &&
      newUserToken !== null &&
      newUserToken !== ''
    ) {
      Cookie.remove('token')
      this.setState({
        ready: true
      })
      // pass the search query too
      this.props.history.push({ pathname: routes.USER_SIGNUP, search: window.location.search })
      return
    }

    const studentToken = getUrlParameterByName('student-token')
    if (
      typeof studentToken !== 'undefined' &&
      studentToken !== null &&
      studentToken !== ''
    ) {
      Cookie.remove('token')
      this.setState({
        ready: true
      })
      // pass the search query too
      this.props.history.push({ pathname: routes.STUDENT_SIGNUP, search: window.location.search })
      return
    }

    // check if we have evaluation token. This means a student want's to give the evaluation for the institution
    const evaluationToken = getUrlParameterByName('section-evaluation-token')
    if (
      typeof evaluationToken !== 'undefined' &&
      evaluationToken !== null &&
      evaluationToken !== ''
    ) {
      Cookie.remove('token')
      this.setState({
        ready: true
      })
      // pass the search query too
      this.props.history.push({ pathname: routes.SECTION_EVALUATION_BY_STUDENT, search: window.location.search })
      return
    }

    const passwordResetToken = getUrlParameterByName('password-reset-token')
    if (
      typeof passwordResetToken !== 'undefined' &&
      passwordResetToken !== null &&
      passwordResetToken !== ''
    ) {
      Cookie.remove('token')
      this.setState({
        ready: true
      })
      this.props.history.push({ pathname: routes.PASSWORD_RESET, search: window.location.search })
      return
    }

    const emailValidationToken = getUrlParameterByName('email-validation-token')
    if (
      typeof emailValidationToken !== 'undefined' &&
      emailValidationToken !== null &&
      emailValidationToken !== ''
    ) {
      Cookie.remove('token')
      this.setState({
        ready: true
      })
      this.props.history.push({ pathname: routes.CONFIRM_ADDITIONAL_EMAIL, search: window.location.search })
      return
    }

    if (
      typeof Cookie.get('token') !== 'undefined' &&
      Cookie.get('token') !== null &&
      Cookie.get('token') !== ''
    ) {
      const switchUserId = Cookie.get('switch_user_id')
      if (
        typeof switchUserId !== 'undefined' &&
        switchUserId !== null &&
        switchUserId !== ''
      ) {
        this.getUserContextAndData(
          JSON.parse(Cookie.get('token')),
          undefined,
          true,
          true
        ).then(() => {
          this.autoLoadUser()
        })
      } else {
        this.getUserContextAndData(JSON.parse(Cookie.get('token')))
      }
      return
    } else {
      if (
        ![routes.PASSWORD_RESET, routes.PASSWORD_CHANGE].includes(
          // TODO: When enabling signup add allowed routes here
          this.props.location.pathname
        )
      ) {
        // redirect to login
        this.navigateToLoginPage()
      }
    }

    this.setState({ ready: true })
  }

  componentWillUnmount () {
    clearInterval(this.timerRefreshAppBannerWarning)
    clearInterval(this.checkUserTimerIndex)
  }

  navigateToLoginPage = () => {
    if (!this.state.isWidgetRoute) {
      this.props.history.push(routes.LOGIN)
    }
  }

  componentDidCatch (error, info) {
    this.setState({
      appCrashedInfo: { error: error, info: info }
    })
  }

  executeSetLanguage = language => {
    return new Promise((resolve) => {
      const { addTranslationForLanguage, setActiveLanguage, getActiveLanguage, getUser } = this.props
      setLanguage({
        language,
        addTranslationForLanguage,
        setActiveLanguage,
        getActiveLanguage,
        getUser: () => { return getUser },
        getPreviousLanguage: previousLanguage => {
          this.previouslySelectedLanguage = getActiveLanguage
        }
      }).then(() => {
        resolve()
      })
    })
  }

  /**
   * This is the same behaviour as we have in UserSwitch component. If we have a previous user id in cookie
   * We get the user data and then we save the user into global state. The switch user view won't be shown
   */
  autoLoadUser = () => {
    request(
      '/api/User/' + Cookie.get('switch_user_id'),
      'GET',
      null,
      this.props.getUser
    ).then(async json => {
      await this.getUserContextAndData(
        this.props.getUser.token,
        json.data.username,
        true
      )

      // set is ready to true
      this.setState({ ready: true })

      // auto navigate to internships
      this.navigateUserToInitialPageAfterLogin()
    })
  };

  /** This is called after login or after switching the user */
  navigateUserToInitialPageAfterLogin = () => {
    const {
      getUser: { roles }
    } = this.props
    /**
     * If the user is a nurse auto display the shifts manager after the internships are loaded
     */
    if (roles.length === 1) {
      if ([ROLE_NURSE, ROLE_VALIDATOR].includes(roles[0])) {
        this.props.history.push(routes.SHIFTS_MANAGER)
      } else if (roles[0] === ROLE_ADMIN) {
        this.props.history.push(routes.CHANGELOG_MANAGEMENT)
      } else {
        this.props.history.push(routes.INTERNSHIPS)
      }
    } else {
      this.props.history.push(routes.INTERNSHIPS)
    }
  };

  /**
   * This is executed as a callback inside LoginView, after the user is signed in
   */
  handleAfterLogin = () => {
    const {
      getUser: { roles }
    } = this.props
    if (getContextFromRoles(roles) !== 'ADMIN') {
      this.navigateUserToInitialPageAfterLogin()
    }
  }

  refreshAppBannerWarning = async () => {
    // We use no-store option on fetch to be sure the app loads the last app-banner.json from the server and not from local disk cache.
    // Before using this options the file would not be updated with the last version and a big part of the users
    // would not get the new banner/maintenance mode.
    const response = await window.fetch(
      getCurrentUrl() + '/config/app-banner.json',
      { cache: 'no-store' }
    )

    let appBanner = null
    try {
      appBanner = await response.json()
    } catch (e) {
      console.error('Error parsing App banner json.')
      return
    }

    if (JSON.stringify(this.props.getAppBanner) === JSON.stringify(appBanner)) { return }

    if (typeof appBanner.message === 'undefined') {
      console.error('App banner "message" not found. Check config file.')
      return
    }
    if (typeof appBanner.type === 'undefined') {
      console.error('App banner "type" not found. Check config file.')
      return
    }
    if (typeof appBanner.maintenance === 'undefined') {
      console.error('App banner "maintenance" not found. Check config file.')
      return
    }
    if (
      appBanner.type !== 'info' &&
      appBanner.type !== 'warning' &&
      appBanner.type !== 'error'
    ) {
      console.error(
        'Unkown app banner type. Only "info", "warning", "error" are permitted.'
      )
      return
    }

    this.props.setAppBanner(appBanner)
  };

  logout = async () => {
    // clear the changeloglist
    this.props.resetChangelog()
    // clear the messages
    this.props.resetMessagesData()
    // clear evaluation questions
    this.props.clearEvaluationQuestions()
    // reset the tags
    this.props.resetTags()
    const allDataReady =
      Object.keys(this.props.getDataReady).filter(
        d => !this.props.getDataReady[d]
      ).length === 0
    await Cookie.remove('switch_user_id')

    if (typeof this.props.getUser.switchUser !== 'undefined') {
      // Preventing any further data storing in the app if the user disconnected since last requests.
      // This should be replaced by a request manager, or something else.
      if (!allDataReady) window.location.reload()

      await this.setState({ ready: false })
      this.getUserContextAndData(this.props.getUser.token)
      return
    }

    await Cookie.remove('token')

    // Preventing any further data storing in the app if the user disconnected since last requests.
    // This should be replaced by a request manager, or something else.
    if (!allDataReady) window.location.reload()

    await this.setState({
      ready: true
    })

    this.navigateToLoginPage()

    this.props.setUser(null)
  };

  handleSignUp = userData => {
    const body = {
      username: userData.username,
      password: userData.password,
      password_confirm: userData.password,
      lastname: userData.lastname,
      firstname: userData.firstname,
      email: userData.email
    }

    request('/signup', 'POST', body)
      .then(json => {
        this.navigateToLoginPage()
      })
  };

  getUserContextAndData = (
    token,
    switchedUsername,
    preventSettingReadyToTrue = false,
    preventLoadingSuperAdmin = false
  ) => {
    return new Promise((resolve, reject) => {
      request('/user/me', 'GET', null, {
        token: token,
        switchUser:
          typeof switchedUsername === 'undefined' ? null : URIEncode(switchedUsername)
      })
        .then(async json => {
          if (typeof json.code !== 'undefined' && json.code === 401) {
            // Session yummy cookie has expired :(
            this.logout()

            return
          }

          if (json === 'password_change') {
            this.props.setUser({ token: token })
            this.setState({ ready: true, showPasswordResetForm: true })

            return
          }

          if (json === 'password_change_set_optins' || json === 'password_change_set_optins_user') {
            this.props.setUser({ token: token })
            this.setState({
              ready: true,
              showPasswordResetForm: true,
              showOptinsForm: true,
              optinsForUser: json === 'password_change_set_optins_user'
            })

            return
          }

          if (json === 'set_optins' || json === 'set_optins_user') {
            this.props.setUser({ token: token })
            this.setState({
              ready: true,
              showPasswordResetForm: false,
              showOptinsForm: true,
              optinsForUser: json === 'set_optins_user'
            })

            return
          }

          const user = json.data

          // check what's the user's language and use it
          if (user.language !== null !== undefined && user.language !== null && user.language !== this.props.getActiveLanguage) {
            this.executeSetLanguage(user.language)
          }

          const context = getContextFromRoles(user.roles)

          user.context = context
          user.token = token

          if (typeof switchedUsername !== 'undefined') {
            user.switchUser = switchedUsername
          }

          await this.props.setUser(user)
          Analytics.setUserDetails(user, this.props.getActiveLanguage)

          if (this.passwordChangeDisplayed) {
            this.setState({ showPasswordResetForm: false, showOptinsForm: false })
          }

          if (!preventSettingReadyToTrue) {
            this.setState({
              ready: true
            })
          }

          this.loadUserData(user, context, preventSettingReadyToTrue, preventLoadingSuperAdmin)

          if (context === ASSISTANT_CONTEXT) {
            this.navigateToLoginPage()
          }

          resolve()
        })
        .catch(err => {
          Cookie.remove('token')
          reject(err)
        })
    })
  };

  loadUserData = (user, context, preventSettingReadyToTrue, preventLoadingSuperAdmin) => {
    if (context === ADMIN_CONTEXT) {
      if (!preventSettingReadyToTrue) {
        this.props.history.push(routes.SWITCH_USER)
      } else if (!preventLoadingSuperAdmin) {
        this.loadSuperAdminData()
      }

      return
    }

    if (MACCS_CONTEXT.includes(context) || context === ASSISTANT_CONTEXT) {
      this.loadMaccsAppData(user)

      return
    }

    if (this.props.isLocalAdmin) {
      request('/internship/students-id', 'POST', user, user)
        .then(async json => {
          await this.props.setUserStudentsId(json.data.map(student => { return student.id }))
        })
        .catch(error => {
          generalErrorHandler(error)
        })
    }

    // make sure we clear existing presets
    this.props.clearInstitutionsPresets()

    switch (user.context) {
      case STUDENT_CONTEXT:
        this.loadStudentData()
        break
      case SCHOOL_CONTEXT:
        this.loadSchoolData()
        break
      case INSTITUTION_CONTEXT:
        this.loadHospitalData()
        break
      case ADMIN_CONTEXT:
        if (!preventLoadingSuperAdmin) {
          this.loadSuperAdminData()
        }
        break
    }
  }

  loadMaccsAppData = async (user) => {
    this.props.fetchWorkingRegimes(user)
  }

  loadSchoolData = async () => {
    this.props.setDataReady({
      schools: false,
      institutions: true,
      students: false,
      internships: true // Internships fetching is not done here for schools, as it is loaded later in the timeline when all other data are ready.
    })

    await this.updateReducer(
      `/schools/0/${appConfig.apiPageSize}`,
      'schools',
      this.props.setSchools,
      schools => {
        if (schools.length) {
          let school = schools[0]
          school = filterSectionsAndSetRootSectionOfSchool(
            school,
            this.props.t('Without section')
          )
          let sections = school.sections

          // User coordinated sections from /user are missing data, we replace them by the ones from /api
          if (this.props.getUser.roles.indexOf('ROLE_COORDINATOR') > -1) {
            const mySections = this.props.getUser.coordinatedSections
            sections = sections.filter(s => {
              for (let i = 0; i < mySections.length; i++) {
                if (s.id === mySections[i].id) {
                  return true
                }
              }
              return false
            })
          }

          school.sections = sections
          return [school]
        }
        return []
      }
    )

    const user = this.props.getUser

    if (this.props.getSchools.length > 0) {
      const schoolId = this.props.getSchools[0].id

      this.props.fetchSchoolSectionsBySchool(schoolId, user)
      this.props.fetchInstitutionsWithActivatedPauseQuotaOption(schoolId, user)
      fetchSchoolOptions({ id: schoolId }, user).then(json => {
        if (json && json.data) {
          this.setState({ schoolOptions: json.data })
        }
      })
    }

    this.props.fetchCanSchoolEditSchedulesByInstitution(user)
    this.props.fetchInstitutionConstraints(user)
    this.props.fetchTags()

    this.updateReducer(
      `/students/0/${appConfig.apiPageSize}`,
      'students',
      this.props.setStudents,
      students => {
        return students
      }
    )
  };

  loadHospitalData = async () => {
    const currentUser = this.props.getUser

    this.props.setDataReady({
      schools: currentUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN),
      institutions: currentUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN),
      students: true,
      internships: true // Internship fetching is done here for hospitals, as it is loaded later in the timeline when all other data are ready.
    })

    if (!currentUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
      this.props.fetchAllInstitutionOptionTypes(currentUser)

      await this.updateReducer(
        `/schools/0/${appConfig.apiPageSize}`,
        'schools',
        this.props.setSchools
      )

      await this.updateReducer(
        `/institutions/0/${appConfig.apiPageSize}`,
        'institutions',
        this.props.setInstitutions,
        institutions => {
          let institution = institutions[0]
          institution = filterSectorsAndSetRootSectorOfInstitution(
            institution,
            this.props.t('Without care unit')
          )

          if (this.props.getUser.roles.indexOf('ROLE_HOSPITAL_ADMIN') > -1) {
            return [institution]
          }

          let sectors = institution.sectors
          const mySectors = this.props.getUser.managedSectors
          sectors = sectors.filter(s => {
            for (let i = 0; i < mySectors.length; i++) {
              if (s.id === mySectors[i].id) {
                return true
              }
            }
            return false
          })

          institution.sectors = sectors
          return [institution]
        }
      )

      const currentInstitutionId = this.props.getInstitutions[0].id

      this.props.fetchTags()

      if (!currentUser.roles.includes(ROLE_VALIDATOR)) {
        this.props.fetchBadges(currentInstitutionId, currentUser)
        this.props.fetchInstitutionQuotas(currentInstitutionId, currentUser)
        this.props.setSelectedInstitution(this.props.getInstitutions[0])
      }

      if (currentUser.roles[0] !== ROLE_VALIDATOR) {
        this.props.fetchInstitutionOptions(currentInstitutionId, currentUser)
      }

      if (currentUser.institutions[0]) {
        this.props.fetchSectorsByInstitution(currentUser.institutions[0].id, currentUser)
      }
    }
  };

  loadSuperAdminData = () => {
    this.props.setDataReady({
      schools: false,
      institutions: false,
      students: true,
      internships: true // Internship fetching is done here for hospitals, as it is loaded later in the timeline when all other data are ready.
    })

    this.updateReducer(
      `/schools/0/${appConfig.apiPageSize}`,
      'schools',
      this.props.setSchools
    )
    this.updateReducer(
      `/institutions/0/${appConfig.apiPageSize}`,
      'institutions',
      this.props.setInstitutions
    )
  }

  loadStudentData = () => {
    this.props.setDataReady({
      schools: true, // School fetching is not done for students, as current school is never displayed for a student.
      institutions: true, // Institution fetching not done for student, as it is loaded later in the internship display when all other data are ready.
      students: true // Students fetching is used for student, as the information is already loaded with /user/me at connection.
    })
  };

  updateReducer = async (
    requestUrl,
    getter,
    setter,
    callbackBeforeStoringData,
    callbackAfterStoringData
  ) => {
    let json = await request(requestUrl, 'GET', null, this.props.getUser)
    let data = json.data

    while (json.nextPage) {
      json = await request(json.nextPage, 'GET', null, this.props.getUser)
      data = data.concat(json.data)
    }

    if (typeof callbackBeforeStoringData !== 'undefined') {
      data = await callbackBeforeStoringData(data)
    }

    // Preventing any further data storing in the app if the user disconnected since last requests.
    // This should be replaced by a request manager, or something else.
    if (this.props.getUser !== null) {
      setter(data)

      const dataReady = objectDeepCopy(this.props.getDataReady)
      dataReady[getter] = true
      this.props.setDataReady(dataReady)
      if (typeof callbackAfterStoringData === 'function') {
        callbackAfterStoringData()
      }
    }
  };

  handleWrongLink = () => {
    this.setState({
      ready: true
    })

    notification.error({
      message: this.props.t('This link does not exist or has expired.'),
      placement: 'bottomRight'
    })
  };

  render () {
    if ((!this.state.ready || !this.state.languageSet) && !this.state.isWidgetRoute) {
      return <AppLoading />
    }

    const inMaintenanceMode =
      this.props.getAppBanner !== null &&
      typeof this.props.getAppBanner.maintenance &&
      this.props.getAppBanner.maintenance

    let context = null
    if (this.props.getUser.roles) {
      context = getContextFromRoles(this.props.getUser.roles)
    }

    return (
      <Theme variables={this.props.getTheme}>
        <GlobalProvider>
          <GlobalFiltersProvider>
            <AppCrash log={this.state.appCrashedInfo} onBack={() => this.setState({ appCrashedInfo: null })} />
            <ApiErrorCatcher />
            {inMaintenanceMode && (
              <div style={{ height: '100vh', padding: '10vw' }}>
                <AppBanner />
              </div>
            )}
            {!inMaintenanceMode && (this.state.showPasswordResetForm || this.state.showOptinsForm) &&
              <BasicFormWrapper>
                <PasswordForceUpdateForm
                  onPasswordChanged={(token) => {
                    Cookie.set('token', JSON.stringify(token), { expires: 7 })
                    this.getUserContextAndData(token).then(() => {
                      this.navigateUserToInitialPageAfterLogin()
                    })
                  }}
                  showOptinsSelection={this.state.showOptinsForm}
                  showPasswordChangeForm={this.state.showPasswordResetForm}
                  userIsNoStudent={this.state.optinsForUser}
                />
              </BasicFormWrapper>}
            {!inMaintenanceMode && this.state.isWidgetRoute && (
              <Switch>
                <Route path={routes.SHIFTS_WIDGET}>
                  <ShiftsWidget />
                </Route>
              </Switch>)}
            {!inMaintenanceMode && !this.state.isWidgetRoute && (
              <Switch>
                <Route path={routes.LOGIN}>
                  {!this.passwordChangeDisplayed &&
                    <LoginView
                    onGetUserContextAndData={this.getUserContextAndData} //eslint-disable-line
                      onAfterLogin={this.handleAfterLogin}
                    />}
                </Route>
                <Route path={routes.PASSWORD_RESET_REQUEST}>
                  {!this.passwordChangeDisplayed &&
                    <AnonymousFormWrapper>
                      <PasswordResetAskForm
                        onBack={() => {
                          this.navigateToLoginPage()
                        }}
                      />
                    </AnonymousFormWrapper>}
                </Route>
                <Route path={routes.PASSWORD_RESET}>
                  {!this.passwordChangeDisplayed &&
                    <BasicFormWrapper>
                      <PasswordResetForm
                        onBack={() => {
                          this.navigateToLoginPage()
                        }}
                      />
                    </BasicFormWrapper>}
                </Route>
                <Route path={routes.CONFIRM_ADDITIONAL_EMAIL}>
                  {!this.passwordChangeDisplayed &&
                    <BasicFormWrapper>
                      <ConfirmAdditionalEmail
                        onBack={() => {
                          this.navigateToLoginPage()
                        }}
                      />
                    </BasicFormWrapper>}
                </Route>
                <Route path={routes.SWITCH_USER}>
                  {!this.passwordChangeDisplayed &&
                    <BasicFormWrapper>
                      <UserSwitch
                    onLogout={this.logout} //eslint-disable-line
                        switchUser={user => {
                          this.getUserContextAndData(
                            this.props.getUser.token,
                            URIEncode(user.username),
                            true
                          ).then(this.navigateUserToInitialPageAfterLogin)
                        }}
                      />
                    </BasicFormWrapper>}
                </Route>
                <Route path={routes.SIGNUP}>
                  {!this.passwordChangeDisplayed &&
                    <BasicFormWrapper>
                      <SignUpForm onSignUp={this.handleSignUp} />
                    </BasicFormWrapper>}
                </Route>
                <Route path={routes.STUDENT_SIGNUP}>
                  {!this.passwordChangeDisplayed &&
                    <BasicFormWrapper>
                      <StudentSignUpForm
                        onWrongToken={this.handleWrongLink}
                    onSignUp={this.getUserContextAndData} //eslint-disable-line
                      />
                    </BasicFormWrapper>}
                </Route>
                <Route path={routes.USER_SIGNUP}>
                  {!this.passwordChangeDisplayed &&
                    <BasicFormWrapper>
                      <StudentSignUpForm
                        onWrongToken={this.handleWrongLink}
                    onSignUp={(token) => {this.getUserContextAndData(token).then(() => {this.navigateUserToInitialPageAfterLogin()})} } //eslint-disable-line
                        isUserSignup
                      />
                    </BasicFormWrapper>}
                </Route>
                <Route path={routes.SECTION_EVALUATION_BY_STUDENT}>
                  <BasicFormWrapper isWide>
                    <Suspense fallback={<div style={{ display: 'flex', flexDirection: 'column', minHeight: '300px', position: 'relative' }} />}>
                      <SectionEvaluationByStudent sectionEvaluationByStudent />
                    </Suspense>
                  </BasicFormWrapper>
                </Route>
                <Route path='/*'>
                  {!this.passwordChangeDisplayed && (
                    <SchoolProvider>
                      <ContextAwareInterface>
                        <AppManager
                          onLogout={this.logout} //eslint-disable-line 
                          schoolOptions={this.state.schoolOptions}
                        />
                      </ContextAwareInterface>
                    </SchoolProvider>
                  )}
                </Route>
              </Switch>
            )}
            {context && ![NONE_CONTEXT, ADMIN_CONTEXT].includes(context) && <ChangeLogNotification />}
            <MessageBox />
            <ChatManager />
          </GlobalFiltersProvider>
        </GlobalProvider>
      </Theme>
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App)
