import React, { Component } from 'react'

import { faKey, faFileArchive } from '@fortawesome/free-solid-svg-icons'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_DATE, DATA_TYPE_BOOLEAN_TAG
} from '../../Components/shared/SmartTable'
import StudentInfo from '../../Components/shared/StudentInfo'
import PublipostageDrawer from '../../Components/institution/PublipostageDrawer'
import { INSTITUTION_OPTION_PUBLIPOSTAGE, GENDER_OPTIONS, COUNTRIES } from '../../utils/constants'
import moment from 'moment'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import Analytics from '../../utils/analytics'
import AdminResetPassword from '../../Components/shared/AdminResetPassword'
import { INITIAL_STATE } from '../../reducers/InstitutionTemplatesReducer/InstitutionTemplatesReducer'

class InstitutionStudentsView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedStudentInfo: null,
      selectedStudentPublipostage: null,
      selectedStudentForPasswordReset: null,
      templateLoaded: false,
      publipostage: false
    }

    this._userIsAdmin = null
  }

  get userIsAdmin () {
    if (this._userIsAdmin === null) {
      const {
        getUser: { roles }
      } = this.props
      this._userIsAdmin =
        roles.includes('ROLE_HOSPITAL_ADMIN') || roles.includes('ROLE_ENCODER')
    }
    return this._userIsAdmin
  }

  get canResetPassword () {
    return ['ROLE_HOSPITAL_ADMIN', 'ROLE_ADMIN'].includes(this.props.getUser.roles[0])
  }

  componentWillUnmount () {
    this.props.setInstitutionTemplatesState(INITIAL_STATE)
    this.setState({ templateLoaded: false, publipostage: false })
  }

  componentDidMount () {
    Analytics.pageView('/institution-students')
    this.showNiss = ['ROLE_HOSPITAL_ADMIN'].includes(this.props.getUser.roles[0])
  }

  static getDerivedStateFromProps (props, state) {
    if (props.getInstitutions[0] && !state.templateLoaded) {
      let havePublipostageOption = false

      props.getInstitutions[0].institutionOptions.forEach(option => {
        if (option.optionType === INSTITUTION_OPTION_PUBLIPOSTAGE) {
          havePublipostageOption = true
        }
      })

      if (havePublipostageOption) {
        props.fetchInstitutionTemplates(props.getInstitutions[0].id, props.getUser)
      }

      return {
        templateLoaded: true,
        publipostage: havePublipostageOption
      }
    }

    return null
  }

  mapGetStudentsToRenderStudents (getStudents) {
    const genderOptions = GENDER_OPTIONS(this.props.t)
    const countries = COUNTRIES(this.props.t)

    return getStudents.map(s => {
      let gender
      genderOptions.filter(g => {
        if (g.id === s.gender) {
          gender = g.name
        }
      })

      let nationality
      countries.filter(c => {
        if (c.id === s.nationality) {
          nationality = c.name
        }
      })

      let sectionName

      if (s.sectionAbbreviation) {
        sectionName = s.sectionAbbreviation
      } else if (s.schoolSectionComment) {
        sectionName = s.schoolSectionComment
      } else {
        sectionName = s.oldSectionName
      }

      return {
        id: s.id,
        section: sectionName,
        firstname: s.firstname,
        lastname: s.lastname,
        email: s.email,
        matricule: s.matricule,
        phoneNumber: s.phoneNumber,
        gender: gender ?? null,
        user: s.user,
        birthdate:
          s.birthdate === null
            ? null
            : moment(s.birthdate.date).format('DD/MM/YYYY'),
        niss: s.niss ?? null,
        nationality: nationality ?? null,
        dataUse:
          s.dataUse === null
            ? null
            : s.dataUse
      }
    })
  }

  render () {
    let students = []

    if (this.props.getStudents.length) {
      students = this.props.isLocalAdmin
        ? this.mapGetStudentsToRenderStudents(this.props.getStudents.filter(s =>
          this.props.getUserStudentsId.includes(s.id))
        )
        : this.mapGetStudentsToRenderStudents(this.props.getStudents)
    }

    let columns = [
      { type: DATA_TYPE_ID, key: 'id' }
    ]

    if (!this.props.getUser.hideStudentSectionForHospitalUser) {
      columns.push({ type: DATA_TYPE_STRING, name: this.props.t('Section'), key: 'section' })
    }

    columns = [
      ...columns,
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Lastname'),
        key: 'lastname'
      },
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Firstname'),
        key: 'firstname'
      },
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Email'),
        key: 'email'
      },
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Registration number'),
        key: 'matricule'
      },
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Phone'),
        key: 'phoneNumber'
      },
      {
        type: DATA_TYPE_DATE,
        name: this.props.t('Birthdate'),
        key: 'birthdate',
        format: 'DD/MM/YYYY'
      },
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Gender'),
        key: 'gender'
      },
      {
        type: DATA_TYPE_STRING,
        name: this.props.t('Nationality'),
        key: 'nationality'
      },
      {
        type: DATA_TYPE_BOOLEAN_TAG,
        name: this.props.t('Data use'),
        key: 'dataUse',
        yesMessage: 'Accepts the use of their data for recruitment purposes',
        noMessage: 'Does not accept the use of their data for recruitment purposes'
      }
    ]

    if (this.showNiss) {
      columns.push(
        {
          type: DATA_TYPE_STRING,
          name: this.props.t('NISS'),
          key: 'niss',
          password: true
        }
      )
    }

    const additionalActions = [
      {
        key: 'student-info',
        iconName: 'user-graduate',
        type: 'primary',
        title: this.props.t('Student sheet'),
        onClick: s => this.setState({ selectedStudentInfo: s })
      }
    ]

    if (this.state.publipostage) {
      additionalActions.push({
        key: 'publipostage',
        iconName: faFileArchive,
        type: 'primary',
        title: this.props.t('Publipostage templates list'),
        onClick: s => { this.setState({ selectedStudentPublipostage: s }) }
      })
    }

    if (this.canResetPassword) {
      // add the button to reset the password
      additionalActions.push({
        key: 'reset-password',
        iconName: faKey,
        type: 'primary',
        title: this.props.t('Reset password'),
        onClick: s => this.setState({ selectedStudentForPasswordReset: s })
      })
    }

    return (
      <div>
        {this.state.templateLoaded &&
          <SmartTable
            columns={columns}
            data={students}
            loading={!this.props.getDataReady.students}
            noDataText={this.props.t(
              'Your institution currently has no student.'
            )}
            onDataDelete={undefined}
            noDataDelete
            additionalActions={additionalActions}
          />}

        <StudentInfo
          student={this.state.selectedStudentInfo}
          onClose={() => this.setState({ selectedStudentInfo: null })}
        />

        {this.canResetPassword &&
          <AdminResetPassword
            userToResetPassword={this.state.selectedStudentForPasswordReset}
            onClose={() => this.setState({ selectedStudentForPasswordReset: null })}
          />}

        {this.state.publipostage &&
          <PublipostageDrawer
            student={this.state.selectedStudentPublipostage}
            onClose={() => this.setState({ selectedStudentPublipostage: null })}
          />}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionStudentsView)
