import React, { useContext } from 'react'

import { Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faList } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import InternshipsManagerView from '../../shared/InternshipsManagerView'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { ROLE_HOSPITAL_ADMIN } from '../../../utils/constants'
import InternshipsRequestsView from './InternshipsRequestsView/InternshipsRequestsView'
import InternshipsListView from './InternshipsList/InternshipsListView'
import IndividualInternshipRequestModal from '../../../Providers/Forms/IndividualInternshipRequestModal'
import { InstitutionContext } from '../../../Providers/InstitutionProvider'
import InternshipRequestFilesDrawer from './InternshipsRequestsView/InternshipRequestFilesDrawer'
import useSessionStorage from '../../../hooks/UseSessionStorage'
import { getUser } from '../../../reducers/UserReducer'
import InternshipsManagerProvider from '../../../Context/InternshipsManager/InternshipsManagerProvider'

const { TabPane } = Tabs

const TAB_KEY = 'institution.internships_view_active_key'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InternshipsView = ({ user, t }) => {
  const { currentRole } = useContext(GlobalContext)
  const institutionContext = useContext(InstitutionContext)

  const [activeKey, setActiveKey] = useSessionStorage(TAB_KEY + user.id, '1')

  return (
    <Tabs activeKey={activeKey} animated={false} onChange={setActiveKey}>
      <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('List view')}</span></div>} key='1'>
        <InternshipsListView />
      </TabPane>
      <TabPane tab={<div><FontAwesomeIcon icon={faCalendar} /> <span>{t('internships_view.titles.internships_timeline')}</span></div>} key='2'>
        <InternshipsManagerProvider>
          <InternshipsManagerView />
        </InternshipsManagerProvider>
      </TabPane>
      {currentRole === ROLE_HOSPITAL_ADMIN && (
        <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('internships_view.titles.internships_request')}</span></div>} key='3'>
          <IndividualInternshipRequestModal schools={institutionContext.schools} institutions={[institutionContext.institution]} sectors={institutionContext.sectors}>
            <InternshipRequestFilesDrawer>
              <InternshipsRequestsView />
            </InternshipRequestFilesDrawer>
          </IndividualInternshipRequestModal>
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps)(InternshipsView)
