import React from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const ContactPersonDetails = ({ contactPerson, type, t }) => {
  return (
    <div className='flex-row'>
      <b>{t(type)}:</b>&nbsp;
      {`${contactPerson.lastname} ${contactPerson.firstname} - `}
      <a href={'mailto:' + contactPerson.email}>{contactPerson.email}</a>
      {contactPerson.phone !== null && contactPerson.phone !== '' ? ` - ${contactPerson.phone}` : ''}
    </div>
  )
}

export default connect(mapStateToProps)(ContactPersonDetails)
