import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

export const getAllSpecialities = user => {
  return requestWithPromise(
    '/api/specialities',
    GET,
    null,
    user
  ).then(json => {
    if (json?.data) {
      return json.data
    }
  })
}

export const getSpecialityYears = (speciality, user) => {
  return requestWithPromise(
    `/api/specialities/${speciality.id}/speciality-years`,
    GET,
    null,
    user
  ).then(json => {
    if (json?.data) {
      return json.data
    }
  })
}
