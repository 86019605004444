import React, { useEffect, useState } from 'react'

import { isMoment } from 'moment'
import { DATE_FORMAT, SHORT_DATE_FORMAT, getTimezoneLessMoment } from '../../../utils/momentjs'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import InternshipCard from './InternshipCard'
import AssistantInfo from '../../../Components/MACCS/Internships/AssistantInfo'
import MaccsInternshipInfo from '../../../Components/shared/MaccsInternshipInfo'
import { getUser } from '../../../reducers/UserReducer'
import MaccsInternshipValidation from '../../../Components/shared/ShiftsManager/MaccsInternshipValidation/MaccsInternshipValidation'
import { internshipStates } from '../../../utils/constants'
import ShiftCell from './ShiftCell'
import { API_DATE_FORMAT } from '../../../utils/apiHelper'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InternshipsScheduler = ({ endDate, internships, startDate, user, onInvalidateInternship, onValidateInternship, t }) => {
  const [columns, setColumns] = useState([])
  const [indexesShifts, setIndexesShifts] = useState({})
  const [internshipToValidate, setInternshipToValidate] = useState(null)
  const [selectedAssistant, setSelectedAssistant] = useState(null)
  const [selectedInternship, setSelectedInternship] = useState(null)

  useEffect(() => {
    if (isMoment(startDate) && isMoment(endDate)) {
      const columns = []

      let date = startDate.clone()

      while (date <= endDate) {
        columns.push({ shortDate: date.format(SHORT_DATE_FORMAT), longDate: date.format(DATE_FORMAT) })
        date = date.add(1, 'days')
      }

      setColumns(columns)
    } else {
      setColumns([])
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (internships && isMoment(startDate) && isMoment(endDate)) {
      const shiftsbyIndex = {}

      internships.forEach(i => {
        shiftsbyIndex[i.id] = {}

        const shifts = i.shifts.sort((a, b) => a.startDate.localeCompare(b.startDate))
        const maxIndex = shifts.length
        let incr = 0
        let shiftIndex = 0
        let date = startDate.clone()
        let shiftDate = shifts.length > 0 && getTimezoneLessMoment(shifts[shiftIndex].startDate).format(DATE_FORMAT)
        let currentDate = date.format(DATE_FORMAT)

        while (date <= endDate && shiftIndex < maxIndex) {
          if (shiftDate === currentDate) {
            shiftsbyIndex[i.id][incr] = shifts[shiftIndex]

            date = date.add(1, 'day')
            currentDate = date.format(DATE_FORMAT)
            incr++
            shiftIndex++

            if (shiftIndex < maxIndex) {
              shiftDate = getTimezoneLessMoment(shifts[shiftIndex].startDate).format(DATE_FORMAT)
            }
          } else if (shiftDate > currentDate) {
            date = date.add(1, 'day')
            currentDate = date.format(DATE_FORMAT)
            incr++
          } else {
            shiftIndex++

            if (shiftIndex < maxIndex) {
              shiftDate = getTimezoneLessMoment(shifts[shiftIndex].startDate).format(DATE_FORMAT)
            }
          }
        }
      })

      setIndexesShifts(shiftsbyIndex)
    }
  }, [internships, startDate, endDate])

  return (
    <div className='internshipsScheduler'>
      <table className='internship-schedule-table'>
        <thead>
          <tr>
            <th> {t('Internships')} </th>
            {columns.map((c, index) => {
              return (
                <th key={`date_${index}`}> {c.shortDate} </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {internships.map(i => {
            const startDate = getTimezoneLessMoment(i.startDate).format(API_DATE_FORMAT)
            const endDate = getTimezoneLessMoment(i.endDate).format(API_DATE_FORMAT)

            return (
              <tr key={`row_${i.id}`}>
                <td>
                  <InternshipCard
                    context={user.context}
                    internship={i}
                    onSelectInternship={setSelectedInternship}
                    onSelectAssistant={setSelectedAssistant}
                    onSelectValidation={setInternshipToValidate}
                  />
                </td>
                {columns.map((c, index) => {
                  let style = {}

                  if (c.longDate > startDate && c.longDate < endDate) {
                    style = { borderTop: '2px solid #39A7FF', borderBottom: '2px solid #39A7FF' }
                  } else if (c.longDate === startDate) {
                    style = { border: '2px solid #39A7FF', borderRight: 'none', borderRadius: '10px 0px 0px 10px' }
                  } else if (c.longDate === endDate) {
                    style = { border: '2px solid #39A7FF', borderLeft: 'none', borderRadius: '0px 10px 10px 0px' }
                  }

                  return (
                    <td key={`col_${index}`} style={style}>
                      {indexesShifts[i.id] && indexesShifts[i.id][index] && (
                        <ShiftCell shift={indexesShifts[i.id][index]} />
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {internshipToValidate && (
        <MaccsInternshipValidation
          maccsInternship={internshipToValidate}
          onClose={() => setInternshipToValidate(null)}
          onValidate={() => {
            setInternshipToValidate({ ...internshipToValidate, state: internshipStates.SCHEDULE_VALIDATED })

            if (typeof onValidateInternship === 'function') {
              onValidateInternship(internshipToValidate)
            }
          }}
          onInvalidate={newState => {
            setInternshipToValidate({ ...internshipStates, state: newState })

            if (typeof onInvalidateInternship === 'function') {
              onInvalidateInternship(internshipToValidate, newState)
            }
          }}
          bottom
        />
      )}
      {selectedAssistant && (
        <AssistantInfo
          assistant={selectedAssistant}
          onClose={() => setSelectedAssistant(null)}
        />
      )}
      {selectedInternship && (
        <MaccsInternshipInfo
          maccsInternship={selectedInternship}
          onClose={() => setSelectedInternship(null)}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsScheduler)
