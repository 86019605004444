import React, { useContext, useEffect, useState } from 'react'

import { getSchoolByUser, oldUpdateSchool } from '../../../../utils/api/school'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING, DATA_TYPE_SELECT } from '../../../shared/SmartTable'
import { validateFormInput } from '../../../../utils'
import { ROLE_ADMIN_RESEAU } from '../../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const School = ({ t, user }) => {
  const { countries } = useContext(GlobalContext)

  const [school, setSchool] = useState(null)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (user) {
      getSchoolByUser(user)
        .then(json => {
          if (json && json.data) {
            setSchool(extractSchoolData(json.data))
          }
        })
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const isSchoolAdmin = user.roles[0] === ROLE_ADMIN_RESEAU

      loadColumns(isSchoolAdmin, countries)
    }
  }, [user, countries])

  const loadColumns = (isSchoolAdmin, countries) => {
    setColumns(
      [
        { type: DATA_TYPE_ID, key: 'id' },
        { type: DATA_TYPE_STRING, name: t('Name'), key: 'name', validate: (data) => validateFormInput('freeText', data, true), disabled: !isSchoolAdmin },
        { type: DATA_TYPE_STRING, name: t('Address'), key: 'address', validate: (data) => validateFormInput('freeText', data, true), disabled: !isSchoolAdmin },
        { type: DATA_TYPE_STRING, name: t('Zipcode'), key: 'zipcode', validate: (data) => validateFormInput('freeText', data, true), disabled: !isSchoolAdmin },
        { type: DATA_TYPE_STRING, name: t('City'), key: 'city', validate: (data) => validateFormInput('name', data, true), disabled: !isSchoolAdmin },
        { type: DATA_TYPE_SELECT, name: t('Country'), key: 'country', options: countries, disabled: !isSchoolAdmin }
      ]
    )
  }

  const handleEditSchool = school => {
    oldUpdateSchool(school, user).then(json => {
      if (json && json.data) {
        setSchool(extractSchoolData(json.data))
      }
    })
  }

  const extractSchoolData = school => {
    return {
      id: school.id,
      name: school.name,
      address: school.address,
      zipcode: school.zipcode,
      country: school.country,
      city: school.city
    }
  }

  return (
    <div>
      <SmartTable
        hideTableControls
        columns={columns}
        data={school !== null ? [school] : []}
        loading={school === null}
        onDataEdit={handleEditSchool}
      />
    </div>
  )
}

export default connect(mapStateToProps)(School)
