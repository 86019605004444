import { isObject, validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'

export const SECTOR_VALIDATORS = [
  { key: VALIDATION_FIELDS.COMMENT, validator: s => validateString(s.comment) },
  { key: VALIDATION_FIELDS.EMPLACEMENT, validator: s => validateString(s.emplacement) },
  { key: VALIDATION_FIELDS.NAME, validator: s => validateString(s.name, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.INSTITUTION, validator: s => s.institution !== null }
]

export const getName = s => {
  return s.name ?? null
}

export const getComment = s => {
  return s.comment ?? null
}

export const getEmplacement = s => {
  return s.emplacement ?? null
}

export const getInstitutionName = s => {
  return s.institution?.name ?? null
}

export class Sector {
  constructor ({ id, archived = false, comment = '', emplacement = '', institution, name = '', pausesEnabled = false, places = 0 } = {}) {
    this.id = id ?? -1
    this.archived = archived
    this.comment = comment
    this.emplacement = emplacement
    this.institution = isObject(institution) ? institution : null
    this.name = name
    this.pausesEnabled = pausesEnabled
    this.places = places
  }

  toRequestBody = () => {
    const body = { ...this }

    body.institution = isObject(this.institution) ? this.institution.id : null

    return body
  }
}
