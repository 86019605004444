import React, {
  createContext, useEffect, useState
} from 'react'
import { getAppStates } from '../../reducers/AppStates/actions'
import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import { getDataReady } from '../../reducers/DataReadyReducer'
import { getTaggedEntities } from '../../reducers/TagsReducer'
import { getTranslate } from 'react-localize-redux'
import { bindActionCreators } from 'redux'
import { setInternshipForChat } from '../../reducers/NewMessagesReducer'
import { getColumnsDates } from '../../Components/shared/InternshipsManager/utils/utils'

const InternshipsDataContext = createContext()

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    currentDate: getAppStates(state).date,
    getDataReady: getDataReady(state.getDataReady),
    getTaggedEntities: getTaggedEntities(state.tags)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChat: bindActionCreators(setInternshipForChat, dispatch)
  }
}

let InternshipsDataProvider = props => {
  const {
    addMode, quotas, t, user, currentDate, getDataReady,
    onEarlyStartDate, onStudentSelected, onInternshipSelected, onInternshipDelete,
    refreshInternships, refreshQuotas, setChat, setInternshipToValidate, internships, weeksShownCount
  } = props

  const [dateColumns, setDateColumns] = useState([])
  const [highlightedIndexes, setHighlightedIndexes] = useState(null)

  useEffect(() => {
    setDateColumns(getColumnsDates(currentDate, weeksShownCount))
  }, [weeksShownCount, currentDate])

  const onLineHover = periodIndexes => {
    setHighlightedIndexes(periodIndexes)
  }

  const onTableLeave = () => {
    setHighlightedIndexes(null)
  }

  return (
    <InternshipsDataContext.Provider value={{
      addMode,
      dateColumns,
      currentDate,
      internships,
      quotas,
      getDataReady,
      highlightedIndexes,
      user,
      onEarlyStartDate,
      onInternshipSelected,
      onInternshipDelete,
      onLineHover,
      onTableLeave,
      onStudentSelected,
      refreshInternships,
      refreshQuotas,
      setChat,
      setInternshipToValidate,
      t
    }}
    >
      {props.children}
    </InternshipsDataContext.Provider>
  )
}

InternshipsDataProvider = connect(mapStateToProps, mapDispatchToProps)(InternshipsDataProvider)

export {
  InternshipsDataProvider as default,
  InternshipsDataContext
}
