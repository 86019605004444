import React, {
  createContext, useEffect, useState
} from 'react'
import { getAppStates } from '../../reducers/AppStates/actions'
import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import { getTaggedEntities } from '../../reducers/TagsReducer'
import { getTranslate } from 'react-localize-redux'
import { bindActionCreators } from 'redux'
import { setInternshipForChat } from '../../reducers/NewMessagesReducer'
import { getColumnsDatesMonth } from '../../Components/shared/InternshipsManager/utils/utils'
import { SCHOOL_MACCS_CONTEXT } from '../../utils/constants'
import { getMaccsLinkedInstitutions, getSchoolSpecialities } from '../../utils/api/school'
import { getAllAssistants } from '../../utils/api/assistant'
import { getAllSpecialities } from '../../utils/api/speciality'

const MaccsInternshipsDataContext = createContext()

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    currentDate: getAppStates(state).date,
    getTaggedEntities: getTaggedEntities(state.tags),
    workingRegimes: getAppStates(state).workingRegimes
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChat: bindActionCreators(setInternshipForChat, dispatch)
  }
}

let MaccsInternshipsDataProvider = props => {
  const {
    addMode, t, user, currentDate, onEarlyStartDate, onStudentSelected, onInternshipSelected, onInternshipDelete,
    refreshInternships, setChat, setInternshipToValidate, internships, weeksShownCount, totalsMessageUnreadByInternships,
    workingRegimes
  } = props

  const [dateColumns, setDateColumns] = useState([])
  const [highlightedIndexes, setHighlightedIndexes] = useState(null)
  const [assistants, setAssistants] = useState([])
  const [institutions, setInstitutions] = useState([])
  const [schoolSpecialities, setSchoolSpecialities] = useState([])
  const [specialities, setSpecialities] = useState([])
  const [dataLoading, setDataLoading] = useState(true)

  useEffect(() => {
    if (user) {
      setDataLoading(true)

      if (user.context === SCHOOL_MACCS_CONTEXT) {
        const iPromise = getMaccsLinkedInstitutions(user.school.id, user)
          .then(data => setInstitutions(data))
        const aPromise = getAllAssistants(user).then(json => {
          if (json && json.data) {
            setAssistants(json.data)
          }
        })
        const ssPromise = getSchoolSpecialities(user.school, user).then(json => {
          if (json?.data) {
            setSchoolSpecialities(json.data)
          }
        })

        Promise.all([iPromise, aPromise, ssPromise]).then(() => {
          setDataLoading(false)
        })
      } else {
        setDataLoading(false)
      }

      getAllSpecialities(user).then(data => {
        setSpecialities(data)
        setDataLoading(false)
      })
    }
  }, [user])

  useEffect(() => {
    setDateColumns(getColumnsDatesMonth(currentDate, 12))
  }, [weeksShownCount, currentDate])

  /* useEffect(() => {
    setDateColumns(getColumnsDates(currentDate, weeksShownCount))
  }, [weeksShownCount, currentDate]) */

  const onLineHover = periodIndexes => {
    setHighlightedIndexes(periodIndexes)
  }

  const onTableLeave = () => {
    setHighlightedIndexes(null)
  }

  return (
    <MaccsInternshipsDataContext.Provider value={{
      addMode,
      assistants,
      dateColumns,
      currentDate,
      dataLoading,
      institutions,
      internships,
      totalsMessageUnreadByInternships,
      highlightedIndexes,
      user,
      workingRegimes,
      onEarlyStartDate,
      onInternshipSelected,
      onInternshipDelete,
      onLineHover,
      onTableLeave,
      onStudentSelected,
      refreshInternships,
      setChat,
      setInternshipToValidate,
      schoolSpecialities,
      specialities,
      t
    }}
    >
      {props.children}
    </MaccsInternshipsDataContext.Provider>
  )
}

MaccsInternshipsDataProvider = connect(mapStateToProps, mapDispatchToProps)(MaccsInternshipsDataProvider)

export {
  MaccsInternshipsDataProvider as default,
  MaccsInternshipsDataContext
}
