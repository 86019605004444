import React, { useState, useEffect } from 'react'
import { Avatar, Button, Card, Drawer, notification } from 'antd'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import LoadingSkeleton from '../../shared/LoadingSkeleton'
import Meta from 'antd/lib/card/Meta'
import moment from 'moment'
import {
  exportMaccsInternshipsHistory,
  getAssistantByIdWithSpecialities,
  getAssistantExtraInformations,
  getAssistantMaccsInternshipWithContexts,
  getAssistantOptin
} from '../../../utils/api/assistant'
import { AGGREGATION_STRING, dataAggregationFactory } from '../../../utils/dataFactories'
import { faAt, faBirthdayCake, faFileSignature, faIdBadge, faPhone } from '@fortawesome/free-solid-svg-icons'
import { getUser } from '../../../reducers/UserReducer'
import InformationDisplay from '../../shared/InformationDisplay'
import { getImageByName } from '../../../utils/api/image'
import FilesManager from '../../shared/FilesManager'
import { SCHOOL_MACCS_CONTEXT, SUPPORTED_LANGUAGES } from '../../../utils/constants'
import { downloadFile, getJobResultWithPromise } from '../../../utils'
import { HTTP_NOT_FOUND } from '../../../utils/constants/http'
import MaccsInternshipInfo from '../../shared/MaccsInternshipInfo'
import { CONTEXTS } from '../../../utils/api/maccsInternship'
import MaccsInternshipCard from '../../shared/MaccsInternshipCard'
import { getFullName } from '../../../utils/entities/schoolSpeciality'

const RECRUITEMENT_DATA_USAGE = 'RECRUITEMENT_DATA_USAGE'
const INTERVAL_DURATION = 1500

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    activeLanguage: getActiveLanguage(state.locale).code
  }
}

const AssistantInfo = ({ activeLanguage, assistant, user, onClose, t }) => {
  const [currentAssistant, setAssistant] = useState(null)
  const [loading, setLoading] = useState(false)
  const [exportMaccsInternshipsLoading, setExportMaccsInternshipsLoading] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [selectedMaccsInternshipInfo, setSelectedMaccsInternshipInfo] = useState(null)
  const [loadFiles, setLoadFiles] = useState(false)
  const [extraPhonesAssistant, setExtraPhonesAssistant] = useState([])
  const [assistantPrivacyInformation, setAssistantPrivacyInformation] = useState([])
  const [maccsInternships, setMaccsInternships] = useState([])

  useEffect(() => {
    if (assistant !== null) {
      setLoading(true)

      getAssistantByIdWithSpecialities(assistant, user)
        .then(data => {
          setAssistant(data)
          setLoading(false)
        })

      if (assistant.profileImageName !== null) {
        getImageByName(assistant.profileImageName, user)
          .then(data => {
            setProfileImage(data)
          })
      }

      getAssistantExtraInformations(assistant, user)
        .then(data => {
          const newExtraPhonesAssistant = []

          if (data.phone) {
            for (const phone of data.phone) {
              newExtraPhonesAssistant.push(phone.value)
            }
          }

          setExtraPhonesAssistant(newExtraPhonesAssistant)
        })

      getAssistantOptin(assistant, RECRUITEMENT_DATA_USAGE, user)
        .then(data => {
          setAssistantPrivacyInformation(data[0])
        })

      getAssistantMaccsInternshipWithContexts(
        assistant,
        { contexts: [CONTEXTS.shifts, CONTEXTS.documents, CONTEXTS.validationDocument] },
        user
      ).then(data => {
        setMaccsInternships(data)
      })
    }
  }, [assistant])

  const handleOnClose = () => {
    setExtraPhonesAssistant([])
    setAssistantPrivacyInformation([])
    setProfileImage(null)
    setAssistant(null)
    onClose()
  }

  const handleMaccsInternshipValidation = updatedMaccsInternship => {
    const updatedMaccsInternshipsList = maccsInternships.map(maccsInternship => {
      if (maccsInternship.id === updatedMaccsInternship.id) {
        return updatedMaccsInternship
      }

      return maccsInternship
    })

    setMaccsInternships(updatedMaccsInternshipsList)
  }

  const renderBody = () => {
    let content = <LoadingSkeleton />

    if (currentAssistant !== null && !loading && Array.isArray(maccsInternships)) {
      content = (
        <div className='flex-column'>
          {renderProfileHeader()}
          {renderExportsButtons()}
          {maccsInternshipCard()}
        </div>
      )
    }

    return content
  }

  const renderProfileHeader = () => {
    return (
      <Card
        cover={
          <Meta
            avatar={<Avatar size={60} src={profileImage || currentAssistant.profileImageName ? profileImage : '/assets/default_profile_img.png'} />}
            title={currentAssistant.lastname + ' ' + currentAssistant.firstname}
            description={displaySpeciality()}
            style={{ margin: '24px 0px 0px 24px' }}
          />
        }
        actions={[
          <Button
            key='assistant_files_button'
            onClick={() => setLoadFiles(!loadFiles)}
          >
            {t('Assistant files')}
          </Button>
        ]}
      >
        <div>
          {renderAssistantInfo()}
        </div>
      </Card>
    )
  }

  const displaySpeciality = () => {
    return getFullName(currentAssistant.schoolSpeciality)
  }

  const renderAssistantInfo = () => {
    const assistantInfo = [
      {
        id: 0,
        icon: faIdBadge,
        title: t('Registration number'),
        caption: currentAssistant.matricule ?? '?'
      },
      {
        id: 1,
        icon: faBirthdayCake,
        title: t('Birthdate'),
        caption:
        currentAssistant.birthdate === null
          ? '?'
          : moment(currentAssistant.birthdate).format('DD/MM/YYYY')
      },
      {
        id: 2,
        icon: faAt,
        title: t('Email'),
        caption: currentAssistant.email
      },
      {
        id: 3,
        icon: faPhone,
        title: t('Phone'),
        caption: currentAssistant.phoneNumber !== null && currentAssistant.phoneNumber !== ''
          ? currentAssistant.phoneNumber
          : '?'
      }
    ]

    if (extraPhonesAssistant.length > 0) {
      const title = !assistantPrivacyInformation?.accepted
        ? 'WARNING : Personnal phones numbers BUT this student doesn’t agree to be contacted for recruitment but for necessities ONLY'
        : 'Student\'s personal phone numbers'

      assistantInfo.push({
        id: 4,
        icon: faPhone,
        title: t(title),
        color: !assistantPrivacyInformation?.accepted ? 'red' : undefined,
        caption: dataAggregationFactory(extraPhonesAssistant, AGGREGATION_STRING)
      })
    }

    assistantInfo.push({
      id: 5,
      icon: faFileSignature,
      title: t('Data use'),
      caption: assistantPrivacyInformation?.accepted
        ? t('Accepts the use of their data for recruitment purposes')
        : t('Does not accept the use of their data for recruitment purposes')
    })

    return (
      <InformationDisplay information={assistantInfo} hideTitle />
    )
  }

  const renderExportsButtons = () => {
    if (user.context === SCHOOL_MACCS_CONTEXT && maccsInternships.length > 0) {
      let style = {}

      if (activeLanguage.toLowerCase() === SUPPORTED_LANGUAGES[2]) {
        style = {
          marginBottom: '5px'
        }
      }

      const className = 'standard-top-margin flex-row'

      return (
        <div className={className}>
          <div className='flex-fill' />
          <Button
            type='default'
            onClick={handleExportMaccsInternships}
            loading={exportMaccsInternshipsLoading}
            style={style}
          >
            {t('Export internships history')}
          </Button>
        </div>
      )
    }

    return (<div />)
  }

  const handleExportMaccsInternships = async () => {
    setExportMaccsInternshipsLoading(true)

    const id = currentAssistant !== null
      ? currentAssistant.id
      : null

    const { job, status } = await exportMaccsInternshipsHistory(id, activeLanguage, user)

    createIntervalAndDownloadSpreadsheet(job, status)
  }

  const createIntervalAndDownloadSpreadsheet = async (job, status) => {
    if (status === 'success') {
      const intervalId = setInterval(() => {
        getJobResultWithPromise(job, user)
          .then(json => {
            if (json.data && !json.error) {
              clearInterval(intervalId)

              const fileName = json.data.result

              try {
                downloadFile(`/spreadsheet/download/${fileName}`, fileName, user, 'GET')
              } catch (err) {
                console.error(err)
              }

              setExportMaccsInternshipsLoading(false)
            }
          })
          .catch(response => {
            if (response.status !== HTTP_NOT_FOUND) {
              clearInterval(intervalId)
              console.error(response)
              notification.error({
                message: this.t('Your file was not generated correctly'),
                placement: 'bottomRight'
              })
            }
          })
      }, INTERVAL_DURATION)
    }
  }

  const maccsInternshipCard = () => {
    return maccsInternships.map((maccsInternship, key) => {
      return (
        <MaccsInternshipCard
          key={key}
          maccsInternship={maccsInternship}
          setSelectedMaccsInternshipInfo={setSelectedMaccsInternshipInfo}
        />
      )
    })
  }

  return (
    <Drawer
      title={t('Assistant sheet')}
      width='550px'
      onClose={handleOnClose}
      visible={assistant !== null}
    >
      {renderBody()}
      <MaccsInternshipInfo
        maccsInternship={selectedMaccsInternshipInfo}
        onClose={() => setSelectedMaccsInternshipInfo(null)}
        onMaccsInternshipValidate={handleMaccsInternshipValidation}
      />
      <FilesManager
        entity={loadFiles ? currentAssistant : null}
        entityName='Assistant'
        onClose={() => setLoadFiles(false)}
        userContextFiltered
        preventChanges
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(AssistantInfo)
