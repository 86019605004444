import React, { useMemo } from 'react'

import { secondsToTime, timeToSeconds, convertTime, HOURS_SPLIT_CHARACTER, HOURS_LABEL_CHARACTER } from '../../../../utils/momentjs'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { isArray } from 'lodash'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const ActsTable = ({ t, data, tableMaxHeight, invalidTotalCategories, totalHoursActs, scheduleComplements, actHoursVerificationEnabled }) => {
  const complementsByType = useMemo(() => {
    const complements = {}

    if (isArray(scheduleComplements)) {
      scheduleComplements.forEach(sc => {
        if (sc.actType !== null) {
          if (complements[sc.actType.name] === undefined) {
            complements[sc.actType.name] = { value: 0, hours: sc.actType.hoursFormat, commentary: '' }
          }

          if (complements[sc.actType.name].hours) {
            complements[sc.actType.name].value += timeToSeconds(sc.value, HOURS_SPLIT_CHARACTER, sc.positive)
          } else {
            complements[sc.actType.name].value += sc.positive ? Number(sc.value) : -Number(sc.value)
          }

          if (sc.commentary) {
            complements[sc.actType.name].commentary += (complements[sc.actType.name].commentary === '' ? '' : ' - ') + sc.commentary
          }
        }
      })
    }

    return complements
  }, [scheduleComplements])

  return (
    <div className='acts-table-wrapper'>
      <h3>{t('Acts summary')}</h3>
      {
        data && data.map(item => {
          const categoryName = item[0] === 'null' ? 'Without category' : item[0]

          return (
            <div className={invalidTotalCategories.includes(categoryName) && actHoursVerificationEnabled ? 'acts-table-row color-border-red' : 'acts-table-row'} key={`act-category-${item[0]}`}>
              <div className={invalidTotalCategories.includes(categoryName) && actHoursVerificationEnabled ? 'category-label color-red' : 'category-label'}>{item[0] !== 'null' ? item[0] : t('Without category')}</div>
              <ul>
                {item[1].map((actTypeTotal, index) => {
                  const hasBonus = !!complementsByType[actTypeTotal.name]
                  const isHours = actTypeTotal.value.includes(HOURS_LABEL_CHARACTER)
                  let value = isHours ? timeToSeconds(actTypeTotal.value, HOURS_LABEL_CHARACTER) : actTypeTotal.value

                  if (hasBonus) {
                    value += complementsByType[actTypeTotal.name].value
                  }

                  if (isHours) {
                    value = secondsToTime(value)
                  }

                  return (
                    <li key={`act-type-total-${index}`} className={hasBonus ? 'has-schedule-component' : ''}>
                      <span className='act-name'>{actTypeTotal.name}</span>
                      <span className='act-value'>{typeof value === 'string' ? value.replace(HOURS_LABEL_CHARACTER, ':') : HOURS_SPLIT_CHARACTER}</span>
                      {hasBonus && (
                        <Tooltip title={(
                          <div className={`schedule-component-title-${index}`}>
                            <span>
                              {
                                t('This act has received a ' + (complementsByType[actTypeTotal.name].value >= 0 ? '' : 'negative ') + 'bonus of ') +
                                secondsToTime(complementsByType[actTypeTotal.name].value)
                              }
                            </span>
                            {complementsByType[actTypeTotal.name].commentary && (
                              <>
                                <br />
                                <br />
                                <span> <u><b>{t('Commentary:')}</b></u> &nbsp; {complementsByType[actTypeTotal.name].commentary} </span>
                              </>
                            )}
                          </div>
                        )}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      )}
                    </li>
                  )
                })}
                {totalHoursActs[categoryName] &&
                  <li className='act-type-total' key='act-type-total'>
                    <span className='act-name'>{t('Total (hours)')}</span>
                    <span className='act-value'>{convertTime(totalHoursActs[categoryName], HOURS_SPLIT_CHARACTER, HOURS_SPLIT_CHARACTER)}</span>
                  </li>}
              </ul>
            </div>
          )
        })
      }
    </div>
  )
}

export default connect(mapStateToProps)(ActsTable)
