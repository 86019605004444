import moment from 'moment'
import { APP_STATES } from './actionsType'

const { SET_PAUSE_ACTIVATION, SET_DATE, GET_WORKING_REGIME } = APP_STATES

const INITIAL_STATE = {
  date: moment().startOf('isoWeek').hours(0).minutes(0).seconds(0),
  pauseActivated: false,
  workingRegimes: [],
  loadings: {
    workingRegimes: true
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DATE: {
      return { ...state, date: action.payload }
    }
    case SET_PAUSE_ACTIVATION: {
      return { ...state, pauseActivated: action.payload }
    }
    case GET_WORKING_REGIME: {
      state.workingRegimes = action.payload
      state.loadings = { ...state.loadings, workingRegimes: false }

      return state
    }
    default:
      return state
  }
}
