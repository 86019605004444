import { requestWithPromise } from '..'
import { GET, makeQueryStringFromObject, PATCH, POST } from '../apiHelper'

export const createAssistant = (assistantData, user) => {
  return requestWithPromise(
    '/api/assistants',
    POST,
    assistantData,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getAllAssistants = user => {
  return requestWithPromise(
    '/api/assistants',
    GET,
    null,
    user
  )
}

export const getAssistantByIdWithSpecialities = (assistant, user) => {
  return requestWithPromise(
    `/api/assistants/${assistant.id}?details[]=specialities`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getAssistantExtraInformations = (assistant, user) => {
  return requestWithPromise(
    `/api/assistants/${assistant.id}/extra-contact-info`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getAssistantOptin = (assistant, type, user) => {
  return requestWithPromise(
    `/api/assistants/${assistant.id}/optin?type=${type}`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getAssistantMaccsInternshipWithContexts = (assistant, parameters, user) => {
  const queryString = makeQueryStringFromObject(parameters)

  return requestWithPromise(
    `/api/assistants/${assistant.id}/maccs-internships${queryString}`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const updateAssistant = (assistant, body, user) => {
  return requestWithPromise(
    `/api/assistants/${assistant.id}`,
    PATCH,
    assistant,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const exportMaccsInternshipsHistory = (assistant, language, user) => {
  return requestWithPromise(
    `/api/assistants/${assistant}/export-maccs-internships-history?language=${language}`,
    GET,
    null,
    user
  ).then(json => {
    if (json) {
      return json
    }
  })
}
