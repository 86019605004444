import React, { useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchAssistant,
  fetchAssistantProfileImage,
  fetchExtraContactInfos,
  fetchOptIn,
  modifyOptIn
} from '../../../reducers/SchoolAssistantsReducer/actions'
import {
  getAssistant,
  getSchool,
  getSpecialityYear,
  getAssistantProfileImage,
  getExtraContactInfosMap,
  getOptInValue
} from '../../../reducers/SchoolAssistantsReducer/selectors'
import PersonalHeader from '../../student/profilePage/PersonalHeader'
import { Col, Row } from 'antd'
import PersonalSchoolInformations from '../../student/profilePage/PersonalSchoolInformations'
import PersonalInformationsForm from '../../student/profilePage/PersonalInformationsForm'

const MIN_WINDOW_WIDTH = 900

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser),
    getAssistant: getAssistant(state),
    getSpecialityYear: getSpecialityYear(state),
    getSchool: getSchool(state),
    getProfileImage: getAssistantProfileImage(state),
    getExtraContactInfosMap: getExtraContactInfosMap(state),
    getOptInValue: getOptInValue(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAssistant: bindActionCreators(fetchAssistant, dispatch),
  fetchAssistantProfileImage: bindActionCreators(fetchAssistantProfileImage, dispatch),
  fetchExtraContactInfos: bindActionCreators(fetchExtraContactInfos, dispatch),
  fetchOptIn: bindActionCreators(fetchOptIn, dispatch),
  modifyOptIn: bindActionCreators(modifyOptIn, dispatch)
})

const AssistantProfilePage = props => {
  const [spanCol, setSpanCol] = useState(0)
  const [loading, setLoading] = useState(true)
  const [assistant, setAssistant] = useState({})
  const [specialityYear, setSpecialityYear] = useState({})
  const [school, setSchool] = useState({})
  const [profileImage, setProfileImage] = useState(null)

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (props.getUser && typeof props.getUser.assistant && typeof props.fetchAssistant === 'function') {
      props.fetchAssistant(props.getUser, setLoading)
    }
  }, [props.getUser, props.fetchAssistant])

  useEffect(() => {
    setAssistant(props.getAssistant)
    setSpecialityYear(props.getSpecialityYear)
    setSchool(props.getSchool)
    setProfileImage(props.getProfileImage)
  }, [
    props.getAssistant,
    props.getSpecialityYear,
    props.getSchool,
    props.getProfileImage
  ])

  useEffect(() => {
    if (props.getUser && typeof props.fetchExtraContactInfos === 'function') {
      props.fetchExtraContactInfos(props.getUser)
    }
  }, [props.getUser, props.fetchExtraContactInfos])

  useEffect(() => {
    if (props.getUser && typeof props.fetchOptIn === 'function') {
      props.fetchOptIn(props.getUser)
    }
  }, [props.getUser, props.fetchOptIn])

  const updateDimensions = () => window.innerWidth <= MIN_WINDOW_WIDTH ? setSpanCol(24) : setSpanCol(12)

  useEffect(() => {
    if (
      props.getUser &&
      typeof props.fetchAssistantProfileImage === 'function' &&
      typeof assistant.profileImageName !== 'undefined' &&
      assistant.profileImageName !== null
    ) {
      props.fetchAssistantProfileImage(props.getUser)
    }
  }, [props.getUser, props.fetchAssistantProfileImage, assistant])

  const subTitle = () => {
    const { specialityName, studyYearName } = specialityYear

    return `${specialityName} (${studyYearName})`
  }

  if (typeof assistant.id === 'undefined' || loading) {
    return <div className='loading-icon black' />
  }

  return (
    <>
      <PersonalHeader
        person={assistant}
        subTitle={subTitle}
        entityName='Assistant'
        profileImage={profileImage}
      />

      <Row span={24}>
        <Col key={0} span={spanCol} style={{ paddingRight: '5px' }}>
          <PersonalSchoolInformations school={school} person={assistant} />
        </Col>

        <Col key={1} span={spanCol}>
          <PersonalInformationsForm
            person={assistant}
            extraContactInfos={props.getExtraContactInfosMap}
            optInValue={props.getOptInValue}
            modifyOptIn={props.modifyOptIn}
            pathApiEntity='/api/assistants'
          />
        </Col>
      </Row>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AssistantProfilePage)
