import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import '../../../assets/internship-state-pellet.scss'
import { faBan, faCheck, faExclamationCircle, faQuestion, faTimes, faCalendarCheck, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { INTERNSHIP_STATES_STRING } from '../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
const {
  ACCEPTED, CANCELED, PENDING, PRE_ACCEPTED, REFUSED, SCHEDULE_ADDED,
  TO_VALIDATE, VALIDATED, WITHOUT_INSTITUTION
} = INTERNSHIP_STATES_STRING
const SECONDARY_ICON_COLOR = '#d32f2f'
const DEFAULT_PARAMS = {
  tooltipText: '',
  icon: null,
  color: '',
  secondaryIcon: null,
  secondaryIconColor: ''
}

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const StringStatePellet = props => {
  const [params, setParams] = useState(DEFAULT_PARAMS)

  useEffect(() => {
    setParams(buildParams(props.state))
  }, [props.state])

  const buildParams = state => {
    const newParams = { ...DEFAULT_PARAMS }

    switch (state) {
      case ACCEPTED:
        newParams.tooltipText = props.t('Accepted, cannot be edited.')
        newParams.color = '#7EB73E'
        newParams.icon = faCheck
        break
      case CANCELED:
        newParams.tooltipText = props.t('Canceled, cannot be edited.')
        newParams.color = '#FFC107'
        newParams.icon = faBan
        break
      case PENDING:
        newParams.tooltipText = props.t('Waiting for approval/refusal.')
        newParams.color = '#03A9F4'
        newParams.icon = faQuestion
        break
      case PRE_ACCEPTED:
        newParams.tooltipText = props.t('Pre-accepted, waiting completion of data completion by the school.')
        newParams.color = '#ADD8E6'
        newParams.icon = faCheck
        break
      case REFUSED:
        newParams.tooltipText = props.t('Refused, see the internship discussion thread.')
        newParams.color = '#F44336'
        newParams.icon = faTimes
        break
      case SCHEDULE_ADDED:
        newParams.tooltipText = props.t('Accepted & modified by institution, cannot be edited')
        newParams.color = '#7EB73E'
        newParams.icon = faCheck
        newParams.secondaryIcon = faCalendarAlt
        break
      case TO_VALIDATE:
        newParams.tooltipText = props.t('Internship will be validated by institution')
        newParams.color = '#7EB73E'
        newParams.icon = faCheck
        newParams.secondaryIcon = faExclamationCircle
        newParams.secondaryIconColor = SECONDARY_ICON_COLOR
        break
      case VALIDATED:
        newParams.tooltipText = props.t('Internship ended & validated')
        newParams.color = '#7EB73E'
        newParams.icon = faCheck
        newParams.secondaryIcon = faCalendarCheck
        break
      case WITHOUT_INSTITUTION:
        newParams.tooltipText = props.t('Without institution/care unit, cannot be approved/refused.')
        newParams.color = '#B7B7B7'
        newParams.icon = faQuestion
    }

    return newParams
  }

  return (
    <Tooltip placement='top' title={params.tooltipText}>
      <div className='internship-state-pellet'>
        <div style={{marginRight: '10px'}} className='icon-container'>
          <div className='primary-icon' style={{ backgroundColor: params.color }}>
            {params.icon && (
              <FontAwesomeIcon icon={params.icon} style={{ margin: 'auto' }} />
            )}
          </div>
          {params.secondaryIcon &&
            <div className='secondary-icon' style={{ color: params.secondaryIconColor ?? SECONDARY_ICON_COLOR }}>
              <FontAwesomeIcon icon={params.secondaryIcon} />
            </div>}
        </div>
        {props.showLabel && (
          <div style={{ marginLeft: params.secondaryIcon ? '11px' : '5px' }}>
            {params.tooltipText}
          </div>
        )}
      </div>
    </Tooltip>
  )
}

export default connect(mapStateToProps)(StringStatePellet)
