import React, { useCallback, useContext, useMemo } from 'react'

import { Drawer } from 'antd'
import { ROLE_ADMIN_HOSPITAL_RO, ROLE_CLINICAL_SECRETARY, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_PRESI_MASTER } from '../../../utils/constants'
import UserSpecialityTable from './UserSpecialityTable'
import { recursiveIncludes } from '../../../utils'

const DRAWER_TITLE = 'Specialities supervised by'
const DEFAULT_TITLE = 'No user selected. Component desactivated'

const SupervisedUserSpecialities = ({ customContext, onClose }) => {
  const {
    user, columns, loading, selectedUser, userSpecialities, userSpecialityYears,
    clearStates, onLinkSpeciality, onModifyLinkSpeciality, onUnlinkSpeciality,
    userIsContactPerson, setMultipleUserContactPersons, setUserContactPerson, t
  } = useContext(customContext)

  const disabledAddButton = useMemo(() => {
    return recursiveIncludes(user.roles, [ROLE_ADMIN_HOSPITAL_RO, ROLE_CLINICAL_SECRETARY, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_PRESI_MASTER])
  }, [user.roles])
  const buildTitle = useMemo(() => selectedUser?.email ? t(DRAWER_TITLE) + ' ' + selectedUser.email : DEFAULT_TITLE, [selectedUser])

  const handleClose = useCallback(() => {
    clearStates()
    onClose()
  }, [clearStates, onClose])

  return (
    <Drawer
      title={buildTitle}
      width='640px'
      onClose={handleClose}
      visible={selectedUser !== null}
    >
      {columns && (
        <UserSpecialityTable
          columns={columns}
          data={userSpecialities ?? userSpecialityYears}
          loading={loading}
          onDataAdd={onLinkSpeciality}
          onDataEdit={onModifyLinkSpeciality}
          onDataDelete={onUnlinkSpeciality}
          addDataText={t('Add a speciality')}
          noDataText={t('This user does not supervise any speciality.')}
          selectAllText={t('Select all')}
          noDataSelected={t('Select a data')}
          deleteText={t('Delete')}
          titlePopConfirmText={t('Delete this speciality ?')}
          yesText={t('Yes')}
          cancelText={t('Cancel')}
          showAddButtonDisabled={disabledAddButton}
          userIsContactPerson={userIsContactPerson}
          setMultipleUserContactPersons={setMultipleUserContactPersons}
          setUserContactPerson={setUserContactPerson}
        />
      )}
    </Drawer>
  )
}

export default (SupervisedUserSpecialities)
