import { FULL_TIME_FORMAT, getTimezoneLessMoment } from '../momentjs'
import { API_DATE_FORMAT } from '../apiHelper'
import { isObject } from '..'

import { ABSENCE_CATEGORY } from './eventCodeType'

export const createShift = (internshipId, startDate, endDate, periodCode = null, pause = null, eventCode = null, startTime = null, endTime = null) => {
  return {
    id: -1,
    internship: internshipId,
    endDate,
    startDate,
    periodCode,
    pause,
    eventCode,
    endTime,
    startTime,
    exactLocation: null
  }
}

export class Shift {
  constructor ({ id, endDate, endTime, eventCodeType, exactLocation, isDesiderata, pause, periodCode, shiftPreset, startDate, startTime }) {
    this.id = id ?? -1
    this.endDate = getTimezoneLessMoment(endDate)
    this.endTime = endTime ?? '00:00:00'
    this.eventCodeType = isObject(eventCodeType) ? eventCodeType : null
    this.exactLocation = exactLocation ?? null
    this.isDesiderata = isDesiderata ?? false
    this.pause = pause ?? 0
    this.periodCode = periodCode ?? null
    this.shiftPreset = shiftPreset ?? null
    this.startDate = getTimezoneLessMoment(startDate)
    this.startTime = startTime ?? '00:00:00'
  }

  toRequestBody () {
    return {
      eventCodeType: this.eventCodeType,
      endDate: this.endDate.format(API_DATE_FORMAT),
      endTime: this.endTime ? this.endTime.format(FULL_TIME_FORMAT) : this.endTime,
      isDesiderata: this.isDesiderata,
      pause: this.pause,
      periodCode: this.periodCode,
      startTime: this.startTime ? this.startTime.format(FULL_TIME_FORMAT) : this.startTime,
      startDate: this.startDate.format(API_DATE_FORMAT)
    }
  }

  setStartDate (date) {
    this.startDate = getTimezoneLessMoment(date)
  }

  setEndDate (date) {
    this.endDate = getTimezoneLessMoment(date)
  }

  getSchedule () {
    const startParts = this.startTime.split(':')
    const endParts = this.endTime.split(':')

    return startParts[0] + 'h' + startParts[1] + ' - ' + endParts[0] + 'h' + endParts[1]
  }

  getPauseIcon () {
    return this.shiftPreset?.shiftPause?.icon ?? null
  }

  isAbsent () {
    return this.eventCodeType && this.eventCodeType.category === ABSENCE_CATEGORY
  }
}
