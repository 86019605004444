import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { getAssistantMaccsInternshipWithContexts } from '../../../utils/api/assistant'
import moment from 'moment'
import InternshipStatePellet from '../../shared/InternshipStatePellet'
import { Collapse } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import MaccsInternshipItem from './MaccsInternshipItem'
import {
  ACCEPTED,
  ACCEPTED_UNMANAGED,
  SCHEDULE_ADDED,
  SCHEDULE_TO_VALIDATE,
  SCHEDULE_VALIDATED
} from '../../../utils/constants'

import '../../../assets/index.scss'

const Panel = Collapse.Panel

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InternshipItemsContainer = ({ filter, t, user, onInternshipSelect }) => {
  const [internships, setInternships] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (typeof filter === 'string') {
      setLoading(true)
      getAssistantMaccsInternshipWithContexts(user.assistant, { filter, contexts: ['validation-document'] }, user)
        .then(data => {
          setInternships(data)
          setLoading(false)
        })
    }
  }, [filter])

  const handleSelectInternship = id => {
    onInternshipSelect(internships.filter(i => i.id === id)[0])
  }

  return (
    <Collapse bordered={false} accordion style={loading ? { minHeight: '70vh' } : {}}>
      {loading && <div className='loading-ring' />}
      {!loading && internships.map(i => {
        const start = moment(i.startDate).format('DD/MM/YYYY')
        const end = moment(i.endDate).format('DD/MM/YYYY')
        const institution = i.institution === null ? t('Undefined institution') : i.institution.name
        const disabled = ![ACCEPTED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED].includes(i.state)

        const title = (
          <div className='flex-column'>
            {institution}<br />

            {t('From') + ' ' + start + ' ' + t('to') + ' ' + end}<br />

            <InternshipStatePellet internship={i} showLabel />
          </div>
        )

        return (
          <Panel header={title} key={i.id} disabled={disabled}>
            {!disabled &&
              <MaccsInternshipItem
                id={i.id}
                internship={i}
                onInternshipSelect={handleSelectInternship}
              />}
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default connect(mapStateToProps)(InternshipItemsContainer)
