import { Col, DatePicker, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { callFunctionIfDefined } from '../../../utils/functionHelper'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { DATE_WITHOUT_TIME, LANGUAGE_LOCALE_TAG_EN } from '../../../utils/constants'
import { getTimezoneLessMoment } from '../../../utils/momentjs'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    activeLanguage: getActiveLanguage(state.locale).code
  }
}

const InternshipDataModal = ({ activeLanguage, internship, maskClosable, studentKey, onCancel, onOk, t }) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (internship) {
      setStartDate(getTimezoneLessMoment(internship.startDate))
      setEndDate(getTimezoneLessMoment(internship.endDate))
    }
  }, [internship])

  const handleCancel = () => {
    callFunctionIfDefined(onCancel, [])
  }

  const handleOk = () => {
    const startDateString = startDate.format(DATE_WITHOUT_TIME) + '00:00:00'
    const endDateString = endDate.format(DATE_WITHOUT_TIME) + '23:59:59'
    callFunctionIfDefined(onOk, [internship, { startDate: startDateString, endDate: endDateString }])
  }

  const renderTitle = () => {
    if (internship === null) {
      return ''
    }

    if (activeLanguage === LANGUAGE_LOCALE_TAG_EN) {
      return `${internship[studentKey]?.firstname ?? t(`Unkown ${studentKey}`)}${t("'s internship")}`
    }

    return `${t("'s internship")} ${internship[studentKey]?.firstname ?? t(`unknown ${studentKey}`)}`
  }

  return (
    <Modal
      className='internship-data-modal'
      title={renderTitle()}
      visible={internship !== null}
      onCancel={handleCancel}
      onOk={handleOk}
      maskClosable={maskClosable ?? false}
    >
      <Row>
        <Col span={6}>
          {`${t('Start date')} :`}
        </Col>
        <Col span={18}>
          <DatePicker value={startDate} onChange={setStartDate} />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {`${t('End date')} :`}
        </Col>
        <Col span={18}>
          <DatePicker value={endDate} onChange={setEndDate} />
        </Col>
      </Row>
    </Modal>
  )
}

export default connect(mapStateToProps)(InternshipDataModal)
