import React, { useContext, useMemo, useState } from 'react'

import { connect } from 'react-redux'
import { addTranslationForLanguage, getActiveLanguage, getTranslate, setActiveLanguage } from 'react-localize-redux'
import { getUser, setUser } from '../../../reducers/UserReducer'
import PersonalHeader from '../../student/profilePage/PersonalHeader'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAt, faEdit, faGlobeEurope, faUser } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Dropdown, Menu, Modal } from 'antd'
import UserForm from './UserForm'
import ChangeOwnPasswordForm from './ChangeOwnPasswordForm'
import { requestWithPromise } from '../../../utils'
import { onSuccess, PATCH } from '../../../utils/apiHelper'
import { bindActionCreators } from 'redux'
import { SUPPORTED_LANGUAGE_LOCALES } from '../../../utils/constants'

import '../../../assets/profile-page.scss'
import { setLanguage } from '../../../utils/locale'

const mapStateToProps = state => ({ activeLanguage: getActiveLanguage(state.locale), t: getTranslate(state.locale), user: getUser(state.getUser) })

const mapDispatchToProps = dispatch => ({
  addTranslation: bindActionCreators(addTranslationForLanguage, dispatch),
  setActiveLanguage: bindActionCreators(setActiveLanguage, dispatch),
  setUser: bindActionCreators(setUser, dispatch)
})

const MaccsProfilePage = ({ activeLanguage, user, addTranslation, onLogout, setActiveLanguage, setUser, t }) => {
  const { profileImage } = useContext(GlobalContext)

  const [openAccountForm, setOpenAccountForm] = useState(false)
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => setOpenAccountForm(true)}> {t('Edit account informations')} </Menu.Item>
      <Menu.Item key='2' onClick={() => setOpenChangePasswordModal(true)}> {t('Change password')} </Menu.Item>
    </Menu>
  )

  const saveUser = updatedUser => {
    const data = {
      firstname: updatedUser.firstname,
      language: updatedUser.language,
      lastname: updatedUser.lastname,
      email: updatedUser.email,
      username: updatedUser.username
    }

    requestWithPromise(`/api/user/${user.id}`, PATCH, data, user).then(json => {
      if (json?.data) {
        const newUser = {
          ...user,
          firstname: json.data.firstname,
          lastname: json.data.lastname,
          email: json.data.email,
          username: json.data.username,
          language: json.data.language
        }

        if (newUser.email !== user.email || newUser.username !== user.username) {
          onLogout()
        }

        setUser(newUser)
        setLanguage({
          language: newUser.language,
          addTranslationForLanguage: addTranslation,
          setActiveLanguage,
          getActiveLanguage,
          getUser: () => { return newUser }
        })
        onSuccess(t('User updated successfully'))
      }
    }
    )
    setOpenAccountForm(false)
  }

  const resetPassword = () => {
    setOpenChangePasswordModal(false)
  }

  const languageText = useMemo(() => SUPPORTED_LANGUAGE_LOCALES.find(l => l === user.language.toLowerCase()), [user.language])

  return (
    <>
      <PersonalHeader
        person={user}
        subTitle={t(user.roles[0])}
        profileImage={profileImage}
      />
      <Card
        className='profile-page-container'
        style={{ marginTop: '1%', borderRadius: '7px' }}
      >
        <div className='account-informations-container'>
          <h2>
            {t('Account informations')}
            <Dropdown overlay={menu} trigger={['click']}>
              <Button style={{ display: 'flex', alignItems: 'center' }} type='primary' ghost>
                <FontAwesomeIcon icon={faEdit} size='1x' />
                &nbsp;{t('Configure')}&nbsp;
                <FontAwesomeIcon icon={faAngleDown} size='1x' />
              </Button>
            </Dropdown>
          </h2>
          <br />
          <div className='information'>
            <h3> <b> {[<FontAwesomeIcon key={0} icon={faAt} />, ' ', t('Email')]} </b> </h3>
            <span> {user.email} </span>
          </div>
          <div className='information'>
            <h3> <b> {[<FontAwesomeIcon key={0} icon={faUser} />, ' ', t('Username')]} </b> </h3>
            <span> {user.username} </span>
          </div>
          <div className='information'>
            <h3> <b> {[<FontAwesomeIcon key={0} icon={faGlobeEurope} />, ' ', t('Language')]} </b> </h3>
            <span> {t(languageText ? 'languages.' + languageText : 'Without language')} </span>
          </div>
        </div>
      </Card>
      <Modal
        visible={openAccountForm}
        title={t('Edit account informations')}
        onCancel={() => setOpenAccountForm(false)}
        footer={null}
        destroyOnClose
      >
        <UserForm data={user} t={t} onSave={saveUser} />
      </Modal>
      <Modal
        visible={openChangePasswordModal}
        title={t('Change password')}
        onCancel={() => setOpenChangePasswordModal(false)}
        footer={null}
        destroyOnClose
      >
        <ChangeOwnPasswordForm user={user} t={t} onSave={resetPassword} />
      </Modal>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MaccsProfilePage)
