import React, { useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Tabs } from 'antd'
import { getUser } from '../../../reducers/UserReducer'
import InternshipItemsContainer from '../../../Components/MACCS/AssistantInternshipsManager/InternshipItemsContainer'
import MaccsShiftsCalendar from '../../../Components/MACCS/AssistantShiftsManager/MaccsShiftsCalendar'

const TabPane = Tabs.TabPane

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const AssistantInternshipsManagerView = props => {
  const [selectedInternship, setSelectedInternship] = useState(null)

  return (
    <div className='assistant-internships-manager-container'>
      {selectedInternship === null &&
        <Tabs defaultActiveKey='1' animated={false} style={{ padding: '3px' }}>
          <TabPane
            key='1'
            tab={props.t('Upcoming internships')}
          >
            <InternshipItemsContainer
              filter='upcoming'
              onInternshipSelect={setSelectedInternship}
            />
          </TabPane>
          <TabPane
            key='2'
            tab={props.t('Past internships')}
          >
            <InternshipItemsContainer
              filter='past'
              onInternshipSelect={setSelectedInternship}
            />
          </TabPane>
        </Tabs>}
      {selectedInternship &&
        <MaccsShiftsCalendar
          internship={selectedInternship}
          onInternshipUpdate={setSelectedInternship}
          onClose={() => setSelectedInternship(null)}
        />}
    </div>
  )
}

export default connect(mapStateToProps)(AssistantInternshipsManagerView)
