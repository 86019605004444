import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'

import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING
} from '../../../Components/shared/SmartTable'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const SchoolSpecialityUsers = props => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.entity) {
      const newUsers = props.entity.users

      for (const user of newUsers) {
        user.role = props.t(user.roles[0])
      }

      setUsers(newUsers)
    }
  }, [props.entity])

  const handleDrawerClose = () => {
    if (typeof props.onClose === 'function') {
      setUsers([])
      props.onClose()
    }
  }

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Lastname'),
      key: 'lastname'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Firstname'),
      key: 'firstname'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Email'),
      key: 'email'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Role'),
      key: 'role'
    }
  ]

  return (
    <Drawer
      title={props.t('Speciality users')}
      width='50%'
      onClose={handleDrawerClose}
      visible={props.entity !== null}
    >
      <SmartTable
        columns={columns}
        data={users}
        loading={loading}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(SchoolSpecialityUsers)
