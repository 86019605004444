import React, { useCallback, useContext, useState } from 'react'

import { Input, Modal, Select } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faEnvelope, faGlobe, faIdBadge, faIdCard, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { onError, onSuccess } from '../../utils/apiHelper'
import ErrorMessage from '../../Components/shared/Messages/ErrorMessage'
import { getUser } from '../../reducers/UserReducer'
import { INSTITUTION_VALIDATORS, Institution } from '../../utils/entities/institution'
import { VALIDATION_FIELDS, isValid } from '../../utils/validators'

import '../../assets/form.scss'
import { createInstitution, updateInstitution } from '../../utils/api/institution'
import { ACTIONS } from '../../Components/shared/DataTable/utils/actions'

const DEFAULT_ERROR = { details: {}, title: '', visible: false, style: { borderColor: 'red' }, missingFields: [] }
const DEFAULT_STYLE = { borderColor: 'lightgray' }

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const InstitutionFormModal = ({ children, user, onSave, t }) => {
  const { countries } = useContext(GlobalContext)

  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(DEFAULT_ERROR)

  const handleSubmit = useCallback(() => {
    const errors = isValid(selectedInstitution, INSTITUTION_VALIDATORS)

    if (errors.keys.length === 0) {
      const parameters = new Institution(selectedInstitution).toRequestBody()
      const promise = selectedInstitution.id === -1 ? createInstitution(user, parameters) : updateInstitution(user, parameters, true, true)
      const action = selectedInstitution.id === -1 ? ACTIONS.CREATE : ACTIONS.EDIT

      promise.then(json => {
        if (json?.data) {
          onSuccess(t(`institution_form_modal.${action}.success`))
          setVisible(false)

          if (typeof onSave === 'function') {
            onSave(json.data, action)
          }
        }
      }).catch(e => {
        onError(t(`institution_form_modal.${action}.error`))
      })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [t, user, selectedInstitution, onSave, onError, onSuccess, setVisible, updateInstitution])

  const handleSelectedInstitution = useCallback(i => {
    setSelectedInstitution(i)
    setVisible(true)
  }, [setVisible, setSelectedInstitution])

  return (
    <>
      {React.cloneElement(children, { onSelectInstitution: handleSelectedInstitution })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
        title={t('institution_form_modal.title')}
        cancelText={t('modal.cancel_button')}
        okText={t(selectedInstitution && selectedInstitution.id === -1 ? 'modal.ok_button.create' : 'modal.ok_button.save')}
        okButtonProps={{ disabled: selectedInstitution === null }}
        afterClose={() => {
          setError(DEFAULT_ERROR)
          setSelectedInstitution(null)
        }}
      >
        {selectedInstitution !== null && (
          <div className='user-form'>
            {error.visible && (
              <ErrorMessage title={error.title} details={error.details} />
            )}
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdCard} /> {t('institution_group_form_modal.label.name')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.NAME) ? error.style : DEFAULT_STYLE}
                value={selectedInstitution.name}
                onChange={e => setSelectedInstitution({ ...selectedInstitution, name: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdBadge} /> {t('institution_group_form_modal.label.acronym')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.ACRONYM) ? error.style : DEFAULT_STYLE}
                value={selectedInstitution.acronym}
                onChange={e => setSelectedInstitution({ ...selectedInstitution, acronym: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faLocationArrow} /> {t('institution_group_form_modal.label.address')} </label>
              </div>
              <Input
                value={selectedInstitution.address}
                onChange={e => setSelectedInstitution({ ...selectedInstitution, address: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faCity} /> {t('institution_group_form_modal.label.city')}</label>
              </div>
              <Input
                value={selectedInstitution.city}
                onChange={e => setSelectedInstitution({ ...selectedInstitution, city: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faEnvelope} /> {t('institution_group_form_modal.label.zipcode')}</label>
              </div>
              <Input
                value={selectedInstitution.zipcode}
                onChange={e => setSelectedInstitution({ ...selectedInstitution, zipcode: e.target.value })}
              />
            </div>
            <div className='form-item form-select'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faGlobe} /> {t('institution_group_form_modal.label.country')}</label>
                <span> {t('form.label.required')} </span>
              </div>
              <Select
                className={error.missingFields.includes(VALIDATION_FIELDS.COUNTRY) ? 'error-input-border' : ''}
                value={selectedInstitution.country?.code ?? null}
                onChange={value => setSelectedInstitution({ ...selectedInstitution, country: { code: value } })}
              >
                {countries.map(c => {
                  return (
                    <Select.Option key={c.id} value={c.code}>
                      {c.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(InstitutionFormModal)
