import React, { useContext, useEffect, useRef, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { Select, Tooltip } from 'antd'
import DatePickerWithDisplacement from '../../../Components/shared/ShiftsManager/ShiftsManagerHeader/DatePickerWithDisplacement'
import Search from 'antd/lib/input/Search'
import { getUser } from '../../../reducers/UserReducer'
import MaccsShiftsExportButton from './MaccsShiftsExportButton'
import { INSTITUTION_MACCS_CONTEXT } from '../../../utils/constants'
import { fetchInstitutionSchools } from '../../../utils/api/institution'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const Header = ({ user, onDateChange, onFiltersChange, t }) => {
  const { currentDate, eventCodes, specialities, setCurrentDate } = useContext(GlobalContext)

  const [filter, setFilter] = useState('')
  const [schools, setSchools] = useState([])
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [selectedSpeciality, setSelectedSpeciality] = useState(null)

  const timer = useRef(null)

  useEffect(() => {
    if (user.context === INSTITUTION_MACCS_CONTEXT) {
      fetchInstitutionSchools(user.institutions[0], user).then(json => {
        if (json?.data) {
          setSchools(json.data)
        }
      })
    }
  }, [user.context])

  useEffect(() => setSelectedSchool(user.school?.id ?? null), [user.school])

  const handleFilterChange = value => {
    setFilter(value)
    clearTimeout(timer.current)

    timer.current = setTimeout(() => {
      const filters = { search: value }

      if (selectedSpeciality) {
        filters.speciality = selectedSpeciality.id
      }

      onFiltersChange(filters)
    }, 500)
  }

  const handleSchoolSelect = (value, option) => {
    const filters = { search: filter }

    if (value !== -1) {
      filters.school = value
    }

    if (selectedSpeciality) {
      filters.speciality = selectedSpeciality.id
    }

    setSelectedSchool(option.props.data)
    onFiltersChange(filters)
  }

  const handleSpecialitySelect = (value, option) => {
    const filters = { search: filter }

    if (value !== -1) {
      filters.speciality = value
    }

    if (selectedSchool && user.context === INSTITUTION_MACCS_CONTEXT) {
      filters.school = selectedSchool.id
    }

    setSelectedSpeciality(option.props.data)
    onFiltersChange(filters)
  }

  const handleOptionsFilter = (input, option, key) => {
    let match = false

    if (option.props.data) {
      const fullAbbreviation = `${option.props.data[key]}`

      match = fullAbbreviation.toLowerCase().includes(input.toLowerCase())
    } else if (input === '/' && option.data === null) {
      match = true
    }

    return match
  }

  return (
    <div className='maccs-shiftsmanager-header-container'>
      <div className='actions'>
        <Search
          placeholder={t('Filter internships')}
          style={{ width: 175, marginRight: 10 }}
          onChange={e => handleFilterChange(e.target.value)}
        />
        {user.context === INSTITUTION_MACCS_CONTEXT && (
          <Select
            value={selectedSchool?.id ?? -1}
            style={{ width: 175, marginRight: 10 }}
            onSelect={handleSchoolSelect}
            filterOption={(input, option) => handleOptionsFilter(input, option, 'name')}
            showSearch
          >
            <Select.Option value={-1} data={null}>
            /
            </Select.Option>
            {schools.map((s, index) => {
              return (
                <Select.Option key={'option_' + index} value={s.id} data={s}>
                  {s.name}
                </Select.Option>
              )
            })}
          </Select>
        )}
        <Select
          value={selectedSpeciality?.id ?? -1}
          style={{ width: 175, marginRight: 10 }}
          onSelect={handleSpecialitySelect}
          filterOption={(input, option) => handleOptionsFilter(input, option, 'abbreviation')}
          showSearch
        >
          <Select.Option value={-1} data={null}>
              /
          </Select.Option>
          {specialities.map((ss, index) => {
            const fullName = `${ss.name}`
            const fullAbbrevation = `${ss.abbreviation}`

            return (
              <Select.Option key={'option_' + index} title={fullName} value={ss.id} data={ss}>
                {fullAbbrevation}
              </Select.Option>
            )
          })}
        </Select>
        <MaccsShiftsExportButton date={currentDate} filters={{ search: filter, speciality: selectedSpeciality?.id }} />
        <div className='flex-fill' />
        <DatePickerWithDisplacement
          startDate={currentDate}
          onDateChange={date => {
            onDateChange()
            setCurrentDate(date)
          }}
        />
      </div>
      <div className='legends'>
        {t('Available event codes : ')}
        {eventCodes.map((e, index) => {
          return (
            <Tooltip key={'code_' + index} title={t('event.code.label.' + e.type)}>
              <b> {index !== 0 && ', '} {t('event.code.' + e.type)} </b>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Header)
