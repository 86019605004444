import React, { useState, useEffect } from 'react'

import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { LANGUAGE_TAG_FR, LANGUAGE_TAG_NL } from '../../utils/constants'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    activeLanguage: getActiveLanguage(state.locale).code
  }
}

const TableFooter = ({ activeLanguage, className, metaData, onPageChange, t }) => {
  const [entriesText, setEntriesText] = useState('')
  const { page, last, totalPages } = metaData

  useEffect(() => {
    const { page, perPage, total } = metaData
    const firstEntry = (page - 1) * perPage + 1
    let lastEntry = page * perPage

    if (lastEntry > total) {
      lastEntry = total
    }

    setEntriesText(getEntriesText({ firstEntry, lastEntry, total }, activeLanguage))
  }, [metaData, activeLanguage])

  const getEntriesText = ({ total, firstEntry, lastEntry }, activeLanguage) => {
    if (total === 1) {
      return t('Showing of the only entry')
    }

    if (activeLanguage === LANGUAGE_TAG_FR) {
      return `Affichage de  ${firstEntry} à ${lastEntry} des ${total} entrées`
    }

    if (activeLanguage === LANGUAGE_TAG_NL) {
      return `Weergave van ${firstEntry} tot ${lastEntry} van de ${total} vermeldingen`
    }

    return `Showing ${firstEntry} to ${lastEntry} of ${total} entries`
  }

  return (
    <div className={`flex-row footer hidden-sigleton-data ${className}`}>
      <div>{entriesText}</div>
      <div className='flex-fill' />
      <div className='flex-row'>
        <Tooltip placement='top' title={t('First page')}>
          <Button
            type='default'
            size='small'
            disabled={last === 1 || page === 1}
            onClick={() => onPageChange(1)}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Previous page')}>
          <Button
            type='default'
            size='small'
            disabled={page === 1}
            onClick={() => onPageChange(page - 1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <div className='page-index'>
          {page}/{totalPages}
        </div>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Next page')}>
          <Button
            type='default'
            size='small'
            disabled={totalPages <= 1 || page === totalPages}
            onClick={() => onPageChange(page + 1)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={t('Last page')}>
          <Button
            type='default'
            size='small'
            disabled={totalPages <= 1 || page === totalPages}
            onClick={() => onPageChange(totalPages)}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TableFooter)
