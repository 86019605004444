import React from 'react'

import { Button, DatePicker, Tooltip } from 'antd'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { getFirstMondayOfMonth } from '../../../utils/momentjs'
import { getTranslate } from 'react-localize-redux'

const WeekPicker = DatePicker.WeekPicker

const TOOLTIP_TEXT_CHEVRON_LEFT = 'Go back one month'
const TOOLTIP_TEXT_CHEVRON_RIGHT = 'Go forward one month'
const DATE_PICKER_STYLE = { width: '125px' }
const NAVIGATION_PERIOD = 'month'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const MonthNavigator = ({ date, dateFormat, loading, onDateChange, t }) => {
  const handleChevronClick = navigationQuantity => {
    handleChange(getFirstMondayOfMonth(date.clone().add(navigationQuantity, NAVIGATION_PERIOD)))
  }

  const handleChange = newDate => {
    if (typeof onDateChange === 'function') {
      onDateChange(newDate.startOf('isoWeek').hours(0).minutes(0).seconds(0))
    }
  }

  return (
    <div className='flex-row'>
      <Tooltip placement='top' title={t(TOOLTIP_TEXT_CHEVRON_LEFT)}>
        <Button
          onClick={() => handleChevronClick(-1)}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </Tooltip>
      <div className='h-spacing' />
      <WeekPicker
        allowClear={false}
        onChange={handleChange}
        format={dateFormat}
        value={date}
        style={DATE_PICKER_STYLE}
        disabled={loading}
      />
      <div className='h-spacing' />
      <Tooltip placement='top' title={t(TOOLTIP_TEXT_CHEVRON_RIGHT)}>
        <Button
          onClick={() => handleChevronClick(1)}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Tooltip>
    </div>
  )
}

export default connect(mapStateToProps)(MonthNavigator)
