import React, { useState, useEffect, useContext } from 'react'

import { MaccsInternshipContext } from '../../../../Context/InternshipsManager/MaccsInternshipProvider'
import { MaccsInternshipsDataContext } from '../../../../Context/InternshipsManager/MaccsInternshipsDataProvider'
import { canonizeString, recursiveIncludes } from '../../../../utils'
import { ACCEPTED_BY_INSTITUTION_STATES, internshipStates, ROLE_ADMIN_RESEAU, ROLE_CLINICAL_SECRETARY, ROLE_PRESI_MASTER } from '../../../../utils/constants'
import AssistantsSelector from '../../../shared/Selectors/AssistantsSelector'
import Selector from '../../../shared/Selectors/Selector'

import '../assets/maccs-internships-manager.scss'

const { ACCEPTED, CANCELED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE } = internshipStates
const DISABLING_STATES = [ACCEPTED, CANCELED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE]

const MaccsSchoolInternshipData = ({ internship, students, schoolSections }) => {
  const { institutions, assistants, schoolSpecialities, specialities, user, workingRegimes, t } = useContext(MaccsInternshipsDataContext)
  const { onUpdate } = useContext(MaccsInternshipContext)

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (internship) {
      setDisabled(getDisablingState(internship))
    }
  }, [internship])

  const getDisablingState = internship => {
    return DISABLING_STATES.includes(internship.state)
  }

  const handleStudentAndYearChange = (value, data) => {
    if (data.assistant) {
      data.assistant = data.assistant.id
    }

    if (data.schoolSpeciality) {
      data.schoolSpeciality = data.schoolSpeciality.id
    }

    onUpdate(data)
  }

  const handleInstitutionChange = value => {
    onUpdate({ institution: value === -1 ? null : value })
  }

  const handleSpecialitySelect = value => {
    onUpdate({ speciality: value === -1 ? null : value })
  }

  const handleWorkingRegimeSelect = value => {
    onUpdate({ workingRegime: value === -1 ? null : value })
  }

  const getAssistantValue = internship => {
    if (internship.assistant) {
      return internship.assistant.id
    }

    if (internship.schoolSpeciality) {
      return -internship.schoolSpeciality.id
    }

    return 0
  }

  const handleAssistantFilter = (input, option) => {
    return canonizeString(option.props.filter).includes(canonizeString(input))
  }

  return (
    <div className='flex-column internship-info-old'>
      <div className='macs-internship-assistant-wrapper'>
        <AssistantsSelector
          disabled={disabled}
          assistants={assistants}
          schoolSpecialities={schoolSpecialities}
          value={getAssistantValue(internship)}
          onSelect={handleStudentAndYearChange}
          onSearch={handleAssistantFilter}
        />
        <Selector
          data={workingRegimes}
          dataKey='name'
          disabled={disabled}
          onSelect={handleWorkingRegimeSelect}
          showArrow={false}
          showEmpty
          showSearch
          value={internship.workingRegime ? internship.workingRegime.id : -1}
          onBodyRendered={d => t(d.name)}
        />
      </div>
      <div className='maccs-internship-institution-wrapper'>
        <Selector
          data={institutions ?? []}
          dataKey='name'
          disabled={disabled}
          onSelect={handleInstitutionChange}
          showArrow={false}
          showEmpty
          showSearch
          value={internship.institution ? internship.institution.id : -1}
        />
        {specialities && (
          <Selector
            data={specialities}
            dataKey='abbreviation'
            disabled={
              disabled ||
              specialities.length === 0 ||
              !recursiveIncludes(user.roles, [ROLE_ADMIN_RESEAU, ROLE_PRESI_MASTER, ROLE_CLINICAL_SECRETARY]) ||
              ACCEPTED_BY_INSTITUTION_STATES.includes(internship.state)
            }
            onSelect={handleSpecialitySelect}
            showArrow={false}
            showEmpty
            showSearch
            value={internship.speciality ? internship.speciality.id : -1}
          />
        )}
      </div>
    </div>
  )
}

export default MaccsSchoolInternshipData
