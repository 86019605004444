import React, {
  createContext, useContext, useEffect, useState
} from 'react'
import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import { MACCS_CONTEXT, MACCS_ENVIRONMENT, NURSES_CONTEXT, ROLE_INSTITUTION_GROUP_ADMIN } from '../../utils/constants'
import InternshipsDataProvider, { InternshipsDataContext } from './InternshipsDataProvider'
import InternshipProvider, { InternshipContext } from './InternshipProvider'
import MaccsInternshipsDataProvider, { MaccsInternshipsDataContext } from './MaccsInternshipsDataProvider'
import MaccsInternshipProvider, { MaccsInternshipContext } from './MaccsInternshipProvider'
import { DAY, MONTH } from '../../utils/momentjs'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { getDataReady } from '../../reducers/DataReadyReducer'
import { getTaggedEntities } from '../../reducers/TagsReducer'
import { getTranslate } from 'react-localize-redux'
import { bindActionCreators } from 'redux'
import { setInternshipForChat } from '../../reducers/NewMessagesReducer'
import { getColumnsDates } from '../../Components/shared/InternshipsManager/utils/utils'
import StudentInfo from '../../Components/shared/StudentInfo'
import InternshipInfo from '../../Components/shared/InternshipInfo'
import { getAppStates } from '../../reducers/AppStates/actions'

const EMPTY_PROVIDER = {
  Provider: null,
  context: null
}
export const InternshipsManagerContext = createContext()

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser),
  currentDate: getAppStates(state).date,
  getDataReady: getDataReady(state.getDataReady),
  getTaggedEntities: getTaggedEntities(state.tags)
})

const mapDispatchToProps = dispatch => ({ setChat: bindActionCreators(setInternshipForChat, dispatch) })

const InternshipsManagerProvider = ({
  addMode, children, quotas, t, user, currentDate, getDataReady, onEarlyStartDate, onStudentSelected, onInternshipSelected, onInternshipDelete,
  refreshInternships, refreshQuotas, setChat, setInternshipToValidate, internships, weeksShownCount
}) => {
  const { environment } = useContext(GlobalContext)

  const [dataProvider, setDataProvider] = useState(EMPTY_PROVIDER)
  const [internshipProvider, setInternshipProvider] = useState(EMPTY_PROVIDER)
  const [periodType, setPeriodType] = useState(DAY)
  const [internshipToEdit, setInternshipToEdit] = useState(null)
  const [dateColumns, setDateColumns] = useState([])
  const [highlightedIndexes, setHighlightedIndexes] = useState(null)
  const [student, setStudent] = useState(null)
  const [internship, setInternship] = useState(null)

  useEffect(() => {
    if (user && user.context && user?.roles) {
      setDataProvider(getDataProvider(user.context, user.roles, environment))
      setInternshipProvider(getInternshipProvider(user.context, user.roles, environment))
      setPeriodType(MACCS_CONTEXT.includes(user.context) || (user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) && environment === MACCS_ENVIRONMENT) ? MONTH : DAY)
    }
  }, [user?.context, user?.roles, environment])

  useEffect(() => setDateColumns(getColumnsDates(currentDate, weeksShownCount)), [weeksShownCount, currentDate])

  const getDataProvider = (context, roles, environment) => {
    const maccsProvider = {
      Provider: MaccsInternshipsDataProvider,
      context: MaccsInternshipsDataContext
    }
    const nursesProvider = {
      Provider: InternshipsDataProvider,
      context: InternshipsDataContext
    }

    if (roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
      return environment === MACCS_ENVIRONMENT ? maccsProvider : nursesProvider
    }

    if (NURSES_CONTEXT.includes(context)) {
      return nursesProvider
    }

    if (MACCS_CONTEXT.includes(context)) {
      return maccsProvider
    }

    return EMPTY_PROVIDER
  }

  const getInternshipProvider = (context, roles, environment) => {
    const maccsProvider = {
      Provider: MaccsInternshipProvider,
      context: MaccsInternshipContext
    }
    const nursesProvider = {
      Provider: InternshipProvider,
      context: InternshipContext
    }

    if (roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
      return environment === MACCS_ENVIRONMENT ? maccsProvider : nursesProvider
    }

    if (NURSES_CONTEXT.includes(context)) {
      return nursesProvider
    }

    if (MACCS_CONTEXT.includes(context)) {
      return maccsProvider
    }

    return EMPTY_PROVIDER
  }

  return (
    <>
      <InternshipsManagerContext.Provider value={{
        addMode,
        dataProvider,
        internshipProvider,
        dateColumns,
        currentDate,
        internships,
        internshipToEdit,
        periodType,
        quotas,
        getDataReady,
        highlightedIndexes,
        user,
        onEarlyStartDate,
        onInternshipSelected,
        onInternshipDelete,
        onLineHover: setHighlightedIndexes,
        onTableLeave: () => setHighlightedIndexes(null),
        onStudentSelected,
        refreshInternships,
        refreshQuotas,
        setChat,
        setInternshipToValidate,
        t,
        setStudent,
        setInternship,
        setInternshipToEdit
      }}
      >
        {children}
      </InternshipsManagerContext.Provider>
      <StudentInfo student={student} onClose={() => setStudent(null)} />
      <InternshipInfo internship={internship} onClose={() => setInternship(null)} />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipsManagerProvider)
