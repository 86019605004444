import React, { useCallback, useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { Drawer } from 'antd'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { ORDER_BY } from '../../../utils/constants'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { debounce } from 'lodash'
import { getInstitutionSectors } from '../../../utils/api/institution'
import IconTooltip from '../../../Components/institution/Quotas/definition/IconTooltip'
import { faClinicMedical } from '@fortawesome/free-solid-svg-icons'
import { COLORS_PALETTE } from '../../../config/colors'
import { getSchoolSpecialities } from '../../../utils/api/school'
import SpecialitiesTable from '../InstitutionsView/SpecialitiesTable'

const DEFAULT_DATA = { data: [], meta: new Metadata({ pageSize: 20 }) }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SchoolSpecialitiesDrawer = ({ school, user, width = 600, onClose, onTriggerAction, t }) => {
  const [specialities, setSpecialities] = useState(DEFAULT_DATA)
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: ORDER_BY.NAME, pageSize: 20 }))
  const [loading, setLoading] = useState(true)

  const fetchSpecialities = useCallback((school, user, tableParameters) => {
    if (school) {
      setLoading(true)

      getSchoolSpecialities(school, user, { ...tableParameters, paginate: true, excludeRoot: true }).then(json => {
        if (json?.data || json?.meta) {
          const specialities = {}

          specialities.data = json?.data ?? []
          specialities.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

          setSpecialities(specialities)
        }

        setLoading(false)
      })
    }
  }, [getInstitutionSectors, setLoading, setSpecialities])

  useEffect(() => {
    const fetch = debounce(() => fetchSpecialities(school, user, tableParameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [school, user.id, tableParameters, fetchSpecialities])

  return (
    <Drawer
      title={<div><IconTooltip icon={faClinicMedical} iconColor={COLORS_PALETTE.SECOND} title={school?.name ?? ''} /> {t('school_specialities_drawer.title')}</div>}
      visible={!!school}
      width={width}
      onClose={() => {
        setSpecialities(DEFAULT_DATA)

        if (typeof onClose === 'function') {
          onClose()
        }
      }}

    >
      <SpecialitiesTable
        data={specialities.data}
        loading={loading}
        metadata={specialities.meta}
        parameters={tableParameters}
        onParametersChange={setTableParameters}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(SchoolSpecialitiesDrawer)
