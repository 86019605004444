import React, { useState } from 'react'

import { faCheck, faCog, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { callFunctionIfDefined } from '../../utils/functionHelper'

const { Item, ItemGroup } = Menu

const DEFAULT_OPTIONS = [
  {
    title: '10 lines',
    value: 10,
    type: 'perPage'
  },
  {
    title: '20 lines',
    value: 20,
    type: 'perPage'
  },
  {
    title: '50 lines',
    value: 50,
    type: 'perPage'
  }
]

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const TableConfig = ({ columns, columnsSelector, metaData, options, onHiddenColumnsChange, onSelect, t }) => {
  const [hiddenColumns, setHiddenColumns] = useState([])

  const handleOptionSelect = (value, type) => {
    callFunctionIfDefined(onSelect, [{ value, type }])
  }

  const handleHiddenColumnClick = (column, hidden) => {
    const newColumns = !hidden
      ? [...hiddenColumns, column.title]
      : hiddenColumns.filter(t => t !== column.title)

    setHiddenColumns(newColumns)
    onHiddenColumnsChange(newColumns)
  }

  const renderOverlay = () => {
    const displayedOptions = Array.isArray(options) ? options : DEFAULT_OPTIONS
    const menuItems = [
      <ItemGroup key='data_display' title={t('Data displaying')}>
        {displayedOptions.map((o, index) => {
          return (
            <Item
              key={index}
              style={{ textAlign: 'right' }}
              onClick={() => handleOptionSelect(o.value, o.type)}
            >
              {t(o.title)}
              {metaData && metaData[o.type] && metaData[o.type] === o.value && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </Item>
          )
        })}
      </ItemGroup>
    ]
    if (columnsSelector && Array.isArray(columns) && columns.length > 0) {
      menuItems.push(
        <ItemGroup key='columns_displaying' title={t('Columns displaying')}>
          {columns.map((c, index) => {
            const hidden = hiddenColumns.includes(c.title)

            return (
              <Item
                key={index}
                style={{ textAlign: 'right' }}
                onClick={() => handleHiddenColumnClick(c, hidden)}
              >
                {t(c.title)}
                <FontAwesomeIcon icon={hidden ? faEyeSlash : faEye} />
              </Item>
            )
          })}
        </ItemGroup>
      )
    }

    return menuItems
  }

  return (
    <div className='table-config-container'>
      <Dropdown
        overlay={(
          <Menu>
            {renderOverlay()}
          </Menu>
        )}
        trigger={['click']}
      >
        <Button>
          <FontAwesomeIcon icon={faCog} />
        </Button>
      </Dropdown>
    </div>
  )
}

export default connect(mapStateToProps)(TableConfig)
