import React, { useEffect, useState } from 'react'

const DEFAULT_STYLE = { margin: '0px 5px 0px 0px', height: '15px', width: '15px' }

const SmallLoadingIcon = ({ style }) => {
  const [currentStyle, setCurrentStyle] = useState({ ...DEFAULT_STYLE })

  useEffect(() => {
    if (style) {
      setCurrentStyle(style)
    }
  }, [style])

  return (
    <div className='center'>
      <div className='loading-icon black' style={currentStyle} />
    </div>
  )
}

export default SmallLoadingIcon
