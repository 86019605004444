import React, { useEffect, useState, useContext } from 'react'

import { faCalendar, faPlus } from '@fortawesome/free-solid-svg-icons'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { MaccsInternshipsDataContext } from '../../../../Context/InternshipsManager/MaccsInternshipsDataProvider'
import { createMaccsInternship } from '../../../../utils/api/maccsInternship'
import { makeMaccsInternship } from '../../../../utils/entities/maccsInternship'
import MonthTitle from './MonthTitle'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const SchoolMonthTitle = ({ startDate, endDate, displayAction, index, user }) => {
  const { highlightedIndexes, refreshInternships } = useContext(MaccsInternshipsDataContext)

  const [isHighlighted, setIsHighlighted] = useState(false)

  useEffect(() => {
    if (typeof index !== 'undefined' && highlightedIndexes) {
      setIsHighlighted(index >= highlightedIndexes.startIndex && index <= highlightedIndexes.lastIndex)
    } else {
      setIsHighlighted(false)
    }
  }, [index, highlightedIndexes])

  const handleAction = ({ startDate, endDate }) => {
    const newInternship = makeMaccsInternship({ startDate, endDate })

    newInternship.school = user.school.id

    createMaccsInternship(newInternship.toRequestBody(), user)
      .then(json => {
        if (json && json.data) {
          refreshInternships()
        }
      })
  }

  return (
    <MonthTitle
      startDate={startDate}
      endDate={endDate}
      actionShown={displayAction}
      icon={displayAction ? faPlus : faCalendar}
      iconTitle={displayAction ? 'Add an internship' : 'Edit schedules'}
      index={index}
      highlighted={isHighlighted}
      onAction={handleAction}
    />
  )
}

export default connect(mapStateToProps)(SchoolMonthTitle)
