import { SCHOOL_ASSISTANTS } from './actionsType'
import { requestImage, requestWithPromise } from '../../utils'
import { dispatchIfData } from '../../utils/apiHelper'
import { OPTIN_TYPES } from '../../utils/constants'
import { getAllAssistants } from '../../utils/api/assistant'

const {
  SET_ASSISTANT,
  SET_ASSISTANTS,
  REMOVE_ASSISTANT,
  SET_SCHOOL_SPECIALITIES,
  SET_ASSISTANT_PROFILE_IMAGE,
  SET_EXTRA_CONTACT_INFO,
  SET_OPTIN
} = SCHOOL_ASSISTANTS

export const getSchoolAssistants = state => {
  return state.schoolAssistants
}

export const fetchAssistant = (user, setLoading) => {
  return async (dispatch, getState) => {
    requestWithPromise(
      `/api/assistants/${user.assistant.id}?details[]=specialities`, 'GET', null, user
    ).then(response => {
      dispatchIfData(dispatch, SET_ASSISTANT, response)
      setLoading(false)
    })
  }
}

export const fetchAssistantProfileImage = user => {
  return async function (dispatch, getState) {
    const response = await requestImage(
      `/api/assistants/${user.assistant.id}/profile-image`,
      'GET',
      null,
      user
    )

    dispatch({ type: SET_ASSISTANT_PROFILE_IMAGE, payload: response })
  }
}

export const fetchExtraContactInfos = user => {
  return async (dispatch, getState) => {
    requestWithPromise(
      `/api/assistants/${user.assistant.id}/extra-contact-info`, 'GET', null, user
    ).then(response => {
      dispatchIfData(dispatch, SET_EXTRA_CONTACT_INFO, response)
    })
  }
}

export const fetchOptIn = user => {
  return async (dispatch, getState) => {
    requestWithPromise(
      `/api/assistants/${user.assistant.id}/optin?type=${OPTIN_TYPES.RECRUITEMENT_DATA_USAGE}`,
      'GET',
      null,
      user
    ).then(response => {
      dispatchIfData(dispatch, SET_OPTIN, response)
    })
  }
}

export const modifyOptIn = (user, optIn, callback) => {
  return async () => {
    requestWithPromise(
      `/api/assistants/${user.assistant.id}/optin`,
      'PATCH',
      { optIn, type: OPTIN_TYPES.RECRUITEMENT_DATA_USAGE },
      user
    ).then(response => {
      callback(optIn)
    })
  }
}

export const fetchAssistants = (user, setLoading) => {
  return async (dispatch, getState) => {
    getAllAssistants(user).then(response => {
      dispatchIfData(dispatch, SET_ASSISTANTS, response)
      setLoading(false)
    })
  }
}

export const removeAssistant = ({ assistantId, user }) => {
  return async (dispatch, getState) => {
    requestWithPromise(
      `/api/assistants/${assistantId}`, 'DELETE', null, user
    ).then(response => {
      dispatchIfData(dispatch, REMOVE_ASSISTANT, { data: assistantId })
    })
  }
}

export const fetchSchoolSpecialities = user => {
  return async (dispatch, getState) => {
    requestWithPromise(
      '/api/school-specialities', 'GET', null, user
    ).then(response => {
      dispatchIfData(dispatch, SET_SCHOOL_SPECIALITIES, response)
    })
  }
}
