import { downloadFile, getJobResultWithPromise, requestMultipart, requestWithPromise } from '..'
import { GET, PATCH, POST } from '../apiHelper'
import { callFunctionIfDefined } from '../functionHelper'
import { exportValidatedMaccsInternship } from './maccsInternship'

export const generateValidationDoc = (body, user, callback, parameters = {}) => {
  exportValidatedMaccsInternship(body, user, parameters).then(job => {
    const longPollingIntervalExport = setInterval(() => {
      getJobResultWithPromise(job, user)
        .then(json => {
          if (
            (typeof json.error === 'undefined' || json.error === false) &&
            typeof json.data !== 'undefined'
          ) {
            clearInterval(longPollingIntervalExport)
            callFunctionIfDefined(callback, [false])

            const fileName = json.data.result

            try {
              downloadFile(
                `/validation-docs/download/${fileName}`,
                fileName,
                user,
                GET
              )
            } catch (err) {
              console.error(err)
              clearInterval(longPollingIntervalExport)
              callFunctionIfDefined(callback, [])
            }
          }
        }).catch(err => {
          err.json().then(json => console.error(json))
          clearInterval(longPollingIntervalExport)
          callFunctionIfDefined(callback, [true])
        })
    }, 1500)
  })
}

export const lockedMaccsInternshipDocuments = (maccsInternshipDocument, user) => {
  return requestWithPromise(
    `/maccs-internship-document/${maccsInternshipDocument.id}/locked`,
    PATCH,
    {},
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const downloadConvention = (document, user) => {
  // We only download the last document.
  try {
    downloadFile(
            `/maccsinternship/document/download/${document.id}`,
            document.originalName,
            user)
  } catch (err) {
    console.error(err)
  }
}

export const uploadDocument = (body, user) => {
  return requestMultipart(
    '/maccsinternship/document/upload',
    POST,
    body,
    user
  )
}
