import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Popover, Button } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { MACCS_CONTEXT, NURSES_CONTEXT } from '../../../utils/constants'
import { getMaccsInternshipMessagesUnreadTotal } from '../../../utils/api/maccsInternshipMessage'
import { markAllAsReadByContext } from '../../../utils/api/internshipMessageByContext'

import '../../../assets/new-messages-notification.scss'

const TIME_RELOAD_MESSAGES = 5000

const NewMessagesNotification = props => {
  const [loading, setLoading] = useState(false)
  const [totalMessagesUnread, setTotalMessagesUnread] = useState(0)

  useEffect(() => {
    if (NURSES_CONTEXT.includes(props.getUser.context)) {
      const messagesUnread = props.getUnreadMessages || 0

      setTotalMessagesUnread(messagesUnread)
    }
  }, [props.getUnreadMessages])

  useEffect(() => {
    if (MACCS_CONTEXT.includes(props.getUser.context)) {
      const runTotalsMessage = user => {
        getMaccsInternshipMessagesUnreadTotal(user).then(json => {
          if (json?.data) {
            setTotalMessagesUnread(json.data.details)
          }
        })
      }

      runTotalsMessage(props.getUser)

      const interval = setInterval(function () {
        runTotalsMessage(props.getUser)
      }, TIME_RELOAD_MESSAGES)

      return () => clearInterval(interval)
    }
  }, [])

  const markAllAsRead = async () => {
    setLoading(true)

    markAllAsReadByContext(props.getUser)
      .then(json => {
        if (NURSES_CONTEXT.includes(props.getUser.context)) {
          props.readAllMessages()
        }

        setLoading(false)
      })
  }

  const notificationButton = (
    <button
      className={`new-messages-notification-wrapper ${totalMessagesUnread > 1000 ? 'small-text' : ''} ${totalMessagesUnread === 0 ? 'disabled' : ''}`}
      type='button' onClick={() => {
        if (NURSES_CONTEXT.includes(props.getUser.context)) {
          props.onClick()
        }
      }}
    >
      <FontAwesomeIcon icon={faBell} />
      {totalMessagesUnread > 0 && <div className='unread-messages'>{totalMessagesUnread}</div>}
    </button>
  )

  if (totalMessagesUnread === 0) {
    return (
      <Tooltip placement='right' title={props.t('You don\'t have unread messages')}>
        {notificationButton}
      </Tooltip>
    )
  }
  return (
    <Popover
      placement='right' content={
        <Button
          loading={loading}
          onClick={markAllAsRead}
          type='primary'
        >
          {props.t('Mark all as read')}
        </Button>
      }
    >
      {notificationButton}
    </Popover>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMessagesNotification)
