export const getName = schoolSpeciality => {
  if (schoolSpeciality && schoolSpeciality.specialityYear) {
    return schoolSpeciality.specialityYear.specialityName
  }

  return 'Without speciality'
}

export const getFullName = schoolSpeciality => {
  if (schoolSpeciality && schoolSpeciality.specialityYear) {
    return `${schoolSpeciality.specialityYear.specialityName} (${schoolSpeciality.specialityYear.studyYearName})`
  }

  return 'Without speciality'
}

export const getAbbreviation = schoolSpeciality => {
  if (schoolSpeciality && schoolSpeciality.specialityYear) {
    return schoolSpeciality.specialityYear.specialityAbbreviation
  }

  return 'Without speciality'
}

export const getFullAbbreviation = schoolSpeciality => {
  if (schoolSpeciality && schoolSpeciality.specialityYear) {
    return `${schoolSpeciality.specialityYear.specialityAbbreviation} (${schoolSpeciality.specialityYear.studyYearName})`
  }

  return 'Without speciality'
}

export const getYear = s => {
  let year = 'Without speciality'

  if (s?.specialityYear) {
    year = s.specialityYear.studyYearName
  }

  return year
}
