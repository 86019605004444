import React, { useContext, useEffect, useState } from 'react'

import { GlobalContext } from '../../../Providers/GlobalProvider'
import MaccsShiftsManager from './MaccsShiftsManager'
import moment from 'moment'

const MaccsShiftManagerView = () => {
  const [end, setEnd] = useState(moment())
  const { currentDate } = useContext(GlobalContext)

  useEffect(() => {
    setEnd(currentDate.clone().add(3, 'week').endOf('isoWeek'))
  }, [currentDate])

  return (
    <MaccsShiftsManager
      start={currentDate}
      end={end}
    />
  )
}

export default MaccsShiftManagerView
