import { notification } from 'antd'
import moment from 'moment'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { MACCS_INTERNSHIPS_MANAGER } from '../../reducers/MaccsInternshipsManager/actionsType'
import { getUser } from '../../reducers/UserReducer'
import { getJobResult } from '../../utils/api/asynchronous'
import { acceptMaccsInternship, cancelMaccsInternship, duplicateMaccsInternship, resetToPending, updateMaccsInternship } from '../../utils/api/maccsInternship'
import { internshipStates } from '../../utils/constants'
import { onError } from '../../utils/apiHelper'

const { PENDING, ACCEPTED, CANCELED, REFUSED } = internshipStates

const TIME_INTERVAL = 500

const MaccsInternshipContext = createContext()

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

let MaccsInternshipProvider = ({ children, internship, user, onEarlyStartDate, refreshInternships, t }) => {
  const [localInternship, setLocalInternship] = useState({})
  const [overlaping, setOverlaping] = useState(false)

  const dispatch = useDispatch()
  const timeInternval = useRef()
  const updateTimer = useRef()

  useEffect(() => {
    if (internship) {
      setLocalInternship(internship)
    }
  }, [internship])

  const onUpdate = body => {
    updateMaccsInternship(localInternship, body, user)
      .then(json => {
        if (json && json.data) {
          setLocalInternship(json.data)
          notification.success({
            message: t('The internship was successfully updated'),
            placement: 'bottomRight'
          })
        }

        if (json?.status && json.status === 'OVERLAP') {
          setOverlaping(true)
        }
      })
  }

  const onPeriodUpdate = internship => {
    const { startDate, endDate } = internship

    setLocalInternship(internship)

    if (updateTimer.current) {
      clearTimeout(updateTimer.current)
    }

    updateTimer.current = setTimeout(() => {
      updateMaccsInternship(
        internship,
        { startDate: startDate, endDate: endDate }
        , user
      ).then(json => {
        if (json && json.data) {
          setLocalInternship(json.data)

          if (moment(startDate) <= moment().startOf('isoWeek')) {
            onEarlyStartDate(true)
          }
        }

        if (json?.status && json.status === 'OVERLAP') {
          setOverlaping(true)
        }
      })
    }, 1000)
  }

  const handleStateChange = state => {
    if (state === REFUSED) {
      dispatch({
        type: MACCS_INTERNSHIPS_MANAGER.SET_REFUSED_MACCS_INTERNSHIP,
        payload: localInternship
      })

      return
    }

    const action = getActionByState(state)

    if (action !== null) {
      action(localInternship, user)
        .then(json => {
          if (json && json.data) {
            refreshInternships()
          }
        })
    }
  }

  const getActionByState = state => {
    if (state === PENDING) {
      return resetToPending
    }

    if (state === ACCEPTED) {
      return acceptMaccsInternship
    }

    if (state === CANCELED) {
      return cancelMaccsInternship
    }

    return null
  }

  const handleDuplicateInternship = () => {
    duplicateMaccsInternship(localInternship, user)
      .then(json => {
        if (!json || !json.job) {
          throw new Error('The job could not be created correctly')
        }

        timeInternval.current = {
          incr: 0,
          interval: setInterval(() => {
            getJobResult(json.job, user).then(json => {
              if (timeInternval.current.incr >= 20) {
                throw new Error('Time out, the internship creation has not succeeded')
              }

              if (json && json.data) {
                clearInterval(timeInternval.current.interval)
                refreshInternships()
              }

              timeInternval.current.incr += 1
            }).catch(error => {
              console.error(error.message)
              onError(t('The internship could not be duplicated correctly.'))

              if (timeInternval.current) {
                clearInterval(timeInternval.current.interval)
              }
            })
          }, TIME_INTERVAL)
        }
      }).catch(error => {
        console.error(error.message)
        onError(t('The internship could not be duplicated correctly.'))

        if (timeInternval.current) {
          clearInterval(timeInternval.current.interval)
        }
      })
  }

  return (
    <MaccsInternshipContext.Provider value={{
      internship: localInternship,
      overlaping,
      handleStateChange,
      handleDuplicateInternship,
      onPeriodUpdate,
      onUpdate,
      setOverlaping
    }}
    >
      {children}
    </MaccsInternshipContext.Provider>
  )
}

MaccsInternshipProvider = connect(mapStateToProps)(MaccsInternshipProvider)

export {
  MaccsInternshipProvider as default,
  MaccsInternshipContext
}
