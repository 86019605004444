import React from 'react'

import { Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import MessagesButton from './MessagesButton'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const AllMessagesReadBell = ({ t, unreadMessagesCount }) => {
  return (
    <Tooltip placement='right' title={t('You don\'t have unread messages')}>
      <div>
        <MessagesButton unreadMessagesCount={unreadMessagesCount} />
      </div>
    </Tooltip>
  )
}

export default connect(mapStateToProps)(AllMessagesReadBell)
