import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import moment from 'moment'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { PRE_ACCEPTED } from '../../../utils/entities/internship'
import { ACCEPTED, AUTO_ACCEPTED, ORDER_BY, SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED } from '../../../utils/constants'
import { FULL_DATE_FORMAT } from '../../../utils/momentjs'
import { getUser } from '../../../reducers/UserReducer'
import { getInstitutionOptions } from '../../../reducers/InstitutionOptionsReducer/actions'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import useSessionStorage from '../../../hooks/UseSessionStorage'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { getAllMaccsInternships } from '../../../utils/api/maccsInternship'
import InternshipsReportingTable from './InternshipsReportingTable'
import { ACTIONS } from '../../../Components/shared/DataTable/utils/actions'
import AssistantInfo from '../../../Components/MACCS/Internships/AssistantInfo'

const AUTHORIZED_STATES = [PRE_ACCEPTED, ACCEPTED, AUTO_ACCEPTED, SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED]
const SESSION_STORAGE_SEARCH_KEY = 'maccs-internships-reporting-view.parameters'
const INITIAL_PERIOD = { startDate: moment().format(FULL_DATE_FORMAT), endDate: moment().add(4, 'weeks').format(FULL_DATE_FORMAT) }
const DEFAULT_PARAMETERS = { orderBy: ORDER_BY.EMAIL, pageSize: 20, period: INITIAL_PERIOD }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    institutionOptions: getInstitutionOptions(state.institutionOptions)
  }
}

const InternshipsReporting = ({ institutions, institutionOptions, user, t }) => {
  const { selectedInstitution } = useContext(GlobalFiltersContext)

  const [data, setData] = useState({ data: [], meta: new Metadata({ pageSize: 20 }) })
  const [rawParameters, setRawParameters] = useSessionStorage(SESSION_STORAGE_SEARCH_KEY, new TableParameters({ ...DEFAULT_PARAMETERS }))
  const [selectedAssistant, setSelectedAssistant] = useState(null)
  const [loading, setLoading] = useState(false)

  const parameters = useMemo(() => new TableParameters({ ...rawParameters, filters: { institution: selectedInstitution } }), [rawParameters, selectedInstitution])

  const fetchData = useCallback((user, parameters) => {
    setLoading(true)

    const queryParameters = parameters.toQueryParameters()

    queryParameters.states = AUTHORIZED_STATES

    getAllMaccsInternships(queryParameters, user).then(json => {
      if (json?.data || json?.meta) {
        const data = {}

        data.data = json?.data ?? []
        data.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setData(data)
      }

      setLoading(false)
    })
  }, [setLoading, setData])

  useEffect(() => {
    const fetch = debounce(() => fetchData(user, parameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [user, parameters, fetchData])

  const handleTriggerAction = useCallback((maccsInternship, action) => {
    if (action === ACTIONS.DISPLAY_ASSISTANT_SHEET) {
      setSelectedAssistant(maccsInternship.assistant)
    }
  }, [setSelectedAssistant])

  return (
    <div className='internships-reporting-container'>
      <InternshipsReportingTable
        data={data.data}
        loading={loading}
        metadata={data.meta}
        parameters={parameters}
        onParametersChange={setRawParameters}
        onTriggerAction={handleTriggerAction}
      />
      <AssistantInfo assistant={selectedAssistant} onClose={() => setSelectedAssistant(null)} />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsReporting)
