import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getAllSchools } from '../../../utils/api/school'
import SchoolsTable, { GLOBAL_INSTITUTION } from './SchoolsTable'
import { debounce } from 'lodash'
import { ACTIONS } from '../../../Components/shared/DataTable/utils/actions'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { MACCS_ENVIRONMENT, NURSE_ENVIRONMENT, ORDER_BY } from '../../../utils/constants'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'
import { onSuccess } from '../../../utils/apiHelper'
import { linkSchoolToInstitutionGroup, unlinkSchoolFromInstitutionGroup } from '../../../utils/api/institutionGroup'
import SchoolSections from '../../../Components/institution/SchoolSections'
import SchoolSpecialitiesDrawer from './SchoolSpecialitiesDrawer'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const DEFAULT_DATA = { data: [], meta: new Metadata({ pageSize: 20 }) }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SchoolsView = ({ user, t }) => {
  const { environment } = useContext(GlobalContext)
  const igContext = useContext(InstitutionGroupContext)

  const [rawSchools, setRawSchools] = useState(DEFAULT_DATA)
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: ORDER_BY.NAME, pageSize: 20 }))
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchSchools = useCallback((user, tableParameters) => {
    setLoading(true)

    getAllSchools(user, { ...tableParameters, paginate: true }).then(json => {
      if (json?.data || json?.meta) {
        const schools = {}

        schools.data = json?.data ?? []
        schools.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setRawSchools(schools)
      }

      setLoading(false)
    })
  }, [getAllSchools, setLoading, setRawSchools])

  const linkToInstitutionGroup = useCallback(school => {
    if (igContext?.institutionGroup && igContext?.setSchools) {
      linkSchoolToInstitutionGroup(user, igContext.institutionGroup, school).then(json => {
        if (json?.data) {
          onSuccess(t('schools_view.api.link_school'))
          igContext.setSchools(json.data.schools)
        }
      })
    }
  }, [user, igContext?.institutionGroup, igContext?.setSchools, t])

  const unlinkFromInstitutionGroup = useCallback(school => {
    if (igContext?.institutionGroup && igContext?.setSchools) {
      unlinkSchoolFromInstitutionGroup(user, igContext.institutionGroup, school).then(json => {
        if (json?.data) {
          onSuccess(t('schools_view.api.unlink_school'))
          igContext.setSchools(json.data.schools)
        }
      })
    }
  }, [user, igContext?.institutionGroup, igContext?.setSchools, t])

  const handleTriggerAction = useCallback((school, action) => {
    if (action === ACTIONS.LINK_INSTITUTION_GROUP_SCHOOL) {
      linkToInstitutionGroup(school)
    } else if (action === ACTIONS.UNLINK_INSTITUTION_GROUP_SCHOOL) {
      unlinkFromInstitutionGroup(school)
    } else if (action === ACTIONS.SELECT) {
      setSelectedSchool(school)
    }
  }, [linkToInstitutionGroup, unlinkFromInstitutionGroup, setSelectedSchool])

  const schools = useMemo(() => {
    const schools = { ...rawSchools }

    if (igContext?.schools) {
      schools.data = rawSchools.data.map(s => {
        return { ...s, linked: igContext.schools.some(igs => igs.id === s.id) }
      })
    }

    return schools
  }, [rawSchools, igContext?.schools])

  useEffect(() => {
    const fetch = debounce(() => fetchSchools(user, tableParameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [user.id, tableParameters, fetchSchools])

  return (
    <>
      <SchoolsTable
        context={GLOBAL_INSTITUTION}
        data={schools.data}
        help='data_table.help.missing_schools.contact_opal'
        loading={loading}
        metadata={schools.meta}
        parameters={tableParameters}
        onParametersChange={setTableParameters}
        onTriggerAction={handleTriggerAction}
      />
      {environment === NURSE_ENVIRONMENT && (
        <SchoolSections
          school={selectedSchool}
          onClose={() => setSelectedSchool(null)}
        />
      )}
      {environment === MACCS_ENVIRONMENT && (
        <SchoolSpecialitiesDrawer
          school={selectedSchool}
          onClose={() => setSelectedSchool(null)}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps)(SchoolsView)
