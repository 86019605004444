import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ContactPersonSpecialitiesInstitutionWidget from '../../../Components/shared/ContactPersonSpecialitiesInstitutionWidget'
import { fetchAllContactPersonsInstitutionMaccs, getContactPersons } from '../../../reducers/ContactPersonsReducer/actions'
import { getSchools } from '../../../reducers/SchoolsReducer'
import { getUser } from '../../../reducers/UserReducer'
import { getSpecialityYearsByInstitutionOnlyWithContactPerson } from '../../../utils/api/institution'
import ContactPersonTable from './ContactPersonTable'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser),
    getSchools: getSchools(state.getSchools),
    getContactPersons: getContactPersons(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAllContactPersonsInstitutionMaccs: bindActionCreators(fetchAllContactPersonsInstitutionMaccs, dispatch)
})

const ContactPersonsInstitutionMaccs = props => {
  const { t, getUser, getContactPersons, fetchAllContactPersonsInstitutionMaccs } = props

  const additionalActions = [
    {
      iconName: 'graduation-cap',
      type: 'primary',
      title: t('Display specialities for this user'),
      onClick: u => setSelectedContactPersonSpeciality(u)
    }
  ]

  const [contactPersons, setContactPersons] = useState([])
  const [optionsSelect, setOptionSelect] = useState([])
  const [selectValue, setSelectValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedContactPersonSpeciality, setSelectedContactPersonSpeciality] = useState(null)
  const [institution, setInstitution] = useState(null)

  useEffect(() => {
    if (getUser.institutions.length > 0 && typeof fetchAllContactPersonsInstitutionMaccs === 'function') {
      setLoading(true)
      fetchAllContactPersonsInstitutionMaccs(getUser.institutions[0].id, getUser)
      setInstitution(getUser.institutions[0].id)

      getSpecialityYearsByInstitutionOnlyWithContactPerson(getUser.institutions[0], getUser)
        .then(data => {
          data.map(specialityYear => {
            specialityYear.name = specialityYear.specialityAbbreviation + ' (' + specialityYear.studyYearName + ')'
          })

          setOptionSelect(data)
        })
    }
  }, [fetchAllContactPersonsInstitutionMaccs, getUser])

  useEffect(() => {
    if (getContactPersons) {
      try {
        setContactPersons(getContactPersons)
        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
  }, [getContactPersons])

  const handleChangeSelectSpeciality = value => {
    if (value === 'all') {
      setContactPersons(getContactPersons)
    } else {
      setContactPersons(getContactPersons.filter(cp => {
        for (const speciality of cp.contactPersonSpecialityYears) {
          if (speciality.id === value) {
            return cp
          }
        }
      }))
    }

    setSelectValue(value)
  }

  return (
    <>
      <ContactPersonTable
        contactPersons={contactPersons}
        loading={loading}
        additionalActions={additionalActions}
        onChangeSelect={handleChangeSelectSpeciality}
        allOptionText={t('All specialities')}
        selectValue={selectValue}
        optionsSelect={optionsSelect}
      />
      <ContactPersonSpecialitiesInstitutionWidget
        user={selectedContactPersonSpeciality}
        institution={institution}
        onClose={() => setSelectedContactPersonSpeciality(null)}
      />
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonsInstitutionMaccs)
