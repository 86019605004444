import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryString } from '../apiHelper'

export const getInstitution = (user, institution, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const fetchInstitutionSchools = (institution, user) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/schools`,
    GET,
    null,
    user
  )
}

export const getInstitutionSectors = (institution, user, parameters = { archived: false }) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/sectors${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getInstitutionRejectionReasons = (institution, user) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/rejection-reasons`,
    GET,
    null,
    user
  ).then(json => json.data)
}

export const getInstitutionSpecialities = (institution, user) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/school-specialities?exclude-root=true`,
    GET,
    null,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const getInstitutionSchools = (institutions, user, setLoading, setSchools, formatAdress) => {
  const promiseStack = []
  let newSchools = []

  for (const institution of institutions) {
    promiseStack.push(
      requestWithPromise(
        `/api/institutions/${institution.id}/schools`,
        GET,
        null,
        user
      ).then(json => {
        if (json && json.data) {
          newSchools = newSchools.concat(json.data)
        }
      })
    )
  }

  Promise.all(promiseStack).then(() => {
    newSchools = formatAdress(newSchools)
    setSchools(newSchools)
    setLoading(false)
  })
}

export const oldCreateInstitution = (institution, user) => {
  institution.acronym = institution.name

  return requestWithPromise(
    '/api/Institution',
    POST,
    institution,
    user
  )
}

export const oldRemoveInstitution = (institution, user) => {
  return requestWithPromise(
    `/api/Institution/${institution.id}`,
    DELETE,
    null,
    user
  )
}

export const oldUpdateInstitution = (institution, user) => {
  return requestWithPromise(
    `/api/Institution/${institution.id}`,
    PATCH,
    institution,
    user
  )
}

export const getSectorsWithContactPerson = (institution, user) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/sectors?withContactPerson=1`,
    GET,
    null,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const getSpecialityYearsByInstitutionOnlyWithContactPerson = (institution, user) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/speciality-years-with-contact-person`,
    GET,
    null,
    user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}
export const updateInstitutionOption = (institution, institutionOption, enable, user) => {
  return requestWithPromise(`/api/institutions/${institution.id}/institution-options/${institutionOption.id}`,
    'PATCH',
    { optionEnabled: enable },
    user
  )
}

export const fetchInstitutionScore = (user, institution, endDate, startDate, additionalData) => {
  return requestWithPromise(
    `/institution/${institution.id}/evaluation-internships/score?end-date=${endDate}&start-date=${startDate}&additional-data=${additionalData}`,
    GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getAllInstitutions = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getInstitutionDocuments = (user, institution) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/documents`,
    GET,
    null,
    user
  )
}

export const getInstitutionShiftPresets = (user, institution) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/shift-presets`,
    GET,
    null,
    user
  )
}

export const getInstitutionUsers = (user, institution, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/users${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const updateInstitution = (user, institution) => {
  return requestWithPromise(
    '/api/Institution/' + institution.id,
    PATCH,
    institution,
    user
  )
}

export const deleteInstitution = (user, institution, parameters = {}) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}${makeQueryString(parameters)}`,
    DELETE,
    null,
    user
  )
}

/** @deprecated 2024-01-16 */
export const deleteInstitutionOld = (user, institution) => {
  return requestWithPromise(
    '/api/Institution/' + institution.id,
    DELETE,
    null,
    user
  )
}

export const createInstitution = (user, institution) => {
  return requestWithPromise(
    '/api/institution',
    POST,
    institution,
    user
  )
}

export const getDesiderataLimitValue = (institution, user, parameters) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/desiderata-limit${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const linkSchoolToInstitution = (user, institution, school) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/schools`,
    POST,
    { school: school.id },
    user
  )
}

export const unlinkSchoolFromInstitution = (user, institution, school) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/schools/${school.id}`,
    DELETE,
    null,
    user
  )
}

export const linkAreaInstitution = (user, institution, areas) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/areas`,
    PATCH,
    { areas },
    user
  )
}

export const unLinkAreaInstitution = (user, institution, areas) => {
  return requestWithPromise(
    `/api/institutions/${institution.id}/areas${makeQueryString({ areas })}`,
    DELETE,
    null,
    user
  )
}
