import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ContactPersonSpecialitiesSchoolWidget from '../../../Components/shared/ContactPersonSpecialitiesSchoolWidget'
import { fetchAllContactPersonsSchoolMaccs, getContactPersons } from '../../../reducers/ContactPersonsReducer/actions'
import { getUser } from '../../../reducers/UserReducer'
import { getSchoolSpecialitiesOnlyWithContactPerson } from '../../../utils/api/school'
import ContactPersonTable from './ContactPersonTable'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser),
    getContactPersons: getContactPersons(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAllContactPersonsSchoolMaccs: bindActionCreators(fetchAllContactPersonsSchoolMaccs, dispatch)
})

const ContactPersonsSchoolMaccs = ({ t, getUser, getContactPersons, fetchAllContactPersonsSchoolMaccs }) => {
  const additionalActions = [
    {
      iconName: 'graduation-cap',
      type: 'primary',
      title: t('Display specialities for this user'),
      onClick: u => setSelectedContactPersonSpeciality(u)
    }
  ]

  const [contactPersons, setContactPersons] = useState([])
  const [optionsSelect, setOptionSelect] = useState([])
  const [selectValue, setSelectValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedContactPersonSpeciality, setSelectedContactPersonSpeciality] = useState(null)
  const [school, setSchool] = useState(null)

  useEffect(() => {
    if (getUser.school && typeof fetchAllContactPersonsSchoolMaccs === 'function') {
      setLoading(true)
      fetchAllContactPersonsSchoolMaccs(getUser.school.id, getUser)
      setSchool(getUser.school.id)

      getSchoolSpecialitiesOnlyWithContactPerson(getUser.school, getUser).then(data => {
        data.map(schoolSpeciality => {
          schoolSpeciality.name = schoolSpeciality.specialityYear.specialityAbbreviation + ' (' + schoolSpeciality.specialityYear.studyYearName + ')'
        })

        setOptionSelect(data)
      })
    }
  }, [fetchAllContactPersonsSchoolMaccs, getUser])

  useEffect(() => {
    if (getContactPersons) {
      try {
        setContactPersons(getContactPersons)
        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
  }, [getContactPersons])

  const handleChangeSelectSpeciality = value => {
    if (value === 'all') {
      setContactPersons(getContactPersons)
    } else {
      setContactPersons(getContactPersons.filter(cp => {
        for (const speciality of cp.contactPersonSchoolSpecialities) {
          if (speciality.id === value) {
            return cp
          }
        }
      }))
    }

    setSelectValue(value)
  }

  return (
    <>
      <ContactPersonTable
        contactPersons={contactPersons}
        loading={loading}
        additionalActions={additionalActions}
        onChangeSelect={handleChangeSelectSpeciality}
        allOptionText={t('All specialities')}
        selectValue={selectValue}
        optionsSelect={optionsSelect}
      />
      <ContactPersonSpecialitiesSchoolWidget
        user={selectedContactPersonSpeciality}
        school={school}
        onClose={() => setSelectedContactPersonSpeciality(null)}
      />
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonsSchoolMaccs)
