import React, { useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { arraySortingByKeyAndType, recursiveColumnsTesting, revertArraySortingByKeyAndType } from '../../../utils/sorting'
import BodyRow from '../../shared/Tables/BodyRow'
import TableFooter from '../../shared/Tables/TableFooter'
import TableHeader from '../../shared/Tables/TableHeader'

const ENTITY_NAME = 'SchoolSpeciality'
const DEFAULT_LINE_SHOWN = 10

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const SpecialitiesTable = ({ additionalActions, columns, data, loading, newData, readOnly, onDataSave, onDataAdd, onDataDelete, t }
) => {
  const [localData, setData] = useState([])
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)
  const [filterKey, setFilterKey] = useState(null)
  const [revertFilter, setRevertFilter] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [linesCount, setLinesCount] = useState(0)
  const [lineShown, setLineShown] = useState(DEFAULT_LINE_SHOWN)
  const [linesDensity, setLinesDensity] = useState('32px')
  const [isDataAvailable, setIsDataAvailable] = useState(false)

  useEffect(() => {
    if (data.length > 0) {
      setIsDataAvailable(true)

      setMaxPageIndex(data.length % lineShown === 0
        ? Math.floor(data.length / lineShown)
        : Math.floor(data.length / lineShown) + 1
      )

      setLinesCount(data.length)

      if (pageIndex !== 1) {
        setPageIndex(1)
      }
    }
  }, [data])

  useEffect(() => {
    if (columns && columns.length > 0) {
      setFilterKey({
        key: columns[0].filterKey ?? columns[0].key,
        type: columns[0].dataType
      })
      if (!searchKey) {
        setSearchKey(columns[0].filterKey ?? columns[0].key)
      }
    }
  }, [columns])

  useEffect(() => {
    if (data) {
      setData(filterTableData(data, filterKey, searchValue, revertFilter))
    }
  }, [data, filterKey, searchValue, searchKey, revertFilter])

  const handleFilterKeyChange = newKey => {
    if (filterKey && newKey.key === filterKey.key) {
      setRevertFilter(!revertFilter)

      return
    }

    setFilterKey(newKey)

    if (revertFilter) {
      setRevertFilter(false)
    }
  }

  const handleSearchFieldChange = searchValue => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    setSearchValue(searchValue)
  }

  const handleSearchColumnSelect = columnFilterKey => {
    setSearchKey(columnFilterKey)
  }

  const handleLineShownSelected = lineShown => {
    setLineShown(lineShown)
  }

  const handleIndexChange = increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  const handleDataSave = (data) => {
    onDataSave(data)
  }

  const handleDataDelete = (deletedData) => {
    onDataDelete(deletedData)
  }

  const getButtonsColumnsWidth = () => {
    const widthInPixel = (additionalActions.length + (onDataDelete ? 1 : 0)) * 33
    return widthInPixel + 'px'
  }

  const filterTableData = (data, filterKey, searchValue, revertFilter) => {
    const newData = data.filter(object => {
      if (!searchValue) {
        return true
      }

      return recursiveColumnsTesting(object, searchValue, columns)
    })

    if (!filterKey) {
      return newData
    }

    return arraySortingByKeyAndType(newData, filterKey.key, filterKey.type, revertFilter)
  }

  const renderContainer = () => {
    if (loading) {
      return (
        <div className='center'>
          <div className='loading-icon black' />
        </div>
      )
    }

    if (isDataAvailable || newData) {
      return (
        <table style={{ width: '100%' }}>
          <thead>
            {renderHead()}
          </thead>
          <tbody>
            {renderAddedData()}
            {isDataAvailable && renderBody()}
          </tbody>
        </table>
      )
    }

    return <span> {t('No data available for this page.')} </span>
  }

  const renderHead = () => {
    return (
      <tr>
        {columns ? renderHeadDetails() : undefined}
        {additionalActions &&
          <th style={{ width: getButtonsColumnsWidth() }} />}
      </tr>
    )
  }

  const renderHeadDetails = () => {
    return columns.map((column, index) => {
      return (
        <th
          key={index}
          className={getClassNameByFilter(column.filterKey ?? column.key)}
          onClick={() => handleFilterKeyChange({
            key: column.filterKey ?? column.key,
            type: column.dataType
          })}
        >
          {t(column.name)}
        </th>
      )
    })
  }

  const getClassNameByFilter = filter => {
    if (filter === filterKey.key) {
      return revertFilter ? 'order-down' : 'order-up'
    }

    return ''
  }

  const renderAddedData = () => {
    if (newData) {
      return (
        <BodyRow
          key='row-1'
          columns={columns}
          data={newData}
          index={-1}
          onDataSave={handleDataSave}
          validator={ENTITY_NAME}
          density={linesDensity}
        />
      )
    }

    return undefined
  }

  const renderBody = () => {
    if (columns) {
      const minIndex = lineShown * (pageIndex - 1)
      const maxIndex = minIndex + lineShown

      return localData.slice(minIndex, maxIndex).map((d, index) => {
        return (
          <BodyRow
            key={'row' + index}
            columns={columns}
            data={d}
            index={index}
            additionalActions={additionalActions}
            onDataDelete={handleDataDelete}
            onDataSave={handleDataSave}
            disable={d.parent === null || readOnly}
            validator={ENTITY_NAME}
            density={linesDensity}
          />
        )
      })
    }

    return <tr> {t('No columns have been found')} </tr>
  }

  return (
    <div className='interactive-table'>
      <TableHeader
        addButtonText='Add a speciality'
        disableAdd={readOnly}
        searchFieldPlaceholder='Search a speciality'
        onDataAdd={onDataAdd}
        onDataFilter={handleSearchFieldChange}
        onSearchColumnSelect={handleSearchColumnSelect}
        onLineShownSelected={handleLineShownSelected}
        onLineDensitySelected={setLinesDensity}
      />
      {renderContainer()}
      {isDataAvailable && (
        <TableFooter
          pageIndex={pageIndex}
          maxPageIndex={maxPageIndex}
          linesCount={linesCount}
          onIndexChange={handleIndexChange}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(SpecialitiesTable)
