import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ContactPersonSectorsWidget from '../../../Components/shared/ContactPersonSectorsWidget'
import { fetchAllContactPersonsInstitution, getContactPersons } from '../../../reducers/ContactPersonsReducer/actions'
import { getInstitutions } from '../../../reducers/InstitutionsReducer'
import { getUser } from '../../../reducers/UserReducer'
import { getSectorsWithContactPerson } from '../../../utils/api/institution'
import ContactPersonTable from './ContactPersonTable'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser),
    getInstitutions: getInstitutions(state),
    getContactPersons: getContactPersons(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAllContactPersonsInstitution: bindActionCreators(fetchAllContactPersonsInstitution, dispatch)
})

const ContactPersonsInstitution = props => {
  const { t, getUser, getInstitutions, getContactPersons, fetchAllContactPersonsInstitution } = props

  const additionalActions = [
    {
      iconName: 'stethoscope',
      type: 'primary',
      title: t('Display care units by this user'),
      onClick: u => setSelectedContactPersonSector(u)
    }
  ]

  const [contactPersons, setContactPersons] = useState([])
  const [optionsSelect, setOptionSelect] = useState([])
  const [selectValue, setSelectValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedContactPersonSector, setSelectedContactPersonSector] = useState(null)
  const [institution, setInstitutiton] = useState(null)

  useEffect(() => {
    if (getInstitutions.length > 0 && typeof fetchAllContactPersonsInstitution === 'function') {
      setLoading(true)
      fetchAllContactPersonsInstitution(getInstitutions[0].id, getUser)
      setInstitutiton(getInstitutions[0].id)

      getSectorsWithContactPerson(getInstitutions[0], getUser)
        .then(data => {
          setOptionSelect(data)
        })
    }
  }, [getInstitutions[0]?.id, fetchAllContactPersonsInstitution, getUser])

  useEffect(() => {
    if (getContactPersons) {
      try {
        setContactPersons(getContactPersons)
        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
  }, [getContactPersons])

  const handleChangeSelectSector = value => {
    if (value === 'all') {
      setContactPersons(getContactPersons)
    } else {
      setContactPersons(getContactPersons.filter(cp => {
        for (const sector of cp.contactPersonSectors) {
          if (sector.id === value) {
            return cp
          }
        }
      }))
    }

    setSelectValue(value)
  }

  return (
    <>
      <ContactPersonTable
        contactPersons={contactPersons}
        loading={loading}
        additionalActions={additionalActions}
        onChangeSelect={handleChangeSelectSector}
        allOptionText={t('All care units')}
        selectValue={selectValue}
        optionsSelect={optionsSelect}
      />
      <ContactPersonSectorsWidget
        user={selectedContactPersonSector}
        institution={institution}
        onClose={() => setSelectedContactPersonSector(null)}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonsInstitution)
