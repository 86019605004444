import React from 'react'

import FreeFieldsAdminPage from '../../../Components/institution/FreeFields/FreeFieldsAdminPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faClinicMedical, faUser } from '@fortawesome/free-solid-svg-icons'
import { Tabs } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { getInstitutionView, getUsersProvider, getUsersView } from './selectors'
import InstitutionUserSpecialitiesProvider, { InstitutionUserSpecialitiesContext } from '../../../Components/MACCS/Settings/InstitutionUserSpecialitiesProvider'
import { INSTITUTION_MACCS_CONTEXT } from '../../../utils/constants'

const { TabPane } = Tabs

const USER_TAB_NAME = 'Manage users'
const PARAMETERS_TAB_NAME = 'Institution parameters'
const FREE_FIELDS_TAB_NAME = 'Manage Free Fields'
const TAB_STYLE = { marginTop: '3px' }

const InstitutionSettings = props => {
  const specialitiesProvider = {
    Provider: InstitutionUserSpecialitiesProvider,
    context: InstitutionUserSpecialitiesContext
  }
  const userContext = props.getUser?.context
  const Users = getUsersView(userContext)
  const Institution = getInstitutionView(userContext)
  const { Provider, context } = getUsersProvider(userContext)

  return (
    <Tabs defaultActiveKey='1' animated={false}>
      <TabPane
        key='1'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon icon={faUser} style={TAB_STYLE} />
            <div className='h-spacing' />
            {props.t(USER_TAB_NAME)}
          </div>
        }
      >
        {Users !== null && Provider !== null && (
          <Provider specialitiesProvider={specialitiesProvider}>
            <Users customContext={context} />
          </Provider>
        )}
      </TabPane>
      <TabPane
        key='2'
        tab={
          <div className='flex-row'>
            <FontAwesomeIcon
              icon={faClinicMedical}
              style={TAB_STYLE}
            />
            <div className='h-spacing' />
            {props.t(PARAMETERS_TAB_NAME)}
          </div>
        }
      >
        <Institution />
      </TabPane>
      {userContext !== INSTITUTION_MACCS_CONTEXT && (
        <TabPane
          key='3'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon
                icon={faBookmark}
                style={TAB_STYLE}
              />
              <div className='h-spacing' />
              {props.t(FREE_FIELDS_TAB_NAME)}
            </div>
          }
        >
          <FreeFieldsAdminPage />
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSettings)
