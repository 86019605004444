import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { MACCS_ENVIRONMENT, ORDER_BY } from '../../../utils/constants'
import { debounce } from 'lodash'
import { deleteUser, getPaginateUsers } from '../../../utils/api/user'
import { onError, onSuccess } from '../../../utils/apiHelper'
import UserFormModal from '../../../HOC/Forms/UserFormModal'
import UsersTable from '../../shared/UsersView/UsersTable'
import AdminResetPassword from '../../../Components/shared/AdminResetPassword'
import { connect } from 'react-redux'
import { ACTIONS } from '../../../Components/shared/DataTable/utils/actions'
import SupervisedUserSpecialities from '../../../Components/MACCS/Settings/SupervisedUserSpecialities'
import InstitutionUserSpecialitiesProvider, { InstitutionUserSpecialitiesContext } from '../../../Components/MACCS/Settings/InstitutionUserSpecialitiesProvider'

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const UsersView = ({ user, t, setSelectedUser }) => {
  const { institutions } = useContext(GlobalContext)
  const { selectedInstitution } = useContext(GlobalFiltersContext)

  const [users, setUsers] = useState({ data: [], meta: new Metadata({ pageSize: 20 }) })
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: ORDER_BY.EMAIL, pageSize: 20, filters: { institution: selectedInstitution } }))
  const [selectedUserPassword, setSelectedUserPassword] = useState(null)
  const [selectedUserSpecialities, setSelectedUserSpecialities] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchUsers = (user, filters) => {
    setLoading(true)

    getPaginateUsers(user, { ...filters, environment: MACCS_ENVIRONMENT }).then(json => {
      if (json?.data || json?.meta) {
        const users = {}

        users.data = json?.data ?? []
        users.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setUsers(users)
      }

      setLoading(false)
    })
  }

  const handleDeleteUser = useCallback(userToDelete => {
    deleteUser(user, userToDelete).then(json => {
      if (json?.data) {
        onSuccess(t('user_view.actions.delete-user.success'))
        fetchUsers(user, tableParameters)
      } else {
        onError(t('user_view.actions.delete-user.error'))
      }
    })
  }, [user, tableParameters, t])

  useEffect(() => {
    const fetch = debounce(() => fetchUsers(user, tableParameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [user.institutionGroup, tableParameters])

  const handleTriggerAction = useCallback((user, action) => {
    if (action === ACTIONS.DELETE) {
      handleDeleteUser(user)
    } else if (action === ACTIONS.RESET_USER_PASSWORD) {
      setSelectedUserPassword(user)
    } else if (action === ACTIONS.DISPLAY_USER_SPECIALITIES) {
      setSelectedUserSpecialities(user)
    }
  }, [handleDeleteUser, setSelectedUserPassword, setSelectedUserSpecialities])

  return (
    <div className='users-view-container'>
      <UserFormModal
        institutions={institutions}
        onSave={() => fetchUsers(user, tableParameters)}
        onTriggerAction={handleTriggerAction}
      >
        <UsersTable
          parameters={tableParameters}
          loading={loading}
          users={users}
          onParametersChange={setTableParameters}
        />
      </UserFormModal>
      <InstitutionUserSpecialitiesProvider selectedUser={selectedUserSpecialities}>
        <SupervisedUserSpecialities
          onClose={() => setSelectedUserSpecialities(null)}
          customContext={InstitutionUserSpecialitiesContext}
        />
      </InstitutionUserSpecialitiesProvider>
      <AdminResetPassword
        userToResetPassword={selectedUserPassword}
        onClose={() => setSelectedUserPassword(null)}
      />
    </div>
  )
}

export default connect(mapStateToProps)(UsersView)
