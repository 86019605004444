import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import {
  ROLE_ADMIN, ROLE_ADMIN_HOSPITAL_RO, ROLE_ADMIN_RESEAU, ROLE_LOCAL_SECRETARY, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_PRESI_MASTER, ROLE_CLINICAL_SECRETARY,
  ROLE_INSTITUTION_GROUP_ADMIN
} from '../../utils/constants'
import { notification } from 'antd'
import { getLanguages, getTranslate } from 'react-localize-redux'
import { recursiveIncludes, requestWithPromise, validateFormInput } from '../../utils'
import { DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_SELECT, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import { deleteUser, getAllUsers, updateUser } from '../../utils/api/users'

const MaccsSettingsContext = createContext()

const DEFAULT_PERMISSIONS = {
  canCreate: false,
  canResetPassword: false,
  canDelete: false
}
const CAN_RESET_PASSWORD = [ROLE_ADMIN_RESEAU, ROLE_ADMIN, ROLE_LOCAL_SECRETARY]
const CAN_DELETE = [ROLE_ADMIN_RESEAU, ROLE_ADMIN, ROLE_LOCAL_SECRETARY]
const CAN_CREATE = [ROLE_ADMIN_RESEAU, ROLE_LOCAL_SECRETARY]

const ADMIN_RESEAU_NAME = 'Network administrator'
const PRESI_MASTER_NAME = 'Master president'
const CLINICAL_SECRETARY_NAME = 'Medical secretary'
const HUMAN_RESSOURCES_DIRECTOR_NAME = 'HR director'
const LOCAL_SECRETARY_NAME = 'Local secretary'
const INTERNSHIPS_MASTER_NAME = 'Internship supervisor'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser),
    t: getTranslate(state.locale),
    languages: getLanguages(state.locale)
  }
}

let MaccsSettingsProvider = ({ children, languages = [], specialitiesProvider, user, t }) => {
  // @TODO 2022/12/08 : Enable revive User feature
  // const [userToRevive, setUserToRevive] = useState(null)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const userPermissions = useMemo(() => {
    const permissions = { ...DEFAULT_PERMISSIONS }

    if (user) {
      const roles = user.roles ?? []

      permissions.canCreate = recursiveIncludes(CAN_CREATE, roles)
      permissions.canResetPassword = recursiveIncludes(CAN_RESET_PASSWORD, roles)
      permissions.canDelete = recursiveIncludes(CAN_DELETE, roles)
    }

    return permissions
  }, [user])
  const roles = useMemo(() => {
    const userRoles = user?.roles ?? []
    let roles = []

    if (recursiveIncludes(userRoles, [ROLE_ADMIN_RESEAU, ROLE_PRESI_MASTER, ROLE_CLINICAL_SECRETARY])) {
      roles = [
        { id: ROLE_ADMIN_RESEAU, name: t(ADMIN_RESEAU_NAME) },
        { id: ROLE_PRESI_MASTER, name: t(PRESI_MASTER_NAME) },
        { id: ROLE_CLINICAL_SECRETARY, name: t(CLINICAL_SECRETARY_NAME) }
      ]
    } else if (userRoles.includes(ROLE_LOCAL_SECRETARY) || userRoles.includes(ROLE_ADMIN_HOSPITAL_RO)) {
      roles = [
        { id: ROLE_LOCAL_SECRETARY, name: t(LOCAL_SECRETARY_NAME) },
        { id: ROLE_INTERNSHIP_MASTER_MACCS, name: t(INTERNSHIPS_MASTER_NAME) },
        { id: ROLE_ADMIN_HOSPITAL_RO, name: t(HUMAN_RESSOURCES_DIRECTOR_NAME) }
      ]
    } else if (userRoles.includes(ROLE_INTERNSHIP_MASTER_MACCS)) {
      roles = [
        { id: ROLE_INTERNSHIP_MASTER_MACCS, name: t(INTERNSHIPS_MASTER_NAME) }
      ]
    } else {
      notification.error({
        message: t('Unsupported role detected, please contact OpalSolutions.'),
        placement: 'bottomRight'
      })
    }

    return roles
  }, [user.roles, t, notification])
  const localLanguages = useMemo(() => languages.map(l => {
    return {
      id: l.code,
      name: t(l.name)
    }
  }), [languages])
  const isDisabled = useCallback(u => {
    const userRoles = user?.roles ?? []
    const readOnlyMode = recursiveIncludes(userRoles, [ROLE_ADMIN_HOSPITAL_RO, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_PRESI_MASTER, ROLE_CLINICAL_SECRETARY])
    const dataDisabled = u.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) ? t(ROLE_INSTITUTION_GROUP_ADMIN) : null

    return readOnlyMode || dataDisabled
  }, [user.roles, t])
  const columns = useMemo(() => {
    return [
      { type: DATA_TYPE_ID, key: 'id' },
      {
        type: DATA_TYPE_STRING,
        name: t('Email'),
        key: 'email',
        validate: data => validateFormInput('email', data, true),
        disabled: isDisabled
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Username'),
        key: 'username',
        validate: data => validateFormInput('email', data, true),
        disabled: isDisabled
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Lastname'),
        key: 'lastname',
        validate: data => validateFormInput('name', data, true),
        disabled: isDisabled
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Firstname'),
        key: 'firstname',
        validate: data => validateFormInput('name', data, true),
        disabled: isDisabled
      },
      {
        type: DATA_TYPE_SELECT,
        name: t('Language'),
        key: 'language',
        options: localLanguages,
        preventAutoSelectDefaultValue: true,
        validate: data => {
          return data !== null
        },
        disabled: isDisabled
      },
      {
        type: DATA_TYPE_SELECT,
        name: t('Role'),
        key: 'roles',
        options: roles,
        disabled: isDisabled
      },
      {
        type: DATA_TYPE_DATE,
        name: t('Last login'),
        key: 'lastLogin',
        format: 'DD/MM/YYYY',
        disabled: true
      }
    ]
  }, [isDisabled, roles, localLanguages, t])

  useEffect(() => {
    if (user) {
      setLoading(true)
      getAllUsers(user, getUsersCallback)
    }
  }, [user])

  const getUsersCallback = users => {
    setUsers(users.map(u => {
      if (u.roles) {
        u.roles = u.roles[0]
      }

      if (u.lastLogin) {
        u.lastLogin = u.lastLogin.split('+')[0]
      }

      return u
    }))
    setLoading(false)
  }

  const handleUserCreation = (newUser, stopTableLoading, reviveUser = false) => {
    if (newUser.email && newUser.username) {
      const userData = { ...newUser, roles: [newUser.roles] }

      userData.reviveDeletedUser = true

      requestWithPromise('/user/create', 'POST', userData, user)
        .then(json => {
          if (json.status && json.status === 'error') {
          // @TODO 8/12/2022 : enable this functionnality for MACCS users into backend
          /* if (json.code === 410) {
            setUserToRevive({ ...user })
          } */

            stopTableLoading()

            return
          }

          const newUsers = [...users]

          newUsers.push({ ...json.data, roles: json.data.roles[0] })

          setUsers(newUsers)
          stopTableLoading()
          notification.success({
            message: t('The user was successfully created'),
            placement: 'bottomRight'
          })
        })
        .catch(error => {
          console.error(error)

          if (typeof stopTableLoading !== 'undefined') {
            stopTableLoading()
          }
        })
    } else {
      stopTableLoading()
    }
  }

  const handleUserModification = (updatedUser, stopTableLoading, savingValidator = false) => {
    const userData = { ...updatedUser, roles: [updatedUser.roles] }

    updateUser(userData, user,
      updatedUser => {
        setUsers(users.map(u => {
          if (u.id === updatedUser.id) {
            updatedUser.roles = updatedUser.roles[0]

            return updatedUser
          }

          return u
        }))
        notification.success({
          message: t('The user was successfully updated'),
          placement: 'bottomRight'
        })
      },
      stopTableLoading
    )
  }

  const handleUserSuppression = deletedUser => {
    deleteUser(deletedUser.id, user,
      status => {
        setUsers(users.filter(u => u.id !== deletedUser.id))
        notification.success({
          message: t('The user was successfully deleted'),
          placement: 'bottomRight'
        })
      })
  }

  return (
    <MaccsSettingsContext.Provider value={{
      user: user,
      userPermissions,
      users,
      loading,
      SpecialitiesProvider: specialitiesProvider,
      columns,
      handleUserCreation,
      handleUserModification,
      handleUserSuppression,
      t: t
    }}
    >
      {children}
    </MaccsSettingsContext.Provider>
  )
}

MaccsSettingsProvider = connect(mapStateToProps)(MaccsSettingsProvider)

export {
  MaccsSettingsProvider as default,
  MaccsSettingsContext
}
