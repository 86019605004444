import { createSelector } from 'reselect'

const getUserManagedSectors = state => state.managedSectors

export const getUserManagedSectorIdList = createSelector(
    [getUserManagedSectors],
    (managedSectors) => {
        if (managedSectors === undefined) {

            return []    
        }

        return managedSectors.map(sector =>{
            return sector.id
        })
    }
) 