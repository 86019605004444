import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import TooltipIconButton from '../../../institution/Quotas/shared/TooltipIconButton'
import moment from 'moment'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const MonthTitle = ({ startDate, endDate, actionShown, icon, iconTitle, index, highlighted, onAction, t }) => {
  const getClassName = (currentIndex, isHighlighted) => {
    let headerClassName = 'month'
    const currentDate = moment()

    if (currentDate >= startDate && currentDate <= endDate) {
      headerClassName += ' is-current-week'
    }

    if (isHighlighted) {
      headerClassName += ' highlighted'
    }

    return headerClassName
  }

  const handleClick = () => {
    callFunctionIfDefined(
      onAction,
      [{ startDate: startDate, endDate: endDate }]
    )
  }

  return (
    <th
      className={getClassName(
        index,
        highlighted
      )}
    >
      <div>
        {t(startDate.format('MMMM'))}
      </div>
      {actionShown && (
        <TooltipIconButton
          placement='bottom'
          title={iconTitle}
          type='primary'
          size='small'
          onClick={handleClick}
          icon={icon}
        />
      )}
    </th>
  )
}

export default connect(mapStateToProps)(MonthTitle)
