import React from 'react'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { INSTITUTION_CONTEXT, INSTITUTION_MACCS_CONTEXT, SCHOOL_CONTEXT, SCHOOL_MACCS_CONTEXT } from '../../../utils/constants'
import ContactPersonsInsitution from './ContactPersonsInsitution'
import ContactPersonsSchool from './ContactPersonsSchool'
import '../../../assets/contact-persons-view.scss'
import ContactPersonsSchoolMaccs from './ContactPersonsSchoolMaccs'
import ContactPersonsInstitutionMaccs from './ContactPersonsInstitutionMaccs'

const mapStateToProps = state => {
  return {
    getUser: getUser(state.getUser)
  }
}

const ContactPersonsView = props => {
  const { getUser } = props

  return (
    <>
      {getUser && getUser.context === INSTITUTION_CONTEXT && (<ContactPersonsInsitution />)}
      {getUser && getUser.context === SCHOOL_CONTEXT && (<ContactPersonsSchool />)}
      {getUser && getUser.context === SCHOOL_MACCS_CONTEXT && (<ContactPersonsSchoolMaccs />)}
      {getUser && getUser.context === INSTITUTION_MACCS_CONTEXT && (<ContactPersonsInstitutionMaccs />)}
    </>
  )
}
export default connect(mapStateToProps)(ContactPersonsView)
