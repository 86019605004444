import React, { useState, useEffect } from 'react'

import { Modal, notification, Button, Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTranslate } from 'react-localize-redux'
import ReasonsRadioGroup from './ReasonsRadioGroup'
import { createRejectionReason, deleteRejectionReason, updateRejectionReason } from '../../../../utils/api/rejectionReason'
import { getUser } from '../../../../reducers/UserReducer'
import { refuseMaccsInternship } from '../../../../utils/api/maccsInternship'
import { connect } from 'react-redux'
import { generalErrorHandler } from '../../../../utils'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../../../shared/SmartTable'
import { getInstitutionRejectionReasons } from '../../../../utils/api/institution'

const TabPane = Tabs.TabPane

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InternshipRejectionModal = ({ internship, t, user, onClose }) => {
  const [selectedReason, setSelectedReason] = useState(null)
  const [rejectionReasons, setRejectionReasons] = useState([])
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (internship) {
      getInstitutionRejectionReasons(internship.institution, user)
        .then(setRejectionReasons)
    }
  }, [internship])

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    { type: DATA_TYPE_STRING, name: t('Reason'), key: 'name' }
  ]

  const handleDatacreation = reason => {
    if (reason.name.length === 0) {
      notification.error({ message: t('A reason must be entered.'), placement: 'bottomRight' })

      return
    }

    createRejectionReason(user.institutions[0], reason, user)
      .then(json => {
        const newRejectionReasons = [...rejectionReasons]

        newRejectionReasons.push(json.data)

        setRejectionReasons(newRejectionReasons)
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const handleDataEdition = (reason) => {
    if (reason.name.length === 0) {
      notification.error({ message: t('A reason must be entered.'), placement: 'bottomRight' })

      return
    }

    updateRejectionReason(reason, user)
      .then(json => {
        const updatedReason = json.data

        setRejectionReasons(rejectionReasons.map(r => {
          if (r.id === updatedReason.id) {
            return updatedReason
          }

          return r
        }))
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const handleDataDeletion = reason => {
    deleteRejectionReason(reason, user)
      .then(json => {
        setRejectionReasons(rejectionReasons.filter(r => r.id !== reason.id))
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const handleInternshipRejection = async () => {
    if (!selectedReason && comment.length === 0) {
      notification.error({ message: t('Please complete your rejection reason.'), placement: 'bottomRight' })

      return
    }

    setLoading(true)

    refuseMaccsInternship(internship, user)
      .then(() => {
        setLoading(false)
        handleClose(true)
        /** @todo 17-01-2023 : Enable messengers */
        /* let comment = t('Internship refused') + ' : '

        if (selectedReason === 0) {
          comment += comment
        } else {
          comment += rejectionReasons.filter(rr => rr.id === selectedReason)[0].name
        }

         sendMaccsInternshipMessage({ maccsInternship: internship.id, content: comment }, user)
          .then(() => {
            readMessage(internship.id)
            request('/internship-message/read', 'POST', { internship: internship.id }, user)

            setLoading(false)
            handleClose(true)
          }) */
      })
  }

  const handleClose = value => {
    setComment('')
    onClose(value)
  }

  return (
    <div>
      <Modal
        title={t('Refuse an internship')}
        visible={!!internship}
        onCancel={handleClose}
        footer={[
          <Button onClick={handleClose} key='cancel' type='default' loading={loading}>
            <FontAwesomeIcon icon='times' />&nbsp;{t('Cancel')}
          </Button>,
          <Button onClick={handleInternshipRejection} key='save' type='primary' loading={loading}>
            <FontAwesomeIcon icon='save' />&nbsp;{t('Save')}
          </Button>
        ]}
      >
        <Tabs defaultActiveKey='1' onChange={null} animated={false}>
          <TabPane tab={t('Refuse an internship')} key='1'>
            {t('Please select a reason to refuse this internship')} :
            <ReasonsRadioGroup
              comment={comment}
              reasons={rejectionReasons}
              selectedReason={selectedReason}
              onChange={setSelectedReason}
              onCommentChange={setComment}
            />
            <div className='v-spacing' />
            {rejectionReasons && (
              <div style={{ marginTop: '30px', color: '#9E9E9E' }}>
                {t('You can add rejection reasons in the second tab.')}
              </div>
            )}
          </TabPane>
          <TabPane tab={t('Rejection reasons')} key='2'>
            <SmartTable
              columns={columns}
              data={rejectionReasons}
              loading={loading}
              noDataText={t('You do not have any internship rejection reason.')}
              onDataAdd={handleDatacreation}
              onDataDelete={handleDataDeletion}
              onDataEdit={handleDataEdition}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipRejectionModal)
