import React, { useEffect, useState } from 'react'

import GlobalQuotaCard from './GlobalQuotaCard'
import QuotaCard from './QuotaCard'
import { DEFAULT_DRAWER_WIDTH, GLOBAL_PLACES_KEY } from '../utils/Constants'
import moment from 'moment'
import { Drawer } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'

import '../../../../assets/smart-table.scss'
import '../../../../assets/quotas.scss'
import GlobalPlacesDrawer from './GlobalPlacesDrawer'
import PauseCard from './PauseCard'
import { createQuota } from '../../../../utils/entities/quota'
import { createPauseQuota } from '../../../../utils/entities/pauseQuota'

const DEFAULT_TITLE = 'Attribute places'
const PAUSES_TITLE = 'Attribute places to pauses'

const CREATION_METHODS = ['addQuota', 'createPauseQuota']
const UPDATION_METHODS = ['updateQuota', 'updatePauseQuota']
const SUPPRESSION_METHODS = ['removeQuota', 'deletePauseQuota']

const PlacesManagingDrawer = props => {
  const { pauseActivated } = props.getAppStates

  const [total, setTotal] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [newPlaces, setNewPlaces] = useState(null)
  const [openGlobalModal, setOpenGlobalModal] = useState(false)

  useEffect(() => {
    if (props.data) {
      const { pauseActivated } = props.getAppStates
      configureHeaderAndDates(props.data, props.pauses, newPlaces, pauseActivated)
    }
  }, [props.data, props.pauses, newPlaces, props.getAppStates])

  const configureHeaderAndDates = (data, pauses, pendingPlaces, pauseActivated) => {
    let newValues = {
      total: data.total,
      remaining: data.total,
      startDate: null,
      endDate: null
    }

    newValues = decrementRemainingByState(data, pauses, newValues, pauseActivated)

    if (data.quotas) {
      data.quotas.forEach(quota => {
        if (!quota.section) {
          newValues = getGlobalValues(newValues, quota)
        }
      })
    }

    if (pendingPlaces) {
      newValues = getSectionValues(newValues, pendingPlaces)
    }

    setTotal(newValues.total)
    setRemaining(newValues.remaining)
    setStartDate(newValues.startDate)
    setEndDate(newValues.endDate)
  }

  const decrementRemainingByState = (data, pauses, newValues, pauseActivated) => {
    if (pauseActivated) {
      if (pauses && pauses.total) {
        newValues.remaining -= pauses.total
      }

      return newValues
    }

    if (data.details) {
      Object.keys(data.details).forEach(sectionId => {
        if (sectionId !== GLOBAL_PLACES_KEY) {
          newValues.remaining -= data.details[sectionId]
        }
      })
    }

    return newValues
  }

  const getGlobalValues = (values, quota) => {
    const { startDate, endDate } = values
    const quotaStartDate = moment(quota.startDate)
    const quotaEndDate = moment(quota.endDate)

    return {
      ...values,
      startDate: startDate === null || startDate < quotaStartDate ? quotaStartDate : startDate,
      endDate: endDate === null || endDate > quotaEndDate ? quotaEndDate : endDate
    }
  }

  const getSectionValues = (values, quota) => {
    const { total, remaining, startDate, endDate } = values

    return { total, remaining: remaining - quota.total, startDate, endDate }
  }

  const renderHeader = () => {
    return (
      <GlobalQuotaCard
        total={total}
        remaining={remaining}
        startDate={startDate}
        endDate={endDate}
        onPlacesAllocated={createNewPlaces}
        onGlobalPlacesDisplayed={() => setOpenGlobalModal(true)}
        allocateButtonDisable={!!newPlaces}
      />
    )
  }

  const createNewPlaces = () => {
    const newPlaces = pauseActivated
      ? createPauseQuota(props.sector, startDate, endDate)
      : createQuota(props.sector, startDate, endDate)

    setNewPlaces(newPlaces)
  }

  const handleOnClose = () => {
    setTotal(0)
    setRemaining(0)
    setStartDate(moment())
    setEndDate(moment())
    setNewPlaces(null)
    props.onClose()
  }

  const handlePlacesSave = quota => {
    const methodName = getArrayItemByBoolean(CREATION_METHODS, pauseActivated)

    props[methodName](quota, props.getUser)
    setNewPlaces(null)
  }

  const handlePlacesUpdate = quota => {
    const methodName = getArrayItemByBoolean(UPDATION_METHODS, pauseActivated)

    props[methodName](quota, props.getUser)
  }

  const handlePlacesSuppression = quota => {
    const methodName = getArrayItemByBoolean(SUPPRESSION_METHODS, pauseActivated)

    props[methodName](quota, props.getUser)
  }

  const incrementRemaining = value => {
    setRemaining(remaining + value)
  }

  const renderInnerBody = () => {
    const body = []
    const data = pauseActivated ? props.pauses : props.data
    const Card = pauseActivated ? PauseCard : QuotaCard

    if (newPlaces) {
      body.push(
        <Card
          key='quota-card-new'
          data={newPlaces}
          onDelete={() => setNewPlaces(null)}
          onSave={handlePlacesSave}
          onPlacesChanged={incrementRemaining}
        />
      )
    }

    if (data) {
      data.quotas.forEach(quota => {
        const newComponent = renderQuotaByState(quota)

        if (newComponent) {
          body.push(newComponent)
        }
      })
    }

    if (body.length === 0) {
      return (<div />)
    }

    return (
      <div className='places-managing-drawer-inner-body'>
        {body}
      </div>
    )
  }

  const renderQuotaByState = quota => {
    return pauseActivated
      ? renderPauseCard(quota)
      : renderPlaceCard(quota)
  }

  const renderPlaceCard = quota => {
    const { section, id } = quota

    if (section) {
      return (
        <QuotaCard
          key={'quota-card-' + id}
          data={quota}
          onDelete={handlePlacesSuppression}
          onSave={handlePlacesUpdate}
          onPlacesChanged={incrementRemaining}
        />
      )
    }

    return null
  }

  const renderPauseCard = quota => {
    const pauseQuota = props.getPauseQuotas[quota]

    if (pauseQuota) {
      return (
        <PauseCard
          key={'quota-card-' + pauseQuota.id}
          data={pauseQuota}
          onDelete={handlePlacesSuppression}
          onSave={handlePlacesUpdate}
          onPlacesChanged={incrementRemaining}
        />
      )
    }

    return null
  }

  const renderBody = () => {
    return (
      <div className='places-managing-drawer-body'>
        {renderHeader()}
        {renderInnerBody()}
      </div>
    )
  }

  const renderDrawerTitle = () => {
    return (
      <div className='places-creater-drawer-title'>
        <h3 style={{ textAlign: 'center', margin: '0px' }}>
          {props.t(pauseActivated ? PAUSES_TITLE : DEFAULT_TITLE)}
        </h3>
      </div>
    )
  }

  const getArrayItemByBoolean = (array, bool) => {
    if (bool) {
      return array[1]
    }

    return array[0]
  }

  return (
    <div>
      <Drawer
        title={renderDrawerTitle()}
        width={DEFAULT_DRAWER_WIDTH}
        onClose={handleOnClose}
        visible={props.visible}
      >
        {renderBody()}
      </Drawer>
      <GlobalPlacesDrawer
        visible={openGlobalModal}
        quotas={props.data?.quotas ?? []}
        onClose={() => setOpenGlobalModal(false)}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacesManagingDrawer)
