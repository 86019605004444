import { Input, Radio, Tooltip } from 'antd'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const RadioGroup = Radio.Group

const RADIO_MAX_WIDTH = '450px'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const ReasonsRadioGroup = ({ comment, reasons, selectedReason, onChange, onCommentChange, t }) => {
  return (
    <RadioGroup onChange={e => onChange(e.target.value)} value={selectedReason}>
      {reasons.map((reason, index) => (
        <Radio
          key={index}
          style={{
            display: 'block',
            overflow: 'hidden',
            maxWidth: RADIO_MAX_WIDTH,
            textOverflow: 'ellipsis'
          }}
          value={reason.id}
        >
          <Tooltip placement='left' title={reason.name}>
            {reason.name}
          </Tooltip>
        </Radio>
      ))}
      <Radio style={{ display: 'block' }} value={0}>
        <Input
          placeholder={t('Other')}
          value={comment}
          onChange={v => onCommentChange(v.target.value)}
        />
      </Radio>
    </RadioGroup>
  )
}

export default connect(mapStateToProps)(ReasonsRadioGroup)
