import React, { useEffect, useState } from 'react'

import { getActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'
import { ABSENCE_CATEGORY } from '../../../utils/entities/eventCodeType'

import '../../../assets/maccs-shifts-manager.scss'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code
  }
}

const ShiftCell = ({ currentLanguage, shift }) => {
  const [localShift, setLocalShift] = useState({ startTime: '', endTime: '', eventCode: '', class: '' })

  useEffect(() => {
    const localShift = {}

    const startTimeParts = shift.startTime.split(':')
    const endTimeParts = shift.endTime.split(':')

    localShift.startTime = startTimeParts[0] + ':' + startTimeParts[1]
    localShift.endTime = endTimeParts[0] + ':' + endTimeParts[1]
    localShift.eventCode = shift.eventCodeType?.eventCodes.find((eventCode) => eventCode.language.languageTag === currentLanguage)?.code
    localShift.class = ''

    if (shift.eventCodeType) {
      localShift.class = shift.eventCodeType.category === ABSENCE_CATEGORY ? 'absent-event-code' : 'event-code'
    }

    setLocalShift(localShift)
  }, [shift, currentLanguage])

  return (
    <div className={'maccs-shift-cell ' + localShift.class}>
      <div className='maccs-shift-cell-time'>{localShift.startTime} - {localShift.endTime}</div>
      <div className='maccs-shift-cell-code'>{localShift.eventCode}</div>
    </div>
  )
}

export default connect(mapStateToProps)(ShiftCell)
