import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import SmartTable, { DATA_TYPE_STRING } from './SmartTable'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ContactPersonSpecialityYears = ({ getContactPersons, title, user, visible, onClose, t }) => {
  const [contactPersons, setContactPersons] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (typeof getContactPersons === 'function' && user && visible) {
      setLoading(true)
      getContactPersons(user)
        .then((data) => {
          setContactPersons(data)
          setLoading(false)
        })
    }
  }, [getContactPersons, user, visible])

  const COLUMNS = [
    {
      type: DATA_TYPE_STRING,
      name: t('lastname'),
      key: 'firstname'
    },
    {
      type: DATA_TYPE_STRING,
      name: t('firstname'),
      key: 'lastname'
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Email'),
      key: 'email'
    },
    {
      type: DATA_TYPE_STRING,
      name: t('Phone number'),
      key: 'phone'
    }
  ]

  const handleClose = () => {
    setContactPersons([])
    onClose()
  }

  return (
    <Drawer
      title={title ?? ''}
      width='640px'
      onClose={handleClose}
      visible={visible}
    >
      <SmartTable
        columns={COLUMNS}
        data={contactPersons}
        loading={loading}
        noDataText={t('Without person contact')}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonSpecialityYears)
