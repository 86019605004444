import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import { Avatar, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import PersonnalFiles from './PersonnalFiles'
import ChooseProfilePictureModal from './ChooseProfilePictureModal'
import { requestMultipart } from '../../../utils'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const ACCEPTED_DOCUMENT_TYPES = ['image/jpeg']
const PATH_ASSETS = '/assets'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const PersonalHeader = props => {
  const [person, setPerson] = useState({})
  const [subTitle, setSubTitle] = useState({})
  const [entityName, setEntityName] = useState(null)
  const [newPicture, setNewPicture] = useState(null)
  const [modalVisibility, setModalVisibility] = useState(false)
  const [profileImage, setProfileImage] = useState(null)

  const uploadingImage = useRef(null)

  useEffect(() => {
    if (props.person) {
      setPerson(props.person)
      setSubTitle(props.subTitle)
      setEntityName(props.entityName)
      setProfileImage(props.profileImage)
    }
  }, [
    props.person,
    props.subTitle,
    props.entityName,
    props.profileImage
  ])

  const handleChangePicture = event => {
    const file = event.target.files[0]

    if (file && ACCEPTED_DOCUMENT_TYPES.includes(file.type)) {
      const reader = new window.FileReader()

      reader.addEventListener('load', () => {
        setNewPicture(reader.result)
        setModalVisibility(true)
      })
      reader.readAsDataURL(file)
    } else {
      notification.error({ message: props.t('Only JPEG and JPG image types are supported'), placement: 'bottomRight' })
    }
  }

  const getCroppedPicture = croppedImage => {
    const oldProfileImageUrl = { ...profileImage }

    setProfileImage(URL.createObjectURL(croppedImage))
    URL.revokeObjectURL(oldProfileImageUrl)

    if (entityName === 'Assistant') {
      requestMultipart(`/api/assistants/${person.id}/profile-image`,
        'POST',
        { file: croppedImage },
        props.getUser
      ).then(json => {})
    } else {
      requestMultipart(`/api/users/${person.id}/profile-image`,
        'POST',
        { file: croppedImage },
        props.getUser
      ).then(json => {})
    }
  }

  if (typeof person.id === 'undefined') {
    return <></>
  }

  return (
    <div className='flex-row profile-page-header' style={{ borderRadius: '7px' }}>
      <div className='profile-page-image' style={{ marginRight: '3%' }}>
        <input ref={uploadingImage} style={{ display: 'none' }} type='file' onChange={handleChangePicture} />

        <Avatar
          src={profileImage || `${PATH_ASSETS}/default_profile_img.png`}
          size={125}
          onClick={() => uploadingImage.current.click()}
        />

        <div className='profile-picture-overlay' onClick={() => uploadingImage.current.click()}>
          <FontAwesomeIcon icon={faEdit} />
        </div>
      </div>

      <div className='profile-page-title'>
        <h1>{person.firstname} {person.lastname}</h1>
        <h2>{subTitle}</h2>

        {typeof entityName !== 'undefined' && (
          <PersonnalFiles entity={person} entityName={entityName} />
        )}

        <ChooseProfilePictureModal
          image={newPicture}
          visible={modalVisibility}
          onOk={() => setModalVisibility(false)}
          onCancel={() => setModalVisibility(false)}
          getCroppedPicture={getCroppedPicture}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(PersonalHeader)
