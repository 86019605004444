import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { fetchAssistants } from '../../../reducers/SchoolAssistantsReducer/actions.js'
import { schoolAssistantsMap } from '../../../reducers/SchoolAssistantsReducer/selectors'
import SmartTable from '../../shared/SmartTable'
import { Button } from 'antd'
import { downloadAssistantMassImportTemplate } from '../../../utils/api/export'
import SmallLoadingIcon from '../../shared/LoadingIcons/SmallLoadingIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileImport } from '@fortawesome/free-solid-svg-icons'
import AssistantsImportModal from './AssistantsImportModal'
import { ROLE_ADMIN_RESEAU } from '../../../utils/constants'

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    formatedAssistants: schoolAssistantsMap(state),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAssistants: bindActionCreators(fetchAssistants, dispatch)
})

const SchoolAssistants = ({ activeLanguage, customContext, formatedAssistants, user, fetchAssistants, t }) => {
  const {
    loading, setLoading, columns, additionalActions, onDataAdd, onDataEdit, onDataDelete
  } = useContext(customContext)
  const [assistants, setAssistants] = useState([])
  const [templateLoading, setTemplateLoading] = useState(false)
  const [displayImportModal, setDisplayImportModal] = useState(false)

  useEffect(() => {
    if (user && typeof fetchAssistants === 'function') {
      fetchAssistants(user, setLoading)
    }
  }, [user, fetchAssistants])

  useEffect(() => {
    setAssistants(formatedAssistants)
  }, [formatedAssistants])

  const handleAssistantTemplateDownload = () => {
    setTemplateLoading(true)

    downloadAssistantMassImportTemplate(user, activeLanguage).then(
      () => setTemplateLoading(false)
    ).catch(() => {
      setTemplateLoading(false)
    })
  }

  return (
    <>
      {user.roles.includes(ROLE_ADMIN_RESEAU) && (
        <div className='assistants-view-header'>
          <Button onClick={handleAssistantTemplateDownload} style={{ marginRight: '10px' }}>
            <div className='flex-row' style={{ alignItems: 'center' }}>
              {templateLoading
                ? <SmallLoadingIcon />
                : <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />}
              {t('Download assistants template')}
            </div>
          </Button>
          <Button onClick={() => setDisplayImportModal(true)}>
            <div className='flex-row' style={{ alignItems: 'center' }}>
              <FontAwesomeIcon icon={faFileImport} style={{ marginRight: '5px' }} />
              {t('Import assistants')}
            </div>
          </Button>
        </div>
      )}
      <SmartTable
        columns={columns}
        data={assistants}
        onDataAdd={onDataAdd}
        onDataEdit={onDataEdit}
        onDataDelete={onDataDelete}
        loading={loading}
        noDataText={t('You do not have any assistants.')}
        addDataText={t('Add an assistant')}
        showSecondaryButtonsInFront
        additionalActions={additionalActions}
      />
      <AssistantsImportModal
        visible={displayImportModal}
        onClose={() => setDisplayImportModal(false)}
        onImportSucceed={() => fetchAssistants(user, setLoading)}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolAssistants)
