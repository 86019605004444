import React, { useState, useEffect, useRef } from 'react'
import PersonnalFiles from './PersonnalFiles'
import ChooseProfilePictureModal from './ChooseProfilePictureModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { notification, Avatar } from 'antd'
import { requestMultipart } from '../../../utils'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import 'react-image-crop/dist/ReactCrop.css'
import { schoolSectionToString } from '../../../utils/entitiesFormaters/SchoolSection'

const ACCEPTED_DOCUMENT_TYPES = ['image/jpeg']

/**
 * @deprecated
 */
const PersonnalHeader = (props) => {
  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [schoolName, setSchoolName] = useState('')
  const [profileImage, setProfileImage] = useState(props.getStudentProfileImage ? props.getStudentProfileImage : null)
  const [newPicture, setNewPicture] = useState(null)
  const [modalVisibility, setModalVisibility] = useState(false)

  const uploadingImage = useRef(null)

  /**
   * Set the student firstname and lastname in local state when the Component is mount
   */
  useEffect(() => {
    if (props.getUser) {
      setLastname(props.getUser.student ? props.getUser.student.lastname : props.getUser.lastname)
      setFirstname(props.getUser.student ? props.getUser.student.firstname : props.getUser.firstname)
    }
  }, [])

  /**
   * Set the student section name and school name in local state every time
   *  the property 'getStudentInformations' changed
   */
  useEffect(() => {
    if (props.getStudentInformations) {
      const sectionId = Object.keys(props.getStudentInformations)[0]

      if (sectionId) {
        setSectionName(schoolSectionToString(props.getStudentInformations[sectionId]))
        setSchoolName(props.getStudentInformations[sectionId].schoolName)
      }
    }
  }, [props.getStudentInformations])

  /**
   * Set the student profile picture in local state every time the property 'getStudentProfileImage' changed
   */
  useEffect(() => {
    if (props.getStudentProfileImage) {
      setProfileImage(props.getStudentProfileImage)
    }
  }, [props.getStudentProfileImage])

  /**
   * Get the cropped picture create in the crop Modal
   * Set the cropped picture as the new profile picture
   * Remove the old profile picture
   * Save the cropped picture as the new profile picture for the student in the backend
   *
   * @param {Blob} croppedImage
   */
  const getCroppedPicture = croppedImage => {
    const oldProdilePictureUrl = { ...profileImage }

    setProfileImage(URL.createObjectURL(croppedImage))
    URL.revokeObjectURL(oldProdilePictureUrl)

    const body = {
      file: croppedImage,
      id: props.getUser.student.id,
      firstname: firstname,
      lastname: lastname
    }

    requestMultipart('/profil/image', 'POST', body, props.getUser)
      .then(json => {
        const user = { ...props.getUser }

        user.student.profileImageName = json.data

        props.fetchStudentProfileImage(json.data, user)
        props.setUser(user)
      })
  }

  /**
   * Upload a new picture form the user computer
   *
   * @param {*} event
   */
  const uploadProfilePicture = event => {
    const file = event.target.files[0]

    if (file && ACCEPTED_DOCUMENT_TYPES.includes(file.type)) {
      const reader = new window.FileReader()

      reader.addEventListener('load', () => {
        setNewPicture(reader.result)
        setModalVisibility(true)
      })
      reader.readAsDataURL(file)
    } else {
      notification.error({ message: props.t('Only JPEG and JPG image types are supported.'), placement: 'bottomRight' })
    }
  }

  /**
   * Close the crop Modal
   */
  const handleModalClossing = () => {
    setModalVisibility(false)
  }

  return (
    <div className='flex-row profile-page-header'>
      <div className='profile-page-image'>
        <Avatar
          src={profileImage || props.getUser.student.profileImageName ? profileImage : '/assets/default_profile_img.png'}
          size={125}
          onClick={() => uploadingImage.current.click()}
        />
        <div
          className='profile-picture-overlay'
          onClick={() => uploadingImage.current.click()}
        >
          <FontAwesomeIcon key={1} icon={faEdit} />
        </div>
        <input ref={uploadingImage} style={{ display: 'none' }} type='file' onChange={uploadProfilePicture} />
      </div>
      <div className='profile-page-title'>
        <h1> {firstname + ' ' + lastname} </h1>
        <h2> {sectionName + ' - ' + schoolName} </h2>
        <PersonnalFiles
          entity={props.getUser.student}
        />
      </div>

      <ChooseProfilePictureModal
        image={newPicture}
        visible={modalVisibility}
        onOk={handleModalClossing}
        onCancel={handleModalClossing}
        getCroppedPicture={getCroppedPicture}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalHeader)
