import React, { useEffect, useState } from 'react'
import SimpleDataTable from './SimpleDataTable'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { Metadata, buildUrlParemeters, retrieveMetadata } from '../../utils/http'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import locale from 'antd/es/date-picker/locale/fr_FR'
import { DatePicker, Tooltip } from 'antd'
import { API_DATE_FORMAT } from '../../utils/apiHelper'
import moment from 'moment'
import SearchField from './SearchField'
import TableConfig from './TableConfig'
import ExportButton from './ExportButton'
import { FULL_DATE_FORMAT } from '../../utils/momentjs'

const { RangePicker } = DatePicker

const DEFAULT_PERIOD = {
  startDate: moment().startOf('isoWeek'),
  endDate: moment().add(1, 'month').endOf('isoWeek')
}

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const DataTable = ({ columns, currentLanguage, user, getData, apiExport, apiDownload, t }) => {
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [data, setData] = useState([])
  const [metaData, setMetadata] = useState({})
  const [period, setPeriod] = useState({ ...DEFAULT_PERIOD })
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (typeof getData === 'function' && user) {
      setLoading(true)

      const parameters = {
        page: metaData.page,
        perPage: metaData.perPage,
        search
      }

      if (period.startDate && period.endDate) {
        parameters.startDate = period.startDate.format(API_DATE_FORMAT)
        parameters.endDate = period.endDate.format(API_DATE_FORMAT)
      }

      getData(user, buildUrlParemeters(parameters)).then(json => {
        if (json && json.data) {
          setData(json.data)
        }

        if (json && json.meta) {
          setMetadata(retrieveMetadata(json.meta))
        }

        setLoading(false)
      })
    }
  }, [getData, search, period, metaData.page, metaData.perPage, user])

  const handlePageChange = newPage => {
    setMetadata({ ...metaData, page: newPage })
  }

  const handleConfigSelect = ({ value, type }) => {
    const newMetadata = new Metadata(metaData)

    newMetadata[type] = value
    newMetadata.page = 1

    setMetadata(newMetadata)
  }

  const handlePeriodChange = dates => {
    setMetadata({ ...metaData, page: 1 })
    setPeriod({ startDate: dates[0], endDate: dates[1] })
  }

  return (
    <div className='data-table'>
      <div className='data-table-header flex-row'>
        <SearchField
          className='assistant-reporting-search-field'
          placeholder='Filter internships'
          value={search}
          onChange={setSearch}
        />
        <TableConfig
          columns={columns}
          metaData={metaData}
          onSelect={handleConfigSelect}
          onHiddenColumnsChange={setHiddenColumns}
          columnsSelector
        />
        {apiExport && apiDownload && (
          <ExportButton
            apiExport={apiExport}
            apiDownload={apiDownload}
            extraParameters={{ endDate: period.endDate.format(FULL_DATE_FORMAT), startDate: period.startDate.format(FULL_DATE_FORMAT), language: currentLanguage }}
          />
        )}
        <div className='flex-fill' />
        <Tooltip
          title={t('Internships dates range')}
          placement='top'
        >
          <RangePicker
            className='assistant-reporting-range-picker'
            locale={locale}
            onChange={handlePeriodChange}
            value={[period.startDate, period.endDate]}
            format='DD/MM/YYYY'
          />
        </Tooltip>
      </div>
      <SimpleDataTable
        columns={columns.filter(c => !hiddenColumns.includes(c.title))}
        data={data}
        loading={loading}
        metaData={metaData}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(DataTable)
