import React, { useEffect, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import ContactPersonDetails from './ContactPersonDetails'
import { getContactPersons } from '../../../../utils/api/maccsInternship'
import { getUser } from '../../../../reducers/UserReducer'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsInternshipContactPersons = ({ mi, t, user }) => {
  const [loading, setLoading] = useState(true)
  const [contactPersons, setContactPersons] = useState({ school: [], institution: [] })

  useEffect(() => {
    if (mi && user) {
      setLoading(true)
      getContactPersons(mi, user)
        .then(data => {
          setContactPersons(data)
          setLoading(false)
        })
    }
  }, [mi, user])

  return (
    <div className='flex-column'>
      {loading &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='loading-icon black' />
          <div style={{ marginLeft: '4px', marginTop: '4px' }}>{t('Loading contact persons')}</div>
        </div>}
      {!loading && contactPersons.school.length === 0 && contactPersons.institution.length === 0 && t('None')}
      {!loading && contactPersons.school.length > 0 && contactPersons.school.map((cp, index) =>
        <ContactPersonDetails key={index} contactPerson={cp} type='School' />
      )}
      {!loading && contactPersons.institution.length > 0 && contactPersons.institution.map((cp, index) =>
        <ContactPersonDetails key={index} contactPerson={cp} type='Institution' />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(MaccsInternshipContactPersons)
