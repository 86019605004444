import React, { useContext, useMemo } from 'react'

import DataTable from '../../../Components/shared/DataTable/DataTable'
import Cookie from 'js-cookie'
import { ACTIONS, Action, DeleteAction } from '../../../Components/shared/DataTable/utils/actions'
import { faEdit, faStethoscope } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Institution, getAddress, getCity, getCountry, getDescription, getName, getZipcode } from '../../../utils/entities/institution'
import { MACCS_ENVIRONMENT, ORDER_BY, ROLE_INSTITUTION_GROUP_ADMIN } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InstitutionsTable = ({ data, loading, metadata, parameters, user, onParametersChange, onSelectInstitution, onTriggerAction, t }) => {
  const { environment } = useContext(GlobalContext)

  const switchUserId = useMemo(() => Cookie.get('switch_user_id'), [])
  const maxHeight = useMemo(() => `calc(100vh - ${switchUserId ? '180px' : '139px'})`, [switchUserId])
  const columns = useMemo(() => [
    {
      title: t('institutions_view.column_title.name'),
      orderBy: ORDER_BY.NAME,
      key: i => {
        const name = getName(i)

        return name.length > 50 ? `${name.slice(0, 47)}...` : name
      },
      tooltip: getName
    },
    { title: t('institutions_view.column_title.address'), orderBy: ORDER_BY.ADDRESS, key: getAddress },
    { title: t('institutions_view.column_title.zipcode'), orderBy: ORDER_BY.ZIPCODE, key: getZipcode },
    { title: t('institutions_view.column_title.city'), orderBy: ORDER_BY.CITY, key: getCity },
    { title: t('institutions_view.column_title.country'), orderBy: ORDER_BY.COUNTRY, key: getCountry },
    { title: t('institutions_view.column_title.description'), orderBy: ORDER_BY.DESCRIPTION, key: getDescription }
  ], [t])
  const actions = useMemo(() => {
    const actions = [new Action({ title: 'institutions_view.actions.edit_institution', icon: faEdit, onClick: onSelectInstitution })]

    if (!user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) || environment !== MACCS_ENVIRONMENT) {
      actions.push(new Action({
        title: 'institutions_view.actions.set_institution_sectors',
        icon: faStethoscope,
        onClick: i => onTriggerAction(i, ACTIONS.DISPLAY_INSTITUTION_SECTORS)
      }))
    }

    actions.push(new DeleteAction({ onDelete: i => onTriggerAction(i, ACTIONS.DELETE) }))

    return actions
  }, [environment, user.roles, onTriggerAction, onSelectInstitution])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => onSelectInstitution(new Institution({ managed: user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) }))}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionsTable)
