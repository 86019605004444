import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Card, Tooltip, Button, Input, Form } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'

import '../../../assets/profile-page.scss'

const NISS_TITLE = 'My NISS'
const NISS_TIPS_TEXT = 'For non-Belgian citizens, this is your social security number'

const NissForm = (props) => {
  const [niss, setNiss] = useState('')
  const [nissEditionMode, setNissEditionMode] = useState(false)
  const [pathUpdateNiss, setPathUpdateNiss] = useState(null)

  const { getFieldDecorator } = props.form

  useEffect(() => {
    if (props.person && props.pathApiEntity && niss === '') {
      setNiss(props.person.niss)
      setPathUpdateNiss(`${props.pathApiEntity}/${props.person.id}/niss`)
    } else if (props.getUser && props.getUser.student) {
      setNiss(props.getUser.student.niss)
    }
  }, [props.getUser, props.person, props.pathApiEntity])

  const handleNissSave = e => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const value = values.niss
        const body = {
          niss: value !== niss ? value : niss
        }

        setNiss(body.niss)

        if (pathUpdateNiss !== null) {
          props.updateNissByPath(body, props.getUser, pathUpdateNiss)
        } else {
          props.updateNissByStudent(body, props.getUser)
        }
      }
    })

    setNissEditionMode(!nissEditionMode)
  }

  const renderNissForm = () => {
    return (
      <Form
        name='nissForm'
        className='student-extra-contact-details'
        onSubmit={handleNissSave}
      >
        <Form.Item>
          {getFieldDecorator('niss')(
            <Input prefix={<FontAwesomeIcon icon='user' />} placeholder={niss} />
          )}
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>{props.t('Save')}</Button>
        </Form.Item>
      </Form>
    )
  }

  const content =
        nissEditionMode
          ? renderNissForm()
          : niss === '' || !niss ? props.t('Please fill out your social security number') : niss

  return (
    <div className='inner-margin' style={{ marginBottom: props.marginBottom ?? '5px' }}>
      <Card
        type='inner'
        title={[props.t(NISS_TITLE), ' ',
          <Tooltip key='personal_section_tooltip' placement='right' title={props.t(NISS_TIPS_TEXT)}>
            <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
          </Tooltip>]}
        extra={(
          <Button
            key='c'
            onClick={() => setNissEditionMode(!nissEditionMode)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        )}
      >
        <div>
          {content}
        </div>
      </Card>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NissForm))
