import SchoolUsers from '../../../Components/school/Users'
import InstitutionUsers from '../../../Components/institution/Users'
import MaccsUsers from '../../../Components/shared/MaccsUsers'
import DefaultSettingsProvider, { DefaultSettingsContext } from '../../../Context/Settings/DefaultSettingsProvider'
import MaccsSettingsProvider, { MaccsSettingsContext } from '../../../Context/Settings/MaccsSettingsProvider'
import School from '../../../Components/school/School'
import SchoolMaccs from '../../../Components/MACCS/Settings/School/School'
import { INSTITUTION_CONTEXT, INSTITUTION_MACCS_CONTEXT, MACCS_CONTEXT, NURSES_CONTEXT, SCHOOL_CONTEXT, SCHOOL_MACCS_CONTEXT } from '../../../utils/constants'
import InstitutionMaccs from '../../../Components/MACCS/Settings/Institution/Institution'
import InstitutionParameters from '../../../Components/institution/InstitutionParameters'

export const getUsersView = context => {
  if (SCHOOL_CONTEXT === context) {
    return SchoolUsers
  }

  if (INSTITUTION_CONTEXT === context) {
    return InstitutionUsers
  }

  if (MACCS_CONTEXT.includes(context)) {
    return MaccsUsers
  }

  return null
}

export const getSchoolView = context => {
  if (context === SCHOOL_MACCS_CONTEXT) {
    return SchoolMaccs
  }

  if (context === SCHOOL_CONTEXT) {
    return School
  }

  return null
}

export const getInstitutionView = context => {
  if (context === INSTITUTION_MACCS_CONTEXT) {
    return InstitutionMaccs
  }

  if (context === INSTITUTION_CONTEXT) {
    return InstitutionParameters
  }

  return null
}

export const getUsersProvider = context => {
  if (NURSES_CONTEXT.includes(context)) {
    return {
      Provider: DefaultSettingsProvider,
      context: DefaultSettingsContext
    }
  }

  if (MACCS_CONTEXT.includes(context)) {
    return {
      Provider: MaccsSettingsProvider,
      context: MaccsSettingsContext
    }
  }

  return {
    Provider: null,
    context: null
  }
}
