import appConfig from '../config'
import removeAccents from 'remove-accents'
import {
  ADMIN_CONTEXT, ASSISTANT_CONTEXT, EUROPEAN_DATE, INSTITUTION_CONTEXT, INSTITUTION_MACCS_CONTEXT, INSTITUTION_MACCS_ROLES, INSTITUTION_ROLES, MD, NONE_CONTEXT,
  ROLE_ADMIN, ROLE_ASSISTANT, ROLE_STUDENT, SCHOOL_CONTEXT, SCHOOL_MACCS_CONTEXT, SCHOOL_MACCS_ROLES, SCHOOL_ROLES, SM, STUDENT_CONTEXT, SUPPORTED_ROLES,
  XS, SUPPORTED_LANGUAGE_LOCALES
} from './constants'
import { URIEncode } from './apiHelper'
import moment from 'moment'
import { HTTP_OK } from './constants/http'
import { isArray, isString } from 'lodash'

export const LANGUAGE_NOT_SUPPORTED = 'LANGUAGE_NOT_SUPPORTED'
export const ROLE_NOT_SUPPORTED = 'ROLE_NOT_SUPPORTED'
export const NOT_A_VALID_STRING = 'NOT_A_VALID_STRING'
export const CANNOT_BE_EMPTY = 'CANNOT_BE_EMPTY'
export const INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT'

function getEnvironment () {
  const envs = [
    { url: 'localhost', env: 'DEV' },
    { url: 'feature-quota-rework-part1.review.interneo.pro', env: 'QUOTAS' },
    { url: 'sandbox.interneo.pro', env: 'SANDBOX' },
    { url: 'review.interneo.pro', env: 'SANDBOX' },
    { url: 'demo.interneo.pro', env: 'TEST' },
    { url: 'ucl.staging.interneo.pro', env: 'MACCS_STAGING_URL_PATTERN' },
    { url: 'staging.interneo.pro', env: 'STAGING' },
    { url: 'app.interneo.pro', env: 'PROD' },
    { url: 'ucl.interneo.pro', env: 'MACCS_PROD_URL_PATTERN' }
  ]

  const filteredEnvs = envs.filter(e => window.location.href.indexOf(e.url) > -1)

  if (filteredEnvs.length > 0) {
    return filteredEnvs[0].env
  }

  return 'TEST'
}

function getApiUrl () {
  if (getEnvironment() === 'DEV') return appConfig.devServer
  if (getEnvironment() === 'QUOTAS') return appConfig.quotasServer
  if (getEnvironment() === 'SANDBOX') return appConfig.sandboxServer
  if (getEnvironment() === 'TEST') return appConfig.testServer
  if (getEnvironment() === 'PROD') return appConfig.prodServer
  if (getEnvironment() === 'STAGING') return appConfig.stagingServeur
  if (getEnvironment() === 'MACCS_STAGING_URL_PATTERN') return appConfig.stagingUclServer
  if (getEnvironment() === 'MACCS_PROD_URL_PATTERN') return appConfig.prodUclServer
}

function getGoogleTrackingId () {
  if (getEnvironment() === 'DEV') return appConfig.googleTrackingIdDev
  if (getEnvironment() === 'QUOTAS') return appConfig.googleTrackingIdDev
  if (getEnvironment() === 'STAGING' || getEnvironment() === 'TEST' || getEnvironment() === 'MACCS_STAGING_URL_PATTERN' || getEnvironment() === 'MACCS_PROD_URL_PATTERN' || getEnvironment() === 'SANDBOX') return appConfig.googleTrackingIdDev
  if (getEnvironment() === 'PROD') return appConfig.googleTrackingIdProd
}

/**
 * Wraps fetch API into a direct JSON request so as to
 * spare some typing.
 *
 * @param  String path URL to send the request to.
 *
 * @return Promise
 */
function fetchJSON (path) {
  return window.fetch(`${getApiUrl()}${path}`).then(response => {
    if (response.status >= 200) {
      return Promise.reject(
        new Error(
          `Error with request to ${response.url} : [${response.status}] ${response.statusText}`
        )
      )
    }

    return response.json()
  })
}

/**
 * @deprecated
 */
function request (path, method, body, user, params) {
  if (typeof params === 'undefined') {
    params = {
      catchError: true,
      catchEntityDeletedError: true
    }
  } else {
    params = {
      catchError: typeof params.catchError === 'undefined' ? true : params.catchError,
      catchEntityDeletedError: typeof params.catchEntityDeletedError === 'undefined' ? true : params.catchEntityDeletedError
    }
  }

  path = getApiUrl() + path
  if (
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.switchUser !== 'undefined' &&
    user.switchUser !== null
  ) {
    const params = path.split('?')[1]

    if (params) {
      path = path.split('?')[0]
    }
    const switchUserParams = '?_switch_user=' + URIEncode(user.switchUser)
    path += params ? switchUserParams + '&&' + params : switchUserParams
  }
  const token =
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.token !== 'undefined' &&
    user.token !== null
      ? user.token
      : null

  const req = new window.Request(path, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: body ? JSON.stringify(body) : undefined
  })

  return window
    .fetch(req)
    .then(response => {
      if (response.status === 418) return 'password_change'
      if (response.status === 419) return 'password_change_set_optins'
      if (response.status === 420) return 'password_change_set_optins_user' // this means the current user is not also a student so we don't show the agreement for "recruitment purposes"
      if (response.status === 421) return 'set_optins'
      if (response.status === 422) return 'set_optins_user' // this means the current user is not also a student so we don't show the agreement for "recruitment purposes"
      return response.json()
    })
    .then(json => {
      if (json.code === 403 && !params.catchError) {
        return json
      }
      if (json.code >= 400 && params.catchError && params.catchEntityDeletedError) {
        document.dispatchEvent(
          new window.CustomEvent('api-error', { detail: { code: json.code, errorDetails: json.message } })
        )
      }

      return json
    })
    .catch(() => {
      document.dispatchEvent(
        new window.CustomEvent('api-error', { detail: { code: 408 } })
      )
    })
}

async function requestWithPromise (path, method, body, user, disableErrorHandler = false, followPromiseError = false) {
  const token = user && user.token ? user.token : null

  path = getApiUrl() + path

  if (user) {
    if (user.switchUser) {
      const splitPath = path.split('?')
      const switchUserParams = '?_switch_user=' + URIEncode(user.switchUser)

      splitPath[0] += splitPath[1] ? switchUserParams + '&' + splitPath[1] : switchUserParams

      path = splitPath[0]
    }
  }

  const req = new window.Request(path, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: body ? JSON.stringify(body) : undefined
  })

  return window.fetch(req).then(response => {
    if (!response.ok) {
      throw response
    }

    return response.json()
  }).catch(response => {
    if (!disableErrorHandler) {
      document.dispatchEvent(
        new window.CustomEvent('api-error-displayer', { detail: response })
      )
    }

    if (followPromiseError) {
      throw response
    }
  })
}

function requestImage (path, method, body, user, params, oldUrl = null) {
  if (typeof params === 'undefined') {
    params = {
      catchError: true,
      catchEntityDeletedError: true
    }
  } else {
    params = {
      catchError: typeof params.catchError === 'undefined' ? true : params.catchError,
      catchEntityDeletedError: typeof params.catchEntityDeletedError === 'undefined' ? true : params.catchEntityDeletedError
    }
  }

  path = getApiUrl() + path
  if (
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.switchUser !== 'undefined' &&
    user.switchUser !== null
  ) {
    const params = path.split('?')[1]

    if (params) {
      path = path.split('?')[0]
    }
    const switchUserParams = '?_switch_user=' + URIEncode(user.switchUser)
    path += params ? switchUserParams + '&&' + params : switchUserParams
  }
  const token =
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.token !== 'undefined' &&
    user.token !== null
      ? user.token
      : null

  const req = new window.Request(path, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: body ? JSON.stringify(body) : undefined
  })

  return window
    .fetch(req)
    .then(response => {
      if (response.status !== HTTP_OK) {
        throw response
      }

      return response.blob()
    })
    .then(blob => {
      return URL.createObjectURL(blob)
    })
}

async function getJobResultWithPromise (jobId, user) {
  let path = getApiUrl() + '/job-result/' + jobId
  const token = isValidUserToken(user) ? user.token : null

  if (jobId === null) {
    throw new Error('jobId cannot be null')
  }

  if (!isValidUser(user)) {
    throw new Error('User must be an object')
  }

  if (inImpersonateMode(user)) {
    path = addSwitchUserParameterToUrl(path, user)
  }

  const req = getNewWindowRequest(path, 'GET', token, null)

  return window.fetch(req)
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response)
      }

      return response.json()
    })
}

function downloadFileWithPromise (path, fileName, user) {
  const method = 'GET'

  if (!fileName) {
    throw new Error('Filename is mandatory')
  }

  if (!isValidUser(user)) {
    throw new Error('User must be an object')
  }

  if (inImpersonateMode(user)) {
    path = addSwitchUserParameterToUrl(path, user)
  }

  const token = isValidUserToken(user)
    ? user.token
    : null

  return window
    .fetch(`${getApiUrl()}${path}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      method: method,
      body: undefined
    })
    .then(async response => {
      if (response.status >= 400) {
        return Promise.reject(response)
      }

      return response.blob()
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

const getNewWindowRequest = (path, method, token, body) => {
  const req = new window.Request(path, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: body ? JSON.stringify(body) : undefined
  })

  return req
}

const isValidUser = user => {
  return !!user
}

const inImpersonateMode = user => {
  return !!user.switchUser
}

const isValidUserToken = user => {
  return !!user.token
}

const addSwitchUserParameterToUrl = (path, user) => {
  const params = path.split('?')[1]
  const url = path.split('?')[0]

  const switchUserParams = '?_switch_user=' + URIEncode(user.switchUser)
  const newPath = url + switchUserParams

  return params ? newPath + '&' + params : newPath
}

function downloadFile (path, fileName, user, method, body) {
  if (!fileName) {
    throw new Error('Filename is mandatory')
  }
  method = typeof method === 'undefined' ? 'GET' : method
  body = typeof method === 'undefined' ? null : JSON.stringify(body)

  if (
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.switchUser !== 'undefined' &&
    user.switchUser !== null
  ) {
    const params = path.split('?')[1]

    if (params) {
      path = path.split('?')[0]
    }
    const switchUserParams = '?_switch_user=' + URIEncode(user.switchUser)
    path += params ? switchUserParams + '&&' + params : switchUserParams
  }

  const token =
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.token !== 'undefined' &&
    user.token !== null
      ? user.token
      : null
  return window
    .fetch(`${getApiUrl()}${path}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      method: method,
      body: body
    })
    .then(async response => {
      if (response.status >= 400) {
        const json = await response.json()
        document.dispatchEvent(
          new window.CustomEvent('api-error', { detail: { code: json.code, errorDetails: json.message } })
        )
        return Promise.reject(
          new Error(
            `Error with request to ${response.url} : [${response.status}] ${response.statusText}`
          )
        )
      }

      return response.blob()
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

function requestMultipart (path, method, body, user, responseIsNotJson = false) {
  const payload = {}
  const headers = {}

  const requestBody = new window.FormData()
  for (const property in body) {
    requestBody.append(property, body[property])
  }

  payload.body = requestBody

  path = getApiUrl() + path
  if (
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.switchUser !== 'undefined' &&
    user.switchUser !== null
  ) {
    path += '?_switch_user=' + URIEncode(user.switchUser)
  }
  const token =
    typeof user !== 'undefined' &&
    user !== null &&
    typeof user.token !== 'undefined' &&
    user.token !== null
      ? user.token
      : null

  if (typeof user.token !== 'undefined' && user.token !== null) {
    headers.Authorization = `Bearer ${token}`
  }

  payload.method = method
  payload.headers = headers

  return window.fetch(path, payload).then(async response => {
    if (response.status >= 400) {
      const errorDetails = await response.json()
      if (errorDetails && errorDetails.message) {
        return Promise.reject(
          new Error(errorDetails.message)
        )
      }
      return Promise.reject(
        new Error(
          `Error with request to ${response.url} : [${response.status}] ${response.statusText}`
        )
      )
    }
    if (responseIsNotJson) {
      return response
    }
    return response.json()
  })
}

/**
 * Check if a string is numeric.
 *
 * @param  String The string to check.
 *
 * @return Boolean
 */
function isNumeric (n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

const isNumber = value => {
  return typeof value === 'number'
}

/*
 * Check the given string is valid.
 *
 * @param The string to validate.
 *
 * @return Boolean
 */
function validateFormInput (type, string, mandatory = false) {
  if (
    mandatory &&
    (typeof string === 'undefined' || string === null || string.length === 0)
  ) {
    return false
  }

  if (!mandatory && typeof string !== 'undefined' && string !== null && string.length === 0) {
    return true
  }

  const inputTypes = {
    date: new RegExp(
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{2}$/
    ),
    email: new RegExp(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    freeText: new RegExp(/^.{0,255}$/),
    freeTextRequired: new RegExp(/^.{1,255}$/),
    name: new RegExp(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/u
    ),
    password: new RegExp(/^.{8,255}$/),
    phone: new RegExp(/^[0-9 ]*$/),
    positiveInteger: new RegExp(/^[0-9]{1,10}$/),
    integer: new RegExp(/^[+\-0-9]{1,10}$/),
    username: new RegExp(/^[A-Za-z0-9._-]{3,15}$/),
    zipcode: new RegExp(/^[0-9a-zA-Z-]+$/)
  }

  return inputTypes[type].test(string)
}

function objectDeepCopy (object) {
  return JSON.parse(JSON.stringify(object))
}

function getUrlParameterByName (name) {
  const url = window.location.href
  name = name.replace(/[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function getCurrentUrl () {
  // Fix for IE.
  return window.location.origin
    ? window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    : window.location.origin
}

function downloadCSV (array) {
  let csv = convertArrayOfObjectsToCSV({ data: array })

  if (csv == null) {
    return
  }

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv
  }

  const filename = 'interneo-export.csv'
  const data = encodeURI(csv)
  const link = document.createElement('a')

  link.setAttribute('href', data)
  link.setAttribute('download', filename)

  document.body.appendChild(link)

  link.click()
  link.remove()
}

function convertArrayOfObjectsToCSV (args) {
  const data = args.data || null

  if (data == null || !data.length) {
    return null
  }

  const columnDelimiter = args.columnDelimiter || ','
  const lineDelimiter = args.lineDelimiter || '\n'
  const keys = Object.keys(data[0])
  let result = ''

  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach(item => {
    keys.forEach((key, index) => {
      if (index > 0) {
        result += columnDelimiter
      }

      result += typeof item[key] === 'undefined' ? '' : item[key]
    })

    result += lineDelimiter
  })

  return result
}

function doesWeeksOverlap (startOfWeek1, endOfWeek1, startOfWeek2, endOfWeek2) {
  return (
    (endOfWeek1.isSameOrAfter(startOfWeek2) &&
      endOfWeek1.isSameOrBefore(endOfWeek2)) ||
    (startOfWeek1.isSameOrBefore(startOfWeek2) &&
      endOfWeek1.isSameOrAfter(endOfWeek2)) ||
    (startOfWeek1.isSameOrAfter(startOfWeek2) &&
      startOfWeek1.isSameOrBefore(endOfWeek2)) ||
    (startOfWeek1.isSameOrAfter(startOfWeek2) &&
      endOfWeek1.isSameOrBefore(endOfWeek2))
  )
}

function removeParentsOfSet (set) {
  // if we have only one item, don't remove it in case this is the parent!
  if (set.length === 1) {
    return set
  }

  set = set.map(s => {
    s.isParent = false
    return s
  })

  for (let i = 0; i < set.length; i++) {
    if (set[i].parent === null) {
      set[i].isParent = true
    }

    for (let j = 0; j < set.length; j++) {
      if (set[j].id === set[i].parent) {
        set[j].isParent = true
      }
    }
  }

  const setWithNoParents = []
  const removedParents = []

  set.forEach(item => {
    if (!item.isParent) {
      delete item.isParent
      setWithNoParents.push(item)
    } else {
      delete item.isParent
      removedParents.push(item)
    }
  })

  return ({ setWithNoParents, removedParents })
}

function getContextFromRoles (roles) {
  let context = NONE_CONTEXT

  if (roles.includes(ROLE_STUDENT)) {
    context = STUDENT_CONTEXT
  } else if (roles.includes(ROLE_ASSISTANT)) {
    context = ASSISTANT_CONTEXT
  } else if (recursiveIncludes(SCHOOL_ROLES, roles)) {
    context = SCHOOL_CONTEXT
  } else if (recursiveIncludes(INSTITUTION_ROLES, roles)) {
    context = INSTITUTION_CONTEXT
  } else if (recursiveIncludes(SCHOOL_MACCS_ROLES, roles)) {
    context = SCHOOL_MACCS_CONTEXT
  } else if (recursiveIncludes(INSTITUTION_MACCS_ROLES, roles)) {
    context = INSTITUTION_MACCS_CONTEXT
  } else if (roles.includes(ROLE_ADMIN)) {
    context = ADMIN_CONTEXT
  }

  return context
}

function canonizeString (string) {
  return removeAccents(string.toString()).toLowerCase()
}

function filterSectionsAndSetRootSectionOfSchool (school, rootName) {
  const sections = school.sections
  const root = sections.filter(s => s.parent === null)[0]
  if (sections.length > 1) {
    // change the root name only when we have more sections
    root.name = rootName
  }
  school.rootSection = root
  const { setWithNoParents, removedParents } = removeParentsOfSet(sections)
  school.sections = setWithNoParents || []
  school.parentSections = removedParents || []
  return school
}

function filterSectorsAndSetRootSectorOfInstitution (institution, rootName) {
  const sectors = institution.sectors
  const root = sectors.filter(s => s.parent === null)[0]

  if (root && sectors.length > 1) {
    // change the root name only when we have more sectors
    root.name = rootName
  }

  institution.rootSector = root

  const { setWithNoParents } = removeParentsOfSet(sectors)

  institution.rootSector = root
  institution.sectors = setWithNoParents || []

  return institution
}

function generalErrorHandler (err) {
  console.error(err)
}

export function recursiveIncludes (array, elements) {
  for (let i = 0; i < elements.length; i++) {
    if (array.includes(elements[i])) {
      return true
    }
  }

  return false
}

/**
 * This method receives minutes and formats them to H:MM
 * @param {number} time
 */
function formatTimeFromMinutes (time, hForm = false) {
  const hours = parseInt(time / 60)
  let minutes = parseInt(time % 60)

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return hForm ? `${hours}h${minutes}` : `${hours}:${minutes}`
}

/**
 * This method is used on the super admin emails list, to transform encoded french characters into human readable ones
 * @param {*} text
 */
function decodeFrenchCharacters (text) {
  text = text.replace(/&eacute;/g, 'é')
  text = text.replace(/&egrave;/g, 'è')
  text = text.replace(/&agrave;/g, 'á')
  text = text.replace(/&aacute;/g, 'à')
  return text
}

/**
 * This parses the url search query and transforms it into an object
 */
function urlSearchParamsToObject () {
  const paramsObject = {}
  const urlParams = new URLSearchParams(window.location.search).entries()

  for (const param of urlParams) {
    const [key, value] = param
    paramsObject[key] = value
  }

  return paramsObject
}

/**
 * Format an integer in a string type into an hours-minutes form
 *
 * @param {integer} integerValue the number to be formated
 *
 * @returns the hours-minutes form of the input number
 */
const formatPositiveIntegerIntoHours = (integerValue, onlyHours = false) => {
  if (onlyHours) {
    return parseInt(integerValue, 10) + 'h00'
  } else {
    let hours = integerValue.length > 2 ? integerValue.slice(0, integerValue.length - 2) : '0'
    let minutes = integerValue.slice(integerValue.length - 2)

    if (minutes >= 60) {
      hours = parseInt(hours, 10) + 1
      minutes -= 60
    }

    return parseInt(hours, 10).toString() + 'h' + (minutes > 9 ? parseInt(minutes, 10).toString() : '0' + parseInt(minutes, 10).toString())
  }
}

/**
 * normalize an hours string
 *
 * @param {string} stringHours the hours stirng to normalize
 *
 * @returns the normalize hours string
 */
const normalizedHoursString = (stringHours) => {
  const HoursminutesArray = stringHours.split('h')

  if (HoursminutesArray[1] >= 60) {
    HoursminutesArray[0] = parseInt(HoursminutesArray[0], 10) + 1
    HoursminutesArray[1] -= 60
  }

  return parseInt(HoursminutesArray[0], 10).toString() +
    'h' +
    (HoursminutesArray[1] > 9
      ? parseInt(HoursminutesArray[1], 10).toString()
      : '0' + parseInt(HoursminutesArray[1], 10).toString())
}

/**
 * convert an hours string into a number of minutes
 *
 * @param {string} stringHours the string hours to convert
 *
 * @returns the number of minutes contains in the hours string
 */
const hoursToMinutes = (stringHours) => {
  const hoursMinutesArray = stringHours.includes('h') ? stringHours.split('h') : [stringHours, '0']

  return hoursMinutesArray[0] * 60 + parseInt(hoursMinutesArray[1], 10)
}

const reverseDate = (initialDate, splitSymbol, joinSymbol) => {
  return initialDate.split(splitSymbol).reverse().join(joinSymbol)
}

const objectToArray = (object) => {
  const objectsArray = []

  Object.keys(object).forEach(key => {
    objectsArray.push(object[key])
  })

  return objectsArray
}

const arrayToObject = (array, keys) => {
  const object = {}

  array.forEach(item => {
    object[getItemValueRecursion(item, keys)] = item
  })

  return object
}

const getItemValueRecursion = (item, keys) => {
  if (!Array.isArray(keys)) {
    return item[keys]
  }

  let value = item

  keys.forEach(key => {
    value = value[key]
  })

  return value
}

const checkIfJsonIsCorrect = json => {
  return (
    (typeof json.error === 'undefined' || json.error === false) &&
    typeof json.data !== 'undefined'
  )
}

const toFirstUpperCase = text => {
  return text[0].toUpperCase() + text.slice(1, text.length)
}

const isObject = value => {
  return value !== null && typeof value === 'object' && !isArray(value)
}

const dateStringOrNull = (value, format = EUROPEAN_DATE, key = null) => {
  let date = null

  if (isObject(value)) {
    const dateValue = key ? value[key] : value
    date = moment(dateValue).format(format)
  }
  return date
}

export const caseInsensitiveInclude = (s1, s2) => {
  let include = false

  if (typeof s1 === 'string' && typeof s2 === 'string') {
    include = s1.toLowerCase().includes(s2.toLowerCase())
  }

  return include
}

export const getPercentage = (numerator, denominator) => {
  let percentage = 0

  if (denominator) {
    percentage = (numerator / denominator) * 100
  }

  return percentage
}

export const isSupportedLanguage = language => {
  const report = {
    valid: true,
    errors: []
  }

  if (!language) {
    report.valid = false
    report.errors.push(CANNOT_BE_EMPTY)
  } else if (!SUPPORTED_LANGUAGE_LOCALES.includes(language)) {
    report.valid = false
    report.errors.push(LANGUAGE_NOT_SUPPORTED)
  }

  return report
}

export const isSupportedRoles = role => {
  const report = {
    valid: true,
    errors: []
  }

  if (!role) {
    report.valid = false
    report.errors.push(CANNOT_BE_EMPTY)
  } else if (!SUPPORTED_ROLES.includes(role)) {
    report.valid = false
    report.errors.push(ROLE_NOT_SUPPORTED)
  }

  return report
}

export const validateString = (string, conditions = {}) => {
  const report = {
    valid: true,
    errors: []
  }

  if (!isString(string)) {
    report.valid = false
    report.errors.push(NOT_A_VALID_STRING)
  } else if (conditions.notEmpty && string === '') {
    report.valid = false
    report.errors.push(CANNOT_BE_EMPTY)
  } else if (conditions.isEmail && !validateFormInput('email', string, true)) {
    report.valid = false
    report.errors.push(INVALID_EMAIL_FORMAT)
  }

  return report
}

export const getAccessorByWidth = width => {
  let accessor = 'xs'

  if (XS < width && width <= SM) {
    accessor = 'sm'
  } else if (SM < width && width <= MD) {
    accessor = 'md'
  } else if (width > MD) {
    accessor = 'lg'
  }

  return accessor
}

export const getEmptyLabelOrValue = (string, type, translator) => {
  if (string === null) {
    string = translator('without_' + type.toLowerCase())
  }

  return string
}

export const getIdOrNull = object => {
  return isObject(object) ? object.id : null
}

export {
  canonizeString,
  decodeFrenchCharacters,
  doesWeeksOverlap,
  downloadCSV,
  getJobResultWithPromise,
  downloadFileWithPromise,
  downloadFile,
  fetchJSON,
  filterSectionsAndSetRootSectionOfSchool,
  filterSectorsAndSetRootSectorOfInstitution,
  formatPositiveIntegerIntoHours,
  formatTimeFromMinutes,
  generalErrorHandler,
  getContextFromRoles,
  getCurrentUrl,
  getEnvironment,
  getGoogleTrackingId,
  getUrlParameterByName,
  hoursToMinutes,
  isNumeric,
  isNumber,
  normalizedHoursString,
  objectDeepCopy,
  removeParentsOfSet,
  request,
  requestImage,
  requestMultipart,
  requestWithPromise,
  reverseDate,
  validateFormInput,
  urlSearchParamsToObject,
  objectToArray,
  arrayToObject,
  getItemValueRecursion,
  checkIfJsonIsCorrect,
  toFirstUpperCase,
  isObject,
  dateStringOrNull
}
