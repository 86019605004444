import { requestWithPromise } from '..'
import { GET, POST } from '../apiHelper'

export const getAllSpecialityYears = user => {
  return requestWithPromise(
    '/api/speciality-years',
    GET,
    null,
    user)
    .then(json => {
      return json && json.data ? json.data : []
    })
}

export const addMultipleContactPersonsToSpecialityYear = (body, user) => {
  return requestWithPromise('/speciality-years/contact-persons', POST, body, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const addContactPersonToSpecialityYear = (specialityYearId, body, user) => {
  return requestWithPromise(`/api/speciality-years/${specialityYearId}/contact-person`, POST, body, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const getContactPersonsSpecialityYear = (specialityYear, user) => {
  return requestWithPromise(`/api/speciality-years/${specialityYear.id}/contact-persons`, GET, null, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}
