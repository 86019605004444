import { BACKEND_DATE_FORMAT } from '../constants'

export const createPauseQuota = (sector, startDate, endDate) => {
  return {
    sector: { id: sector.id },
    pause: null,
    section: null,
    school: null,
    sectionToYear: null,
    total: 0,
    startDate: startDate.format(BACKEND_DATE_FORMAT),
    endDate: endDate.format(BACKEND_DATE_FORMAT)
  }
}
