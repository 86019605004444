import React, { useState } from 'react'
import { Alert, Button, Form, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { changePassword } from '../../../utils/api/users'
import { onSuccess } from '../../../utils/apiHelper'

const ChangeOwnPasswordForm = ({ user, t, onSave }) => {
  const [data, setData] = useState({})
  const [error, setError] = useState({ visible: false, message: '' })
  const handleSubmit = e => {
    e.preventDefault()
    if (!data.password || !data.confirmPassword) setError({ visible: true, message: t('Please fill in all fields') })
    else if (data.password !== data.confirmPassword) setError({ visible: true, message: t('Passwords do not match') })
    else if (data.password.length < 8) setError({ visible: true, message: t('Password must be at least 8 characters long') })
    else {
      onSave()
      changePassword(user, data.password, data.confirmPassword).then(json => {
        if (json) {
          onSuccess(t('Password changed successfully'))
        }
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error.visible && (
        <div className='form-alert'>
          <Alert message={error.message} type='error' showIcon style={{ width: '100%' }} />
        </div>
      )}
      <div className='form-item'>
        <label className='form-label'><FontAwesomeIcon icon={faKey} /> {t('New password')}</label>
        <Input.Password
          placeholder={t('Password')}
          autoComplete='off'
          name='password'
          value={data.password}
          onChange={e => setData({ ...data, password: e.target.value })}
        />
      </div>
      <div className='form-item'>
        <label className='form-label'><FontAwesomeIcon icon={faKey} /> {t('Confirm new password')}</label>
        <Input.Password
          placeholder={t('Confirm password')}
          autoComplete='off'
          name='confirmPassword'
          value={data.confirmPassword}
          onChange={e => setData({ ...data, confirmPassword: e.target.value })}
        />
      </div>
      <div className='form-footer'>
        <Button type='primary' htmlType='submit' className='full-width'>
          <FontAwesomeIcon icon='sync' />&nbsp;
          {t('Reset password')}
        </Button>
      </div>
    </Form>
  )
}

export default ChangeOwnPasswordForm
