import React, { useEffect, useState } from 'react'

import { Button, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faCalendarPlus,
  faFileAlt,
  faInfoCircle,
  faMedkit,
  faPeopleArrows
} from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getInstitutionDescription, getSpeciality, getWorkingRegimeName } from '../../../utils/entities/maccsInternship'
import MaccsInternshipContactPersons from './ContactPersons/MaccsInternshipContactPersons'
import FilesListDisplay from './FilesListDisplay'
import { getInstitutionDocuments } from '../../../utils/api/institution'
import moment from 'moment'
import ConventionButton from './ConventionButton'
import { getMaccsInternshipDocuments } from '../../../utils/api/maccsInternship'
import FilesManager from '../../shared/FilesManager'

const internshipInfo = [
  { icon: faMedkit, title: 'Speciality', caption: getSpeciality },
  { icon: faBriefcase, title: 'Working regime', caption: getWorkingRegimeName, translate: true },
  { icon: faPeopleArrows, title: 'Contact persons', caption: mi => <MaccsInternshipContactPersons mi={mi} /> },
  { icon: faInfoCircle, title: 'Information about institution', caption: getInstitutionDescription, translate: true },
  {
    icon: faFileAlt,
    title: 'Files attached to the institution',
    caption: mi => <FilesListDisplay route={getInstitutionDocuments} entity={mi.institution} type='institution' />
  }
]

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsInternshipItem = ({ internship, t, user, onInternshipSelect }) => {
  const [convention, setConvention] = useState(null)
  const [documentLoading, setDocumentLoading] = useState(true)
  const [openFileManager, setOpenFileManager] = useState(false)

  useEffect(() => {
    if (internship) {
      setDocumentLoading(true)
      getMaccsInternshipDocuments(internship, user, { groups: ['read'] })
        .then(data => {
          const convention = data
            .sort((d1, d2) => moment(d1.updatedAt).isSameOrAfter(moment(d2.updatedAt)) ? -1 : 1)
            .filter(d => d.type === 'convention')[0]

          setConvention(convention)
          setDocumentLoading(false)
        })
    }
  }, [internship])

  const renderCaption = (caption, translate) => {
    if (typeof caption === 'function') {
      return translate ? t(caption(internship)) : caption(internship)
    }
  }

  return (
    <>
      <Tooltip placement='left' title={t('Schedule')}>
        <div className='flex-row' style={{ marginBottom: '5px' }}>
          <div style={{ width: '20px' }}>
            <FontAwesomeIcon icon={faCalendarPlus} />
          </div>
          <div className='flex-column'>
            <b>{t('Schedule')}</b>
            <Button size='small' type='primary' onClick={() => onInternshipSelect(internship.id)}>
              <FontAwesomeIcon icon='calendar-plus' />&nbsp;{t('See / edit')}
            </Button>
          </div>
        </div>
      </Tooltip>
      {internshipInfo.map((i, index) => {
        return (
          <Tooltip placement='left' title={t(i.title)} key={index}>
            <div className='flex-row' style={{ marginBottom: '5px' }}>
              <div style={{ width: '20px' }}>
                <FontAwesomeIcon icon={i.icon} />
              </div>
              <div className={i.color ? 'flex-column text-color-' + i.color : 'flex-column'}>
                <b>{t(i.title)}</b>
                {renderCaption(i.caption, i.translate)}
              </div>
            </div>
          </Tooltip>
        )
      })}
      <Tooltip placement='left' title={t('Internship convention')}>
        <div className='flex-row' style={{ marginBottom: '5px' }}>
          <div style={{ width: '20px' }}>
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
          <div className='flex-column'>
            <b>{t('Internship convention')}</b>
            <ConventionButton
              document={convention}
              internship={internship}
              afterDocumentDownload={setConvention}
              loading={documentLoading}
            />
          </div>
        </div>
      </Tooltip>
      <Tooltip placement='left' title={t('Internship files')}>
        <div className='flex-row' style={{ marginBottom: '5px' }}>
          <div style={{ width: '20px' }}>
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
          <div className='flex-column'>
            <b>{t('Internship files')}</b>
            <Button size='small' type='primary' onClick={() => setOpenFileManager(true)}>
              <FontAwesomeIcon icon='paperclip' />&nbsp;{t('See / attach')}
            </Button>
          </div>
        </div>
      </Tooltip>
      <FilesManager
        width='100%'
        entity={openFileManager ? internship : null}
        entityName='maccsinternship'
        getDocuments={() => getMaccsInternshipDocuments(internship, user)}
        onClose={() => setOpenFileManager(false)}
        preventChanges={!!internship.assistantValidationDocument}
      />
    </>
  )
}

export default connect(mapStateToProps)(MaccsInternshipItem)
