import React, { useContext } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../../routes'
import { GlobalContext } from '../../Providers/GlobalProvider'
import InstitutionProvider from '../../Providers/InstitutionProvider'
import MaccsProfilePage from '../../Components/MACCS/ProfilePage/MaccsProfilePage'
import MaccsInternshipsManagerView from '../../Views/MACCS/MaccsInternshipsManager/MaccsInternshipsManagerView'
import InternshipsManagerProvider from '../../Context/InternshipsManager/InternshipsManagerProvider'
import AssistantsView from '../../Views/MACCS/Assistants/AssistantsView'
import InstitutionSettings from '../../Views/shared/Settings/InstitutionSettings'
import AssistantsReportingView from '../../Views/AssistantReportingView'
import MaccsShiftManagerView from '../../Views/MACCS/ShiftsManager/MaccsShiftsManagerView'
import ContactPersonsView from '../../Views/shared/ContactPersonsView/ContactPersonsView'
import SchoolsView from '../../Views/institution/SchoolsView'

const MaccsInstitutionRouter = ({ onLogout }) => {
  const { setCurrentDate } = useContext(GlobalContext)

  return (
    <InstitutionProvider>
      <Switch>
        <Route path={routes.INTERNSHIPS}>
          <InternshipsManagerProvider>
            <MaccsInternshipsManagerView loadShiftsManager={setCurrentDate} />
          </InternshipsManagerProvider>
        </Route>
        <Route path={routes.PROFILE_PAGE}>
          <MaccsProfilePage onLogout={onLogout} />
        </Route>
        <Route path={routes.ASSISTANTS}>
          <AssistantsView />
        </Route>
        <Route path={routes.SETTINGS}>
          <InstitutionSettings />
        </Route>
        <Route path={routes.BADGES}>
          <AssistantsReportingView />
        </Route>
        <Route path={routes.SHIFTS_MANAGER}>
          <MaccsShiftManagerView />
        </Route>
        <Route path={routes.SCHOOLS}>
          <SchoolsView />
        </Route>
        <Route path={routes.CONTACT_PERSONS}>
          <ContactPersonsView />
        </Route>
        <Route path={routes.BADGES}>
          <AssistantsReportingView />
        </Route>
      </Switch>
    </InstitutionProvider>
  )
}

export default withRouter(MaccsInstitutionRouter)
