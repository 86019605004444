import { requestWithPromise } from '../../utils'
import { GET, POST } from '../../utils/apiHelper'
import { CONTACT_PERSONS } from './actionsType'

const { GET_CONTACT_PERSONS, UPDATE_CONTACT_PERSON } = CONTACT_PERSONS

const fetchAllContactPersonsInstitution = (institutionId, user) => {
  return async dispatch => {
    const { data } = await requestWithPromise(`/api/institutions/${institutionId}/contact-persons`, GET, null, user)

    if (data) {
      dispatch({ type: GET_CONTACT_PERSONS, payload: data })
    }
  }
}

const fetchAllContactPersonsSchool = (schoolId, user) => {
  return async dispatch => {
    const { data } = await requestWithPromise(`/api/schools/${schoolId}/contact-persons`, GET, null, user)

    if (data) {
      dispatch({ type: GET_CONTACT_PERSONS, payload: data })
    }
  }
}

const fetchAllContactPersonsInstitutionMaccs = (institutionId, user) => {
  return async dispatch => {
    const { data } = await requestWithPromise(`/api/institutions/${institutionId}/contact-persons-speciality-year`, GET, null, user)

    if (data) {
      dispatch({ type: GET_CONTACT_PERSONS, payload: data })
    }
  }
}

const fetchAllContactPersonsSchoolMaccs = (schoolId, user) => {
  return async dispatch => {
    const { data } = await requestWithPromise(`/api/schools/${schoolId}/contact-persons-school-specialities`, GET, null, user)

    if (data) {
      dispatch({ type: GET_CONTACT_PERSONS, payload: data })
    }
  }
}

const updateContactPerson = (userToUpdate, user) => {
  return async dispatch => {
    const body = {
      email: userToUpdate.email,
      lastname: userToUpdate.lastname,
      firstname: userToUpdate.firstname,
      phone: userToUpdate.phone
    }
    const { data } = await requestWithPromise(`/user/modify/${userToUpdate.id}`, POST, body, user)

    if (data) {
      dispatch({ type: UPDATE_CONTACT_PERSON, payload: data })
    }
  }
}

const getContactPersons = state => {
  return state.contactPersons
}

export {
  fetchAllContactPersonsInstitution,
  fetchAllContactPersonsInstitutionMaccs,
  fetchAllContactPersonsSchool,
  fetchAllContactPersonsSchoolMaccs,
  updateContactPerson,
  getContactPersons
}
