import React, { useState, useEffect } from 'react'

import '../../../assets/profile-page.scss'
import { Form, Input, Button, Card, notification, Tooltip } from 'antd'
import { getUser } from '../../../reducers/UserReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import pullAt from 'lodash/pullAt'
import { generalErrorHandler, request, requestWithPromise, validateFormInput } from '../../../utils'
import { getTranslate } from 'react-localize-redux'
import { connect } from '../../../reducers/Dispatchers'
import NissForm from './NissForm'
import OptInForm from './OptInForm'
import { POST } from '../../../utils/apiHelper'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { xs: { span: 24 } },
  wrapperCol: { xs: { span: 24 } }
}

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const PRIVATE_DATA_TITLE = 'My personal contact information'
const TIPS_TEXT = 'Information necessary for the preparation of your internship and contact in case of problems.\n' +
  'Possibility of contact for recruitment purposes if desired.'

const PersonalInformationsForm = props => {
  const [person, setPerson] = useState(null)
  const [personalMails, setPersonalMails] = useState([])
  const [personalPhoneNumbers, setPersonalPhoneNumbers] = useState([])
  const [phonesEditionMode, setPhonesEditionMode] = useState(false)
  const [emailsEditionMode, setEmailsEditionMode] = useState(false)
  const [newPhoneNumbers, setNewPhoneNumbers] = useState([])
  const [newEmails, setNewEmails] = useState([])
  const [pathApiEntity, setPathApiEntity] = useState(null)

  const { getFieldDecorator } = props.form

  useEffect(() => {
    setPerson(props.person)
    setPathApiEntity(props.pathApiEntity)
  }, [
    props.person,
    props.pathApiEntity
  ])

  useEffect(() => {
    if (
      typeof props.extraContactInfos.emails !== 'undefined' &&
      typeof props.extraContactInfos.phones !== 'undefined'
    ) {
      const { emails, phones } = props.extraContactInfos

      setPersonalMails(emails)
      setPersonalPhoneNumbers(phones)
    }
  }, [
    props.extraContactInfos
  ])

  const dataAggregationFactory = dataArray => {
    if (dataArray.length) {
      return (
        <ul>
          {dataArray.map((value, index) => <li key={index}> {value} </li>)}
        </ul>
      )
    }

    return ''
  }

  const handleAddRow = rowName => {
    if (rowName === 'emails') {
      const newNewEmails = [...newEmails]

      newNewEmails.push('')
      setNewEmails(newNewEmails)
    } else {
      const newNewPhoneNumbers = [...newPhoneNumbers]

      newNewPhoneNumbers.push('')
      setNewPhoneNumbers(newNewPhoneNumbers)
    }
  }

  const removeRow = (getter, setter, index) => {
    const newData = [...getter].slice()

    pullAt(newData, [index])

    setter(newData)
  }

  const checkEmail = (rule, value, callback) => {
    if (!validateFormInput('email', value)) {
      callback(props.t('This email address is not correct !'))
    }

    callback()
  }

  const checkPhone = (rule, value, callback) => {
    if (!validateFormInput('phone', value)) {
      callback(props.t('Only numbers are authorized !'))
    }

    callback()
  }

  const toggleEditionMode = type => {
    const sectionHandler = {
      phones: {
        setter: setPhonesEditionMode,
        getter: phonesEditionMode,
        cleaner: setNewPhoneNumbers
      },
      emails: {
        setter: setEmailsEditionMode,
        getter: emailsEditionMode,
        cleaner: setNewEmails
      }
    }

    if (sectionHandler[type].getter) {
      const form = props.form

      form.validateFields().then(values => {
        const body = {
          emails: type === 'emails' ? [] : personalMails,
          phones: type === 'phones' ? [] : personalPhoneNumbers
        }

        const cutSection = type.slice(0, -1)

        for (const [key, value] of Object.entries(values)) {
          if (key.includes(cutSection)) {
            body[type].push(value.toLowerCase())
          }
        }

        sectionHandler[type].setter(!sectionHandler[type].getter)
        sectionHandler[type].cleaner([])

        requestWithPromise(
          `${pathApiEntity}/${person.id}/extra-contact-info`,
          POST,
          body,
          props.getUser
        ).then(json => {
          let extraContactInfoMap = { emails: [], phones: [] }

          Object.keys(json.data).map(t => {
            json.data[t].map((eci, k) => {
              const type = eci.type + (eci.type.slice(-1) !== 's' ? 's' : '')

              extraContactInfoMap[type].push(eci.value)
            })
          })

          setPersonalMails(extraContactInfoMap.emails)
          setPersonalPhoneNumbers(extraContactInfoMap.phones)

          notification.success({ message: props.t('Saved additional details'), placement: 'bottomRight' })
        }).catch(error => {
          generalErrorHandler(error)

          notification.error({ message: props.t('Error saving additional details.'), placement: 'bottomRight' })
        })
      }).catch(error => {
        generalErrorHandler(error)
      })
    } else {
      sectionHandler[type].setter(!sectionHandler[type].getter)
    }
  }

  const render = () => {
    const emailsData = emailsEditionMode ? '' : dataAggregationFactory(personalMails)
    const phonesData = phonesEditionMode ? '' : dataAggregationFactory(personalPhoneNumbers)

    const cardToDisplay = {
      optInForm: {
        content: <OptInForm
          person={person}
          pathApiEntity={pathApiEntity}
          optInValue={props.optInValue}
          modifyOptIn={props.modifyOptIn}
          marginBottom='10px'
        />,
        componentView: true
      },
      nissForm: {
        content: <NissForm person={person} pathApiEntity={pathApiEntity} marginBottom='10px' />,
        componentView: true
      },
      mails: {
        title: [<FontAwesomeIcon key={1} icon='at' />, ' ', props.t('Email')],
        content: emailsEditionMode ? renderMailsForm() : emailsData === '' ? props.t("You don't record yet a personnal email") : emailsData,
        extra: [<Button key='a' onClick={() => setEmailsEditionMode(!emailsEditionMode)}> <FontAwesomeIcon icon={faEdit} /> </Button>],
        componentView: false
      },
      phonesNumbers: {
        title: [<FontAwesomeIcon key={2} icon='phone' />, ' ', props.t('Phone number')],
        content: phonesEditionMode ? renderPhonesNumberForm() : phonesData === '' ? props.t("You don't record yet a personnal phone number") : phonesData,
        extra: [<Button key='b' onClick={() => setPhonesEditionMode(!phonesEditionMode)}> <FontAwesomeIcon icon={faEdit} /> </Button>],
        componentView: false
      }
    }

    const keyToRendered = Object.keys(cardToDisplay).filter(cardKey => cardToDisplay[cardKey].content !== '')

    return keyToRendered.map((cardKey, index) => (
        <div
          key={index}
          className={index !== keyToRendered.length ? 'inner-margin' : ''}
        >
          {cardToDisplay[cardKey].componentView
            ?
              cardToDisplay[cardKey].content
            :
              <Card
                key={cardKey}
                type="inner"
                title={cardToDisplay[cardKey].title}
                extra={cardToDisplay[cardKey].extra}
                style={{ marginBottom: '10px' }}
              >
                {cardToDisplay[cardKey].content}
              </Card>
          }
        </div>
      )
    )
  }

  const renderMailsForm = () => {
    const formBody = personalMails.concat(newEmails).map((emailRow, index) => {
      return (
        <div key={`email-row-${index}`} className='item-row'>
          <FormItem {...formItemLayout}>
            {getFieldDecorator(`email${index}`, { rules: [{ validator: checkEmail }], initialValue: personalMails[index] })(
              <Input prefix={<FontAwesomeIcon icon='at' />} placeholder={props.t('Email address')} />
            )}
          </FormItem>
          <Button onClick={() => { removeRow(personalMails, setPersonalMails, index) }}>
            <FontAwesomeIcon icon='trash' />
          </Button>
        </div>
      )
    })

    return (
      <Form className='student-extra-contact-details'>
        <label>{props.t('student.additional.contact.info.email')}</label>
        {formBody}
        <div className='add-button-wrapper'>
          <Button type='primary' onClick={() => handleAddRow('emails')}>{props.t('Add email address')}</Button>
          <div className='flex-fill' />
          <Button type='primary' onClick={() => toggleEditionMode('emails')}>{props.t('Save')}</Button>
        </div>
      </Form>
    )
  }

  const renderPhonesNumberForm = () => {
      const formBody = personalPhoneNumbers.concat(newPhoneNumbers).map((phoneRow, index) => {
        return (
          <div key={`email-row-${index}`} className='item-row'>
            <FormItem {...formItemLayout}>
              {getFieldDecorator(`phone${index}`, { rules: [{ validator: checkPhone }], initialValue: personalPhoneNumbers[index] })(
                <Input prefix={<FontAwesomeIcon icon='phone' />} placeholder={props.t('Phone address')} />
              )}
            </FormItem>
            <Button onClick={() => { removeRow(personalPhoneNumbers, setPersonalPhoneNumbers, index) }}>
              <FontAwesomeIcon icon='trash' />
            </Button>
          </div>
        )
      })

      return (
        <Form className='student-extra-contact-details'>
          <label>{props.t('student.additional.contact.info.phone')}</label>
          {formBody}
          <div className='add-button-wrapper'>
            <Button type='primary' onClick={() => handleAddRow('phones')}>{props.t('Add phone number')}</Button>
            <div className='flex-fill' />
            <Button type='primary' onClick={() => toggleEditionMode('phones')}>{props.t('Save')}</Button>
          </div>
        </Form>
      )
    }

  return (
    <>
      <h2 style={{ margin: '35px 5px 15px' }}>
        {[props.t(PRIVATE_DATA_TITLE), ' ',
          <Tooltip key='personal_section_tooltip' placement='right' title={props.t(TIPS_TEXT)}>
            <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
          </Tooltip>
        ]}
      </h2>

      {render()}
    </>
  )
}

export default connect(mapStateToProps)(Form.create()(PersonalInformationsForm))
