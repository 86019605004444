import moment from 'moment'
import { BACKEND_DATE_FORMAT } from '../constants'
import { toEuropeanDate } from '../momentjs'

export const IN_INSTITUTION_STATE = 'in-institution'
export const SCHEDULE_DISPLAY_STATE = 'schedule-display'

export const makeMaccsInternship = ({ startDate, endDate, school, institution, internshipYear, assistant, workingRegime }) => {
  return {
    startDate: startDate ?? moment(),
    endDate: endDate ?? moment(),
    school: school ?? null,
    institution: institution ?? null,
    assistant: assistant ?? null,
    workingRegime: workingRegime ?? null,
    toRequestBody () {
      return {
        ...this,
        startDate: this.startDate.format(BACKEND_DATE_FORMAT),
        endDate: this.endDate.format(BACKEND_DATE_FORMAT)
      }
    }
  }
}

export const getSchoolName = mi => {
  if (!mi.school) {
    return 'Without school'
  }

  return mi.school.name
}

export const getSchoolId = mi => {
  if (!mi.school) {
    return null
  }

  return mi.school.id
}

export const getAssistantLastname = mi => {
  if (!mi.assistant) {
    return '/'
  }

  return mi.assistant.lastname
}

export const getAssistantFirstname = mi => {
  if (!mi.assistant) {
    return '/'
  }

  return mi.assistant.firstname
}

export const getAssistantEmail = mi => {
  if (!mi.assistant) {
    return '/'
  }

  return mi.assistant.email
}

export const getAssistantMatricule = mi => {
  if (!mi.assistant) {
    return '/'
  }

  return mi.assistant.matricule
}

export const getAssistantPhone = mi => {
  if (!mi.assistant) {
    return '/'
  }

  return mi.assistant.phone
}

export const getAssistantBirthdate = mi => {
  if (!mi.assistant) {
    return '/'
  }

  return toEuropeanDate(moment(mi.assistant.birthdate))
}

export const getStartDate = mi => {
  if (!mi.startDate) {
    return '/'
  }

  return toEuropeanDate(moment(mi.startDate.split('+')[0]))
}

export const getEndDate = mi => {
  if (!mi.endDate) {
    return '/'
  }

  return toEuropeanDate(moment(mi.endDate.split('+')[0]))
}

export const getSpeciality = mi => {
  if (!mi.speciality) {
    return 'Without speciality'
  }

  return mi.speciality.name
}

export const getInstitutionDescription = mi => {
  if (!mi.institution) {
    return 'Without institution'
  }

  return mi.institution.description ?? 'None'
}

export const getSpecialityAbbreviation = mi => {
  return mi.speciality?.abbreviation ?? '/'
}

export const getAssistantFullSpecialityAbbreviation = mi => {
  let abbreviation = '/'

  if (mi.assistant?.schoolSpeciality?.specialityYear) {
    const speciality = mi.assistant.schoolSpeciality.specialityYear

    abbreviation = `${speciality.specialityAbbreviation} (${speciality.studyYearName})`
  }

  return abbreviation
}

export const getAssistantFullFinancialYearAbbreviation = mi => {
  let abbreviation = '/'

  if (mi.assistant?.financialYear?.specialityAbbreviation) {
    abbreviation = `${mi.assistant.financialYear.specialityAbbreviation} (${mi.assistant.financialYear.studyYearName})`
  }

  return abbreviation
}

export const getWorkingRegimeName = mi => {
  return mi.workingRegimeName ?? '/'
}

export const getInstitutionName = mi => {
  let name = 'Without institution'

  if (mi.institution) {
    name = mi.institution.name
  }

  return name
}
