import React, { useState } from 'react'
import { Button, Form, InputNumber, Modal, TimePicker } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { createMaccsInternshipShift } from '../../../utils/api/maccsInternship'
import { getUser } from '../../../reducers/UserReducer'
import { onError, onSuccess } from '../../../utils/apiHelper'
import moment from 'moment'
import MaccsEventCodeSelect from './Components/MaccsEventCodeSelect'
import { Shift } from '../../../utils/entities/shift'

const HOUR_FORMAT = 'HH:mm'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsDesiderataCreationModal = ({ dates, internship, visible, onClose, onMultipleCreation, user, t }) => {
  const [values, setValues] = useState({})

  const handleSubmit = () => {
    const startDate = moment(dates.start)
    const endDate = moment(dates.end)
    const numberOfDays = endDate.diff(startDate, 'day')

    if (numberOfDays === 1) {
      createShift(startDate, endDate)
    } else {
      createMultipleShifts(startDate, endDate, numberOfDays)
    }
  }

  const createMultipleShifts = (startDate, endDate, number) => {
    const promises = []

    for (let i = 0; i < number; i++) {
      if (startDate > endDate) {
        break
      }

      promises.push(createShiftWithError(startDate, startDate.clone().add(1, 'day')))
      startDate.add(1, 'day')
    }

    Promise.all(promises).then(() => {
      onSuccess(t('The shifts were successfully created'))
      onMultipleCreation()
    }).catch(() => {
      onError(t('One or multiple shifts could not be created'))
      onMultipleCreation()
    })

    handleClose(null, true)
  }

  const createShiftWithError = (startDate, endDate) => {
    const shift = new Shift({
      ...values,
      startDate,
      endDate: (values.startTime < values.endTime ? startDate : endDate),
      eventCodeType: { id: values.eventCodeType }
    })

    return createMaccsInternshipShift(internship, shift, user).then(json => {
      const newShift = json?.data ?? null

      if (newShift === null) {
        throw new Error('This shift cannot be created')
      }
    })
  }

  const createShift = (startDate, endDate) => {
    const shift = new Shift({
      ...values,
      startDate,
      endDate: (values.startTime < values.endTime ? startDate : endDate),
      eventCodeType: { id: values.eventCodeType }
    })

    createMaccsInternshipShift(internship, shift, user).then(json => {
      const newShift = json?.data ?? null

      if (newShift !== null) {
        onSuccess(t('The shift has been successfully created'))
      } else {
        onError(t('The shift could not be created'))
      }

      handleClose(newShift)
    })
  }

  const handleClose = (shift = null) => {
    onClose(shift)
  }

  const handleEventCodeSelection = eventCode => {
    setValues({ ...values, eventCodeType: eventCode.typeId })
  }

  const renderFooter = () => {
    return [
      <Button
        key='ok'
        type='primary'
        onClick={handleSubmit}
        disabled={!values.startTime || !values.endTime}
      >
        {t('Submit')}
      </Button>,
      <Button
        key='cancel'
        type='primary'
        onClick={() => handleClose()}
      >
        Cancel
      </Button>
    ]
  }

  return (
    <Modal
      title={t('Desiderata from ') + dates.startStr + t(' to ') + dates.endStr}
      onCancel={() => handleClose()}
      visible={visible}
      footer={renderFooter()}
      destroyOnClose
    >
      <Form
        id='customScheduleForm'
        layout='vertical'
        initialvalues={values}
      >
        <Form.Item name='startTime' label={t('Start time')}>
          <TimePicker
            size='large'
            minuteStep={5}
            value={values.startTime}
            format={HOUR_FORMAT}
            allowClear
            onChange={(value, timeString) => {
              setValues({
                ...values,
                startTime: value.set({ second: 0 })
              })
            }}
          />
        </Form.Item>
        <Form.Item name='endTime' label={t('End time')}>
          <TimePicker
            size='large'
            minuteStep={5}
            value={values.endTime}
            format={HOUR_FORMAT}
            allowClear
            onChange={(value, timeString) => {
              setValues({
                ...values,
                endTime: value.set({ second: 0 })
              })
            }}
          />
        </Form.Item>
        <Form.Item name='pause' label={t('Pause')}>
          <InputNumber
            size='large'
            min={0}
            step={5}
            value={values.pause ?? 0}
            allowClear
            onChange={(value) => {
              setValues({ ...values, pause: value })
            }}
          />
        </Form.Item>
      </Form>
      <MaccsEventCodeSelect value={values.eventCodeType} onSelect={handleEventCodeSelection} />
    </Modal>
  )
}

export default connect(mapStateToProps)(MaccsDesiderataCreationModal)
