import React, { useState, useEffect } from 'react'
import { Select } from 'antd'

const Option = Select.Option
const STYLE = {
  width: '100%'
}
const PLACEHOLDER = 'Select a year'

const StudyYearSelect = props => {
  const [displayValue, setDisplayValue] = useState('')
  const [specialityYears, setSpecialityYears] = useState({})

  useEffect(() => {
    if (props.data && props.valueKey && (props.dataColumn[props.valueKey] || props.valueKey === -1) && props.dataColumn) {
      setDisplayValue('')
      setSpecialityYears(props.dataColumn)
    }
  }, [props.data, props.valueKey, props.dataColumn])

  const handleChange = (value) => {
    const data = { ...props.data }

    if (!data.newSpeciality) {
      data['newSpeciality'] = specialityYears[data.specialityYear.id].speciality.id
    }

    data['newStudyYear'] = value
    data['complete'] = true

    props.onChange(data)
  }

  const renderOptions = () => {

    let currentSpeciality = null

    if (props.data.newSpeciality) {
      currentSpeciality = props.data.newSpeciality
    } else {
      let currentSpecialityYear = specialityYears[props.data.specialityYear.id]
      currentSpeciality = currentSpecialityYear.speciality.id
    }
    
    const currentspecialityYears = Object.keys(specialityYears).filter(key => specialityYears[key].speciality.id === currentSpeciality)

    return currentspecialityYears.map(key => {
      const value = specialityYears[key].studyYear.id
      const content = specialityYears[key].studyYearName
      const title = specialityYears[key].studyYearName

      const newOption = renderOption(key, value, content, title)

      if (specialityYears[key].studyYear.id === props.valueKey && props.data.complete && displayValue === '') {
        setDisplayValue(specialityYears[key].studyYear.id)
      }

      return newOption
    })
  }

  const renderOption = (key, value, content, title) => {
    if (title) {
      return (
        <Option
          key={'option' + key}
          value={value}
          title={title}
        >
          {content}
        </Option>
      )
    }

    return (
      <Option
        key={'option' + key}
        value={value}
      >
        {content}
      </Option>
    )
  }

  const filterOptions = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return specialityYears[props.valueKey] !== undefined || (props.valueKey === -1 && props.data.newSpeciality !== undefined)
  ? (
    <Select
      showSearch
      placeholder={PLACEHOLDER}
      optionFilterProp='children'
      onChange={handleChange}
      disabled={props.disabled}
      onFocus={() => props.onFocus()}
      onBlur={() => props.onBlur()}
      style={STYLE}
      value={displayValue}
      filterOption={filterOptions}
    >
      {renderOptions()}
    </Select>
  )
  : (<div />)
}
export default StudyYearSelect