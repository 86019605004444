import React, { useState, useEffect } from 'react'

import { Button, Dropdown, Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronDown, faFileAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { internshipStates } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'
import FilesManager, { INTERNSHIP_VALIDATION_TYPE } from '../../shared/FilesManager'
import MaccsInternshipValidation from '../../shared/ShiftsManager/MaccsInternshipValidation/MaccsInternshipValidation'
import { generateValidationDoc } from '../../../utils/api/maccsInternshipDocument'
import { deleteValidationDocument, getValidationDocument } from '../../../utils/api/maccsInternship'
import { onError } from '../../../utils/apiHelper'

const { SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED } = internshipStates
const AUTHORIZED_VALIDATION_STATES = [SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, ACCEPTED_UNMANAGED]
const VALIDATION_FILES_LIMIT = 1

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const CalendarHeader = ({ activeLanguage, internship, shifts, loading, user, onClose, onInternshipUpdate, t }) => {
  const [printingValidationDocument, setPrintingValidationDocument] = useState(false)
  const [internshipFilesManagerOpen, setInternshipFilesManagerOpen] = useState(false)
  const [canUploadValidationDoc, setCanUploadValidationDoc] = useState(false)
  const [isValidatedWithSignedFile, setIsValidatedWithSignedFile] = useState(false)
  const [additionalActions, setAdditionalActions] = useState([])
  const [internshipSummaryVisible, setInternshipSummaryVisible] = useState(false)
  const [currentInternship, setCurrentInternship] = useState(null)

  useEffect(() => {
    if (internship && shifts) {
      setCurrentInternship({ ...internship, shifts })
    }
  }, [internship, shifts])

  useEffect(() => {
    if (internship) {
      setCanUploadValidationDoc(AUTHORIZED_VALIDATION_STATES.includes(internship.state))
      setIsValidatedWithSignedFile(internship.state === SCHEDULE_VALIDATED && internship.assistantValidationDocument)
    }
  }, [internship])

  useEffect(() => {
    displayActionsMenu(canUploadValidationDoc, isValidatedWithSignedFile, internship, t)
  }, [canUploadValidationDoc, isValidatedWithSignedFile, internship.state, t])

  const displayActionsMenu = (canUpload, validatedAndSigned, internship, t) => {
    const actions = []

    if (canUpload || validatedAndSigned || internship.state === SCHEDULE_VALIDATED) {
      actions.push(
        <Menu.Item
          key='menu-1'
          disabled={printingValidationDocument}
          onClick={(handleDocumentPrint)}
        >
          <FontAwesomeIcon icon={faFileAlt} />&nbsp;
          {t(isValidatedWithSignedFile ? 'Download signed validation document' : 'Print Validation document')}
        </Menu.Item>
      )
    }

    if (canUpload) {
      actions.push(
        <Menu.Item
          key='menu-2'
          className='desiderata-header-button-upload'
          disabled={printingValidationDocument}
          onClick={() => setInternshipFilesManagerOpen(true)}
        >
          <FontAwesomeIcon icon={faPaperclip} />&nbsp;{t('Upload signed validation document')}
        </Menu.Item>
      )
    }

    actions.push(
      <Menu.Item
        key='menu-3'
        onClick={() => setInternshipSummaryVisible(true)}
      >
        <FontAwesomeIcon icon={faCalendar} />&nbsp;{t('View schedule summary')}
      </Menu.Item>
    )

    setAdditionalActions(actions)
  }

  const handleDocumentPrint = () => {
    setPrintingValidationDocument(true)

    generateValidationDoc(
      { maccsInternship: internship.id },
      user,
      error => {
        if (error) {
          onError(t('An error has occured during the validation document generation'))
        }

        setPrintingValidationDocument(false)
      },
      { language: activeLanguage }
    )
  }

  const handleValidationFileDeletion = file => {
    deleteValidationDocument(internship, user).then(() => {
      onInternshipUpdate({
        ...internship,
        state: SCHEDULE_ADDED,
        assistantValidationDocument: null
      })
    })
  }

  const handleValidationFileUpload = file => {
    onInternshipUpdate({
      ...internship,
      state: SCHEDULE_TO_VALIDATE
    })
  }

  return (
    <div className='flex-row sticky-student-stages-bar' style={getUser.switchUser ? { top: '41px' } : null}>
      <Button type='primary' onClick={onClose}>
        <FontAwesomeIcon icon='chevron-left' />&nbsp;{t('Back')}
      </Button>
      <div className='flex-fill' />
      {loading ? (
        <div className='loading-icon black' style={{ marginRight: '15px' }} />
      ) : null}

      {additionalActions.length > 0 &&
        <Dropdown overlay={<Menu>{additionalActions}</Menu>}>
          <Button>{t('Additional actions')}&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronDown} /></Button>
        </Dropdown>}

      <FilesManager
        width='100%'
        entity={internshipFilesManagerOpen ? internship : null}
        entityName='maccsinternship'
        maxFiles={VALIDATION_FILES_LIMIT}
        fileType={INTERNSHIP_VALIDATION_TYPE}
        uploadParams={[{ key: 'validationDocument', value: true }]}
        getDocuments={() => getValidationDocument(internship, user)}
        onClose={() => setInternshipFilesManagerOpen(false)}
        onFileDelete={handleValidationFileDeletion}
        onUploadsucceeded={handleValidationFileUpload}
      />

      {internshipSummaryVisible &&
        <MaccsInternshipValidation
          maccsInternship={currentInternship}
          onClose={() => setInternshipSummaryVisible(false)}
          bottom={false}
          title={t('Summary of the internship')}
        />}
    </div>
  )
}

export default connect(mapStateToProps)(CalendarHeader)
