import React, { useState, useEffect } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import SpecialitiesTable from '../../../Components/MACCS/Specialities/SpecialitiesTable'
import { getUser } from '../../../reducers/UserReducer'
import { arrayToObject } from '../../../utils'
import { faPeopleArrows, faUser } from '@fortawesome/free-solid-svg-icons'
import SchoolSpecialityUsers from '../../../Components/shared/SchoolSpecialityUsers/SchoolSpecialityUsers'
import { createSchoolSpeciality, deleteSchoolSpeciality, getAllSchoolSpeciality, getContactPersonsSchoolSpeciality, updateSchoolSpeciality } from '../../../utils/api/schoolSpeciality'
import { getAllSpecialities } from '../../../utils/api/speciality'
import { getAllSpecialityYears } from '../../../utils/api/specialityYear'
import { notification } from 'antd'
import { ROLE_CLINICAL_SECRETARY } from '../../../utils/constants'
import ContactPersonSpecialityYears from '../../../Components/shared/ContactPersonSpecialityYears'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SpecialitiesView = ({ user, t }) => {
  const [schoolSpecialities, setSchoolSpecialities] = useState([])
  const [specialities, setSpecialities] = useState({})
  const [specialityYears, setSpecialityYears] = useState({})
  const [selectedSpecialityUsers, setSelectedSpecialityUsers] = useState(null)
  const [loading, setLoading] = useState(false)
  const [newData, setNewData] = useState(null)
  const [selectedSchoolSpeciality, setSelectedSchoolSpeciality] = useState(null)
  const [readOnly, setReadOnly] = useState(true)

  useEffect(() => {
    if (user) {
      refresh(user)
      setReadOnly(user.roles.includes(ROLE_CLINICAL_SECRETARY))
    }
  }, [user])

  const refresh = user => {
    setLoading(true)
    const promiseStack = []

    promiseStack.push(getAllSchoolSpeciality(user).then(setSchoolSpecialities))
    promiseStack.push(getAllSpecialities(user)
      .then(data => {
        setSpecialities(arrayToObject(data, ['id']))
      }))
    promiseStack.push(getAllSpecialityYears(user)
      .then(data => {
        setSpecialityYears(arrayToObject(data, ['id']))
      }))

    Promise.all(promiseStack).then(() => {
      setLoading(false)
    })
  }

  const handleDataSave = (data) => {
    const newSchoolSpecialityKey = Object.keys(specialityYears)
      .filter(key =>
        specialityYears[key].speciality.id === data.newSpeciality &&
          specialityYears[key].studyYear.id === data.newStudyYear
      )

    if (data.id === -1) {
      const body = {
        specialityYear: parseInt(newSchoolSpecialityKey[0]),
        actsMandatory: data.actsMandatory,
        school: user.school.id,
        comment: data.comment
      }

      createSchoolSpeciality(body, user)
        .then(data => {
          refresh(user)
          notification.success({
            message: t('The speciality was successfully created'),
            placement: 'bottomRight'
          })
        })

      return
    }

    const body = {
      comment: data.comment
    }

    if (data.specialityYear.id !== newSchoolSpecialityKey[0]) {
      body.specialityYear = newSchoolSpecialityKey[0]
    }

    updateSchoolSpeciality(data, body, user).then(() => {
      refresh(user)
      if (newSchoolSpecialityKey[0]) {
        data.specialityYear.id = newSchoolSpecialityKey[0]
      }

      notification.success({
        message: t('The speciality was successfully updated'),
        placement: 'bottomRight'
      })
    })
  }

  const handleDataAdd = () => {
    setNewData(makeNewData())
  }

  const makeNewData = () => {
    return {
      complete: false,
      actsMandatory: false,
      id: -1,
      school: -1,
      specialityYear: -1
    }
  }

  const handleDataDelete = schoolSpeciality => {
    deleteSchoolSpeciality(schoolSpeciality, user).then(jsonData => {
      refresh(user)
      notification.success({
        message: t('The speciality was successfully deleted'),
        placement: 'bottomRight'
      })
    })
  }

  const renderContainer = () => {
    const COLUMNS = [
      {
        type: 'SpecialitySelect',
        dataType: 'string',
        name: 'Name',
        key: data => {
          if (data.specialityYear !== undefined) {
            if (data.newSpeciality !== undefined) {
              return data.newSpeciality
            }

            if (data.id === -1) {
              return -1
            }

            return specialityYears[data.specialityYear.id].speciality.id
          }

          return null
        },
        filterKey: data => {
          if (data !== undefined && data.specialityYear !== undefined && !loading) {
            return specialityYears[data.specialityYear.id].specialityAbbreviation
          }
        },
        data: specialities,
        keyError: 'specialityId'
      },
      {
        type: 'StudyYearSelect',
        dataType: 'string',
        name: 'Year',
        key: data => {
          if (data.specialityYear !== undefined) {
            if (data.newStudyYear !== undefined) {
              return data.newStudyYear
            }

            if (data.id === -1) {
              return -1
            }

            return specialityYears[data.specialityYear.id].studyYear.id
          }

          return null
        },
        filterKey: data => {
          if (data !== undefined && data.specialityYear !== undefined && !loading) {
            return specialityYears[data.specialityYear.id].studyYearName
          }
        },
        data: specialityYears,
        keyError: 'schoolYearId'
      },
      {
        type: 'Input',
        dataType: 'string',
        name: 'Comment for the assistants',
        key: 'comment'
      }
    ]

    const additionalActions = [
      {
        icon: faUser,
        type: 'primary',
        title: 'Show speciality users',
        handleOnClick: s => setSelectedSpecialityUsers(s)
      },
      {
        icon: faPeopleArrows,
        type: 'primary',
        title: 'The contact persons for this speciality',
        handleOnClick: s => setSelectedSchoolSpeciality(s)
      }
    ]

    return (
      <div>
        <SpecialitiesTable
          additionalActions={additionalActions}
          columns={COLUMNS}
          data={schoolSpecialities}
          loading={loading}
          newData={newData}
          readOnly={readOnly}
          onDataSave={handleDataSave}
          onDataAdd={handleDataAdd}
          onDataDelete={handleDataDelete}
        />
        <SchoolSpecialityUsers
          entity={selectedSpecialityUsers}
          onClose={() => setSelectedSpecialityUsers(null)}
        />
        <ContactPersonSpecialityYears
          getContactPersons={user => getContactPersonsSchoolSpeciality(selectedSchoolSpeciality, user)}
          title={
            selectedSchoolSpeciality === null
              ? ''
              : `${t('Contact persons of')} ${selectedSchoolSpeciality.specialityYear.specialityAbbreviation} (${selectedSchoolSpeciality.specialityYear.studyYearName})`
          }
          visible={!!selectedSchoolSpeciality}
          onClose={() => setSelectedSchoolSpeciality(null)}
        />
      </div>
    )
  }

  return (
    renderContainer()
  )
}

export default connect(mapStateToProps)(SpecialitiesView)
