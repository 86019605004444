import React, { useState, useRef } from 'react'
import { Button, Modal, Upload, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { importAssistants } from '../../../utils/api/export'
import { getUser } from '../../../reducers/UserReducer'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { callFunctionIfDefined } from '../../../utils/functionHelper'

import '../../../assets/students-import-modal.scss'

const { Dragger } = Upload

const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const AssistantsImportModal = ({ activeLanguage, user, visible, onClose, onImportSucceed, t }) => {
  const [fileToUpload, setFileToUpload] = useState(null)
  const [importing, setImporting] = useState(false)
  const [importSuccessful, setImportSuccessful] = useState(false)
  const [importPercentage, setImportPercentage] = useState(0)

  const progressTimer = useRef(null)

  const updateProgress = () => {
    let percentage = 0
    setImportPercentage(percentage)
    progressTimer.current = setInterval(() => {
      if (percentage >= 99) {
        clearInterval(progressTimer.current)
        return false
      }
      percentage += percentage >= 90 ? 1 : 10
      setImportPercentage(percentage)
    }, 600)
  }

  const finishedUpload = () => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current)
    }
    setImportPercentage(100)
    setImportSuccessful(true)
  }

  const handleFileUpload = async () => {
    if (ACCEPTED_DOCUMENT_TYPES.indexOf(fileToUpload.type) === -1) {
      return onError(t('Only Excel and CSV file types are supported.'))
    }

    setImporting(true)
    updateProgress()

    importAssistants(fileToUpload, user, activeLanguage)
      .then(() => {
        onSuccess(t('The template has been successfully dowloaded'))
        setFileToUpload(null)
        finishedUpload()
        callFunctionIfDefined(onImportSucceed, [])
      })
      .catch(err => {
        setImporting(false)
        setFileToUpload(null)
        setImportPercentage(0)
        onError(t(err.message))
      })
  }

  const handleClose = () => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current)
    }
    setFileToUpload(null)
    setImporting(false)
    setImportPercentage(0)
    setImportSuccessful(false)

    onClose()
  }

  return (
    <Modal
      title={t('Import assistants')}
      visible={visible}
      onCancel={handleClose}
      footer={
        importSuccessful
          ? [
            <Button
              onClick={handleClose}
              key='cancel'
              type='primary'
            >
              {t('Close')}
            </Button>
          ]
          : [
            <Button
              onClick={handleClose}
              key='cancel'
              type='default'
              disabled={importing}
            >
              <FontAwesomeIcon icon='times' />&nbsp;
              {t('Cancel')}
            </Button>,
            <Button
              key='save'
              type='primary'
              onClick={handleFileUpload}
              disabled={!fileToUpload}
            >
              <FontAwesomeIcon icon='check' />&nbsp;
              {t('OK, proceed')}
            </Button>]
      }
    >
      <div className='students-import-modal-body'>
        <div className='upload-type-description'>
          <ul>
            <li> {t('If the assistant email is not registered in Interneo, a assistant account will be created.')} </li>
            <li> {t('If the email is already registered in Interneo, the assistant data will be updated.')} </li>
          </ul>
        </div>
        <div className='upload-area'>
          {!importing &&
            <Dragger
              name='file'
              accept='text/csv,.csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              beforeUpload={(file) => {
                setFileToUpload(file)
                return false
              }}
              onRemove={() => { setFileToUpload(null) }}
            >
              <p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
              <p className='ant-upload-hint'>{t('Only Excel and CSV file types are supported.')}
              </p>
            </Dragger>}
          {importing &&
            <div className='importing-animation'>
              <Progress type='circle' percent={importPercentage} />
            </div>}
        </div>
      </div>

    </Modal>
  )
}

export default connect(mapStateToProps)(AssistantsImportModal)
