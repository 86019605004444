import React, { useCallback, useState, useEffect, useMemo } from 'react'

import Analytics from '../../utils/analytics'
import ArchivedSectorsModal from '../../Components/institution/ArchivedSectorsModal'
import FilesManager from '../../Components/shared/FilesManager'
import SectorSectionUsers from '../../Components/shared/SectorSectionUsers/index'
import SmartTable, { DATA_TYPE_BOOLEAN, DATA_TYPE_ID, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import { QUOTA_PER_PAUSE } from '../../utils/constants'
import { validateFormInput, generalErrorHandler } from '../../utils'
import { Button, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchSectorsFilesCount, getSectors, getFilesCount } from '../../reducers/SectorsReducer/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser, setUserManagedSectors } from '../../reducers/UserReducer'
import { getInstitutions, setInstitutions } from '../../reducers/InstitutionsReducer'
import { isLocalAdmin } from '../../Selectors/localAdministrator/localAdministrator'
import { getInstitutionOptions, getInstitutionOptionsLoaded } from '../../reducers/InstitutionOptionsReducer/actions'
import { faBox, faClock, faFile, faUser } from '@fortawesome/free-solid-svg-icons'
import { archiveSector, createSectorOld, updateSectorOld } from '../../utils/api/sector'

const DEFAULT_COLUMNS = [
  { type: DATA_TYPE_ID, key: 'id' },
  {
    type: DATA_TYPE_STRING,
    name: 'Name',
    key: 'name',
    validate: data => validateFormInput('freeText', data, true)
  },
  { type: DATA_TYPE_STRING, name: 'Comment for the students', key: 'comment' },
  { type: DATA_TYPE_STRING, name: 'Road', key: 'emplacement' }
]

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  getSectors: getSectors(state),
  filesCount: getFilesCount(state),
  user: getUser(state.getUser),
  institutions: getInstitutions(state),
  isLocalAdmin: isLocalAdmin(state.getUser),
  optionsLoaded: getInstitutionOptionsLoaded(state.institutionOptions),
  institutionOptions: getInstitutionOptions(state.institutionOptions)
})

const mapDispatchToProps = dispatch => ({
  fetchSectorsFilesCount: bindActionCreators(fetchSectorsFilesCount, dispatch),
  setInstitutions: bindActionCreators(setInstitutions, dispatch),
  setUserManagedSectors: bindActionCreators(setUserManagedSectors, dispatch)
})

const InstitutionSectorsView = ({
  displayManagePresets, institutionOptions, institutions, isLocalAdmin, filesCount, optionsLoaded, user, fetchSectorsFilesCount,
  setInstitutions, setUserManagedSectors, t
}) => {
  const [rawSectors, setRawSectors] = useState([])
  const [archivedSectors, setArchivedSectors] = useState([])
  const [selectedSectorFiles, setSelectedSectorFiles] = useState(null)
  const [selectedSectorUsers, setSelectedSectorUsers] = useState(null)
  const [isArchivedSectorsModalVisible, setIsArchivedSectorsModalVisible] = useState(false)

  const currentInstitution = useMemo(() => institutions[0], [institutions])
  const sectors = useMemo(() => rawSectors.map(s => {
    s.fileCount = filesCount[s.id] ?? 0

    return s
  }), [rawSectors, filesCount])
  const useQuotasPerPause = useMemo(() => institutionOptions.reduce((acc, o) => {
    return o.optionType.type === QUOTA_PER_PAUSE && o.optionEnabled ? true : acc
  }, false), [institutionOptions])
  const columns = useMemo(() => {
    const columns = DEFAULT_COLUMNS.map(c => {
      if (c.name) c.name = t(c.name)

      return c
    })

    if (useQuotasPerPause) {
      columns.push({ type: DATA_TYPE_BOOLEAN, name: t('Per pause'), key: 'pausesEnabled' })
    }

    return columns
  }, [t, useQuotasPerPause])

  const setManagedSectors = useCallback(sectors => {
    if (isLocalAdmin) {
      setUserManagedSectors(sectors)
    }
  }, [isLocalAdmin, setUserManagedSectors])

  const sortSectorByArchived = useCallback((sectors, archived = false) => sectors.reduce((acc, s) => {
    if (s.archived) {
      acc.archived.push(s)
    } else {
      acc.notArchived.push(s)
    }

    return acc
  }, { archived: [], notArchived: [] }), [])

  useEffect(() => {
    Analytics.pageView('/institution-sectors')
  }, [])

  useEffect(() => {
    if (user) {
      let sectors = []

      if (isLocalAdmin && user.managedSectors) {
        sectors = user.managedSectors
      } else if (currentInstitution?.sectors) {
        sectors = currentInstitution.sectors
      }

      const { archived, notArchived } = sortSectorByArchived(sectors)

      setRawSectors(notArchived)
      setArchivedSectors(archived)
    }
  }, [user, currentInstitution, isLocalAdmin, sortSectorByArchived])

  useEffect(() => {
    if (user && currentInstitution && typeof fetchSectorsFilesCount === 'function') {
      fetchSectorsFilesCount(currentInstitution.id, user)
    }
  }, [user, currentInstitution, fetchSectorsFilesCount])

  const getSectorBody = useCallback((sector, create) => {
    const body = {
      institution: currentInstitution.id,
      name: sector.name,
      comment: sector.comment,
      emplacement: sector.emplacement,
      pausesEnabled: sector.pausesEnabled
    }

    if (create) {
      body.acronym = sector.name
      body.places = 0
      body.archived = false
      body.pausesEnabled = sector.pausesEnabled ?? false
    }

    return body
  }, [currentInstitution])

  const createSector = useCallback(sector => {
    createSectorOld(user, getSectorBody(sector, true)).then(json => {
      const sector = json.data

      currentInstitution.sectors = [...currentInstitution.sectors, sector]

      setInstitutions([{ ...currentInstitution }])
      setManagedSectors([...user.managedSectors, sector])
    }).catch(error => { generalErrorHandler(error) })
  }, [currentInstitution, user, setInstitutions, getSectorBody, setManagedSectors])

  const updateSector = useCallback(sector => {
    updateSectorOld(user, sector, getSectorBody(sector, false)).then(json => {
      const newSector = json.data

      currentInstitution.sectors = currentInstitution.sectors.map(s => s.id === newSector.id ? newSector : s)

      setInstitutions([{ ...currentInstitution }])
      setManagedSectors(user.managedSectors.map(s => s.id === newSector.id ? newSector : s))
    })
      .catch(error => { generalErrorHandler(error) })
  }, [currentInstitution, user, setInstitutions, getSectorBody, setManagedSectors])

  const archive = useCallback((sector, toArchive) => {
    archiveSector(user, sector, toArchive).then(json => {
      const newSector = json.data

      currentInstitution.sectors = currentInstitution.sectors.map(s => s.id === newSector.id ? newSector : s)

      setArchivedSectors([...archivedSectors, newSector])
      setInstitutions([{ ...currentInstitution }])
      setManagedSectors(user.managedSectors.map(s => s.id === newSector.id ? newSector : s))
    }).catch(error => { generalErrorHandler(error) })
  }, [archivedSectors, currentInstitution, user, setArchivedSectors, setInstitutions, setManagedSectors])

  const handleDeleteSector = useCallback(sector => {
    currentInstitution.sectors = currentInstitution.sectors.filter(s => s.id !== sector.id)

    setUserManagedSectors(user.managedSectors.filter(s => s.id !== sector.id))
    setInstitutions([{ ...currentInstitution }])
  }, [user.managedSectors, setUserManagedSectors, setInstitutions, currentInstitution])

  const additionalActions = useMemo(() => {
    const additionalActions = [
      { iconName: faUser, type: 'primary', title: t('Show care unit users'), onClick: s => setSelectedSectorUsers(s) },
      {
        iconName: faClock,
        type: 'primary',
        title: t('Manage presets'),
        onClick: s => {
          if (typeof displayManagePresets === 'function') {
            displayManagePresets(s)
          }
        }
      },
      {
        iconName: faFile,
        type: 'primary',
        title: t('Manage files'),
        customClassCallback: data => { return data.fileCount > 0 ? 'green-button' : '' },
        onClick: s => setSelectedSectorFiles(s)
      }
    ]

    if (user.roles.indexOf('ROLE_HOSPITAL_ADMIN') > -1) {
      additionalActions.push({ iconName: faBox, type: 'danger', title: t('Archive'), popconfirm: true, onClick: s => archive(s, true) })
    }

    return additionalActions
  }, [t, archive, displayManagePresets, setSelectedSectorFiles, setSelectedSectorUsers, user.roles])

  return (
    <div>
      <SmartTable
        columns={columns}
        data={sectors}
        loading={!optionsLoaded}
        onDataAdd={user.roles.indexOf('ROLE_HOSPITAL_ADMIN') > -1 ? createSector : undefined}
        onDataEdit={user.roles.indexOf('ROLE_HOSPITAL_ADMIN') > -1 ? updateSector : undefined}
        addDataText={t('Add a care unit')}
        noDataText={t('You do not have any care unit.')}
        additionalActions={additionalActions}
        archivedSectorsButton={
          <Tooltip placement='top' title={t('View archived care units')}>
            <Button onClick={() => setIsArchivedSectorsModalVisible(true)}>
              <FontAwesomeIcon icon='box' />&nbsp;
              {t('View archived care units')}
            </Button>
          </Tooltip>
        }
      />
      <FilesManager
        entity={selectedSectorFiles}
        entityName='Sector'
        onClose={() => setSelectedSectorFiles(null)}
        userContextFiltered
      />
      <SectorSectionUsers
        entity={selectedSectorUsers}
        onClose={() => setSelectedSectorUsers(null)}
      />
      <ArchivedSectorsModal
        institution={currentInstitution}
        visible={isArchivedSectorsModalVisible}
        onOk={() => setIsArchivedSectorsModalVisible(false)}
        onCancel={() => setIsArchivedSectorsModalVisible(false)}
        onDelete={handleDeleteSector}
        unarchive={archive}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSectorsView)
