import React, { Suspense } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../../routes'
import ChangeLogView from '../../Views/shared/ChangeLogView'
import InstitutionEvaluationSummaryView from '../../Views/institution/InstitutionEvaluationSummaryView'
import InstitutionStudentsView from '../../Views/institution/InstitutionStudentsView'
import InstitutionProvider from '../../Providers/InstitutionProvider'
import InstitutionGroupProvider from '../../Providers/InstitutionGroupProvider'
import SchoolsView from '../../Views/institutionGroup/SchoolsView/SchoolsView'
import InstitutionsView from '../../Views/institutionGroup/InstitutionsView/InstitutionsView'
import SectorsView from '../../Views/school/validator/SectorsView'
import InternshipsManagerProvider from '../../Context/InternshipsManager/InternshipsManagerProvider'
import MaccsProfilePage from '../../Components/MACCS/ProfilePage/MaccsProfilePage'
import MaccsInternshipsManagerView from '../../Views/MACCS/MaccsInternshipsManager/MaccsInternshipsManagerView'
import InstitutionGroupSettingsView from '../../Views/MACCS/InstitutionGroupSettings/InstitutionGroupSettingsView'
import ReportingView from '../../Views/MACCS/ReportingView/ReportingView'

const MaccsInstitutionGroupAdminRouter = ({ onLogout }) => {
  return (
    <InstitutionProvider>
      <InstitutionGroupProvider>
        <Switch>
          <Route path={routes.INTERNSHIPS}>
            <InternshipsManagerProvider>
              <MaccsInternshipsManagerView />
            </InternshipsManagerProvider>
          </Route>
          <Route path={routes.STUDENTS}>
            <InstitutionStudentsView />
          </Route>
          <Route path={routes.SETTINGS}>
            <InstitutionGroupSettingsView />
          </Route>
          <Route path={routes.BADGES}>
            <ReportingView />
          </Route>
          <Route path={routes.SCHOOLS}>
            <SchoolsView />
          </Route>
          <Route path={routes.INSTITUTIONS}>
            <InstitutionsView />
          </Route>
          <Route path={routes.INSTITUTION_SECTORS}>
            <SectorsView />
          </Route>
          <Route path={routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
          <Route path={routes.PROFILE_PAGE}>
            <MaccsProfilePage onLogout={onLogout} />
          </Route>
          <Route path={routes.STUDENTS_EVALUATION}>
            <Suspense fallback={<div>Loading...</div>}>
              <InstitutionEvaluationSummaryView />
            </Suspense>
          </Route>
        </Switch>
      </InstitutionGroupProvider>
    </InstitutionProvider>
  )
}

export default withRouter(MaccsInstitutionGroupAdminRouter)
