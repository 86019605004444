import React from 'react'

import { Button, Popover } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { callFunctionIfDefined } from '../../../utils/functionHelper'
import MessagesButton from './MessagesButton'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const HasUnreadMessagesBell = ({ loading, unreadMessagesCount, onClick, t }) => {
  const markAllAsRead = () => {
    callFunctionIfDefined(onClick)
  }

  return (
    <Popover
      placement='right'
      content={
        <Button
          loading={loading}
          onClick={markAllAsRead}
          type='primary'
        >
          {t('Mark all as read')}
        </Button>
      }
    >
      <div>
        <MessagesButton unreadMessagesCount={unreadMessagesCount} />
      </div>
    </Popover>
  )
}

export default connect(mapStateToProps)(HasUnreadMessagesBell)
