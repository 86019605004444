import { combineReducers } from 'redux'

import appStates from './AppStates/AppStates'
import contactPersons from './ContactPersonsReducer/ContactPersonsReducer'
import getAppBanner from './AppBannerReducer'
import getAppScroll from './AppScrollReducer'
import getDataReady from './DataReadyReducer'
import getInstitutions from './InstitutionsReducer'
import getSchools from './SchoolsReducer'
import getStudents from './StudentsReducer/StudentsReducer'
import getTheme from './ThemeReducer'
import getUser from './UserReducer'
import getChangelog from './ChangelogReducer'
import getEvaluationQuestions from './EvaluationQuestionsReducer'
import getFreeFields from './FreeFieldsReducer'
import getBadgesProperties from './BadgesReducer'
import schoolSections from './SchoolSectionsReducer/SchoolSectionsReducer'
import actTypeCategories from './ActTypeCategoriesReducer'
import institutionConstraints from './InstitutionConstraintsReducer/InstitutionConstraintsReducer'
import institutionOptionTypes from './InstitutionOptionTypesReducer/InstitutionOptionTypesReducer'
import institutionOptions from './InstitutionOptionsReducer/InstitutionOptionsReducer'
import institutionTemplates from './InstitutionTemplatesReducer/InstitutionTemplatesReducer'
import satisfactionRatioFilter from './SatisfactionRatioFilterReducer'
import shifts from './ShiftsReducer/ShiftsReducer'
import presets from './ShiftsPresetsReducer/ShiftsPresetsReducer'
import newMessages from './NewMessagesReducer'
import tags from './TagsReducer'
import pauseQuotas from './PauseQuotasReducer/PauseQuotasReducer'
import profileDatas from './ProfilPageReducer'
import sectors from './SectorsReducer/SectorsReducer'
import quotas from './QuotasReducer/QuotasReducer'
import internships from './InternshipsReducer/InternshipsReducer'
import { localeReducer as locale } from 'react-localize-redux'
import shiftsManager from './ShiftsManagerReducer/ShiftsManagerReducer'
import studentsExtraInformations from './StudentsExtraInfos'
import internshipsManager from './InternshipsManagerReducer/InternshipsManagerReducer'
import schoolAssistants from './SchoolAssistantsReducer'
import MaccsInternshipsManager from './MaccsInternshipsManager'

export default combineReducers({
  actTypeCategories: actTypeCategories,
  appStates: appStates,
  contactPersons: contactPersons,
  getAppBanner: getAppBanner,
  getAppScroll: getAppScroll,
  getDataReady: getDataReady,
  getInstitutions: getInstitutions,
  getSchools: getSchools,
  getStudents: getStudents,
  getTheme: getTheme,
  getUser: getUser,
  getChangelog: getChangelog,
  getEvaluationQuestions: getEvaluationQuestions,
  getFreeFields: getFreeFields,
  getBadgesProperties: getBadgesProperties,
  institutionConstraints: institutionConstraints,
  institutionOptionTypes: institutionOptionTypes,
  institutionOptions: institutionOptions,
  institutionTemplates: institutionTemplates,
  internships: internships,
  internshipsManager: internshipsManager,
  maccsInternshipsManager: MaccsInternshipsManager,
  newMessages: newMessages,
  profileDatas: profileDatas,
  sectors: sectors,
  quotas: quotas,
  satisfactionRatioFilters: satisfactionRatioFilter,
  shifts: shifts,
  pauseQuotas: pauseQuotas,
  presets: presets,
  shiftsManager: shiftsManager,
  studentsExtraInformations: studentsExtraInformations,
  tags: tags,
  schoolSections: schoolSections,
  locale: locale,
  schoolAssistants
})
