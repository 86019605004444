export const SCHOOL_ASSISTANTS = {
  SET_ASSISTANT: 'SCHOOL_ASSISTANTS_SET_ASSISTANT',
  SET_ASSISTANTS: 'SCHOOL_ASSISTANT_SET_ASSISTANTS',
  REMOVE_ASSISTANT: 'SCHOOL_ASSISTANT_REMOVE_ASSISTANT',
  SET_SCHOOL_SPECIALITIES: 'SCHOOL_ASSISTANTS_SET_SCHOOL_SPECIALITIES',
  SET_ASSISTANT_PROFILE_IMAGE: 'SCHOOL_ASSISTANTS_SET_ASSISTANT_PROFILE_IMAGE',
  SET_EXTRA_CONTACT_INFO: 'SCHOOL_ASSISTANTS_SET_EXTRA_CONTACT_INFO',
  SET_OPTIN: 'SCHOOL_ASSISTANTS_SET_OPTIN',
  UPDATE_ASSISTANT: 'UPDATE_ASSISTANT'
}
