import React, { createContext, useEffect, useMemo, useState } from 'react'

import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import { getAllEventCodes } from '../utils/api/eventCode'
import { getAllLanguages } from '../utils/api/language'
import { getUserOptins } from '../utils/api/user'
import moment from 'moment'
import { MACCS_CONTEXT, NONE_CONTEXT, NURSE_ENVIRONMENT, NURSES_CONTEXT, ROLE_ADMIN, ROLE_UNKNOW } from '../utils/constants'
import { getAllSpecialities } from '../utils/api/speciality'
import { getProfileImage } from '../utils/api/users'
import { HTTP_NOT_FOUND } from '../utils/constants/http'
import { onError } from '../utils/apiHelper'
import { getConstants } from '../utils/api/global'
import { arrayToObject } from '../utils'
import { CARPOOL_OPTIN_TEXTS, CARPOOL_TYPE, DATA_SHARING_TEXTS, DATA_SHARING_TYPE, UNSUPPORTED_TYPE } from '../utils/entities/optin'
import useLocalStorage from '../hooks/UseLocalStorage'
import { getAllAreas } from '../utils/api/area'

const ENVIRONMENT_KEY = 'global_provider.environment'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const INVALID_STATUS = 0
const VALID_STATUS = 1

export const GlobalContext = createContext()

const GlobalProvider = ({ children, currentLanguage, user, t }) => {
  const [currentDate, setCurrentDate] = useState(moment().startOf('day'))
  const [countries, setCountries] = useState([])
  const [environment, setEnvironment] = useLocalStorage(ENVIRONMENT_KEY, NURSE_ENVIRONMENT)
  const [eventCodes, setEventCodes] = useState({ data: [], status: VALID_STATUS })
  const [institutions, setInstitutions] = useState([])
  const [languages, setLanguages] = useState([])
  const [optins, setOptins] = useState([])
  const [pauses, setPauses] = useState([])
  const [specialities, setSpecialities] = useState([])
  const [schoolYears, setSchoolYears] = useState([])
  const [sections, setSections] = useState([])
  const [sectionToYears, setSectionToYears] = useState([])
  const [studentMetadata, setStudentMetadata] = useState([])
  const [tutorials, setTutorials] = useState([])
  const [unSeenChangelogs, setUnseenChangelogs] = useState([])
  const [areas, setAreas] = useState([])

  const [loading, setLoading] = useState(true)
  const [profileImage, setProfileImage] = useState(null)

  const nursesMode = useMemo(() => NURSES_CONTEXT.includes(user.context), [user.context])
  const context = useMemo(() => user?.context ?? NONE_CONTEXT, [user?.context])
  const currentRole = useMemo(() => user?.roles?.[0] ?? ROLE_UNKNOW, [user?.roles])
  const sectionsObject = useMemo(() => arrayToObject(sections, 'id'), [sections])
  const sectionToYearsBySection = useMemo(() => {
    const sectionToYearsBySection = {}

    sectionToYears.forEach(sty => {
      const sectionId = sty.section === null ? -1 : sty.section.id

      if (!sectionToYearsBySection[sectionId]) {
        sectionToYearsBySection[sectionId] = []
      }

      sectionToYearsBySection[sectionId].push(sty)
    })

    return sectionToYearsBySection
  }, [sectionToYears])

  useEffect(() => {
    if (MACCS_CONTEXT.includes(user.context)) {
      setCurrentDate(moment().startOf('month').hours(0).minutes(0).seconds(0))
    } else {
      setCurrentDate(moment().startOf('isoWeek').hours(0).minutes(0).seconds(0))
    }
  }, [user.context])

  useEffect(() => { getAllLanguages().then(setLanguages) }, [])

  useEffect(() => {
    if (user?.id) {
      getAllAreas(user).then(json => {
        if (json?.data) {
          setAreas(json.data)
        }
      })
    }
  }, [user.id])

  useEffect(() => {
    if (user?.id) {
      setLoading(true)

      getConstants(user).then(json => {
        if (json?.data) {
          setEventCodes({ data: json.data.eventCodes, status: VALID_STATUS })
          setInstitutions(json.data.institutions)
          setOptins(getOptinsType(json.data.optins))
          setPauses(json.data.pauses)
          setSections(json.data.sections.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation)))
          setSectionToYears(json.data.sectionToYears)
          setCountries(json.data.countries)
          setSchoolYears(json.data.schoolYears)
          setStudentMetadata(json.data.studentMetadata)
          setTutorials(getTutorialsByUrl(json.data.tutorials))

          if (!user.roles.includes(ROLE_ADMIN)) {
            setUnseenChangelogs(json.data.changelogs)
          }
        }

        setLoading(false)
      })

      const promiseStack = []

      if (MACCS_CONTEXT.includes(user.context)) {
        promiseStack.push(getAllSpecialities(user).then(specialities => {
          if (specialities) {
            setSpecialities(specialities)
          }
        }))
      }

      if (user.profileImageName && !user.roles.includes('ROLE_ASSISTANT')) {
        getProfileImage(user).then(img => {
          if (img) {
            setProfileImage(img)
          }
        }).catch(e => {
          if (e.status !== HTTP_NOT_FOUND) {
            onError(t('Impossible to retrieve your profile picture'))
          }

          setProfileImage(null)
        })
      }

      Promise.all(promiseStack).then(() => setLoading(false))
    }

    return () => {
      setLoading(true)
    }
  }, [user.id])

  useEffect(() => {
    if (eventCodes.status === INVALID_STATUS) {
      getAllEventCodes(user).then(data => setEventCodes({ data, status: VALID_STATUS }))
    }
  }, [user.id, eventCodes.status])

  const getTutorialsByUrl = tutorials => {
    const tutorialsByUrl = {}

    tutorials.forEach(t => {
      tutorialsByUrl[t.link] = t
    })

    return tutorialsByUrl
  }

  const getOptinsType = optins => {
    return optins.map(o => {
      if (DATA_SHARING_TEXTS.includes(o.text)) {
        o.type = DATA_SHARING_TYPE
      } else if (CARPOOL_OPTIN_TEXTS.includes(o.text)) {
        o.type = CARPOOL_TYPE
      } else {
        o.type = UNSUPPORTED_TYPE
      }

      return o
    })
  }

  const fetchOptins = user => {
    return getUserOptins(user).then(json => {
      if (json?.data) {
        setOptins(getOptinsType(json.data))
      }
    })
  }

  const fetchOptinsWithLoading = user => {
    setLoading(true)

    return fetchOptins(user).then(() => setLoading(false))
  }

  return (
    <GlobalContext.Provider
      value={{
        context,
        countries,
        currentDate,
        currentRole,
        globalLoading: loading,
        environment,
        eventCodes: eventCodes.data,
        institutions,
        languages,
        nursesMode,
        optins,
        specialities,
        profileImage,
        sections,
        pauses,
        schoolYears,
        sectionsObject,
        sectionToYears,
        sectionToYearsBySection,
        studentMetadata,
        tutorials,
        unSeenChangelogs,
        loading,
        fetchOptins: fetchOptinsWithLoading,
        invalidateEventCodes: () => setEventCodes({ ...eventCodes, status: INVALID_STATUS }),
        setCurrentDate,
        setEnvironment,
        setUnseenChangelogs,
        setTutorials,
        setInstitutions,
        areas
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default connect(mapStateToProps)(GlobalProvider)
