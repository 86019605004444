import { requestWithPromise } from '..'
import { GET, PATCH, makeQueryString } from '../apiHelper'

export const getInternshipConvention = (internship, user) => {
  const parameters = { type: 'convention', orderBy: 'updatedAt', orderDirection: 'desc' }

  return requestWithPromise(
    `/api/internships/${internship.id}/documents${makeQueryString(parameters)}`,
    GET,
    null,
    user
  ).then(json => json?.data[0] ?? null)
}

export const updateInternshipDocument = (document, user) => {
  return requestWithPromise(
    `/api/InternshipDocument/${document.id}`,
    PATCH,
    { locked: true },
    user
  )
}
