import React, { useState } from 'react'

import { INTERNSHIP_STATES_STRING, SCHOOL_CONTEXT } from '../../../utils/constants'
import { Select } from 'antd'
import { callFunctionIfDefined } from '../../../utils/functionHelper'
import StringStateOptionBody from './StringStateOptionBody'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'

const OptGroup = Select.OptGroup
const Option = Select.Option

const {
  ACCEPTED, CANCELED, PENDING, PRE_ACCEPTED, REFUSED, SCHEDULE_ADDED,
  TO_VALIDATE, VALIDATED, WITHOUT_INSTITUTION
} = INTERNSHIP_STATES_STRING
const DEFAULT_OPTIONS = [
  {
    key: 'accepted',
    value: ACCEPTED,
    text: 'Accepted'
  },
  {
    key: 'schedule-added',
    value: SCHEDULE_ADDED,
    text: 'Accepted, schedule added'
  },
  {
    key: 'schedule-validated',
    value: VALIDATED,
    text: 'Accepted, schedule validated'
  },
  {
    key: 'schedule-to-validate',
    value: TO_VALIDATE,
    text: 'Accepted, schedule to validate'
  },
  {
    key: 'refused',
    value: REFUSED,
    text: 'Refused'
  },
  {
    key: 'canceled',
    value: CANCELED,
    text: 'Canceled'
  },
  {
    key: 'pending',
    value: PENDING,
    text: 'Pending'
  },
  {
    key: 'pre-accepted',
    value: PRE_ACCEPTED,
    text: 'Pre-accepted'
  }
]

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const StringStatesSelector = ({ user, value, onOptionSelect, t }) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false)

  const handleOptionSelect = e => {
    callFunctionIfDefined(onOptionSelect, [e])
  }

  const renderOptions = () => {
    const options = [
      <Option key='select-all' value={null}>
        {t('All internships')}
      </Option>
    ]

    DEFAULT_OPTIONS.forEach(option => {
      options.push(
        <Option key={option.key} value={option.value}>
          <StringStateOptionBody
            state={option.value}
            text={t(option.text)}
          />
        </Option>
      )
    })

    if (user.context === SCHOOL_CONTEXT) {
      options.push(
        <Option key='without-institution' value={WITHOUT_INSTITUTION}>
          <StringStateOptionBody
            state={WITHOUT_INSTITUTION}
            text={t('Without institution')}
          />
        </Option>
      )
    }

    return (
      <OptGroup label={`${t('Filter internships')} : `} listHeight={600}>
        {options}
      </OptGroup>
    )
  }

  const getDivStyle = () => {
    return {
      width: 210,
      zIndex: selectIsOpen ? 9999 : 'initial'
    }
  }

  const getSelectStyle = () => {
    return { width: selectIsOpen ? 250 : 210 }
  }

  return (
    <div
      style={getDivStyle()}
    >
      <Select
        defaultValue={null}
        value={value}
        onChange={handleOptionSelect}
        onDropdownVisibleChange={setSelectIsOpen}
        style={getSelectStyle()}
        dropdownClassName='internships-state-filter'
      >
        {renderOptions()}
      </Select>
    </div>
  )
}

export default connect(mapStateToProps)(StringStatesSelector)
