import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import {
  INSTITUTION_MACCS_CONTEXT, ORDER_BY_ASSISTANT, ORDER_BY_CREATED_AT,
  ORDER_BY_INSTITUTION, ORDER_BY_SCHOOL, ORDER_BY_SCHOOL_SPECIALITY,
  ORDER_BY_START_DATE, SCHOOL_MACCS_CONTEXT
} from '../../../utils/constants'
import OrderBySelector from '../../shared/OrderBySelector'

const SELECT_SORTING_LABEL_TEXT = 'Order internships by:'
const DEFAULT_OPTIONS = [
  { key: 'creation-date', value: ORDER_BY_CREATED_AT, text: 'Creation date' },
  { key: 'start-date', value: ORDER_BY_START_DATE, text: 'Start date' },
  { key: 'assistant', value: ORDER_BY_ASSISTANT, text: 'Assistants' },
  { key: 'school-speciality', value: ORDER_BY_SCHOOL_SPECIALITY, text: 'Specialities' }
]
const INSTITUTION_ADDITIONAL_OPTION = {
  key: 'school', value: ORDER_BY_SCHOOL, text: 'Schools'
}
const SCHOOL_ADDITIONAL_OPTION = {
  key: 'institution', value: ORDER_BY_INSTITUTION, text: 'Institutions'
}

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsInternshipStatesOrderBy = props => {
  const [options, setOptions] = useState([...DEFAULT_OPTIONS])

  useEffect(() => {
    if (typeof props.t === 'function' && props.user) {
      setOptions(buildOptions(props.user.context, props.t))
    }
  }, [props.t, props.user.context])

  const buildOptions = (context, translate) => {
    const additionalOption = getAdditionalOptionByContext(context)
    const options = DEFAULT_OPTIONS.map(option => {
      return { ...option, text: translate(option.text) }
    })

    if (additionalOption !== null) {
      options.push({ ...additionalOption, text: translate(additionalOption.text) })
    }

    return options
  }

  const getAdditionalOptionByContext = context => {
    if (context === SCHOOL_MACCS_CONTEXT) {
      return SCHOOL_ADDITIONAL_OPTION
    }

    if (context === INSTITUTION_MACCS_CONTEXT) {
      return INSTITUTION_ADDITIONAL_OPTION
    }

    return null
  }

  return (
    <OrderBySelector
      options={options}
      label={SELECT_SORTING_LABEL_TEXT}
      value={props.value}
      defaultValue={ORDER_BY_CREATED_AT}
      onChange={props.onChange}
      onDirectionChange={props.onDirectionChange}
    />
  )
}

export default connect(mapStateToProps)(MaccsInternshipStatesOrderBy)
